import HttpService from "../../common/services/HttpService";
import PointModel from "../models/PointModel";

class PointsService {
    static instance = new PointsService();
    
    async getUserPointsAsync(userId, options = { startDate: null, endDate: null }) {
        const path = "/api/admin/user/" + userId + "/point";
        const sd = options?.startDate || null;
        const ed = options?.endDate || null;
        const rsp = await HttpService.instance.getWithDateRangeAsync(path, sd, ed);
        
        return PointModel.fromJsonArray(rsp?.data);
    }
}

export default PointsService;
