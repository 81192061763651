import React from 'react';
import ChallengeMasterScreen from "./ChallengeMasterScreen";
import {useParams} from "react-router-dom";

const ChallengeEntryDetailsScreen = (props) => {
    const {} = props;
    const { challengeEntryId } = useParams();
    
    const onChallenge = (challenge) => {
        console.log("On Challenge Entries: ", challenge.name);
    };
    
    return (<ChallengeMasterScreen onChallenge={onChallenge} selection={"entries"}>
        <div className={"pad constrain"}>
            <h2>Challenge Entry Details</h2>
        </div>
    </ChallengeMasterScreen>);
};

export default ChallengeEntryDetailsScreen;
