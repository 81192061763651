import React from 'react';
import FuelMasterScreen from "./FuelMasterScreen";

const FuelSettingsScreen = (props) => {
    const {} = props;

    return (<FuelMasterScreen>
        <div className={"pad"}>
            <h2>Fuel Settings</h2>
            
        </div>
    </FuelMasterScreen>);
};

export default FuelSettingsScreen;

