import UserModel from "../../users/models/UserModel";
import ImageModel from "../../media/models/ImageModel";
import VideoViewModel from "../../media/models/VideoViewModel";

class ArticleModel {
    static entityType = 59;
    static create = (json) => new ArticleModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.userId = json.user_id || null;
        this.author = new UserModel(json.author);

        this.image = new ImageModel(json.image);
        this.imageId = json.image_id || null;

        this.video = !!json?.video?.url ? new VideoViewModel(json.video) : null;
        this.videoId = json.video_id || null;

        this.contentType = json.content_type || null;
        this.title = json.title || null;
        this.webUrl = json.web_url || null;
        this.url = json.url || null;
        this.content = json.content || "";

        this.status = json.status || 0;
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            status: this.status,
            video_id: this.videoId,
            image_id: this.imageId,
            content_type: this.contentType,
            title: this.title,
            content: this.content,
            web_url: this.webUrl,
            url: this.url,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.contentType?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.title?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.webUrl?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.url?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => ArticleModel.create(json));
    }
}

export default ArticleModel;
