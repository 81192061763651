import React from 'react';
import {Link} from "react-router-dom";
import {faBowlRice, faEdit} from "@fortawesome/free-solid-svg-icons";
import DefReset from "../../common/models/DefReset";

const ChallengeSubMenu = (props) => {
    const { selection, challenge } = props;
    const classes = {};
    
    classes[selection || "overview"] = "selected";

    const basePath = "/challenges/" + challenge?.id + "/";
    const elements = ChallengeSubMenu.menuItems.map((m, i) => {
        const key = m.path || "";
        return (<li key={"menu-item-" + i} className={classes[key || "overview"] || ""}><Link to={basePath + key}>
            {m.caption}
        </Link></li>);
    });

    return (<div className={"sub-menu"} id={"challenge-menu"}>
        <ul className={"sub-menu"}>
            {elements}
        </ul>
    </div>);
};

ChallengeSubMenu.menuItems = [
    { path: "", caption: "Details" },
    { path: "entries", caption: "Challenge Entries", icon: faBowlRice, description: "" },
    { path: "edit", caption: "Edit Challenge", icon: faEdit, description: "" },
];

export default ChallengeSubMenu;
