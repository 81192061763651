import HttpService from "../../common/services/HttpService";
import MetricModel from "../models/MetricModel";
import DashboardModel from "../models/DashboardModel";
import GraphItemModel from "../models/GraphItemModel";
import PlatformMetricsModel from "../models/PlatformMetricsModel";

class MetricService { 
    static instance = new MetricService();

    constructor() {
        this.metricsMap = {};
        this.metrics = [];
        this.metricMap = {};
        
        this.dashboardMap = {};
        this.userMetricsMap = {};
        
        this.platformMetrics = null;
    }

    /***
     * Get the dashboard for the given key.
     * @param options{startDate: Date|null, endDate: Date|null, dashboardKey: string|null}
     * @returns {Promise<DashboardModel>}
     */
    async getDashboardAsync(options) {
        let dashboardKey = options?.key || "";

        const path = "/api/admin/dashboard/" + dashboardKey;
        if (!dashboardKey) dashboardKey = "home";

        const startDate = options?.startDate || null;
        const endDate = options?.endDate || null;

        const rsp = await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate);
        const json = rsp?.data;

        if (!!json) {
            const dashboard = new DashboardModel(json);
            this.dashboardMap[dashboardKey] = dashboard;
            return dashboard;
        }
    }
    
    async getPlatformMetricsAsync(options) {
        const path = "/api/metric";
        const startDate = options?.startDate || null;
        const endDate = options?.endDate || null;

        const rsp = await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate);
        const json = rsp?.data;
        
        this.platformMetrics = new PlatformMetricsModel(json);
        
        return this.platformMetrics;
    }
    
    async getUserCountsAsync(options) {
        const path = "/api/metric/user-count";
        const startDate = options?.startDate || null;
        const endDate = options?.endDate || null;

        const rsp = await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate);
        const json = rsp?.data;

        return GraphItemModel.fromJsonArray(json);
    }
    
    async getScreenViewsAsync(options) {
        const path = "/api/metric/screen-view-count";
        const startDate = options?.startDate || null;
        const endDate = options?.endDate || null;

        const rsp = await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate);
        const json = rsp?.data;

        return GraphItemModel.fromJsonArray(json);
    }

    async getUserDashboardAsync(userId, options) {
        const path = "/api/admin/dashboard/user/" + userId;
        const startDate = options?.startDate || null;
        const endDate = options?.endDate || null;

        const rsp = await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate);
        const json = rsp?.data;

        if (!!json) {
            const dashboard = new DashboardModel(json);
            this.userMetricsMap[userId] = dashboard;
            return dashboard;
        }

        return null;
    }
    
    async getMetricAsync(metricId) {
        const path = '/api/metric/' + metricId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.metricMap[metricId] = new MetricModel(response.data);
            return me.metricMap[metricId];
        });
    }

    async getMetricsAsync() {
        const path = "/api/metric";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.metrics = response.data.map((metric) => {
                return new MetricModel(metric);
            });

            return me.metrics;
        });
    }
}

export default MetricService;
