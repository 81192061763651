import ImageModel from "../../media/models/ImageModel";
import FoodModel from "./FoodModel";
import MealFoodModel from "./MealFoodModel";

class MealModel {
    static entityType = 43;
    static create = (json) => new MealModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.imageId = json.image_id || null;
        this.name = json.name || null;
        this.image = !!json.image?.url ? new ImageModel(json.image) : null;
        this.description = json.description || null;
        this.calories = json.calories || 0;
        this.status = json.status || 0;
        this.foods = MealFoodModel.fromJsonArray(json.foods);
        this.media = ImageModel.fromJsonArray(json.media);
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            image_id: this.imageId,
            name: this.name,
            description: this.description,
            calories: this.calories,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.name?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.description?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.calories?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => MealModel.create(json));
    }
}

export default MealModel;
