import React, {useEffect, useState} from 'react';
import UserService from "../services/UserService";
import DefReset from "../../common/models/DefReset";
import MetricService from "../../metrics/services/MetricService";
import DateTime from "../../common/ui/DateTime";
import EmptyBox from "../../common/ui/EmptyBox";
import LoadingBox from "../../common/ui/LoadingBox";
import SearchFilter from "../../common/ui/search-filter/SearchFilter";
import DatePicker from "../../common/ui/date-picker/DatePicker";
import DebugJson from "../../common/ui/DebugJson";
import ReactModal from "../../common/ui/react-modal/ReactModal";

const UserScreenViews = (props) => {
    const { userId, options:initialOptions } = props;
    
    const [screenState, setScreenState] = useState({ 
        id: 0,
        allItems: null,
        items : null,
        term: null,
        options: initialOptions || { startDate: new Date().toDate(), endDate: new Date() },
        lastCount: -1,
    });
    
    const setScreenViews = (items) => {
        const newState = {...screenState};
        newState.items = items;
        setScreenState(newState);
    };

    const getScreenViewsAsync = async (force = false, options = null) => {
        const screenViews = screenState.allItems;

        if (Array.isArray(screenViews) && screenViews.length > 0 && screenState.items != null && !force) 
            return screenViews;

        options ??= screenState.options || {};
        const items = await UserService.instance.getUserScreenViewsAsync(userId, options);
        
        if (!Array.isArray(items)) return;

        const newState = {...screenState};
        newState.items = items;
        newState.lastCount = items.length;
        
        if (!newState.allItems) newState.allItems = items;
        
        setScreenState(newState);
    };

    const refresh = (e) => {
        DefReset.stopEvent(e);
        setScreenViews(null);
    };

    const onSearchType = (term, items) => {
        console.log("Item Count: ", items?.length);
        console.log("All Count: ", screenState.allItems);

        if (screenState.lastCount !== items?.length) {
            const newState = {...screenState};
            newState.items = items;
            newState.lastCount = items.length;
            newState.term = term;
            
            setScreenState(newState);
        }
    }

    const onSearchAsync = async (term) => {
        console.log("Search Term: ", term);
        
        return await getScreenViewsAsync(true, {...screenState, term: term});
    }

    const onDateChanged = async (startDate, endDate) => {
        //
    };

    const showJsonAsync = async (item, e) => {
        DefReset.stopEvent(e);
        
        const dialogOptions = { title: "Toast", hasCancelButton: false };
        const jsonElement = (<DebugJson value={item} title={item.screenName} />);
        
        const dialog = new ReactModal();
        await dialog.openFormDialogAsync(jsonElement, () => {
            return true;
        }, dialogOptions);
    };
    
    const onDateClicked = async (startDate, endDate) => {
        const newState = {...screenState};
        newState.options ??= {};
        newState.options.startDate = startDate;
        newState.options.endDate = endDate;
        newState.items = null;
        
        setScreenState(newState);
    };
    
    const setControllers = () => {
        //
    };

    useEffect(() => {
        const x = setControllers();
        let _;
        
        if (!screenState?.allItems || screenState.items == null)
            _ = getScreenViewsAsync();

        return x;
    }, [screenState]);

    const elements = Array.isArray(screenState.items) ? screenState.items.map((item, i) => {
        //
        return (<tr key={"item-" + i}>
            <td><a onClick={(e) => showJsonAsync(item, e)}>{item.screenName}</a></td>
            <td>{item.deviceTypeName}</td>
            <td>{item.version}</td>
            <td><DateTime time={true} value={item.created} /></td>
        </tr>);
    }) : screenState.allItems === null ? (<tr><td></td><td><LoadingBox>Loading Screen Views</LoadingBox></td><td></td></tr>) : (<tr><td colSpan={3}><EmptyBox /></td></tr>);
    
    return (<div className={"user-screen-views screen-views"}>
        <div className={"date-search"}>
            <div><SearchFilter fields={["screenName"]} items={screenState?.allItems} onChange={(e, items) => onSearchType(e, items)} onClick={onSearchAsync} /></div>
            <div style={{flex: "4"}}></div>
            <div><DatePicker
                startDate={screenState.options?.startDate}
                endDate={screenState.options?.endDate}
                onChange={onDateChanged}
                onClick={onDateClicked}
            /></div>
        </div>
        
        <table className={"table"}>
            <thead>
            <tr onClick={refresh}>
                <th>Screen</th>
                <th>Device</th>
                <th>App Version</th>
                <th>Date</th>
            </tr>
            </thead>
            
            <tbody>{elements}</tbody>
        </table>
    </div>);
};

export default UserScreenViews;

