import React, {useEffect, useState} from 'react';
import UserMasterScreen from "./UserMasterScreen";
import UserService from "../../components/users/services/UserService";
import {Link, useParams} from "react-router-dom";
import SearchFilter from "../../components/common/ui/search-filter/SearchFilter";
import DatePicker from "../../components/common/ui/date-picker/DatePicker";
import HabitService from "../../components/habits/services/HabitService";
import DateTime from "../../components/common/ui/DateTime";
import LoadingBox from "../../components/common/ui/LoadingBox";
import UserHabitsTable from "../../components/users/ui/UserHabitsTable";

const PersonHabitsScreen = (props) => {
    const {} = props;
    const { userId } = useParams();
    const [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    const options = useState({})[0];
    const onUser = (u) => {
        setUser(u);        
    };
    
    useEffect(() => {
        if (!!user?.id && typeof options?.setTitle == "function")
            options.setTitle(user?.name);
    }, [user]);

    useEffect(() => {
        const id = Math.round(Math.random() * 999999).toString();
    }, []);


    return (
        <UserMasterScreen user={user} options={options} onUser={onUser} selection={"habits"}>
            <div className={"pad constrain"}>
                <h2>Habits</h2>
                <UserHabitsTable userId={userId} />
            </div>
        </UserMasterScreen>
    );

};

export default PersonHabitsScreen;