import React, {useEffect, useState} from 'react';
import SearchFilter from "../../common/ui/search-filter/SearchFilter";
import FoodService from "../services/FoodService";
import LoadingBox from "../../common/ui/LoadingBox";
import "./ingredient-list-selector.css";
import DefReset from "../../common/models/DefReset";

const IngredientListSelector = (props) => {
    const { onSelected } = props;
    const [screenState, setScreenState] = useState({ isVisible: false, term: null, items: FoodService.instance.foods || null });
    const filterOptions = useState({})[0];
    const ingredients = FoodService.instance.foods || null;

    const onSearchChange = (term, items) => {
        if (document.activeElement?.tagName === "BODY") {
            return;
        }
        
        const newState = {...screenState, items: items, term: term };
        newState.isVisible = document.activeElement?.id === "filter-term";
        
        setScreenState(newState);
    };

    const getFoodsAsync = async (force = false) => {
        let foods = FoodService.instance.foods;
        
        if (Array.isArray(foods) && foods.length > 0 && force !== true)
            return foods;

        foods = await FoodService.instance.getFoodsAsync();
        
        if (!Array.isArray(foods)) return;
        
        const newState = {
            ...screenState,
            items: foods
        };
        
        setScreenState(newState);
        
        return foods;
    };
    
    const onFieldClick = (e) => {
        console.log("Id: ", document.activeElement?.id);
        console.log(document.activeElement?.tagName, document.activeElement)
    };
    
    const onFocusChange = (isFocus, e) => {
        const isVisible = isFocus === true || !!screenState.term;
        const newState = { ...screenState };
        const delay = isFocus !== true && !screenState.term;
        
        console.log("FocusChange: ", isFocus, isVisible, e);
        
        newState.isVisible = isVisible || isFocus;
        
        if (delay !== true) {
            setScreenState(newState);
            return;
        }
        
        const id = setTimeout(() => {
            clearTimeout(id);
            setScreenState(newState);
        }, 150);
    };
    
    const onItemSelected = (food, e) => {
        DefReset.stopEvent(e);
        console.warn("onItemSelected: ", e);
        
        const id = setTimeout(() => {
            clearTimeout(id);
            
            if (typeof filterOptions.setText === "function")
                filterOptions.setText("");
            
            const newState = {
                ...screenState,
                isVisible: false,
                selectedItem: food
            };
            
            setScreenState(newState);
        }, 300);
        
        return false;
    };

    useEffect(() => {
        if (!!screenState.selectedItem?.id) {
            if (typeof onSelected === "function")
                onSelected(screenState.selectedItem);
            
            delete screenState.selectedItem;
        }
        
    }, [screenState]);
    
    useEffect(() => {
        let _ = getFoodsAsync();
    }, []);
    
    const elements = Array.isArray(screenState.items) ? 
        screenState.items.map((item, index) => {
            const imageElement = !!item.image?.url ?
                (<img src={item.image.getThumb("256", true)} className={"thumb"} />) :
                (<span className={"thumb"}>No Image</span>);
            
            return (<div className={"food-selection"} key={"ingredient-result-" + index} title={JSON.stringify(item, null, 4)} onClick={(e) => onItemSelected(item, e)}>
                <div>
                    <span>{imageElement}</span>
                    <span>{item.name}</span>
                </div>
            </div>);
        }) :
        (<LoadingBox />);
    
    const resultStyle = {};
    
    if (screenState.isVisible !== true)
        resultStyle.display = "none";
    
    return (<div className={"ingredient-list-selector"}>
        <div style={{display: "inline-block", minWidth: "50%"}}>
            <SearchFilter placeholder={"Search Ingredients"} onChange={onSearchChange} onFieldClick={onFieldClick} onFocusChange={onFocusChange} items={ingredients} options={filterOptions} />
            <div className={"hover-container"} style={resultStyle}>
                <div className={"hover-results"}>{elements}</div>
            </div>
        </div>
    </div>);
};

export default IngredientListSelector;
