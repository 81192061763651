import UserModel from "../../users/models/UserModel";

class SessionModel {
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        this.user = new UserModel(json.user);
        this.userId = json.user_id || null;
        this.created = json.created ? new Date(json.created) : null;
        this.modified = json.modified ? new Date(json.modified) : null;
    }
}

export default SessionModel;
