'use client'

import React, { useEffect, useRef, useState } from 'react';
import MessageService from '../services/MessageService';
import MessageEditorForm from './MessageEditorForm';
import ReactModal from "../../common/ui/react-modal/ReactModal";
import AppEngine from "../../common/models/DefReset";
import DateTime from "../../common/ui/DateTime";
import LoadingBox from "../../common/ui/LoadingBox";
import {Link} from "react-router-dom";

const MessagesTable = (props) => {
    const { onClick, options } = props;
    const [screenState, setScreenState] = useState();

    const getTemplatesAsync = async (force = false) => {
        if (!force && MessageService.instance.messageTemplates?.length > 0) return;

        const templates = await MessageService.instance.getMessageTemplatesAsync();
        const newState = { ...screenState };

        if (templates?.length >= 0) newState.templates = templates;
        setScreenState(newState);
    };

    const refresh = (e) => {
        AppEngine.stopEvent(e);
        let _ = getTemplatesAsync(true);
    };

    const showMessage = async (template, e) => {
        const options = { buttonCaption: "Save Message Template"};
        const editorOptions = { useButton: false };
        const dialog = new ReactModal();
        const messageForm = (<MessageEditorForm id={template?.id} value={template} options={editorOptions} />);
        const templateId = template?.id || null;

        await dialog.openFormDialogAsync(messageForm, async (e) => {
            console.log("Save Template: ", e);
            AppEngine.stopEvent(e);

            if (typeof editorOptions?.getJson === "function") {
                const json = editorOptions.getJson();

                if (!json) {
                    console.error("Failed. No json data: ", json);
                    return;
                }

                const updatedTemplate = await MessageService.instance.saveMessageTemplateAsync(json, templateId).catch((err) => {
                    handleError(err);
                    return null;
                });

                if (!updatedTemplate?.id) return false;

                await getTemplatesAsync(true);

                return true;
            } else {
                alert("No getData function found in options object");
            }

            return false;
        }, options);
    };

    const handleError = (err) => {
        const newState = { ...screenState };
        newState.error = err?.response?.data?.message || err?.message || err?.toString() || "Unknown Error";
        setScreenState(newState);

        return null;
    };

    const stripHtml = (html = "") => {
        const doc = new DOMParser().parseFromString(html + "", 'text/html');
        return doc.body.textContent || "";
    };

    const createLineItem = (item, index) => {
        let preview = stripHtml(item.message);
        let subject = stripHtml(item.subject) || "(No Subject)";

        if (preview.length > 100) preview = preview.substring(0, 100) + "...";

        return (<tr key={"table-line-item-" + index} className={"message-template"}>
            <td><Link onClick={(e) => showMessage(item, e)} to={"#templates-" + item.id}>{item.name || "No Name"}</Link></td>
            <td className={"template-subject"}>{subject}</td>
            <td className={"template-preview"}>{preview}</td>
            <td><DateTime value={item.modified || item.created} /></td>
        </tr>);
    };

    const setControls = () => {
        if (!!options) {
            options.refreshAsync = async (e) => await getTemplatesAsync(true);
        }
    };
    
    useEffect(() => {
        setControls();
        let _ = getTemplatesAsync();
    }, []);

    const templates = MessageService.instance.messageTemplates;
    const elements = Array.isArray(templates) ? templates.map(createLineItem) : (<LoadingBox />);
    const table = elements?.length > 0 ? (<table className="table" onClick={(e) => refresh(e) }>
        <thead>
        <tr>
            <th>Name</th>
            <th>Subject</th>
            <th>Message Preview</th>
            <th>Last Update</th>
        </tr>
        </thead>
        <tbody>{elements}</tbody>
    </table>) : elements;

    return (<div className="messages-table">
        {table}
    </div>)
};

export default MessagesTable;
