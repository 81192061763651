import HttpService from "../../common/services/HttpService";
import SocialModel from "../models/SocialModel";
import SocialStatsModel from "../models/SocialStatsModel";

class SocialService { 
    static instance = new SocialService();

    constructor() {
        this.socialStatsMap = {};
    }

    async getSocialStatsAsync(entityId, entityType = 0) {
        if (typeof entityType !== "number") 
            throw new Error("Invalid EntityType: " + (typeof entityType));
        
        const path = "/api/social/" + entityType + "/stats/" + entityId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const json = response.data;
            
            if (!json?.entity_id) {
                return null;
            }

            const socialStats = new SocialStatsModel(json);
            me.socialStatsMap[socialStats.entityKey] = socialStats;
            return socialStats;
        });
    }
}

export default SocialService;
