import React from 'react';

const HtmlContent = (props) => {
    const {children} = props;

    if (typeof children !== "string") return children;

    const content = children.trim();
    
    const items = content.split("\n").map((text, i) => {
        if (text.trim().length < 1) text = (<>&nbsp;</>);
        return (<span className={"html-line"} key={"text-" + i}>{text}</span>);
    });

    return (<>{items}</>);
};

export default HtmlContent;
