import React, {useEffect, useState} from 'react';
import ChallengeMasterScreen from "./ChallengeMasterScreen";
import {Link, useParams} from "react-router-dom";
import ChallengeService from "../../components/challenges/services/ChallengeService";
import DefTable from "../../components/common/ui/elements/DefTable";

const ChallengeEntriesScreen = (props) => {
    const { challengeId} = useParams();
    const [challenge, setChallenge] = useState(ChallengeService.instance.challengeMap[challengeId] || null);

    const options = useState({
        fetch: async (opts) => {
            const fetchOptions = {
                startDate: opts.startDate,
                endDate: opts.endDate,
            };
            
            const force = opts.force === true;
            const items = await ChallengeService.instance.getChallengeEntriesAsync(challengeId, fetchOptions);
            console.warn("Items: ", items);
            
            return items;
        },
        getValue: (item, column, index) => {
            if (index === 0) return (<Link to={"/challenges/" + challengeId + "/entries/" + item.id}>{item.user?.name || "No Name"}</Link>);
            if (index === 1) return item.description || "...";
            
            if (column.field === "created") return item?.created || "Unknown";
            return (item[column.field] || "Unknown") + "-x";
        },
        columns: [
            { name: "Name", field: "name" },
            { name: "Notes", field: "notes" },
            { name: "Date", field: "created" },
        ],
    })[0];
    
    const onChallenge = (ch) => {
        if (!!ch?.id) setChallenge(ch);
    };

    useEffect(() => {
        //
    }, []);

    return (<ChallengeMasterScreen onChallenge={onChallenge} selection={"entries"}>
        <div className={"pad constrain"}>
            <h2>Challenge Entries</h2>
            <p></p>
            
            <div className={""}>
                <DefTable options={options} />
            </div>
        </div>        
    </ChallengeMasterScreen>);
};

export default ChallengeEntriesScreen;

