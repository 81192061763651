import React from 'react';

const DebugJson = (props) => {
    const { options, id, className, title, value, json } = props;

    const cn = typeof className === "string" ? className : "";
    const elementId = typeof id === "string" ? id : "";
    
    const titleElement = typeof title === "string" ? (<h2>{title}</h2>) : null;
    
    return (<>{titleElement}<pre className={"debug json " + cn} id={elementId}>
        {JSON.stringify(json || value || options, null, 4)}
    </pre></>);
};

export default DebugJson;
