import React, {useEffect, useState} from 'react';
import FuelMasterScreen from "./FuelMasterScreen";
import MealService from "../../components/fuel/services/MealService";
import DateTime from "../../components/common/ui/DateTime";
import {Link} from "react-router-dom";
import AddNewButton from "../../components/common/ui/AddNewButton";
import {faUtensils} from "@fortawesome/free-solid-svg-icons";
import LoadingBox from "../../components/common/ui/LoadingBox";
import SortableList, {SortableGrip} from "../../components/common/ui/sortable-list/SortableList";

const MealsScreen = (props) => {
    const {} = props;
    const [screenState, setScreenState] = useState({ id: 0 });

    const onReSort = async (items, index, e) => {
        console.log("onResort: ", index);
        const sortItems = SortableList.createItems(items);
        await MealService.instance.updateMealSortingAsync(sortItems);
    };
    
    const getMealsAsync = async (force = false) => {
        let meals = MealService.instance.meals;
        
        if (Array.isArray(meals) && meals.length > 0 && force !== true)
            return meals;
        
        meals = await MealService.instance.getMealsAsync();
        setScreenState({ id: new Date().getTime() });
        
        return meals;
    };
    
    useEffect(() => {
        let _ = getMealsAsync();
    }, []);
    
    const meals = MealService.instance.meals;
    const elements = Array.isArray(meals) ? meals.map((meal, index) => {
        return (<tr key={"meal-" + index} className={"status-" + meal.status + ""} tabIndex={index} slot={meal.id}>
            <td>
                <Link to={"/fuel/meals/" + meal.id}>{meal.name}</Link>
            </td>
            <td></td>
            <td className={"has-grip"}>
                <DateTime value={meal.created} />
                <SortableGrip />
            </td>
        </tr>);
    }) : (<LoadingBox>Getting Meals...</LoadingBox>);

    const mealsElement = elements.length > 0 ? (<table width={"100%"} className={"table"}>
        <thead> 
        <tr>
            <th>Name</th>
            <th>Preview</th>
            <th>Date!</th>
        </tr>
        </thead>
        <SortableList onDrop={onReSort} tagName={"tbody"}>{elements}</SortableList>
    </table>) : elements;

    return (<FuelMasterScreen selection={"meals"}>
        <div className={"pad"}>
            <h2 className={"nav with-button"}>
                <span>Meals</span>
                <AddNewButton href={"/fuel/meals/00-00"} icon={faUtensils}>New Meal/Recipe</AddNewButton>
            </h2>

            {mealsElement}
        </div>
        
    </FuelMasterScreen>);

};

export default MealsScreen;
