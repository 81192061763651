//import { useCookies } from "react-cookie";
import axios from "axios";
import DateTime from "../ui/DateTime";

class HttpService {
    static sessionKey = "session-id";
    static apiPort = "5001";
    static emptyResponse = {
        data: {},
        message: 'no session id'
    };

    static getDomain() {
        return window.location.hostname;
    }

    static instance = new HttpService();

    constructor() {
        this.baseUrl = '';
        const env = process.env.NODE_ENV || "dev";

        if (env.startsWith("prod")) {
            this.baseUrl = "https://api.jockofuel.app";
        } else {
            if (!this.baseUrl) {
                const hostName = window.location.hostname;
                
                if (hostName.startsWith("192.") || hostName.startsWith("localhost")) {
                    this.baseUrl = "http://192.168.1.151:5001";
                } else {
                    this.baseUrl = "http://" + window.location.hostname + ":" + HttpService.apiPort;
                }
            }
            
            console.log("BaseUrl: ", this.baseUrl);
        }
        
        this.sessionId = null;
        
        this.onUnauthorizedResponse = (err) => { 
            console.error('Unauthorized response (default)');
            
            if (!!this.sessionId) { 
                this.sessionId = null;
                this.setSessionId(null);
            }
            
            return err || null;
        };

        let _ = this.getIpAddressAsync();
    }
    
    setSessionId(sessionId) { 
        this.sessionId = sessionId;
    }
    
    getHeaderConfig(headers) {
        if (!!headers) return { headers: headers };

        headers = { 'Content-Type': 'application/json' };

        if (this.sessionId) headers['session-id'] = this.sessionId?.toString() ?? "";
        if (this.ipAddress) headers['X-Forwarded-For'] = this.ipAddress?.toString() ?? "";
        
        return { headers: headers };
    }

    createUrlWithDateRange(path, startDate, endDate, ignoreTimezone = false) {
        if (DateTime.isDate(startDate)) startDate = startDate.toDate().toFormDate();
        else startDate = '';

        if (DateTime.isDate(endDate)) endDate = endDate.toDate().toFormDate();
        else endDate = '';

        let qa = path.indexOf('?') > -1 ? '&' : '?';
        let tmz = ignoreTimezone === true ? "" : "&tmz=" + new Date().getTimezoneOffset().toString();
        
        if (!!tmz) {
            if (path.indexOf("tmz=")) {
                const tokens = path.split("&");
                const idx = tokens.findIndex((t) => t.startsWith("tmz="));
                if (idx >= 0) tokens.splice(idx, 1);
                path = tokens.join("&");
            }
        }
        
        path += qa + 'start-date=' + startDate + '&end-date=' + endDate + tmz;
        
        console.log("Date Path: ", path);
        console.log(" > QS: ", qa);
        
        return path;
    }

    /**
     * Calls the getAsync method with the given url and date range. 
     * The date range is formatted to fit the platform query string start-date/end-date naming convention* 
     * @param path
     * @param startDate
     * @param endDate
     * @returns {Promise<AxiosResponse<[any]|null>|void|null>|any}
     */
    async getWithDateRangeAsync(path, startDate, endDate) {
        let url = this.createUrlWithDateRange(path, startDate, endDate);
        console.log('Date Url: ' + url);
        return await HttpService.instance.getAsync(url);
    }

    /**
     * Uses ipify.org to get the ip address of the client, then saves it for later use in the header "X-Forwarded-For"
     * @returns {Promise<AxiosResponse<any>>}
     */
    async getIpAddressAsync() {
        const me = this;
        
        return await this.getAsync("https://api.ipify.org/?format=json", true).then((rsp) => {
            let ip = rsp?.data?.ip;
            if (ip) {
                me.ipAddress = ip;
                console.log('IP Address Set to: ' + ip);
                
                return ip;
            }
            
            return null;
        });
    }

    cleanPath(path) {
        if (!path) return '';

        if (!path.startsWith('http')) {
            if (!path.startsWith('/')) path = '/' + path;
            if (window.location.hostname.startsWith("192."))
                return "http://" + window.location.hostname + ":" + HttpService.apiPort + path;
            path = this.baseUrl + path;
        }
        
        return path;
    }

    async getAsync(path, isPublic, headers) {
        let h = this.getHeaderConfig(headers);
        
        if (!isPublic && !h["headers"][HttpService.sessionKey]) {
            //console.error("No getting because no session-id");
            return HttpService.emptyResponse;
        }

        const url = this.cleanPath(path);
        
        return await axios.get(url, h).catch((err) => {
            if (err?.response?.status === 401) {
                this.onUnauthorizedResponse(err);
            }
            
            throw err;
        });
    }

    async postAsync(path, payload, headers) {
        let url = this.cleanPath(path);
        let h = this.getHeaderConfig(headers);
        
        return await axios.post(url, payload, h).catch((err) => {
            for(let p in err) {
                console.log(p, typeof err[p], err[p]);
            }
            
            console.error("Communication Error:", err);
            
            if (err.response?.status === 401) {
                this.onUnauthorizedResponse();
            }
            
            throw err;
        });
    }

    async putAsync(path, payload, headers) {
        return await axios.put(this.cleanPath(path), payload, this.getHeaderConfig(headers)).catch((err) => {
            if (err.response?.status === 401) {
                this.onUnauthorizedResponse();
            }
            throw err;
        });
    }

    async uploadAsync(path, files, headers, fileId = null, responseType = null) {
        const formData = new FormData();

        if (!fileId) fileId = 'files';

        // Update the formData object
        if (!Array.isArray(files)) files = [files];

        const videoOptions = {};
        
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            formData.append(
                fileId,
                file,
                file.name
            );
            
            if (file.name.endsWith("mp4") || file.name.endsWith("mov")) {
                videoOptions[file.name] = { width: 100, height: 100 };
            }
        }

        if (Object.keys(videoOptions).length > 0) {
            formData.append("videoOptions", JSON.stringify(videoOptions));
        }
        
        if (!headers) headers = {};

        let config = { headers: headers};
        
        if (typeof headers['session-id'] === 'undefined' && this.sessionId)
            config.headers['session-id'] = this.sessionId || "";

        console.log('Uploading file: ' + path);
        
        if (responseType) {
            //axios.responseType = responseType;
            config.responseType = responseType;
        }
        
        return await axios.post(this.cleanPath(path), formData, config).catch((err) => {
            if (err.response.status === 401) {
                this.onUnauthorizedResponse();
            }
            throw err;
        });
    }

    async deleteAsync(path, headers) {
        return await axios.delete(this.cleanPath(path), this.getHeaderConfig(headers)).catch((err) => {
            if (err.response.status === 401) {
                this.onUnauthorizedResponse();
            }
            throw err;
        });
    }

    async getBlobAsync(path, options = {}) {
        console.warn("Download Options Sending:");
        const me = this;

        return await HttpService.instance.getAsync(path, options, null, "blob").then((response) => {
            const contentTypeHeader = response?.headers?.["content-type"] || "application/octet-stream; name=\"\"";
            const contentTypeTokens = contentTypeHeader.split(";");
            const contentType = (contentTypeTokens[0] || "image/png").trim();

            let fileName = options?.fileName ||
                (contentTypeTokens.length < 1 ? contentType.replaceAll("/", ".") : contentTypeTokens[1]) ||
                response?.headers?.["file-name"] ||
                (response?.headers?.["content-disposition"]?.split(";")[1]?.split("=")[1] || "file-" + (new Date()).getTime() + "." + contentType.split("/")[1]);

            if (fileName.indexOf("=") > 0) fileName = fileName.split("=")[1].replaceAll("\"", "");
            
            console.log("Content Type: " + contentType);
            console.log("File Name: " + fileName);
            console.warn(JSON.stringify(response?.headers));

            const blobModel = {
                blob: new Blob([response.data], { type: contentType }),
                fileName: fileName,
                headers: response?.headers,
            };

            if (options?.download === true) { 
                me.downloadBlob(blobModel);
            }

            return blobModel;
        });
    }
    
    downloadBlob(blobModel) {
        if (!blobModel?.blob) { 
            console.error("Blob Model is invalid: " + (typeof blobModel));
            return false;
        }

        const href = window.URL.createObjectURL(blobModel.blob)
        const link = document.createElement('a');
        
        link.href = href;
        link.setAttribute('download', blobModel.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(href);

        return true;
    }


}

export default HttpService;
