'use client'
import React, {createRef, useEffect, useRef, useState} from 'react';
import MessageService from '../services/MessageService';
import ConfigModel from "../../configuration/ConfigModel";
import Editor from 'react-simple-wysiwyg';

const MessageEditorForm = (props) => {
    const { value, options, id: templateId } = props;
    const [screenState, setScreenState] = useState({ html: value?.message, template: value });

    const subjectRef = useRef();
    const messageRef = createRef();
    const fromNameRef = useRef();
    const fromEmailRef = useRef();
    const contentRef = useRef();
    
    const createJson = () => {
        return {
            subject: subjectRef.current?.value || "",
            message: messageRef.current?.value || screenState.html || "",
            from_name: fromNameRef.current?.value || null,
            from_email: fromEmailRef.current?.value || null,
        };
    };

    const setControls = () => {
        if (!!options) {
            options.getJson = (json) => createJson();
        }
    };

    const setData = () => {
        if (!!subjectRef.current) subjectRef.current.value = screenState.template?.subject || "";
        if (!!messageRef.current) messageRef.current.value = screenState.template?.message || "";
        if (!!fromNameRef.current) fromNameRef.current.value = screenState.template?.from_name || "";
        if (!!fromEmailRef.current) fromEmailRef.current.value = screenState.template?.from_email || "";
    };

    const getTemplateAsync = async (force = false) => {
        if (!templateId) return;
        if (!force && !!screenState.template?.id) return;

        const t = await MessageService.instance.getMessageTemplateAsync(templateId);
        if (!!t?.id) setScreenState({ template: t });
    };

    const onWysiwygChange = (e) => {
        const html = e?.target?.value || "NULL";
        if (!!messageRef.current) messageRef.current.value = html;
        if (!!value) value.message = html;
        
        const news = { ...screenState, html: html };
        setScreenState(news);
    };
    
    useEffect(() => {
        setData();
        setControls();
    }, [screenState]);

    useEffect(() => {

        let _ = getTemplateAsync();
    }, []);

    const buttonElement = options?.useButton !== false ? (<div className={"form-group buttons"}>
        <button type={"submit"} className={"button"}>Save Template</button>
    </div>): null;

    return (<div className={"form"}>
        <h2 style={{textAlign: "center", marginBottom: "16px", paddingTop: "1px", marginTop: "0"}}>{value?.name}</h2>
        <div className={"form-group"}>
            <label htmlFor={"message"}>Default Subject</label>
            <input id={"subject"} ref={subjectRef} type="text" defaultValue={value?.subject} name={"subject"} placeholder={"Ex: Jocok Fuel Registration Confirmation"} />
        </div>
        <div className={"form-group double"}>
            <div className={""}>
                <label htmlFor={"from-name"}>From Name</label>
                <input id={"from-name"} ref={fromNameRef} type="text" defaultValue={value?.fromName} name={"from-name"} placeholder={"Jocko Fuel"} />
                <div className={"form-notes"}>"{ConfigModel.companyName}" will be used if left blank</div>
            </div>
            <div className={""}>
                <label htmlFor={"rom-email"}>From Email</label>
                <input id={"from-email"} ref={fromEmailRef} type="text" defaultValue={value?.fromEmail} name={"from-email"} placeholder={"support@sugarjuice.com"} />
                <div className={"form-notes"}>{ConfigModel.companyEmail} will be used if left blank</div>
            </div>
        </div>
        <div className={"form-group"}>
            <label htmlFor={"message"}>Message</label>
            <span className={"html-editor"}><Editor value={value?.message} onChange={onWysiwygChange} /></span>
        </div>

        {buttonElement}
    </div>);
}

export default MessageEditorForm;
