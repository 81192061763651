import React, {useEffect, useRef, useState} from 'react';
import DefReset from "../../models/DefReset";
import DefButton from "../elements/DefButton";

const DatePicker = (props) => {
    const { startDate, endDate, onChange, onClick, options } = props;
    const startRef = useRef();
    const endRef = useRef();

    const onDateChange = (e, id) => {
        DefReset.stopEvent(e);
        if (typeof onChange === "function") onChange(startRef.current?.value, endRef.current?.value, id);
    };
    
    const onDateClick = async (e) => {
        DefReset.stopEvent(e);
        
        const x = (typeof onClick === "function") ?
            onClick(startRef.current?.value, parseEndDate()) :
            null;
        
        return (typeof x?.then === "function") ? await x : x;
    };

    const parseEndDate = (endDate = null) => {
        let ed = endDate || endRef.current?.value;
        if (!ed) return null;
        
        // If end date is today, then null it out, 
        // so the server knows to use the "most recent" date in the system
        if (typeof ed?.getTime !== "function") {
            ed = new Date(ed);

            // Adjust for timezone
            if (typeof ed.toISOString === "function") {
                const tmz = ed.getTimezoneOffset();
                ed = ed.addMinutes(tmz);
                console.warn("Parse (" + tmz + "): ", ed.toISOString());
            }
        }
        
        if (typeof ed?.getTime !== "function") return null;
        const d = new Date();

        const utc = ed; // new Date(ed.getTime() + ed.getTimezoneOffset() * 60000);
        console.log("D: ", d);
        console.log("ED: ", ed);
        
        return (d.getDay() === utc.getDay() && d.getMonth() === utc.getMonth() && d.getFullYear() === utc.getFullYear()) ?
            null :
            ed;
    }
    
    const createJson = () => {
        const startDate = startRef.current?.value;
        console.log("Date Picker Start Date: ", startDate);
        
        return {
            startDate: startDate,
            endDate: parseEndDate(),
        };
    };
    
    const setControls = () => {
        if (typeof options === "object" && !!options) {
            options.getData = () => createJson();
            options.setDate = (sd, ed) => {
                if (!!startRef.current) startRef.current.value = sd;
                if (!!endRef.current) endRef.current.value = ed;
                onDateChange(null, null);
            };
            
            console.warn("Date Options Set");
        }
    };
    
    useEffect(() => {
        setControls();
    }, []);

    const defaultStartDate = typeof startDate?.getTime === "function" ? startDate : (typeof options?.startDate?.getTime === "function" ? options.startDate : DatePicker.startDate);
    const defaultEndDate = typeof endDate?.getTime === "function" ? endDate : (typeof options?.endDate?.getTime === "function" ? options.endDate : DatePicker.endDate);
    
    const formStartDate = defaultStartDate.toISOString().split("T")[0];
    const formEndDate = defaultEndDate.toISOString().split("T")[0];
    
    return (<div className={"date-selector form"}>
            <span><input type={"date"} id={"start-date"} defaultValue={formStartDate} ref={startRef} onChange={(e) => onDateChange(e, "start-date")} /></span>
            <span>to</span>
            <span><input type={"date"} id={"end-date"} defaultValue={formEndDate} ref={endRef} onChange={(e) => onDateChange(e, "end-date")} /></span>
            <span><DefButton id={"refresh-button"} onClick={onDateClick}>Refresh</DefButton></span>
        </div>);
};

DatePicker.startDate = new Date().addDays(-14);
DatePicker.endDate = new Date();

export default DatePicker;

