import React, {useEffect, useRef, useState} from 'react';
import SettingsMasterScreen from "../SettingsMasterScreen";
import ContentService from "../../../components/settings/services/ContentService";
import Initials from "../../../components/common/ui/Initials";
import {Link} from "react-router-dom";
import DateTime from "../../../components/common/ui/DateTime";
import MediaUploader from "../../../components/media/ui/MediaUploader";
import DefReset from "../../../components/common/models/DefReset";
import ReactModal from "../../../components/common/ui/react-modal/ReactModal";
import MediaForm from "../../../components/media/ui/MediaForm";
import MediaService from "../../../components/media/services/MediaService";

const ContentScreen = (props) => {
    const {children} = props;
    const [contentList, setContentList] = useState(ContentService.instance.content || null);
    
    const titleRef = useRef();
    const headlineRef = useRef();
    
    const getContentAsync = async (collectionId, force = false) => {
        if (contentList?.length > 0 && force !== true) return contentList;
        
        const c = await ContentService.instance.getContentsAsync(collectionId);
        
        if (c?.length > 0) {
            setContentList(c);
        }
    }

    const createJson = (mediaItem) => {
        const json = !!mediaItem ? mediaItem.toJson() : {};
        json.name = titleRef.current?.value || json.name;
        json.description = headlineRef.current?.value || json.description;
        
        return json;
    };
    
    const saveContentAsync = async (mediaItem, e) => {
        DefReset.stopEvent(e);
        const json = createJson();
    };
    
    const handleErrors = (ex, dialogOptions = {}) => {
        console.error(ex);
        return false;
    };
    
    const openEditorAsync = async (mediaItem, e) => {
        DefReset.stopEvent(e);
        const imageId = mediaItem?.id || null;
        if (!imageId) return false;
        
        const editOptions = { noSave: true };
        const mediaForm = (<MediaForm title={"Edit Content"} options={editOptions} defaultValue={mediaItem} />);
        
        const dialog = new ReactModal();
        await dialog.openFormDialogAsync(mediaForm,  async (e) => {
            const p = editOptions.getData();
            const json = (typeof p?.then === "function") ? await p : p;
            console.log("Got Json:");
            console.log(JSON.stringify(json, null, 4));
            
            if (!json?.url) return false;
            
            const result = await MediaService.instance.saveImageAsync(json, imageId).catch((ex) => handleErrors(ex, editOptions));
            if (result !== false) {
                await refreshAsync();
                return true;
            }
            
            return false;
        }, editOptions);
    };
    
    const onSession = (session) => {
        //
    };
    
    const identifierNames = {
        "welcome": "Home Screen",
        "learn": "Learn Screen",
        "challenges": "Challenges Screen",
        "workouts": "Workout Screen",
        "fuel": "Fuel Screen",
    };
    
    const refreshAsync = async () => {
        return await getContentAsync(null, true);
    };
    
    useEffect(() => {
        let _ = getContentAsync();
    }, []);
    
    const list = Array.isArray(contentList) ? contentList : contentList || [];
    
    const elements = list.map((mediaItem, index) => {
        const iconImage = (<Initials size={24}>{mediaItem?.name}</Initials>)
        const imageUrl = mediaItem.isVideo ? mediaItem?.image?.url : mediaItem.url;
        const imageElement = !!imageUrl ? (<img alt={"Profile Photo"} className={"media uploader"} src={imageUrl} />) : null;
        const style = { backgroundImage: imageUrl, cursor: "pointer" };
        const uploadUrl = "/api/image/" + mediaItem.id + "/upload";
        const defaultName = index === 0 ? "welcome" : "";
        
        return (<div onClick={(e) => openEditorAsync(mediaItem, e)} key={"content-" + index} className={"content-item"} style={style} title={mediaItem.id}>
            <div>
                <div className={"thumb"}>
                    {imageElement}
                    <div>
                        <h2>{mediaItem.name || "Untitled"}</h2>
                        <div style={{paddingTop: "8px", fontSize: "14px"}}>{mediaItem?.description}</div>
                    </div>
                </div>
                
                <label>
                    <a className={"title-edit"} onClick={(e) => openEditorAsync(mediaItem, e)}>
                        {identifierNames[mediaItem.identifier] || mediaItem.identifier || defaultName}
                    </a>
                    <span><DateTime value={mediaItem.created} /></span>
                </label>
            </div>                
        </div>);
    });
    
    return (<SettingsMasterScreen selection={"content"}>
        <div className={"pad"}>
            <h2>Content: Header Panels</h2>
            <div className={"grid media"}>
                {elements}
            </div>
        </div>
    </SettingsMasterScreen>);
};

export default ContentScreen;
