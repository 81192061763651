import React from 'react';

import AdminMasterScreen from "../AdminMasterScreen";
import SettingsSubMenu from "../../components/settings/ui/SettingsSubMenu";

const SettingsMasterScreen = (props) => {
    const { children, selection, title } = props;

    return (<AdminMasterScreen selection={"settings"} title={title || "App Management"}>
        <div className={"column-content settings"}>
            <div className={"column"}>
                <SettingsSubMenu selection={selection} />
            </div>
            <div className={"content"}>
                {children}
            </div>
        </div>
    </AdminMasterScreen>);

};

export default SettingsMasterScreen;
