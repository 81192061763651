class MessageTemplateModel {
    static entityType = 10;
    static create = (json) => new MessageTemplateModel(json);

    constructor(json) {
        if (!json) json = {};

        this.id = json.id || null;

        this.messageTemplateType = json.message_template_type || 0;
        this.messageType = json.message_type || 0;
        this.messageTypeName = json.message_type_name || "General";
        this.name = json.name || null;
        this.subject = json.subject || null;
        this.message = json.message || null;
        this.fromName = json.from_name || null;
        this.fromEmail = json.from_email || null;
        this.status = json.status || 0;

        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }

    toJson() {
        return {
            message_template_type: this.messageTemplateType || 0,
            message_type: this.messageType || 0,
            subject: this.subject || null,
            message: this.message || null,
            from_name: this.fromName || null,
            from_email: this.fromEmail || null,
        };
    }

    searchFor(term) {
        if (typeof term === "number") term = term.toString();
        if (!term) return true;

        term = term.toLowerCase();

        if (this.subject?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.message?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.fromName?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.fromEmail?.toString().toLowerCase().indexOf(term) >= 0) return true;

        return (term === this.id?.toLowerCase());
    }

    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => MessageTemplateModel.create(json));
    }
}

export default MessageTemplateModel;
