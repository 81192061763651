import HttpService from "../../common/services/HttpService";
import UserModel from "../models/UserModel";
import AuthenticationService from "../../authentication/services/AuthenticationService";
import PlatformMetricsModel from "../../metrics/models/PlatformMetricsModel";
import ScreenViewModel from "../../activities/models/ScreenViewModel";
import MediaModel from "../../media/models/MediaModel";

class UserService {
    static instance = new UserService();
    
    constructor() {
        this.users = null;
        this.userMap = {};
        this.getting = {};
        this.platformMetricsMap = {};
    }
    
    async getUsersAsync(options = {}) {
        const { startDate, endDate, force } = options;
        const searchTerm = options?.term || "";
        const path = "/api/admin/user" + (typeof searchTerm === "string" && searchTerm.length > 2 ? "?term=" + encodeURI(searchTerm) : "");
        
        const sd = options?.startDate || null;
        const ed = options?.endDate || null;
        
        return await HttpService.instance.getWithDateRangeAsync(path, sd, ed).then((rsp) => {
            const users = rsp?.data;
            
            if (!Array.isArray(users)) {
                console.log("Users is not an array");
                console.log(rsp);
                return this.users;
            } else {
                console.log("Got Users: ", users.length);
            }
            
            this.users = UserModel.fromJsonArray(users);
            this.userMap = {};
            this.users.forEach(user => this.userMap[user.id] = user);
            
            return this.users;
        });
    }
    
    async syncUserAsync(userId) {
        const path = "/api/admin/user/" + userId + "/sync";
        return await HttpService.instance.postAsync(path, {}).then((rsp) => new UserModel(rsp.data));
    }
    
    async getUserMediaAsync(userId, options = {}) {
        const path = "/api/admin/user/" + userId + "/media"
        const sd = options?.startDate || null;
        const ed = options?.endDate || null;
        
        return await HttpService.instance.getWithDateRangeAsync(path, sd, ed).then((rsp) => {
            const mediaItems = MediaModel.fromJsonArray(rsp.data);
            console.log("Media Items: ", mediaItems?.length);
            return mediaItems;
        });
    }
    
    async getUserMetricsAsync(userId, options = {}) {
        const { startDate, endDate, force } = options;
        const path = "/api/admin/user/" + userId + "/dashboard";
        
        const sd = startDate ? startDate.toISOString() : null;
        const ed = endDate ? endDate.toISOString() : null;
        
        return await HttpService.instance.getWithDateRangeAsync(path, sd, ed).then((rsp) => {
            const json = rsp?.data;
            
            if (typeof json?.like_count === "number") {
                return new PlatformMetricsModel(json);
            }
            
            return null;
        });
    }
    
    async getUserAsync(userId) {
        if (this.getting[userId] === true) {
            const u = this.userMap[userId];
            
            if (!u) {
                console.warn("Cached User is oddly not found: ", userId);
                console.log(u);
                return false;
            }
            
            return u;
        }
        
        this.getting[userId] = true;
        
        const path = "/api/admin/user/" + userId;

        return await HttpService.instance.getAsync(path).then((rsp) => {
            delete this.getting[userId]
            
            const json = rsp?.data;
            if (!json?.id) return 0;
            
            this.userMap[userId] = new UserModel(json);
            
            return this.userMap[userId];
        }).catch((ex) => {
            delete this.getting[userId]
            console.error(ex);
            throw ex;
        });
    }

    async getPlatformMetricsAsync(userId, options = { startDate: null, endDate: null }) {
        const path = "/api/admin/user/" + userId + "/dashboard";
        const startDate = options?.startDate || null;
        const endDate = options?.endDate || null;

        const rsp = await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate);
        const json = rsp?.data;

        this.platformMetricsMap[userId] = new PlatformMetricsModel(json);
        
        console.log("Good!");

        return this.platformMetricsMap[userId];
    }

    async getUserScreenViewsAsync(userId, options) {
        const timezoneOffset = new Date().getTimezoneOffset();
        const path = "/api/admin/user/" + userId + "/screen-view?tmz=" + timezoneOffset.toString();
        const startDate = options?.startDate || null;
        const endDate = options?.endDate || null;

        console.log("Screen View Path: ");
        console.warn(path);
        
        const rsp = await HttpService.instance.getWithDateRangeAsync(path, startDate, endDate);
        const json = rsp?.data;

        return ScreenViewModel.fromJsonArray(json);
    }

    async updateUserAsync(userId, userJson) {
        const path = "/api/admin/user/" + userId;
        const json = {
            first_name: userJson.firstName || userJson.first_name,
            last_name: userJson.lastName || userJson.last_name,
            phone: userJson.phone || null,
            role_id: userJson.role_id,
        };
        
        console.warn("Remember: We don't update the email here.");
        
        return await HttpService.instance.postAsync(path, json);
    }
}

export default UserService;
