import HttpService from "../../common/services/HttpService";
import ChallengeModel from "../models/ChallengeModel";
import ChallengeEntryModel from "../models/ChallengeEntryModel";
import PlatformMetricsModel from "../../metrics/models/PlatformMetricsModel";
import GraphItemModel from "../../metrics/models/GraphItemModel";

class ChallengeService { 
    static instance = new ChallengeService();

    constructor() {
        this.challengesMap = {};
        this.challenges = [];
        this.challengeMap = {};
        this.entriesMap = {};
        this.dashboardMap = {};
        this.chartDataMap = {};
        this.chartUserDataMap = {};
    }
    
    async getChallengeEntriesAsync(challengeId, options = {}) {
        const path = "/api/challenge/" + challengeId + "/challenge-entry";
        const me = this;
        
        return await HttpService.instance.getAsync(path).then((response) => {
            const items = ChallengeEntryModel.fromJsonArray(response.data);

            if (Array.isArray(items)) {
                me.entriesMap[challengeId] = items;
            }

            return items;
        });
    }

    async getChallengeDashboardAsync(challengeId, options = {}) {
        const path = "/api/challenge/" + challengeId + "/dashboard";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const dashboard = new PlatformMetricsModel(response.data);
            me.dashboardMap[challengeId] = dashboard;
            
            return dashboard;
        });
    }

    async getChallengeEntryCountsAsync(challengeId, options = {}) {
        const path = "/api/metric/challenge-entry-count/" + challengeId + "";
        const me = this;
        const sd = options?.startDate || null;
        const ed = options?.endDate || null;
        
        return await HttpService.instance.getWithDateRangeAsync(path, sd, ed).then((response) => {
            const graphItems = GraphItemModel.fromJsonArray(response.data);
            
            if (Array.isArray(graphItems)) {
                //me.entriesMap[challengeId] = graphItems;
                return graphItems;
            }
            
            return graphItems || null;
        });
    }

    async getChallengeEntryUserCountsAsync(challengeId, options = {}) {
        const path = "/api/metric/challenge-entry-user-count/" + challengeId + "";
        const me = this;
        const sd = options?.startDate || null;
        const ed = options?.endDate || null;

        return await HttpService.instance.getWithDateRangeAsync(path, sd, ed).then((response) => {
            const graphItems = GraphItemModel.fromJsonArray(response.data);

            if (Array.isArray(graphItems)) {
                //me.entryUsersMap[challengeId] = graphItems;
                return graphItems;
            }

            return graphItems || null;
        });
    }
    
    async getChallengeAsync(challengeId) {
        const path = '/api/challenge/' + challengeId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.challengeMap[challengeId] = new ChallengeModel(response.data);
            return me.challengeMap[challengeId];
        });
    }

    async getChallengesAsync(status = 0) {
        const path = "/api/challenge?status=" + status;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const items = ChallengeModel.fromJsonArray(response.data);
            
            if (Array.isArray(items)) {
                me.challenges = items;
            }
            
            return items;
        });
    }
    
    async getUserChallengeEntriesAsync(userId, options = {}) {
        const path = "/api/user/" + userId + "/challenge-entry";
        const me = this;
        
        const sd = !!options?.startDate ? options.startDate : new Date().addDays(-7);
        const ed = options?.endDate;
        
        const rsp = await HttpService.instance.getAsync(path);
        if (!Array.isArray(rsp?.data)) return [];
        
        const entries = ChallengeEntryModel.fromJsonArray(rsp.data);

        me.entriesMap[userId] = entries;

        return entries;

    }
    
    async saveChallengeAsync(challengeJson, challengeId = null) {
        const path = "/api/challenge" + (typeof challengeId === "string" && challengeId.length > 30 ? "/" + challengeId : "");
        const me = this;
        
        return await HttpService.instance.postAsync(path, challengeJson).then((response) => {
            const ch = new ChallengeModel(response.data);
            
            if (!!ch?.id) {
                console.warn("Success: ", ch?.id);
                me.challengeMap[ch.id] = ch;
                
                const index = me.challenges.findIndex((c) => c.id === ch.id);
                if (index >= 0) me.challenges[index] = ch;
                
                return ch;
            } else {
                console.error("Failed: ", ch);
            }
            
            return null;
        });
    }
}

export default ChallengeService;
