import FoodModel from "./FoodModel";
import ImageModel from "../../media/models/ImageModel";

class MealFoodModel {
    static entityType = 64;
    static create = (json) => new MealFoodModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;

        this.name = json.name || null;
        this.cookingDirections = json.cooking_directions || null;
        this.amount = json.amount || 1;
        this.calories = json.calories || 0;
        this.protein = json.protein || 0;
        this.carbs = json.carbs || 0;
        this.fat = json.fat || 0;
        this.sugar = json.sugar || 0;
        this.amountUnits = json.amount_units || 0;
        this.amountUnitsName = json.amount_units_name || "x";
        this.status = json.status || 0;

        this.mealId = json.meal_id || null;
        this.foodId = json.food_id || null;
        this.imageId = json.image_id || null;
        
        this.image = !!json.image?.url ? new ImageModel(json.image) : null;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            meal_id: this.mealId,
            food_id: this.foodId,
            image_id: this.imageId,
            amount: this.amount,
            calories: this.calories,
            protein: this.protein,
            carbs: this.carbs,
            fat: this.fat,
            sugar: this.sugar,
            amount_units: this.amountUnits,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.amountUnits?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => MealFoodModel.create(json));
    }
}

export default MealFoodModel;
