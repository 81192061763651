class PlatformMetricsModel {
    constructor(json) {
        if (!json) json = {};
        
        this.userCount = json.user_count || 0;
        this.pointCount = json.point_count || 0;
        this.userHabitCount = json.user_habit_count || 0;
        this.commentCount = json.comment_count || 0;
        this.likeCount = json.like_count
        this.startDate = json.start_date || null;
        this.endDate = json.end_date || null;
    }
}

export default PlatformMetricsModel;
