import React, {useEffect, useState} from 'react';
import AdminMasterScreen from "../AdminMasterScreen";
import ArticleService from "../../components/learn/services/ArticleService";
import {useParams} from "react-router-dom";

const LearnMasterScreen = (props) => {
    const {children, articleId:initialArticleId, article:initialArticle, onArticle} = props;
    const { articleId:queryArticleId } = useParams();
    
    const articleId = initialArticleId || initialArticle?.id || queryArticleId;
    const [article, setArticles] = useState(initialArticle || ArticleService.instance.articleMap[articleId] || null);
        
    const getArticleAsync = async (force = false) => {
        if (!!article?.id && force !== true) {
            if (typeof onArticle === "function") onArticle(article);
            return article;
        }

        console.log("Getting article [" + articleId + "]...");
        
        const a = await ArticleService.instance.getArticleAsync(articleId);
        
        if (!!a?.id) setArticles(a);
        else console.warn("No Article: ", a);
        
        return a || article;
    };
    
    const onSession = (session) => {
        let _ = getArticleAsync();
    };

    useEffect(() => {
        if (!!article?.id && typeof onArticle === "function") 
            onArticle(article);
        
    }, [article]);
    
    useEffect(() => {
        let _ = getArticleAsync();
    }, []);
    
    return (<AdminMasterScreen title={"Learn"} selection={"learn"} onSession={onSession}>
        {children}
        </AdminMasterScreen>);
};

export default LearnMasterScreen;