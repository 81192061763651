import UserModel from "../../users/models/UserModel";

class CommentModel {
    static entityType = 3;
    static create = (json) => new CommentModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.userId = json.user_id || null;
        this.entityId = json.entity_id || null;
        this.entityType = json.entity_type || 0;
        this.entityTypeName = json.entity_type_name || "";
        this.text = json.text || null;
        this.ipAddress = json.ip_address || null;
        this.status = json.status || 0;
        this.user = new UserModel(json.user);
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            user_id: this.userId,
            entity_id: this.entityId,
            entity_type: this.entityType,
            text: this.text,
            ip_address: this.ipAddress,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.text?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.ipAddress?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => CommentModel.create(json));
    }
}

export default CommentModel;
