import React from 'react';

const PreviewText = (props) => {
    const { maxLength, children, ellipsis, className } = props;
    
    if (!children) return (<></>);
    
    let text = typeof children === "string" ? children : children?.toString();
    const m = parseInt(maxLength?.toString()) || 64;
    if (m >= text.length) return (<>{text}</>);
    
    text = text.substring(0, m);
    const el = (typeof ellipsis === "string" ? ellipsis : null) || "...";
    
    return (<>{text}{el}</>);
};

export default PreviewText;