import HttpService from "../../common/services/HttpService";
import MediaModel from "../../media/models/MediaModel";

class ContentService {
    static instance = new ContentService();
    static appHeadersCollectionId = "FF112FB1-C00F-4F14-8AEF-11E7A69E743D";

    constructor(props) {
        this.content = [];
    }

    async getContentsAsync(collectionId = null) {
        const path = "/api/collection" + (typeof collectionId === "string" && collectionId.length > 30 ? "/" + collectionId : "");
        const rsp = await HttpService.instance.getAsync(path);
        const jsonArray = rsp?.data;
        
        if (!Array.isArray(jsonArray)) {
            console.log(jsonArray);
            return [];
        }
        
        this.content = MediaModel.fromJsonArray(jsonArray);
        return this.content;
    }
}

export default ContentService;
