import React, {useEffect, useState} from 'react';
import UserMasterScreen from "./UserMasterScreen";
import HabitService from "../../components/habits/services/HabitService";
import {useParams} from "react-router-dom";
import UserService from "../../components/users/services/UserService";
import SocialPanel from "../../components/common/ui/social/SocialPanel";
import HabitModel from "../../components/habits/models/HabitModel";
import UserHabitModel from "../../components/habits/models/UserHabitModel";
import DateTime from "../../components/common/ui/DateTime";
import LoadingBox from "../../components/common/ui/LoadingBox";
import GalleryUploader from "../../components/media/ui/GalleryUploader";
import DefReset from "../../components/common/models/DefReset";
import CommentsList from "../../components/messaging/ui/comments/CommentsList";

const UserHabitDetailsScreen = (props) => {
    const {} = props;
    const { userId, userHabitId } = useParams();
    const [screenState, setScreenState] = useState({
        user: UserService.instance.userMap[userId] || null,
        userHabit: HabitService.instance.userHabitMap[userHabitId] || null,
        id: 0,
        lastCount: -1,
        options: {},
        commentOptions: {entityLink: false, entityId: userHabitId },
    });

    const user = screenState.user;
    const userHabit = screenState.userHabit;
    
    const setUser = (u) => {
        const newState = {...screenState};
        newState.user = u;
        setScreenState(newState);
    };

    const setUserHabit = (u) => {
        const newState = {...screenState};
        newState.userHabit = u;
        setScreenState(newState);
    };
    
    const onUser = (u) => {
        console.error("Got User: " + u?.name);
        setUser(u);
    };
    
    const getUserHabitAsync = async (force = false) => {
        if (!!userHabit?.id && force !== true) {
            setMasterUser();
            return userHabit;
        }

        if (!user?.id) return userHabit;
        
        if (HabitService.instance.habits.length === 0) await HabitService.instance.getHabitsAsync();
        const m = await HabitService.instance.getUserHabitAsync(userHabitId);
        
        if (!m?.id) {
            console.error("No user habit: ", m);
            return userHabit;
        }
        
        console.warn("Got User Habit Details: " + m.habitId);
        
        m.habit = HabitService.instance.habitMap[m.habitId];
        setUserHabit(m);

        return m || userHabit;
    };

    const setMasterUser = (e) => {
        DefReset.stopEvent(e);

        if (!screenState.userHabit?.user?.id) {
            return;
        }

        const u = screenState.userHabit.user;

        if (typeof screenState.options?.setUser === "function") {
            console.warn("Setting User: ", u);
            screenState.options.setUser(screenState.userHabit.user);
        } else {
            console.error("No User to Set");
        }
    };

    useEffect(() => {
        setMasterUser();
    }, [screenState]);
    
    useEffect(() => {
        let _ = getUserHabitAsync();
    }, [user]);

    const uh = userHabit || HabitService.instance.userHabitMap[userHabitId];
    if (!!uh?.habitId && !uh.habit) uh.habit = HabitService.instance.habitMap[uh.habitId];
    
    const titleElement = (!!user?.id || !!uh?.id) ? (<h2>{user?.name} Habit: { uh?.habit?.name || "..." }</h2>) : null;
    const galleryOptions = { grid: false };
    const body = !!userHabit?.id ? (<div className={"constrain"}>
        <GalleryUploader id={"user-habit-details"} className={"user-habits"} entityType={UserHabitModel.entityType} entityId={userHabit.id} defaultValue={userHabit?.media} options={galleryOptions} />
    </div>) : (userHabit === null ? (<LoadingBox>Loading Habits...</LoadingBox>) : null);
    
    const jsonElement = !!userHabit?.id ? (<pre>{JSON.stringify(userHabit?.json, null, 4)}</pre>) : null
    const dateFormat = uh?.habit?.habitKey === HabitModel.wakeupKey ? "HH:mm" : "MMMM DAY, yyyy hh:mm [ap]";
    const completedClass = uh?.habit?.habitKey === HabitModel.wakeupKey ? "completed time wakeup" : "completed";
    const prefix = uh?.habit?.habitKey === HabitModel.wakeupKey ? "Woke Up" : "Completed: ";
    
    const completedElements = !!userHabit?.habit ?
        [(<div key={"completed-time-key"} className={completedClass}>
            <strong>{prefix}:</strong>
            {" "}<DateTime value={userHabit?.completedDate} format={dateFormat} time={true} />
        </div>)] : [];
    
    const workoutName = userHabit?.data["workout_name"] || "";
    const notesElement = !!userHabit?.notes ? (<p className={"user-content"}><strong>Notes: </strong> {userHabit?.notes}</p>) : null;
    
    if (userHabit?.habitKey === HabitModel.workoutKey && !!workoutName) {
        completedElements.push((<div key={"completed-time-duration"} className={"completed"}>
            <strong>Workout Name:</strong>
            {" "}{workoutName}
        </div>));
    } else {
        completedElements.push((<div key={"completed-time-duration"} className={"completed"}><strong>HabitKey:</strong> {userHabit?.habitKey}</div>));
    }

    const commentElements = (<CommentsList options={screenState.commentOptions} />);
    
    return (<UserMasterScreen selection={"habits"} title={"UserHabitDetails"} onUser={onUser} options={screenState.options}>
        <div className={"pad constrain"}>
            {titleElement}
            
            <div className={"details"}>
                <p className={"p800"}>{userHabit?.habit?.longDescription}</p>
            </div>
            
            <div className={"bottom16 items"}>
               <div className={"double-spaced"}>
                   {completedElements}
               </div>
                
                <div style={{marginLeft: "auto", paddingRight: "8px"}}>
                    <SocialPanel entityId={userHabitId} entityType={UserHabitModel.entityType} />
                </div>
            </div>
            
            <div className={""}>{notesElement}</div>
            
            <div>{body}</div>
            <div className={"comments constrain"}>{commentElements}</div>
            <div className={"constrain"}>{jsonElement}</div>
        </div>
    </UserMasterScreen>);

};

export default UserHabitDetailsScreen;
