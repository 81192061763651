import React, {useEffect, useState} from 'react';
import AdminMasterScreen from "../AdminMasterScreen";
import WorkoutsSubMenu from "../../components/workouts/ui/WorkoutsSubMenu";

const WorkoutsMasterScreen = (props) => {
    const {children, selection, onSession} = props;

    const onWorkoutSession = (session) => {
        if (typeof onSession === "function") onSession(session);
    };
    
    useEffect(() => {
        //
    }, []);

    return (<AdminMasterScreen selection={"workouts"} title={"Workouts"} onSession={onWorkoutSession}>
        <div className={"column-content workouts"}>
            <div className={"column"}>
                <WorkoutsSubMenu selection={selection} />
            </div>
            <div className={"content"}>
                {children}
            </div>
        </div>
    </AdminMasterScreen>);

};

export default WorkoutsMasterScreen;
