import React, {useEffect, useRef} from 'react';
import DefReset from "../../common/models/DefReset";
import AuthenticationService from "../../authentication/services/AuthenticationService";
import LoadingBox from "../../common/ui/LoadingBox";

const PersonForm = (props) => {
    const { onChange, onClick, options, value } = props;

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const roleRef = useRef();
    
    const enabledEmail = options?.email === true;
    
    const createJson = () => {
        const rid = parseInt(roleRef.current?.value || "");
        
        return {
            first_name: firstNameRef.current?.value,
            last_name: lastNameRef.current?.value,
            email: enabledEmail ? emailRef.current?.value : null,
            phone: phoneRef.current?.value,
            role_id: isNaN(rid) ? null : rid,
        };
    }
    
    const onFormChange = (e, id) => {
        DefReset.stopEvent(e);
        
        if (typeof onChange !== "function") return;
        const json = createJson();
        
        onChange(json);
    };
    
    const setControllers = () => {
        if (!!options) options.setValue = (value) => {
            if (!!firstNameRef.current)
                firstNameRef.current.value = value?.firstName || value?.first_name || "";
            
            if (!!lastNameRef.current)
                lastNameRef.current.value = value?.lastName || value?.last_name || "";
            
            if (enabledEmail && !!emailRef.current)
                emailRef.current.value = value?.email || "";
            
            if (!!phoneRef.current)
                phoneRef.current.value = value?.phone || "";

            if (!!roleRef.current)
                roleRef.current.value = (value?.role_id || value?.roleId || 0).toString();
        }
    }
    
    useEffect(() => {
        setControllers();
        const id = setTimeout(() => {
            clearTimeout(id);
            onFormChange();
        }, 100);
    }, []);
    
    const userRoleId = value?.auth?.role_id || value?.auth?.roleId || 0;
    const currentExtraRoleOption = userRoleId > 49 ?
        (<option value={userRoleId.toString()}>{ value?.auth?.role_name || "Admin Level ({userRoleId})"}</option>) : 
        null;
    
    const roleElement = (<div className={"form-group"} id={"role-element"}>
            <label htmlFor={"role"}>Role</label>
            <select id={"role"} ref={roleRef} defaultValue={value?.roleId || ""} onChange={(e) => onFormChange(e, "role_id")}>
                <option value={"0"}>Basic User</option>
                <option value={"49"}>Admin</option>
                {currentExtraRoleOption}
            </select>
        </div>
    );
    
    if (!value) return (<LoadingBox>Loading User</LoadingBox>);
    
    return (<div className={"person-form form"}>
        <div className={"form-group double"}>
            <div>
                <label htmlFor={"first_name"}>First Name</label>
                <input id={"first_name"} ref={firstNameRef} defaultValue={value?.firstName || value?.first_name || ""} onChange={(e) => onFormChange(e, "first_name")} type={"text"} />
            </div>
            <div>
                <label htmlFor={"last_name"}>Last Name</label>
                <input id={"last_name"} ref={lastNameRef} defaultValue={value?.lastName || value?.last_name || ""} onChange={(e) => onFormChange(e, "last_name")} type={"text"} />
            </div>
        </div>
        
        <div className={"form-group"}>
            <label htmlFor={"email"}>Email</label>
            <input disabled={!enabledEmail} id={"email"} ref={emailRef} defaultValue={value?.email || ""} onChange={(e) => onFormChange(e, "email")} type={"email"} />
        </div>
        
        <div className={"form-group"}>
            <label htmlFor={"phone"}>Phone</label>
            <input id={"phone"} ref={phoneRef} defaultValue={value?.phone || ""} onChange={(e) => onFormChange(e, "phone")} type={"tel"} />
        </div>

        {roleElement}
    </div>);
};

export default PersonForm;
