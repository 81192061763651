import HttpService from "../../common/services/HttpService";
import MealModel from "../models/MealModel";
import FoodModel from "../models/FoodModel";

class FoodService { 
    static instance = new FoodService();

    constructor() {
        this.foodsMap = {};
        this.foods = [];
        this.foodMap = {};
    }

    async getFoodAsync(foodId) {
        const path = '/api/food/' + foodId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.foodMap[foodId] = new FoodModel(response.data);
            return me.foodMap[foodId];
        });
    }

    async getFoodsAsync() {
        const path = "/api/food";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.foods = FoodModel.fromJsonArray(response.data);
            return me.foods;
        });
    }
    
    async saveFoodAsync(json, foodId = null) {
        const path = "/api/food" + (!!foodId ? "/" + foodId : "");
        const me = this;

        return await HttpService.instance.postAsync(path, json).then((response) => {
            return new FoodModel(response.data);
        });
    }

    async deleteFoodAsync(foodId) {
        const path = "/api/food/" + foodId;
        const me = this;

        return await HttpService.instance.deleteAsync(path).then((response) => {
            return response.data;
        });
    }
}

export default FoodService;
