import React, {useEffect, useState} from 'react';
import WorkoutsMasterScreen from "./WorkoutsMasterScreen";
import WorkoutService from "../../components/workouts/services/WorkoutService";

const WorkoutSettingsScreen = (props) => {
    const {} = props;

    useEffect(() => {
        //
    }, []);

    return (<WorkoutsMasterScreen selection={"settings"}>
        <div className={"pad"}>
            <h2 className={"def-header"}>Workout Units and Settings</h2>
        </div>
    </WorkoutsMasterScreen>);

};

export default WorkoutSettingsScreen;
