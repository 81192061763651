import React, {useEffect, useState} from 'react';

import "./SocialPanel.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMessage, faThumbsUp} from "@fortawesome/free-solid-svg-icons";
import SocialService from "../../../social/services/SocialService";
import SocialStatsModel from "../../../social/models/SocialStatsModel";

const SocialPanel = (props) => {
    const { entityId, entityType, value } = props;
    const [socialStats, setSocialStats] = useState(value || SocialService.instance.socialStatsMap[SocialStatsModel.createKey(entityId || "", entityType || 0)]);

    const getStatsAsync = async (force = false) => {
        if (socialStats?.likeCount >= 0 && force !== true) return socialStats;
        if (!entityId || !entityType) return socialStats;
        
        console.log("Getting Stats [" + entityId + "]: " + entityType);
        
        const stats = await SocialService.instance.getSocialStatsAsync(entityId, entityType);
        
        if (!!stats) {
            console.log("Good.");
            setSocialStats(stats);
            return stats;
        }
        
        console.error("Failed: ", stats);
        
        return null;
    };
    
    useEffect(() => {
        let _ = getStatsAsync();
    }, []);
    
    const likeCount = socialStats?.likeCount || socialStats?.likes?.length || 0;
    const commentCount = socialStats?.commentCount || socialStats?.comments?.length || 0;
    
    return (<div className={"social-panel"}>
        <span>
            <span className={"icon"}><FontAwesomeIcon icon={faThumbsUp} /></span>
            <label>{likeCount}</label>
        </span>

        <span>        
            <span className={"icon"}><FontAwesomeIcon icon={faMessage} /></span>
            <label>{commentCount}</label>
        </span>
    </div>);
};

export default SocialPanel;