import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DefReset from "../models/DefReset";

const AddNewButton = (props) => {
    const { children, href, onClick, icon } = props;

    const handleClick = (e) => {
        const rsp = (typeof onClick === "function") ? onClick(e) : null;
        
        if (rsp === false) {
            DefReset.stopEvent(e);
            return false
        }
        
        // Do something
    };
    
    let body = children || "Add New";
    if (!!icon && typeof icon === "object")
        body = (<><span><FontAwesomeIcon icon={icon} /></span><span>{body}</span></>);
    
    return (<span className={"add-new-button"}>
        <Link onClick={handleClick} to={href || "/"}>{body}</Link>
    </span>);
};

export default AddNewButton;
