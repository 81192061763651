import React from 'react';
import {Link} from "react-router-dom";
import UserHabitModel from "../../habits/models/UserHabitModel";
import ImageModel from "../../media/models/ImageModel";
import VideoModel from "../../media/models/VideoModel";
import WorkoutModel from "../../workouts/models/WorkoutModel";
import ChallengeModel from "../../challenges/models/ChallengeModel";
import ArticleModel from "../../learn/models/ArticleModel";

const EntityLink = (props) => {
    const { children, value, defaultValue, options, userId:initialUserId, entityId:initialEntityId, entityType:initialEntityType } = props;

    const userId = initialUserId || options?.userId || null;
    const entity = value;
    
    if (!entity?.id && !entity?.entityId) return (<>{defaultValue || ""}</>);
    
    const entityType = initialEntityType || entity?.entityType || 0;
    const body = children;
    
    let path = "/";
    
    if (typeof userId === "string" && userId.length > 30) 
        path = "/people/" + userId + "/";
    
    let slug = "";
    
    switch(entityType) {
        case UserHabitModel.entityType:
            slug = "user-habit";
            break;
        case ImageModel.entityType:
            slug = "images";
            break;
        case VideoModel.entityType:
            slug = "videos";
            break;
        case WorkoutModel.entityType:
            slug = "workouts";
            path = "/";
            break;
        case ChallengeModel.entityType:
            slug = "challenges";
            path = "/";
            break;
        case ArticleModel.entityType:
            slug = "learn";
            path = "/";
            break;
    }
    
    if (!slug) return (<>{body}</>);
    
    const id = initialEntityId || value?.entityId || value?.id || "";
    if (!id) return (<>{body}</>);
    
    return (<Link to={path + slug + "/" + id}>{body}</Link>);
};

export default EntityLink;