import React, {useEffect, useState} from 'react';
import AdminMasterScreen from "../AdminMasterScreen";
import {Link} from "react-router-dom";
import DateTime from "../../components/common/ui/DateTime";
import UserService from "../../components/users/services/UserService";
import DefReset from "../../components/common/models/DefReset";
import Initials from "../../components/common/ui/Initials";
import MediaService from "../../components/media/services/MediaService";
import SearchFilter from "../../components/common/ui/search-filter/SearchFilter";
import DatePicker from "../../components/common/ui/date-picker/DatePicker";
import EmptyBox from "../../components/common/ui/EmptyBox";
import LoadingBox from "../../components/common/ui/LoadingBox";

const PeopleScreen = (props) => {
    const {} = props;
    const [screenState, setScreenState] = useState({
        users: UserService.instance.users || null, 
        id: 0,
        startDate: null,
        endDate: null,
        lastCount: -1
    });
    
    const users = screenState.users;

    const getPeopleAsync = async (force = false, searchTerm = null) => {
        if (screenState.users?.length > 0 && force !== true) return;
        
        const options = {
            term: searchTerm || screenState.term,
            startDate: screenState.startDate,
            endDate: screenState.endDate,
        };
        
        const users = await UserService.instance.getUsersAsync(options);
        
        const newState = {
            users: users,
            loading: false,
            id: new Date().getTime(),
            lastCount: users?.length || 0, 
        };
        
        setScreenState(newState);
    };

    const showImage = (e, imageUrl) => {
        DefReset.stopEvent(e);
        MediaService.instance.showImage(imageUrl);
    };
    
    const onSearchType = (e, items) => {
        console.log("Filter Type: ", e);
        console.log("Item Count: ", items?.length);

        if (screenState.lastCount !== items?.length) {
            const newState = {
                users: items,
                id: new Date().getTime(),
                lastCount: items?.length,
            };
            
            setScreenState(newState);
        }
    }
    
    const onSearchAsync = async (term) => {
        console.log("Search Term: ", term);
        const newState = {...screenState};
        newState.term = term;
        newState.users = null;
        //return await getPeopleAsync(true, term);
        
        setScreenState(newState);
    }
    
    const onDateChanged = async (startDate, endDate) => {
        const newState = {...screenState};
        newState.startDate = startDate;
        newState.endDate = endDate;
        
        setScreenState(newState);
    };

    const onDateClicked = async (startDate, endDate) => {
        const newState = {...screenState};
        newState.startDate = startDate;
        newState.endDate = endDate;
        newState.term = "";
        newState.users = null;
        
        setScreenState(newState);
    };
    
    const onSession = (session) => {
        if (!!session?.id) {
            const newState = {...screenState, items: null };
            console.log("Session Good... Getting");
            setScreenState(newState);
        }
    };
    
    useEffect(() => {
        let _ = getPeopleAsync();
    }, [screenState]);
    
    const elements = ((users?.length || 0) === 0) ? 
        (screenState.users === null ? (<div><h1>Loading...</h1></div>) : (<tr key={"empty"}><td colSpan={4} style={{textAlign: "center", padding: "48px 0"}}><EmptyBox>No Users Found</EmptyBox></td></tr>)) : 
        users.map((user, index) => {
            const link = "" + user.id;
            const imageUrl = user?.image?.url || user?.imageUrl || null;
            const profileImage = !!imageUrl ? (<a onClick={(e) => showImage(e, imageUrl)}><img alt={"Profile Photo"} className={"profile size24"} src={imageUrl} /></a>) :
                (<Initials size={24}>{user?.name}</Initials>);
    
            return (<tr key={"person-" + index}>
                    <td>{profileImage}</td>
                    <td><Link to={link}>{user.firstName + " " + user.lastName}</Link></td>
                    <td><Link to={link}>{user.email}</Link></td>
                    <td><DateTime date={user.created} time={true} /></td>
                </tr>);
        });
    
    const usersBody = screenState?.users !== null ? (<table className={"table"}>
        <thead>
        <tr>
            <th></th>
            <th>Name</th>
            <th>Email</th>
            <th>Date</th>
        </tr>
        </thead>
        <tbody>
        {elements}
        </tbody>
    </table>) : (<LoadingBox>Loading Users...</LoadingBox>);
    
    return (<AdminMasterScreen onSession={onSession} title={"People"} selection={"people"}>
            <div className={"date-search"}>
                <div><SearchFilter items={UserService.instance.users} onChange={(e, items) => onSearchType(e, items)} onClick={onSearchAsync} /></div>
                <div style={{flex: "4"}}></div>
                <div><DatePicker onChange={onDateChanged} onClick={onDateClicked} /></div>
            </div>
            
            <div className={"metric"}>
                {usersBody}
            </div>
        </AdminMasterScreen>
    );

};

export default PeopleScreen;