import './App.css';
import './components/metrics/ui/Metrics.css';
import AdminMasterScreen from "./routes/AdminMasterScreen";
import AuthenticationService from "./components/authentication/services/AuthenticationService";
import {useEffect, useState} from "react";
import BarChart from "./components/metrics/ui/charts/BarChart";
import MetricService from "./components/metrics/services/MetricService";
import HabitService from "./components/habits/services/HabitService";
import PlatformDashboard from "./components/metrics/ui/PlatformDashboard";
import FeedbackList from "./components/messaging/ui/FeedbackList";
import LineChart from "./components/metrics/ui/charts/LineChart";

function App() {
    const [user, setUser] = useState(AuthenticationService.instance.session?.user || null);
    const me = AuthenticationService.instance.session?.user;

    const name = user?.firstName || me?.firstName || JSON.stringify(me) || "Ja!";
    const [metrics, setMetrics] = useState({ counts: null });

    const options = useState({
        users: {
            endDate: new Date(),
            startDate: new Date().addDays(-14),
            fetch: async (opts) => {
                return await MetricService.instance.getUserCountsAsync(opts);
            }, 
        },
        screenViews: {
            endDate: new Date(),
            startDate: new Date().addDays(-14),
            fetch: async (opts) => {
                return await MetricService.instance.getScreenViewsAsync(opts);
            },
        },
        feedback: {
            status: [0],
            empty: true,
            force: true,
        },
        habits: {},
    })[0];
    
    const setControllers = () => {
        AuthenticationService.instance.addEventListener("auth", (session) => {
            if (!session?.id) {
                console.warn("No Session on event 'Auth', exiting.");
            }
            
            console.warn("Session Good.");
            
            let _ = HabitService.instance.getHabitsAsync();
            if (typeof options.feedback?.refresh === "function") options.feedback.refresh();
            
        }, "app");
        
        return async () => {
            AuthenticationService.instance.removeEventListener("auth", "app");
        };
    };
    
    const getTitle = () => {
        const u = AuthenticationService.instance.session?.user;
        if (!u?.id) return null;
        
        const hr = new Date().getHours();
        const prefix = (hr > 4 ? "Good Evening, " : hr > 12 ? "Good Afternoon, " : "Good Morning, ");
        
        if (!u?.firstName) return prefix;
        return "Good Evening, " + (u?.firstName || "");
    };
    
    const onSession = (session) => {
        setUser(session?.user);
    };
    
    useEffect(() => {
        const x = setControllers();

        //
        
        return x;
    }, []);
    
    const title = getTitle();
    const titleElement = !!title ? (<h2 className={"main-title"}>{title}</h2>) : null;
    
    return (<AdminMasterScreen title={"DEF Reset Admin"} onSession={onSession}>
        {titleElement}
        
        <p></p>
        
        <PlatformDashboard />
        
        <div className={"constrain"}>
            <FeedbackList className={"top32"} options={options.feedback} />
        </div>

        <div className={"constrain"}>
            <BarChart className={"top32"} title={"User Registrations by Day"} options={options.users} />
            <LineChart className={"top32"} title={"Screen Views by Day"} options={options.screenViews} />
        </div>
        
    </AdminMasterScreen>);
}

export default App;
