class VideoViewModel {
    static entityType = 69;
    static create = (json) => new VideoViewModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.videoId = json.video_id || null;
        this.userId = json.user_id || null;
        this.timestamp = json.timestamp || null;
        this.deviceType = json.device_type || 0;
        this.screenWidth = json.screen_width || 0;
        this.screenHeight = json.screen_height || 0;
        this.ipAddress = json.ip_address || null;
        this.userAgent = json.user_agent || null;
        this.lat = json.lat || null;
        this.lon = json.lon || null;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            video_id: this.videoId,
            user_id: this.userId,
            timestamp: this.timestamp,
            device_type: this.deviceType,
            screen_width: this.screenWidth,
            screen_height: this.screenHeight,
            ip_address: this.ipAddress,
            user_agent: this.userAgent,
            lat: this.lat,
            lon: this.lon,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.screenWidth?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.screenHeight?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.ipAddress?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.userAgent?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.lat?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.lon?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => VideoViewModel.create(json));
    }
}

export default VideoViewModel;
