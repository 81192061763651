import React, {useEffect, useState} from 'react';
import ExerciseService from "../services/ExerciseService";
import SearchFilter from "../../common/ui/search-filter/SearchFilter";
import Initials from "../../common/ui/Initials";
import DefReset from "../../common/models/DefReset";
import ExerciseActionPanel from "./ExerciseActionPanel";
import DropTarget from "../../common/ui/DropTarget";
import FileUploadModel from "../../common/models/FileUploadModel";
import {Link} from "react-router-dom";

const ExercisesList = (props) => {
    const { className, excludeIds, actions, onActionClick, id, onClick, options} = props;
    
    const [data, setData] = useState({ term: "", isFocused: false, excludeIds: Array.isArray(excludeIds) ? excludeIds : [] });
    const [exercises, setExercises] = useState(ExerciseService.instance.exercises || []);

    const getExercisesAsync = async (force = false) => {
        const es = await ExerciseService.instance.getExercisesAsync();
        
        if (!es?.length) {
            console.error("Exercises not found: ", es);
            return exercises;
        }

        setExercises(es);

        return es;
    };
    
    const onFilter = (term, items) => {
        data.term = term;
        
        // if (typeof options.filter === "function")
        //     items = options.filter(items, term);

        if (!Array.isArray(items)) throw new Error("Items is not an array");
        
        if (Array.isArray(data.excludeIds)) {
            console.log("Filtering Exercise Items[" + data.excludeIds.length + "] with count: ", items.length);
            items = items.filter((exercise) => !data.excludeIds.includes(exercise.id));
            console.log("Filtered Exercise Items[" + data.excludeIds.length + "] with count: ", items.length);
        }
        
        setExercises(items);
    };
    
    const onItemClick = (e, exercise) => {
        DefReset.stopEvent(e);
        console.log("Exercise Click: ", exercise?.name);
        
        if (typeof onClick !== "function") return;
        
        onClick(exercise);
    }
    
    const onPanelItemClick = (exercise, index, e) => {
        DefReset.stopEvent(e);
        
        const rsp = (typeof onActionClick === "function") ? onActionClick(exercise, index) : null;
        
        if (Array.isArray(rsp)) {
            console.warn("Setting Data: ", rsp.length);
            setData({...data, excludeIds: rsp});
        } else {
            console.error("Response is not an array");
        }
    };
    
    const onFileDroppedAsync = async (index, fileItems, otherItems, payload, e) => {
        DefReset.stopEvent(e);
        console.log("File Count: ", fileItems.length);
        if (fileItems.length <= 0) {
            console.warn("No files dropped");
            return;
        }
        
        const f = new FileUploadModel(fileItems[0], "", (a, b, c) => {
            console.log("a: ", a);
            console.log("b: ", b);
            console.log("c: ", c);
        });
    };
    
    const onFocusChange = (isFocused, e) => {
        const newData = {...data, isFocused: isFocused};
        console.log("Focus Changed: " + isFocused);
        
        setData(newData);
        
        return isFocused;
    };

    const setControls = () => {
        if (typeof options === "object" && !!options) {
            options.setExcludedIds = (excludedIds) => {
                setData({...data, excludeIds: excludedIds});
            };
            
            options.refresh = async (force = true) => {
                return await getExercisesAsync(force);
            };
            
            //
        }
    };
    useEffect(() => {
        setControls();
    }, [exercises, data]);

    useEffect(() => {
        let _ = getExercisesAsync();
    }, []);
    
    const createPanel = typeof onActionClick === "function" ?
        (exercise, index) => {
            const actionItems = Array.isArray(actions) ?
                actions.map((a, i) => (<span className={"exercise-action-button"} key={"item-" + i.toString()} onClick={(e) => onPanelItemClick(exercise, i, e)}>{a.name || "Add+"}</span>)) :
                null;
            
            if (actionItems == null) return null;
            
            return (<span>{actionItems}</span>);
        } :
        (exercises, index) => null;
    
    if (!Array.isArray(exercises)) {
        console.warn("Bad Exercises: ");
        console.log(exercises);
    }
    
    const elements = exercises?.map((exercise, i) => {
        const imageElement = exercise.imageUrl ?
            (<img src={exercise.imageUrl} alt={exercise.name} />) :
            (<Initials borderRadius={8}>{(exercise.name || "E")}</Initials>);
        
        const innerItems = (<>
            <td className={"image"}><Link to={"/workouts/exercises/" + exercise.id}>{imageElement}</Link></td>
            <td>
                <h3>{exercise.name}</h3>
                <p>{exercise.description || exercise.id || "No description available"}</p>
            </td>
            <td>{createPanel(exercise, i)}</td></>);

        return (<DropTarget tr={true} onDrop={(fileItems, otherItems, payload, e) => onFileDroppedAsync(i, fileItems, otherItems, payload, e)} key={exercise.id} className={"clickable"} onClick={(e) => onItemClick(e, exercise)}>
            {innerItems}
        </DropTarget>);
    });
    
    const focusClass = data.isFocused ? "focused" : "";
    
    return (<div className={"exercises-list"}>
        <div className={"long-filter"}>
            <SearchFilter onChange={onFilter} 
                  onFocusChange={onFocusChange} 
                  options={options}
                  items={ExerciseService.instance.exercises || []} 
                  fields={["name", "description"]} 
            />
        </div>
        <div className={"exercises " + focusClass + ""}>
            <div>
                <table className={"exercises-table"} width={"100%"}>
                    <tbody>{elements}</tbody>
                </table>
            </div>
        </div>            
    </div>);
};

export default ExercisesList;
