import React, {useState} from 'react';
import WorkoutMasterScreen from "./WorkoutMasterScreen";
import {Link, useParams} from "react-router-dom";
import WorkoutService from "../../components/workouts/services/WorkoutService";
import MediaUploader from "../../components/media/ui/MediaUploader";

const WorkoutMediaScreen = (props) => {
    const {} = props;
    const {parentWorkoutId, workoutId} = useParams();
    const [workouts, setWorkouts] = useState({ workout: WorkoutService.instance.workoutMap[workoutId || ""], parentWorkout: WorkoutService.instance.workoutMap[parentWorkoutId || ""]});

    const onWorkouts = (w, p) => {
        w.parentWorkout = p;
        setWorkouts({ workout: w, parentWorkout: p });
    };

    const workout = workouts?.workout;
    const uploadUrl = !!workout?.id ? "/api/admin/workout/" + workout.id + "/media" : null;
    
    const navWorkoutElements = !!workout?.parentWorkout?.id ?
        (<><Link to={"/workouts/" + workout.parentWorkout.id}>{workout.parentWorkout?.name}</Link> <span className={"bullet"}>&#10095;</span> </>) :
        null;

    const media = workouts?.workout?.media;
    const mediaElements = Array.isArray(media) ? media.map((m, i) => {
        return (<div key={m.id + "-" + i} className={"card"}>
            <img src={m.url} alt={""} />
        </div>);
    }) : [(<div key={"no-media-key"}>No Media</div>)];
    
    const uploadForm = !!workouts?.workout && !!uploadUrl ? (<MediaUploader uploadUrl={uploadUrl}>
        <div key={"upload-body"} className={""} style={{display: "inline-block"}}>
            <div className={"wrap-grid"}>
                {mediaElements}
            </div>
        </div>        
    </MediaUploader>) : null;
    
    return (<WorkoutMasterScreen onWorkout={onWorkouts} selection={"media"}>
        <div className={"pad"}>
            <h3 className={"nav"}>
                {navWorkoutElements}
                <span>{workout?.name} Media</span>
            </h3>

            <p>Media for the workout.</p>
            {uploadForm}
        </div>
    </WorkoutMasterScreen>);

};

export default WorkoutMediaScreen;
