import React from 'react';

const Initials = (props) => {
    const { children, size, borderRadius } = props;

    let initials = (children?.toString() || "").trim();
    if (!initials) initials = "NA";
    else {
        initials = initials.split(" ", 2).map((n) => n[0]).join("");
    }
    
    const sz = typeof size === "number" && size > 0 ? size : 64;
    const style = {width: sz + "px", height: sz + "px", fontSize: Math.round(sz * 0.4) + "px", borderRadius: borderRadius || null};
    
    return (<span style={style} className={"initials"}>{initials.toUpperCase()}</span>);
};

export default Initials;