import React, {useState} from 'react';
import {Link} from "react-router-dom";

const MealSubMenu = (props) => {
    const { selection, meal, s, trailing } = props;
    const classes = {};
    
    classes[selection || "main"] = "selected";

    const basePath = meal?.id ?
        ("/fuel/meals/" + meal?.id) : null;

    return (<div className={"meal-sub-menu"}>
        <div className={"sub-sub-menu"}>
            <span className={classes["main"] || ""}><Link to={basePath}>Edit Description</Link></span>
            <span className={classes["recipes"] || ""}><Link to={basePath + "/recipes"}>Recipe Details</Link></span>
            <span className={classes["directions"] || ""}><Link to={basePath + "/directions"}>Directions</Link></span>
            {trailing}
        </div>
    </div>);
};

export default MealSubMenu;
