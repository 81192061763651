class PointModel {
    static entityType = 55;
    static create = (json) => new PointModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.userId = json.user_id || null;
        this.value = json.value || 0;
        this.pointKey = json.point_key || null;
        this.remoteId = json.remote_id || null;
        this.description = json.description || null;
        this.status = json.status || 0;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            user_id: this.userId,
            value: this.value,
            point_key: this.pointKey,
            remote_id: this.remoteId,
            description: this.description,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.value?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.pointKey?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.remoteId?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.description?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => PointModel.create(json));
    }
}

export default PointModel;
