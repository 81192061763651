import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import UserService from "../../components/users/services/UserService";
import PersonMasterScreen from "./UserMasterScreen";

const UserAbuseScreen = (props) => {
    const {} = props;
    const { userId } = useParams();
    const [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    const [options, setOptions] = useState({ reactClassName: "PersonHabitsScreen" });

    const deleteUserAsync= (e) => {
        
    };
    
    useEffect(() => {
        if (!!user?.id && typeof options?.setTitle == "function")
            options.setTitle(user?.name);
    }, [user]);

    useEffect(() => {
        //
    }, []);

    return (
        <PersonMasterScreen user={user} options={options} onUser={setUser} selection={"abuse"}>
            <div className={"pad constrain"}>
                <h2>Abuse and Reports</h2>
                <p className={"p"}>
                    Be <strong>VERY careful</strong> with the actions below. Some are irreversible and will delete all data for the user.
                </p>
                
                <div className={"panel"}>
                    <h4>Delete User</h4>
                    <p>Remove all info and cancel their account</p>
                    <button onClick={deleteUserAsync}>Delete User</button>
                </div>

                
            </div>
        </PersonMasterScreen>
    );


};

export default UserAbuseScreen;