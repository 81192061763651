import React, {useEffect, useState} from 'react';
import UserMasterScreen from "../UserMasterScreen";
import {useParams} from "react-router-dom";
import VideoPlayer from "../../../components/media/ui/VideoPlayer";
import MediaGallery from "../../../components/media/ui/MediaGallery";
import LoadingBox from "../../../components/common/ui/LoadingBox";
import UserMediaGallery from "../../../components/users/ui/UserMediaGallery";

const UserMediaScreen = (props) => {
    const { } = props;
    const { userId, id } = useParams();
    const [screenState, setScreenState] = useState({ refresh: false, user: null });

    const onUser = (u) => {
        const newState = { ...screenState, user: u };
        setScreenState(newState);
    };

    useEffect(() => {
        
    }, []);
    
    const user = screenState.user;
    const titleWidget = !!user?.id ? (<h2>{user.name}: Media</h2>) : null;
    const galleryElement = !!user?.id ? (<UserMediaGallery userId={userId} />) : (<LoadingBox />);
    
    return (<UserMasterScreen onUser={onUser} selection={"media"}>
        <div className={"pad"}>
            {titleWidget}
            
            <p>Media Gallery</p>
            
            {galleryElement}
        </div>
    </UserMasterScreen>);
};

export default UserMediaScreen;
