import React, {useEffect} from 'react';

import { useState } from 'react';
import DefReset from "../../models/DefReset";

const SortableList = (props) => {
    const { children, options, onDrop, tagName:elementTagName, id:elementId } = props;
    const [items, setItems] = useState(children);
    const [draggedItem, setDraggedItem] = useState(null);
    
    const onDragDrop = (e, index) => {
        if (typeof onDrop !== "function") return;
        DefReset.stopEvent(e);
        
        const dataItems = items.map((item, index) => {
            return {
                index: index,
                item: item,
                key: item.props?.slot,
                itemKey: item.props?.key,
                tabIndex: item.props?.tabIndex || index,
                id: item.props?.id,
            };
        });
        
        onDrop(dataItems, index, e);
    };

    const handleDragStart = (e, index) => {
        console.log("Drag Sort: ", index);
        setDraggedItem(items[index]);
        e.dataTransfer.effectAllowed = 'move';
    };

    const handleDragOver = (e, index) => {
        console.log("Drag Over: ", index);
        DefReset.stopEvent(e);
        if (items[index] === draggedItem) return;

        const newItems = [...items];
        const draggedItemIndex = items.indexOf(draggedItem);
        
        newItems.splice(draggedItemIndex, 1);
        newItems.splice(index, 0, draggedItem);
        setItems(newItems);
    };

    useEffect(() => {
        //
    }, [items]);
    
    const body = Array.isArray(items) ? items.map((sortableListItem, index) => {
        const onDragStart = handleDragStart;
        const onDragOver = handleDragOver;
        const ti = typeof sortableListItem.props?.tabIndex === "number" ? sortableListItem.props.tabIndex : index;
        const key = "sortable-" + ti + "";
        
        let className = (sortableListItem.props?.className || "");
        if (className.indexOf("sortable-item") < 0) className = ("sortable-item " + className).trim();

        const newProps = {
            ...sortableListItem.props,
            draggable: true,
            key: key,
            className: className,
            onDragStart: (e) =>  onDragStart(e, index),
            onDragOver: (e) => onDragOver(e, index),
            onDrop: (e) => onDragDrop(e, index),
        };
        
        const elementTagName = sortableListItem.type || "div";
        return React.createElement(elementTagName, newProps, sortableListItem.props?.children || sortableListItem);
        
    }) : (<div className={"error"}>No items to sort. The body of a SortableList must be an array {JSON.stringify(items, null, 4)}</div>);
    
    let tagName = "ul";
    if (typeof elementTagName === "string") tagName = elementTagName;
    else if (children?.length === 0 || (children?.length > 0 && children[0].type === "li")) {
        tagName = "ul";
    } else {
        tagName = "div";
    }
    
    const tagProps = { className: options?.className || "sortable-item", id: elementId || "sortable-list" };
    
    return React.createElement(tagName, tagProps, body);
};

SortableList.createItems = (items, idField = "key") => {
    if (!Array.isArray(items)) return [];
    
    return items.map((item, index) => {
        let id = null;
        const itemType = (typeof item).toString();
        
        if (["string", "boolean", "number"].includes(itemType)) id = item;
        else if (!!idField) id = item[idField];
        
        if (!id) id = item?.props?.slot || item?.id || item?.key || null;
        
        return { id: id, sort: index };
    });
};

export const SortableGrip = (props) => {
    const { className } = props;
    return (<span className={"grip " + (className ? " " + className : "")}>&#x2630;</span>);
};


export default SortableList;
