import React from 'react';
import AdminMasterScreen from "../AdminMasterScreen";
import FuelMasterScreen from "./FuelMasterScreen";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBowlFood, faBowlRice, faCarrot, faGears, faJar} from "@fortawesome/free-solid-svg-icons";
import FuelSubMenu from "../../components/fuel/ui/FuelSubMenu";
import {Link} from "react-router-dom";

const FuelScreen = (props) => {
    const {} = props;

    const elements = FuelSubMenu.menuItems.filter((x) => !!x.icon).map((m, i) => {
        //
        return (<Link key={"tile-" + i} className={"tile"} to={"/fuel/" + m.path}>
            <h3>{m.caption}</h3>
            <FontAwesomeIcon size={"3x"} icon={m.icon} />
            <p>{m.description}</p>
        </Link>);
    });
    
    return (<FuelMasterScreen>
            <div className={"pad"}>
                <div>
                    <h2>Fuel Section Overview</h2>
                    <p className={"p"}>
                        This section is where you manage meals, recipes, products, and other Fuel related settings
                    </p>
                </div>
                
                <div className={"wrap-grid"} style={{gap: "16px"}}>
                    {elements}
                </div>
            </div>
        </FuelMasterScreen>
    );

};

export default FuelScreen;
