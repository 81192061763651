class ScreenViewModel {
    static entityType = 67;
    static create = (json) => new ScreenViewModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.userId = json.user_id || null;
        this.version = json.version || null;
        this.sessionId = json.session_id || null;
        this.screenName = json.screen_name || null;
        this.deviceType = json.device_type || 0;
        this.deviceTypeName = json.device_type_name || "";
        this.screenWidth = json.screen_width || 0;
        this.screenHeight = json.screen_height || 0;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;

        this.json = json || null;
    }
    
    toJson() {
        return {
            user_id: this.userId,
            session_id: this.sessionId,
            screen_name: this.screenName,
            device_type: this.deviceType,
            screen_width: this.screenWidth,
            screen_height: this.screenHeight,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.screenName?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.screenWidth?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.screenHeight?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => ScreenViewModel.create(json));
    }
}

export default ScreenViewModel;
