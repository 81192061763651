import ExerciseModel from "../models/ExerciseModel";
import HttpService from "../../common/services/HttpService";

class ExerciseService {
    static instance = new ExerciseService();

    constructor(props) {
        this.exercises = [];
        this.exerciseMap = {};
    }

    async getExercisesAsync() {
        const path = "/api/exercise";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.exercises = response.data.map((exercise) => {
                const e = new ExerciseModel(exercise);
                if (!me.exerciseMap[e.id]) me.exerciseMap[e.id] = e;

                return e;
            });

            return me.exercises;
        });
    }
    
    async getExerciseAsync(exerciseId) {
        const path = '/api/exercise/' + exerciseId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const e = new ExerciseModel(response.data);
            
            if (!e?.id) return null;
            
            me.exerciseMap[exerciseId] = e;
            
            return e;
        });
    }
    
    async saveExerciseAsync(exerciseJson, exerciseId = null) {
        const path = "/api/exercise" + (typeof exerciseId === "string" && exerciseId.length > 30 ? "/" + exerciseId : "");
        console.log("Saving exercise: " + exerciseId);
        
        const rsp = await HttpService.instance.postAsync(path, exerciseJson);
        
        if (!rsp?.data?.id) return null;
        
        const exercise = new ExerciseModel(rsp.data);
        
        this.exerciseMap[exercise.id] = exercise;
        
        return exercise;
    }
}

export default ExerciseService;
