class ProductModel {
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.name = json.name || null;
        this.description = json.description || null;
        this.price = json.price || 0;
        this.status = json.status || 0;
        this.remoteId = json.remote_id || "";
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new ProductModel(json));
    }
}

export default ProductModel;
