import React, {useEffect, useState} from 'react';
import CommentsList from "../../components/messaging/ui/comments/CommentsList";
import ReactModal from "../../components/common/ui/react-modal/ReactModal";
import DebugJson from "../../components/common/ui/DebugJson";
import {useParams} from "react-router-dom";
import {setOptions} from "marked";
import AdminMasterScreen from "../AdminMasterScreen";
import ModerationMasterScreen from "./ModerationMasterScreen";
import MediaGallery from "../../components/media/ui/MediaGallery";
import MediaService from "../../components/media/services/MediaService";
import LoadingBox from "../../components/common/ui/LoadingBox";
import VideoPlayer from "../../components/media/ui/VideoPlayer";
import DefReset from "../../components/common/models/DefReset";
import MediaForm from "../../components/media/ui/MediaForm";

const ModeratedMediaScreen = (props) => {
    const {} = props;
    const { entityId } = useParams();

    const [screenState, setScreenState] = useState({ 
        refresh: false,
        user: null,
        media: MediaService.instance.platformMediaItems || null,
    });
    
    const [options, setOptions] = useState({
        endDate: null,
        startDate: null,
        mediaOptions: {
            captions: false,
            sizeTag: "256x256",
        },
        entityId: entityId,
        url: "/api/comment/" + ((typeof entityId === "string" && entityId.length > 30 ? entityId : "") || "all"),
    });

    const onSession = (sh) => {
        if (!!sh?.user?.id) {
            let _ = getMediaItemsAsync();
        }
    };
    
    const refreshAsync = async (e) => {
        DefReset.stopEvent(e);
        return await getMediaItemsAsync(true);
    };
    
    const getMediaItemsAsync = (force = false) => {
        if (!force && Array.isArray(screenState.media)) return screenState.media;
        
        const options = {};
        
        return MediaService.instance.getPlatformMediaAsync(options).then((media) => {
            setScreenState({ ...screenState, media: media });
            return media;
        });
    };
    
    const onMediaSaved = (mediaItem, index) => {
        if (index >= 0 && !!mediaItem?.id) {
            const newState = {...screenState};
            newState.media[index] = mediaItem;
            console.log("SAVED [" + index + "]: ", mediaItem);

            setScreenState(newState);
        } else {
            console.error("Not saved: ", mediaItem);
            console.log(" > Index: ", index);
        }
    };    

    useEffect(() => {
        //console.log("UseEffect Comments Screen: ", entityId);
        let _ = getMediaItemsAsync();
    }, [screenState]);

    const mediaGallery = screenState.media?.length >= 0 ?
        (<MediaGallery options={options.mediaOptions} media={screenState.media} onSave={onMediaSaved} />) :
        (<LoadingBox>Loading Media</LoadingBox>);
    
    return (<ModerationMasterScreen selection={"media"} onSession={onSession}>
        <div className={"pad"}>
            <h2 className={"nav with-button"}>
                <span>Incoming Media</span>
                <span className={"add-new"}>
                    <a onClick={(e) => refreshAsync(e)}>Refresh</a>
                </span>
            </h2>
            
            <div className={"user-media-gallery"}>
                {mediaGallery}
            </div>
        </div>
    </ModerationMasterScreen>);
};

export default ModeratedMediaScreen;
