import React from 'react';

const ErrorBox = (props) => {
    const { children, id, className } = props;

    const cn = typeof className === "string" ? className : "";
    const elementId = typeof id === "string" ? id : "";
    return (<span id={elementId} className={"error-box " + cn}>{children}</span>);
};

export default ErrorBox;