import React, {useEffect, useState} from 'react';
import FuelMasterScreen from "./FuelMasterScreen";
import MealSubMenu from "../../components/fuel/ui/MealSubMenu";
import IngredientListSelector from "../../components/fuel/ui/IngredientListSelector";
import {useParams} from "react-router-dom";
import MediaUploader from "../../components/media/ui/MediaUploader";
import ImageModel from "../../components/media/models/ImageModel";
import MealModel from "../../components/fuel/models/MealModel";
import MealService from "../../components/fuel/services/MealService";
import MediaService from "../../components/media/services/MediaService";
import DefReset from "../../components/common/models/DefReset";
import DefButton from "../../components/common/ui/elements/DefButton";
import SortableList from "../../components/common/ui/sortable-list/SortableList";
import LoadingBox from "../../components/common/ui/LoadingBox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSort} from "@fortawesome/free-solid-svg-icons";
import CollectionService from "../../components/collections/services/CollectionService";

const MealRecipeDirectionsScreen = (props) => {
    const {} = props;
    const { mealId:recipeId } = useParams();
    const uploadOptions = useState({ clearOnComplete: true })[0];
    const [screenState, setScreenState] = useState({
        id: 0,
        recipe: null,
        editId: null,
        status: 0,
        isSorting: false,
    });

    const recipe = screenState.recipe || {};
    const uploadKey = recipe?.id || "new";

    const toggleIsSorting = (e, forceValue = null) => {
        if (typeof forceValue !== "boolean") forceValue = !screenState.isSorting;
        setScreenState({ ...screenState, isSorting: forceValue });
    };
    
    const onDrop = (fileModels, items) => {
        //
    };
    
    const onImageClicked = (e) => {};

    async function scrollToElementAsync(elementId, duration = 500) {
        return new Promise((resolve, reject) => {

            const targetElement = document.querySelector("#" + elementId);
            const targetPosition = (targetElement.getBoundingClientRect().top + window.scrollY) - 128;
            const startPosition = window.scrollY;
            const distance = targetPosition - startPosition;

            let startTime = null;

            function animation(currentTime) {
                if (startTime === null) startTime = currentTime;

                const timeElapsed = currentTime - startTime;
                const run = easeInOutQuad(timeElapsed, startPosition, distance, duration);

                window.scrollTo(0, run);

                if (timeElapsed < duration) requestAnimationFrame(animation);
                else {
                    //
                    resolve();
                }
            }

            function easeInOutQuad(t, b, c, d) {
                t /= d / 2;
                if (t < 1) return (c / 2) * t * t + b;
                t--;

                return (-c / 2) * (t * (t - 2) - 1) + b;
            }

            requestAnimationFrame(animation);
        });
    }
    
    const onImageUploaded = async (imageSets) => {
        if (!Array.isArray(imageSets)) {
            if (!!imageSets?.url) imageSets = [imageSets];
            else return;
        }
        
        const imageId = imageSets[0].id;
        await getRecipeAsync(true, { scrollToId: imageId, editId: imageId });
    };
    
    const scrollToImage = (imageId, e) => {
        DefReset.stopEvent(e);
        let _ = scrollToElementAsync("direction-item-" + imageId, 400);
    };
    
    const onNewImageDrop = (fileModels) => {
        if (!screenState.recipe?.id) return false;
        
        const json = { description: "Add Description" };
        const recipe = screenState.recipe;
        
        if (!Array.isArray(recipe.media)) recipe.media = [];
        
        const newState = {...screenState};
        newState.recipe = recipe;

        for(let i = 0; i < fileModels.length; i++) {
            const fileModel = fileModels[i];
            const json = { url: fileModel.filePath, id: "new-" + (Math.random() * 99999999).toString(16).toLowerCase() };
            
            recipe.media.push(new ImageModel(json));
        }
        
        setScreenState(newState);
    }
    
    const onSortDrop = async (sortItems, index, e) => {
        const models = sortItems.map((item, index) => {
            return {
                id: item.id,
                sort: index,
            };
        });
        
        await CollectionService.instance.updateSortingAsync(models, recipeId);
    };
    
    const deleteMediaAsync = async (id, e) => {
        DefReset.stopEvent(e);
        
        const elementId = "direction-item-" + id;
        DefReset.setClassName(elementId, "hidden");
        
        await MediaService.instance.deleteCollectionItemAsync(id, recipeId, MealModel.entityType);
        await getRecipeAsync(true);
    };
    
    const selectMedia = (id, e) => {
        DefReset.stopEvent(e);
        setScreenState({ ...screenState, editId: id});
    };

    const saveDirectionsAsync = async (imageSet, e) => {
        DefReset.stopEvent(e);
        if (!imageSet?.id) return false;
        
        const description = document.getElementById("image-description-" + imageSet.id)?.value || "Add Description";
        
        const json = {
            name: recipe.name + " Directions",
            description: description,
        };
        
        const m = await MediaService.instance.saveImageAsync(json, imageSet.id);
        await getRecipeAsync(true);
    };
    
    const getRecipeAsync = async (force = false, postExtras = null) => {
        if (!!recipe?.id && recipe.id.length > 30 && force !== true)
            return recipe;

        const m = await MealService.instance.getMealAsync(recipeId);

        if (!!m?.id) {
            console.log("Got recipe good: ", m);

            if (!postExtras) postExtras = {};
            
            const newState = {
                ...screenState,
                recipe: m,
                editId: null, ...postExtras,
            };
            
            setScreenState(newState);

            return m;
        }

        console.error("Failed to get recipe: ", m);
        return recipe;
    };
    
    const toHtmlElements = (text) => {
        const lines = (text || "").trim().split("\n");
        return lines.map((item, index) => (<p key={"line-" + index}>{item}</p>));
    };

    useEffect(() => {
        if (!!screenState.scrollToId) {
            scrollToImage(screenState.scrollToId, null);
            screenState.scrollToId = null;
        }
        
    }, [screenState]);
    
    useEffect(() => {
        if (!recipeId) return;
        let _ = getRecipeAsync();
    }, []);
    
    const elements = recipe?.media?.map((mediaItem, index) => {
        const itemBody = (screenState.editId === mediaItem.id) ? (<div slot={mediaItem.id}>
                <div><textarea style={{height: "120px"}} defaultValue={mediaItem.description} id={"image-description-" + mediaItem.id}></textarea></div>
                <div style={{padding: "12px 0", alignItems: "center", width: "100%"}} className={"flex"}>
                    <span><DefButton
                        onClick={(e) => saveDirectionsAsync(mediaItem, e)}>Save Directions</DefButton></span>
                    <span style={{marginLeft: "auto"}}><a onClick={(e) => deleteMediaAsync(mediaItem.id, e)}>Delete</a></span>
                    <span style={{marginLeft: "16px"}}><a onClick={(e) => selectMedia(null, e)}>Cancel</a></span>
                </div>
            </div>
        ) : (<p className={"text-editable"} onClick={(e) => selectMedia(mediaItem.id, e)}>
        {toHtmlElements(mediaItem.description || "Click to edit")}
            </p>);
        
        return (<div id={"direction-item-" + mediaItem.id} key={"media-" + mediaItem.id} className={"fader cooking-direction"}>
            {itemBody}
            <div><img className={"cooking-direction-image"} src={mediaItem.url}/></div>
        </div>);
    });

    const activityElement = (<div className={"center-uploading"}>
        <span className={"flex-label"}>
            <span className={""}>Uploading...</span>
            <label className={"spinner"}></label>
        </span>
    </div>);
    
    const directionsElement = Array.isArray(elements) ? 
        (<SortableList onDrop={onSortDrop} className={"form"} tagName={"div"}>{elements}</SortableList>) : 
        (<LoadingBox />);
    
    const sortClassName = screenState.isSorting ? "sorting" : "";
    const sortIcon = (<div style={{marginLeft: "auto"}} className={"sort-icon"}>
        <a onClick={(e) => toggleIsSorting(e)}>
            <FontAwesomeIcon icon={faSort}/>
            <span>Sort Directions</span>
        </a>
    </div>);
    
    return (<FuelMasterScreen selection={"meals"} title={"Meal Recipe"}>
        <div className={"pad recipe-directions " + sortClassName}>
            <h2 className={""} onClick={(e) => scrollToImage("ec088924-d326-49bd-823a-6d12e7d58df0", e)}>
                <span>Recipe: {recipe?.name}</span>
                <span></span>
            </h2>

            <MealSubMenu meal={recipe} selection={"directions"} trailing={sortIcon} />
            
            <div className={"un-sort"}>
                <MediaUploader 
                    options={uploadOptions}
                    activity={activityElement}
                    onClick={onImageClicked} 
                    onComplete={onImageUploaded} uploadUrl={"/api/media/" + MealModel.entityType + "/collection/" + recipeId + "?save=1"}
                    className={"directions-image-dropper"} count={1} onDrop={onNewImageDrop}
                >
                    <div className={"non-working"}>Drop a Large Image Here to Add a Step</div>
                    <div className={"working-only spinner"}>
                        <label></label>
                    </div>
                </MediaUploader>
            </div>

            {directionsElement}
            
            <div>
                <pre>{JSON.stringify(recipe, null, 4)}</pre>
            </div>
        </div>
    </FuelMasterScreen>);

};

export default MealRecipeDirectionsScreen;  
