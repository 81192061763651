import React, {useEffect, useState} from 'react';
import AdminMasterScreen from "../AdminMasterScreen";
import {Link, useParams} from "react-router-dom";
import PreviewText from "../../components/common/ui/PreviewText";
import DateTime from "../../components/common/ui/DateTime";
import HabitService from "../../components/habits/services/HabitService";

const HabitsScreen = (props) => {
    const {} = props;
    const [habits, setHabits] = useState(HabitService.instance.habits || []);

    const getHabitDetailsAsync = async (force = false) => {
        if (Array.isArray(habits) && habits.length > 0 && force !== true)
            return habits;

        const hs = await HabitService.instance.getHabitsAsync();

        if (Array.isArray(hs)) {
            setHabits(hs);
            return hs;
        }

        return hs;
    };

    useEffect(() => {
        let _ = getHabitDetailsAsync();
    }, []);

    const elements = Array.isArray(habits) ? habits.map((habit, idx) => {
        const link = "/habits/" + habit.id;
        const className = habit.status === 0 ? "draft" : "";

        return (<tr className={className} key={"challenge-" + idx}>
            <td width={200}><Link to={link}>{habit.name?.toString()}</Link></td>
            <td className={"medium"} style={{paddingRight: "24px"}}><Link to={link}><PreviewText maxLength={128}>{habit.description?.toString()}</PreviewText></Link></td>
            <td className={"medium"}><Link to={link}><PreviewText maxLength={128}>{habit.longDescription || ""}</PreviewText></Link></td>
            <td width={120}><DateTime value={habit.modified || habit.created} /></td>
        </tr>);
    }) : null;

    return (<AdminMasterScreen selection={"habits"} title={"Habits"}>
        <div className={"pad"}>
            <div>
                <h2>Habits</h2>
                <p className={"p"}>Below are all the challenges issued; past, present, and future</p>
            </div>

            <div>
                <table className={"table habits"}>
                    <thead>
                    <tr>
                        <th width={200}>Name</th>
                        <th>Headline</th>
                        <th>Preview Description</th>
                        <th>Modified</th>
                    </tr>
                    </thead>

                    <tbody>{elements}</tbody>
                </table>
            </div>
        </div>

    </AdminMasterScreen>);

};

export default HabitsScreen;
