import React, {useEffect, useState} from 'react';
import CommentsList from "../../components/messaging/ui/comments/CommentsList";
import ReactModal from "../../components/common/ui/react-modal/ReactModal";
import DebugJson from "../../components/common/ui/DebugJson";
import {useParams} from "react-router-dom";
import {setOptions} from "marked";
import AdminMasterScreen from "../AdminMasterScreen";
import ModerationMasterScreen from "./ModerationMasterScreen";
import DefReset from "../../components/common/models/DefReset";

const CommentsScreen = (props) => {
    const {} = props;
    const { entityId } = useParams();
    
    const [options, setOptions] = useState({
        entityId: entityId,
        commentOptions: {
            endDate: null,
            startDate: null,
            useRelative: true,
        },
        url: "/api/comment/" + ((typeof entityId === "string" && entityId.length > 30 ? entityId : "") || "all"),
    });
    
    const refreshCommentsAsync = async (e) => {
        DefReset.stopEvent(e);

        if (typeof options?.commentOptions.refreshAsync === "function") {
            let _ = options.commentOptions.refreshAsync(true);
        } else {
            console.warn("No Options to refresh comments: ", options.commentOptions);
        }
    };
    
    const onCommentClick = async (commentModel, index, e) => {
        const dialogOptions = { title: "Comment Json" };
        const dialog = new ReactModal();
        const body = (<DebugJson value={commentModel} />);

        await dialog.openFormDialogAsync(body, (e) => {
            //
        }, dialogOptions);
    };
    
    useEffect(() => {
        //console.log("UseEffect Comments Screen: ", entityId);
    }, []);

    return (<ModerationMasterScreen selection={"comments"}>
        <div className={"pad"}>
            <h2 className={"nav with-button"}>
                <span>Comments</span>
                <span className={"add-new"}>
                    <a onClick={refreshCommentsAsync}>Refresh</a>
                </span>
            </h2>
            <div>
                <CommentsList entityId={entityId} onClick={onCommentClick} options={options.commentOptions} />
            </div>
        </div>
    </ModerationMasterScreen>);
};

export default CommentsScreen;
