import CommentModel from "../../messaging/models/CommentModel";
import UserModel from "../../users/models/UserModel";

class SocialStatsModel {
    
    constructor(json) {
        if (!json) json = {};

        this.entityId = json.entity_id || "";
        this.entityType = json.entity_type || 0;
        this.entityKey = SocialStatsModel.createKey(this.entityId, this.entityType);
        
        this.commentCount = json.commentCount || 0;
        this.likeCount = json.likeCount || 0;
        
        this.comments = CommentModel.fromJsonArray(json.comments);
        this.likes = UserModel.fromJsonArray(json.likes);
    }

    static createKey(entityId, entityType) {
        return entityId.toLowerCase() + "-" + entityType.toString();
    }

}

export default SocialStatsModel;
