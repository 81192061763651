import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import UserService from "../../components/users/services/UserService";
import PersonMasterScreen from "./UserMasterScreen";
import ChallengeService from "../../components/challenges/services/ChallengeService";

const UserChallengesScreen = (props) => {
    const {} = props;
    const { userId } = useParams();
    const [viewState, setViewState] = useState({
        user: UserService.instance.userMap[userId] || null,
        entries: ChallengeService.instance.entriesMap[userId] || [],
    });
    
    const [options, setOptions] = useState({ reactClassName: "UserChallengesScreen" });

    const setUser = (u) => {
        const newState = {...viewState};
        newState.user = u;
        setViewState(newState);
    };
    
    const setEntries = (es) => {
        const newState = {...viewState};
        newState.entries = es;
        setViewState(newState);
    };

    const user = viewState.user || null;
    const entries = viewState.entries || [];
    
    const getChallengeEntriesAsync = async (force = false, u = null) => {
        if (Array.isArray(viewState.entries) && viewState.entries.length > 0 && force !== true) return viewState.entries;
        
        const es = await ChallengeService.instance.getUserChallengeEntriesAsync(userId);
        
        if (Array.isArray(es)) {
            console.warn("Entries Good: " + es.length);
            const newState = {...viewState};
            
            if (!!u?.id) newState.user = u;
            setViewState(newState);
            
            return es;
        }
        
        if (!!u?.id) {
            setUser(u);
        }
        
        return es;
    };
    
    const onUser = (u) => {
        let _ = getChallengeEntriesAsync(false, u);
    };
    
    const setControllers = () => {
        // const screenId = "person-details";
        // AuthenticationService.instance.addEventListener("auth", (session) => {
        //     getUserAsync();
        // }, screenId);
        // return async () => {
        //     console.warn("Removing Details Event Listener");
        //     AuthenticationService.instance.removeEventListener("auth", screenId);
        // };
    };

    useEffect(() => {
        const id = Math.round(Math.random() * 999999).toString();
        setControllers();
    }, []);

    const elements = entries.map((entry, index) => {
        return (<div key={"entry-" + index}>
            <h3>{entry.id}</h3>
            <div>{entry.description}</div>
        </div>);
    });
    
    return (
        <PersonMasterScreen user={user} options={options} selection={"challenges"} onUser={onUser}>
            <div className={"pad"}>
                <h2>Challenge Entries</h2>
                <p className={"p800"}>All Challenge Entries...</p>
                
                <div>{elements}</div>
            </div>

        </PersonMasterScreen>
    );
};

export default UserChallengesScreen;