import React from 'react';
import AdminMasterScreen from "../AdminMasterScreen";
import SettingsMasterScreen from "./SettingsMasterScreen";
import FuelSubMenu from "../../components/fuel/ui/FuelSubMenu";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SettingsSubMenu from "../../components/settings/ui/SettingsSubMenu";

const SettingsScreen = (props) => {
    const {} = props;

    const elements = SettingsSubMenu.menuItems.filter((x) => !!x.icon && !!x.path).map((m, i) => {
        
        return (<Link key={"tile-" + i} className={"tile"} to={"/settings/" + m.path}>
            <h3>{m.caption}</h3>
            <FontAwesomeIcon size={"3x"} icon={m.icon} />
            <p>{m.description}</p>
        </Link>);
    });
    
    return (<SettingsMasterScreen>
            <div className={"pad constrain"}>
                <h2>Overview</h2>
                <p>This section where you can update settings, handle content, and generally manage the mobile app</p>

                <div className={"wrap-grid"} style={{gap: "16px"}}>
                    {elements}
                </div>
            </div>
        </SettingsMasterScreen>);

};

export default SettingsScreen;
