import ImageModel from "../../media/models/ImageModel";
import VideoViewModel from "../../media/models/VideoViewModel";
import VideoModel from "../../media/models/VideoModel";

class ChallengeModel {
    static entityType = 39;
    static create = (json) => new ChallengeModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.imageId = json.image_id || null;
        this.videoId = json.video_id || null;
        this.issuedByUserId = json.issued_by_user_id || null;
        this.name = json.name || null;
        this.description = json.description || null;
        this.habitId = json.habit_id || null;
        this.issueDate = !!json.issue_date ? new Date(json.issue_date) : null;
        this.startDate = !!json.start_date ? new Date(json.start_date) : null;
        this.endDate = !!json.end_date ? new Date(json.end_date) : null;
        this.weekNumber = json.week_number || 0;
        this.status = json.status || 0;
        
        this.image = !!json.image?.url ? new ImageModel(json.image) : null;
        this.video = !!json.video?.url ? new VideoModel(json.video) : null;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            image_id: this.imageId || this.image?.id,
            video_id: this.videoId || this.video?.id,
            issued_by_user_id: this.issuedByUserId,
            name: this.name,
            description: this.description,
            issue_date: this.issueDate,
            start_date: this.startDate,
            end_date: this.endDate,
            week_number: this.weekNumber,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.name?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.description?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.weekNumber?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => ChallengeModel.create(json));
    }
}

export default ChallengeModel;
