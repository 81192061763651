import React, {useEffect, useState} from 'react';
import NumberDisplay from "../../common/ui/NumberDisplay";
import MetricService from "../services/MetricService";
import DefReset from "../../common/models/DefReset";
import {Link} from "react-router-dom";

const PlatformDashboard = (props) => {
    const {} = props;
    const [dashboardData, setDashboardData] = useState(MetricService.instance.platformMetrics || null);

    const getMetricsAsync = async (force = false) => {
        if (dashboardData?.userCount > 0 && !force) return dashboardData;

        const options = {};
        const platformCounts = await MetricService.instance.getPlatformMetricsAsync(options);
        console.log("Platform Counts: ", platformCounts);

        setDashboardData(platformCounts);
    };

    const refresh = (e) => {
        DefReset.stopEvent(e);
        let _ = getMetricsAsync(true);
    };
    
    const setControllers = () => {
        //
    };
    
    useEffect(() => {
        const x = setControllers();
        let _ = getMetricsAsync();

        return x;
    }, []);

    return (<div className={"metric-panels constrain"} style={{marginTop: "24px"}} onClick={(e) => refresh(e)}>
        <Link to={"/people"}>
            <span><NumberDisplay value={dashboardData?.userCount} /></span>
            <label>Users</label>
        </Link>

        <div>
            <span><NumberDisplay value={dashboardData?.userHabitCount} /></span>
            <label>Habits</label>
        </div>

        <div>
            <span><NumberDisplay value={dashboardData?.pointCount} /></span>
            <label>Points</label>
        </div>

        <div>
            <span><NumberDisplay value={dashboardData?.commentCount} /></span>
            <label>Comments</label>
        </div>

        <div>
            <span><NumberDisplay value={dashboardData?.likeCount} /></span>
            <label>Likes</label>
        </div>

    </div>);
};

export default PlatformDashboard;