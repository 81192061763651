import HttpService from "../../common/services/HttpService";
import ProductModel from "../models/ProductModel";

class ProductService {
    static instance = new ProductService();    
    
    constructor() {
        this.productsMap = {};
        this.products = null;
        this.productMap = {};
    }
    
    async getProductsAsync() {
        const path = "/api/product";
        
        const rsp = await HttpService.instance.getAsync(path);
        
        if (Array.isArray(rsp.data)) {
            this.products = ProductModel.fromJsonArray(rsp.data);
            this.updateMap();
        }
        
        return this.products || [];
    }

    async getProductAsync(productId) {
        const path = "/api/product/" + productId;

        const product = await HttpService.instance.getAsync(path);
        if (!!product?.id) {
            this.products.push(product);
            return product;
        }
        
        return null;
    }
    
    updateMap() {
        this.productsMap = {};
        if (!this.products) return;
        
        for (let i = 0; i < this.products.length; i++) {
            const product = this.products[i];
            this.productsMap[product.id] = product;
        }
    }
}

export default ProductService;
