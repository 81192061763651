import HttpService from "../../common/services/HttpService";
import HabitModel from "../models/HabitModel";
import UserHabitModel from "../models/UserHabitModel";

class HabitService { 
    static instance = new HabitService();

    constructor() {
        this.habitsMap = {};
        this.habits = [];
        this.habitMap = {};
        this.userHabitsMap = {};
        this.userHabitMap = {};
    }

    async getHabitAsync(habitId) {
        const path = '/api/habit/' + habitId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const h = new HabitModel(response.data);
            if (!!h?.id) me.habitMap[habitId] = h;
            return h || null;
        });
    }
    
    async getAdminUserHabitsAsync(options = {}) {
        const path = "/api/admin/user-habit";
        const sd = !!options?.startDate ? options.startDate : new Date().addDays(-1);
        const ed = options?.endDate;
        return await HttpService.instance.getWithDateRangeAsync(path, sd, ed).then((response) => UserHabitModel.fromJsonArray(response.data));
    }

    async getUserHabitsAsync(userId, options) {
        const path = "/api/user/" + userId + "/user-habit";
        const me = this;
        
        const sd = !!options?.startDate ? options.startDate : new Date().addDays(-60);
        const ed = options?.endDate;

        return await HttpService.instance.getWithDateRangeAsync(path, sd, ed).then((response) => {
            const habits = UserHabitModel.fromJsonArray(response.data);
            me.userHabitsMap[userId] = habits;
            return habits;
        });
    }

    async getUserHabitAsync(id) {
        const path = "/api/user-habit/" + id;
        const me = this;
        
        const rsp = await HttpService.instance.getAsync(path);
        const h = new UserHabitModel(rsp?.data);
        
        if (!!h?.id) {
            this.userHabitMap[id] = h;
            return h;
        }
        
        return null;
    }
    
    async getHabitsAsync() {
        const path = "/api/habit";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.habits = HabitModel.fromJsonArray(response.data);
            me.updateMap();
            return me.habits;
        });
    }

    updateMap() {
        this.habitMap = {};

        for (let i = 0; i < this.habits.length; i++) {
            const h = this.habits[i];
            if (!!h?.id) this.habitMap[h.id] = h;
        }
    }

    async saveHabitAsync(habitJson, habitId) {
        if (typeof habitId !== "string" || habitId.length < 30)
            throw new Error("Missing HabitId");

        const path = "/api/habit/" + habitId;
        const rsp = await HttpService.instance.postAsync(path, habitJson);
        const h = new HabitModel(rsp?.data);

        if (!!h?.id) {
            this.habitMap[h.id] = h;
            return this.habitMap[h.id];
        }

        return null;
    }    
}

export default HabitService;
