import UserHabitModel from "../../habits/models/UserHabitModel";

class DefReset {
    static stopEvent(e) {
        if (typeof e?.preventDefault === "function")
            e.preventDefault();
        
        if (typeof e?.stopPropagation === "function")
            e.stopPropagation();
    }
    
    static slugMap = {
        //"": "user-habit",
    };
    
    static emptyGuid = "00000000-0000-0000-0000-000000000000";
    
    static setClassName(id, className) {
        if (!id) throw new Error("setClassName: Missing id: " + id + "");
        const element = typeof id === "string" ? document.getElementById(id) : id;
        
        if (typeof element?.classList === "undefined") return;
        if (element.classList.contains(className)) return true;
        
        element.classList.add(className);
        
        return true;
    }

    static removeClassName(id, className) {
        if (!id) throw new Error("setClassName: Missing id: " + id + "");
        const element = typeof id === "string" ? document.getElementById(id) : id;

        if (typeof element?.classList === "undefined") return false;
        if (!element.classList.contains(className)) return true;

        element.classList.remove(className);

        return true;
    }    
    static toggleClassName(id, className) {
        if (!id) throw new Error("setClassName: Missing id: " + id + "");
        const element = typeof id === "string" ? document.getElementById(id) : id;

        if (typeof element?.classList === "undefined") return false;
        
        if (element.classList.contains(className)) element.classList.remove(className);
        else element.classList.add(className);

        return true;
    }
    
    static setEnabled(elementId, enabled = true) {
        const element = document.getElementById(elementId);
        if (!element) return false;

        const attribute = document.createAttribute("disabled");
        
        if (enabled === false) {
            element.attributes.setNamedItem(attribute);
        } else if (element.attributes.getNamedItem("disabled") !== null) {
            element.attributes.removeNamedItem("disabled");
        }
        
        return true;
    }
    
    static getSlug(entityType) {
        switch (entityType) {
            case UserHabitModel.entityType:
                return "user-habit";
        }
        
        return "entity-" + entityType + "";
    }
    
    static isEnabled(elementId) {
        const element = document.getElementById(elementId);
        if (!element) return false;
        
        return element.attributes.getNamedItem("disabled") === null;
    }
}

export default DefReset;
