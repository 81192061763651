import React, {useEffect, useState} from 'react';
import NumberDisplay from "../../common/ui/NumberDisplay";
import DefReset from "../../common/models/DefReset";
import UserService from "../services/UserService";
import {Link} from "react-router-dom";

const UserDashboard = (props) => {
    const { userId, options } = props;
    const [dashboardData, setDashboardData] = useState(null);

    const getMetricsAsync = async (force = false) => {
        if (dashboardData?.likeCount > 0 && !force) return dashboardData;

        const options = {};
        const platformCounts = await UserService.instance.getPlatformMetricsAsync(userId, options);
        console.log("Platform Counts: ", platformCounts);

        setDashboardData(platformCounts);
    };

    const refresh = (e) => {
        DefReset.stopEvent(e);
        let _ = getMetricsAsync(true);
    };

    const setControllers = () => {
        //
    };

    useEffect(() => {
        const x = setControllers();
        let _ = getMetricsAsync();

        return x;
    }, []);
    
    if (typeof dashboardData?.userCount !== "number") return (<div>Loading...</div>);

    return (<div title={userId + ""} className={"metric-panels constrain"} style={{marginTop: "24px"}} onClick={(e) => refresh(e)}>
        <div>
            <span><NumberDisplay value={dashboardData?.userCount} /></span>
            <label>Screen Views</label>
        </div>

        <Link to={"/people/" + userId + "/habits"}>
            <span><NumberDisplay value={dashboardData?.userHabitCount} /></span>
            <label>Habits</label>
        </Link>

        <Link to={"/people/" + userId + "/points"}>
            <span><NumberDisplay value={dashboardData?.pointCount} /></span>
            <label>Points</label>
        </Link>

        <div>
            <span><NumberDisplay value={dashboardData?.commentCount} /></span>
            <label>Comments</label>
        </div>

        <div>
            <span><NumberDisplay value={dashboardData?.likeCount} /></span>
            <label>Likes</label>
        </div>

    </div>);
};

export default UserDashboard;
