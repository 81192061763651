import React, {useEffect, useState} from 'react';
import ChallengeMasterScreen from "./ChallengeMasterScreen";
import ChallengeService from "../../components/challenges/services/ChallengeService";
import {useParams} from "react-router-dom";
import BarChart from "../../components/metrics/ui/charts/BarChart";
import GraphItemModel from "../../components/metrics/models/GraphItemModel";

const ChallengeDetailsScreen = (props) => {
    const {} = props;
    const { challengeId} = useParams();
    const [screenState  , setScreenState] = useState({
        challenge: ChallengeService.instance.challengeMap[challengeId] || null,
        chartData: ChallengeService.instance.chartDataMap[challengeId] || null,
        dashboard: ChallengeService.instance.dashboardMap[challengeId] || null,
        chartOptions: {
            fetch: async (options) => {
                console.log("Fetching Challenge Entry Graph...");
                const opts = {
                    startDate: options?.startDate,
                    endDate: options?.endDate,
                };
                
                console.log(opts);
                
                const items = await ChallengeService.instance.getChallengeEntryCountsAsync(challengeId, opts);
                
                console.log("Got Items: ", items?.length);
                console.log(" > ", items);
                
                return items;
            },
        },
    });
    
    const setChallenge = (ch) => {
        const newState = {...screenState};
        newState.challenge = ch;
        setScreenState(newState);
    };
    
    const getDashboardAsync = async (force = false, rerender = false) => {
        if (force !== true && !!screenState.chartData) {
            console.warn("Already has chart data.");
            return screenState.dashboard;
        }
        
        const options = { startDate : null, endDate: null };
        const dashboard = await ChallengeService.instance.getChallengeDashboardAsync(challengeId, options);
        
        if (!rerender || !dashboard) return dashboard || null;

        if (rerender === true) {
            const newState = {...screenState};
            newState.dashboard = dashboard;
            setScreenState(newState);
        }

        return dashboard;
    };
    
    const getGraphItemsAsync = async (force = false, rerender = false) => {
        if (force !== true && !!screenState.chartData) {
            console.warn("Already has chart data.");
            return screenState.chartData;
        }

        const options = { startDate : null, endDate: null };
        const items = await ChallengeService.instance.getChallengeEntryCountsAsync(challengeId, options);
        
        if (!rerender || !Array.isArray(items)) return items || null;

        const newState = {...screenState};
        newState.chartData = items;
        setScreenState(newState);

        return items;
    };
    
    const onChallenge = (ch) => {
        if (!!ch?.id) setChallenge(ch);
    };

    useEffect(() => {
        let _ = getDashboardAsync();
    }, [screenState]);

    return (<ChallengeMasterScreen onChallenge={onChallenge}>
            <div className={"pad"}>
                <h2>{screenState.challenge?.name}</h2>
                <p className={"p"}>{screenState.challenge?.description}</p>
                
                <div>
                    <BarChart options={screenState.chartOptions} />
                </div>
            </div>
    </ChallengeMasterScreen>);

};

export default ChallengeDetailsScreen;