import UserModel from "../../users/models/UserModel";
import MediaModel from "../../media/models/MediaModel";

class FeedbackModel {
    static entityType = 68;
    static create = (json) => new FeedbackModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.userId = json.user_id || null;
        this.user = new UserModel(json.user);
        this.feedbackType = json.feedback_type || 0;
        this.deviceType = json.device_type || 0;
        this.deviceTypeName = json.device_type_name || "";
        this.media = MediaModel.fromJsonArray(json.media) || [];
        this.message = json.message || null;
        this.version = json.version || null;
        this.status = json.status || 0;
        this.thread = FeedbackModel.fromJsonArray(json.thread) || [];

        this.readDate = !!json.read_date ? new Date(json.read_date) : null;
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            user_id: this.userId,
            feedback_type: this.feedbackType,
            device_type: this.deviceType,
            message: this.message,
            version: this.version,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.message?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.version?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => FeedbackModel.create(json));
    }
}

export default FeedbackModel;
