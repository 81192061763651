import React from 'react';
import VideoModel from "../models/VideoModel";

const YouTubeVideo = (props) => {
    const { videoUrl, video, width, height, options } = props;
    const url = VideoModel.createYouTubeUrl(videoUrl || video?.url);

    if (typeof url !== "string" || !url)
        return (<span>No YouTube Video URL</span>);
    
    let w = width > 0 ? width : video?.width || 0;
    let h = height > 0 ? height : video?.height || 0;
    
    if (w <= 0 && h <= 0) {
        w = 800;
        h = w * (3.0/4.0)
    } else if (w <= 0) {
        w = h * (4.0 / 3.0)
    } else if (h <= 0) {
        h = w * (3.0/4.0)
    }
    
    return (<iframe
        width={w} height={h}
        src={url}
        title={video?.name || video?.url || "YouTube Video"}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>);
};

export default YouTubeVideo;    