import React, {useEffect, useState} from 'react';
import DefReset from "../models/DefReset";

const RefreshButton = (props) => {
    const { refresh, options, onClick, href, className, children, id, state } = props;
    const elementId = useState(typeof id === "string" ? id : "refresh-" + (Math.random() * 99999999).toString(16).toLowerCase())[0];
    const [screenState, setScreenState] = useState({ id: 0, state: state >= -100 ? state : 0 });
    
    const handleClick = async (e) => {
        DefReset.stopEvent(e);
        if (screenState.state > 0) return false;

        setScreenState({ ...screenState, state: 1, id: new Date().getTime() });
        
        const rsp = typeof onClick === "function" ? onClick(e) : null;
        const result = typeof rsp?.then === "function" ? await rsp : rsp;
        
        if (result === false) return false;
        
        setScreenState({ ...screenState, state: 0, id: new Date().getTime() });
    };

    const setOptions = () => {
        if (!options || typeof options !== "object") return;
        
        options.setState = (state) => {
            if (typeof state === "number")
                setScreenState({ ...screenState, state: state, id: new Date().getTime() });
        };
    };
    
    useEffect(() => {
        setOptions();
    }, [screenState]);
    
    const classes = ["refresh-button"];
    if (typeof className === "string" && !!className) classes.push(className);
    
    if (screenState.state > 0) {
        classes.push("refreshing");
        classes.push("refreshing-" + screenState.state + "");
    }
    
    const cn = classes.join(" ");
    
    return (<span id={elementId} className={cn} onClick={handleClick}>
        <span className={"spinner"}>
            <label></label>
        </span>
        <span>{children || "Refresh"}</span>
    </span>);
};

export default RefreshButton;
