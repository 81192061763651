import React, {useEffect, useState} from 'react';
import LoadingBox from "../../../common/ui/LoadingBox";
import EmptyBox from "../../../common/ui/EmptyBox";
import "../Messaging.css";
import CommentService from "../../services/CommentService";
import CommentLineItem from "./CommentLineItem";
import DefReset from "../../../common/models/DefReset";

const CommentsList = (props) => {
    const { options:initialOptions, defaultValue, entityId:initialEntityId, onClick } = props;
    const [screenState, setScreenState] = useState({ 
        comments: defaultValue || null,
        options: !!initialOptions && typeof initialOptions === "object" ?
            initialOptions :
            { entityId: initialEntityId || "", useRelative: true },
        value: defaultValue || null,
    });

    const getCommentsAsync = async (force = false) => {
        if (force !== true && screenState.comments?.length > 0) {
            return screenState.comments;
        }
        
        const options = {
            userId: screenState.options?.userId || null,
            entityId: screenState.options?.entityId || null,
            startDate: screenState.options?.startDate || null,
            endDate: screenState.options?.endDate || null,
        };
        
        console.log("Getting Comments: ", options.entityId);
        
        const comments = await CommentService.instance.getCommentsAsync(options);
        const newState = { ...screenState, comments: comments, done: true };
        
        setScreenState(newState);
    };
    
    const setEntityId = (entityId, e) => {
        if (typeof entityId !== "string" || !entityId)
            entityId = null;
        
        const newState = {...screenState};
        newState.comments = null;
        newState.options ??= {};
        newState.options.entityId = entityId;
        delete newState.done;
        
        setScreenState(newState);
    };

    const onCommentClick = async (commentModel, index, e) => {
        console.log("Comment Clicked", commentModel, index, e);
        if (typeof onClick !== "function") return false;

        DefReset.stopEvent(e);
        
        const rsp = onClick(commentModel, index, e);
        const result = typeof rsp?.then === "function" ? await rsp : rsp;
        
        if (result === false) return false;
        
        return result;
    };
    
    const setControls = () => {
        if (screenState.isInit === true)
            return null;
        
        if (!!screenState.options && typeof screenState.options.refreshAsync !== "function") {
            const newState = {...screenState};
            newState.options.refreshAsync = async (force = false) => await getCommentsAsync(force);
            
            setScreenState(newState);
        }
        
        return () => {
            //
        };
    }
    
    useEffect(() => {
        if (screenState.comments != null || screenState.done === true) return;
        let _ = getCommentsAsync();
    }, [screenState]);
    
    useEffect(() => {
        const x = setControls();
        
        //
        
        return x;
    }, []);

    const entityId = screenState.options?.entityId;
    
    const elements = screenState?.comments?.length > 0 ? screenState.comments.map((comment, index) => {
        return (<CommentLineItem options={screenState.options} hasLink={screenState.options?.entityLink !== false} entityId={entityId} onChange={(e) => setEntityId(comment.entityId, e)} onClick={(c, e) => onCommentClick(c, index, e)} key={"comment-item-" + index} comment={comment} />);
    }) : (screenState?.comments === null ? (<LoadingBox>Loading Comments...</LoadingBox>) : (<EmptyBox>No Comments</EmptyBox>));
    
    return (<div className={"comments"}>
        {elements}
    </div>);
};

export default CommentsList;
