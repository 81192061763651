import React, {useEffect, useRef, useState} from 'react';
import MediaUploader from "./MediaUploader";
import MediaService from "../services/MediaService";
import VideoModel from "../models/VideoModel";
import DefReset from "../../common/models/DefReset";
import ReactModal from "../../common/ui/react-modal/ReactModal";
import DebugJson from "../../common/ui/DebugJson";
import VideoPlayer from "./VideoPlayer";
import ImageModel from "../models/ImageModel";

const MediaForm = (props) => {
    const {defaultValue, title, options:initialOptions} = props;
    const [options, setOptions] = useState(initialOptions || {});
    
    const [mediaState, setMediaState] = useState({
        showVideo: false,
    });
    
    const nameRef = useRef();
    const descriptionRef = useRef();
    const urlRef = useRef();
    const identifierRef = useRef();
    const imageRef = useRef();
    const sizeRef = useRef({ width: defaultValue?.width || -1, height: defaultValue?.height || -1 });

    const image = defaultValue?.image || null;
    const video = defaultValue?.video || null;
    
    const createJsonAsync = async () => {
        const json = {
            name: nameRef.current?.value || null,
            description: descriptionRef.current?.value || null,
            url: await getMediaUrlAsync(),
            identifier: identifierRef.current?.value || null,
            image_id: await getImageId(),
            file_name: null,
            privacy: null,
            height: sizeRef.current?.height || -2,
            width: sizeRef.current?.width || -2,
        };

        if (!json.image_id) {
            console.warn("Image is is null: ", json);
            json.image_id = null;
        }
        
        return json;
    };
    
    const toggleVideoDisplay = (e) => {
        DefReset.stopEvent(e);
        
        const newState = {...mediaState};
        newState.showVideo = !newState.showVideo;
        
        setMediaState(newState);
    };

    /**
     * This includes pasted items
     * @param fileModels
     * @returns {Promise<void>}
     */
    const onMediaDrop = async (fileModels) => {
        if (!!defaultValue?.id && defaultValue?.isVideo === true) {
            const fileModel = fileModels.length > 0 ? fileModels[0] : null;
            
            if (!!fileModel?.filePath) {
                if (!defaultValue.video?.imageId) {
                    // If there's no thumbnail for the video, auto-upload it
                    console.log("Auto updating thumbnail...");
                    imageRef.current.value = "UPLOADING";
                    //await MediaService.instance.uploadVideoThumbnailAsync(defaultValue.id, fileModel);
                    imageRef.current.value = "UPLOADED";
                } else {
                    if (!!imageRef.current) imageRef.current.value = "DROPPED";
                }
            } else {
                console.error("No ImageId: ", fileModels);
            }
        }

        if (!!imageRef.current) imageRef.current.value = "DROPPED";
    };
    
    const getMediaUrlAsync = async () => {
        await getImageId();
        return urlRef.current?.value || null;
    }

    const getImageId = async () => {
        let imageId = imageRef.current?.value || null;
        if (!imageId) {
            console.warn("ImageId is not set in imageRef.current.value. Exiting.");
            return null;
        }
        
        let count = 10;
        
        const isGuid = (value) => {
            return (value?.length === 36 && value.split("-").length === 5);            
        };
        
        while (count > 0 && !isGuid(imageId)) {
            console.warn("Checking ImageId[" + count + "]: ", imageId);
            await new Promise((resolve) => setTimeout(resolve, 500));
            imageId = imageRef.current?.value || null;
            count--;
        }
        
        //await MediaUploader.getImageIdAsync(uploadKey);
        if (!isGuid(imageId)) return null;
        
        return imageId;
    };
    
    const onUploaded = (json) => {
        let mediaItem = null;
        
        console.log("Upload Good with raw json:");
        console.log(JSON.stringify(json, null, 4));
        if (Array.isArray(json))
        {
            if (json.length === 0) return;
            json = json[0];
        }
        
        const isImage = (json?.content_type || "image/").startsWith("image/") === true;
        
        mediaItem = isImage ?
            new ImageModel(json) :
            new VideoModel(json);

        console.log("onUploaded: ", mediaItem);

        //options?.noSave === true
        if (isImage) {
            if (!!urlRef.current)
                urlRef.current.value = mediaItem?.url;
            
            if (!!imageRef.current)
                imageRef.current.value = null;
            
            return;
        }
        
        //const itemId = isImage ? mediaItem?.id : mediaItem?.imageId;
        // If it's an image, we only update the URL (also because there is no associated .imageId)
        const itemId = mediaItem?.imageId; 
        
        console.warn("Media ItemId: ", itemId);
        
        imageRef.current.value = itemId;
    };
    
    const setControls = () => {
        if (typeof options === "object" && !!options) {
            options.getData = () => createJsonAsync();
        }
    };
    
    const onVideoSizeMeasured = (videoSize) => {
        sizeRef.current = videoSize;
    };
    
    const createMediaDisplay = () => {
        if (mediaState.showVideo === true) {
            return (<VideoPlayer video={defaultValue?.video} onSize={onVideoSizeMeasured} />);
        }

        const mediaClassName = options.fullSize === true ? "media-content" : "media-thumb";
        
        return !!defaultValue?.image?.url ?
            (<img alt={""} className={mediaClassName} src={defaultValue?.image?.url} />) :
            (<span className={"video-dropper"}>
                <span>Drop Video Thumb Here</span>
                <span><a onClick={toggleVideoDisplay}>Show Video</a></span>
            </span>);        
    };
    
    const wrapTopElements = (childElements) => {
        const videoImageElement = createMediaDisplay();

        const uploadUrl = defaultValue?.isVideo === true && (!defaultValue.video?.imageId || defaultValue.video.imageId === DefReset.emptyGuid) ?
            "/api/video/" + defaultValue.id + "/thumb" :
            "/api/media/upload?no-save=" + (options?.noSave === true ? "1" : "0");
        
        if (options.fullSize === true) {
            return (<div className={"media-view"}>
                <MediaUploader className={"image-dropper"} onDrop={onMediaDrop} onComplete={onUploaded} uploadUrl={uploadUrl}>
                    {videoImageElement}
                </MediaUploader>

                <div className={"top12"}>{childElements}</div>
            </div>);
        }
        
        return (<div className={"form-flex"}>
            <div className={"media-thumb"}>
                <MediaUploader className={"image-dropper"} onDrop={onMediaDrop} onComplete={onUploaded} uploadUrl={uploadUrl}>
                    {videoImageElement}
                </MediaUploader>
            </div>
            <div>{childElements}</div>
        </div>);
    };
    
    useEffect(() => {
        setControls();
    }, []);
    
    const elementTitle = typeof title === "string" ? title : options?.title || null;
    const titleElement = elementTitle ? (<h2>{elementTitle}</h2>) : null;
    const model = defaultValue?.video || defaultValue?.image || null;
    
    const topElements = [
        (<div className={"form-group"} key={"name"}>
            <label>Name:</label>
            <input ref={nameRef} type={"text"} defaultValue={model?.name} />
        </div>),
        (<div className={"form-group"} key={"identifier"}>
            <label>Identifier:</label>
            <input ref={identifierRef} type={"text"} defaultValue={model?.identifier} />
        </div>)];

    const topElement = options.upload !== false ?
        wrapTopElements(topElements) : topElements;
    
    return (<div className={"form media-form"}>
        {titleElement}
        {topElement}
        
        <div className={"form-group"}>
            <label>Media URL:</label>
            <input ref={urlRef} type={"text"} defaultValue={model?.url} />
        </div>

        <div className={"form-group"}>
            <label>Description:</label>
            <textarea ref={descriptionRef} defaultValue={model?.description} className={"medium"}></textarea>
        </div>
        
        <input type={"hidden"} ref={imageRef} id={"image-id-element"} />
    </div>);
};

MediaForm.openDialogAsync = async (mediaItem, index, url = null, options = null, onSave = null) => {
    const dialog = new ReactModal();
    const imageUrl = url || mediaItem.image?.url || mediaItem.url;

    const isVideo = mediaItem.isVideo;
    const entityTypeName = isVideo ? "Video" : "Image";
    const refEntityTypeName = mediaItem.entityType === VideoModel.entityType || mediaItem.entityType === ImageModel.entityType ? 
        "" :
        mediaItem.entityTypeName;
    
    const onTitleClick = (e) => {
        DefReset.stopEvent(e);
        if (typeof options?.onTitleClick === "function") {
            options.onTitleClick(mediaItem, index, e);
        }
    };
    
    const dialogOptions = {
        titlePath: "/people/" + mediaItem.userId + "/" + DefReset.getSlug(mediaItem.entityType) + "/" + mediaItem.id,
        title: options?.title || "Edit " + (refEntityTypeName + " " + entityTypeName).trim() + "",
        className: "user-media-dialog",
    };

    options ??= {};
    
    const editorOptions = {...options};
    editorOptions.display = true;
    editorOptions.title = "";
    
    if (typeof editorOptions.fullSize !== "boolean")
        editorOptions.fullSize = true;

    const mediaForm = (<MediaForm defaultValue={mediaItem} options={editorOptions} />);
    const mediaBody = (<div>{mediaForm}<div><strong>IsVideo: {isVideo + ""}</strong></div><div><DebugJson value={mediaItem} /></div></div>)

    await dialog.openFormDialogAsync(mediaBody, async (e) => {
        // Closed.
        DefReset.stopEvent(e);

        const json = editorOptions.getData();
        
        const rsp = isVideo ?
            await MediaService.instance.saveVideoAsync(json, mediaItem.id) :
            await MediaService.instance.saveImageAsync(json, mediaItem.id);

        console.log("Saved.");
        
        const x = (typeof onSave === "function") ? onSave(rsp) : true;
        
        return (typeof x?.then === "function") ? await x : x;
    }, dialogOptions);

    return false;
};

export default MediaForm;
