import React, {useEffect, useRef, useState} from 'react';
import FuelMasterScreen from "./FuelMasterScreen";
import MealService from "../../components/fuel/services/MealService";
import {useParams} from "react-router-dom";
import DefButton from "../../components/common/ui/elements/DefButton";
import MediaUploader from "../../components/media/ui/MediaUploader";
import {Navigate} from "react-router-dom";
import MealSubMenu from "../../components/fuel/ui/MealSubMenu";
import SearchFilter from "../../components/common/ui/search-filter/SearchFilter";
import IngredientListSelector from "../../components/fuel/ui/IngredientListSelector";
import MealFoodModel from "../../components/fuel/models/MealFoodModel";
import DefReset from "../../components/common/models/DefReset";

const MealRecipeDetailsScreen = (props) => {
    const {} = props;
    const { mealId:recipeId } = useParams();
    const [screenState, setScreenState] = useState({ 
        id: 0,
        recipe: null,
        editId: null,
        status: 0,
    });
    const nameRef = useRef();
    const descriptionRef = useRef();
    const recipe = screenState.recipe || {};
    const uploadKey = recipe?.id || "new";
    
    const setRecipe = (recipe) => {
        setScreenState({ ...screenState, recipe: recipe });
    };
    
    const getRecipeAsync = async (force = false) => {
        if (!!recipe?.id && recipe.id.length > 30 && force !== true)
            return recipe;

        const m = await MealService.instance.getMealAsync(recipeId);

        if (!!m?.id) {
            console.log("Got recipe good: ", m);
            setRecipe(m);
            return m;
        }

        console.error("Failed to get recipe: ", m);
        return recipe;
    };
    
    const onFoodSelected = (food) => {
        console.warn("Food is selected: ", food);
        
        const foods = screenState.recipe.foods || [];
        const mf = new MealFoodModel(food.toJson());
        mf.amount = 1.0;
        mf.image = food.image;
        mf.id = !!food.id ? food.id : "new-" + (Math.random() * 99999999).toString(16).toLowerCase();
        mf.foodId = food.id;
        mf.cookingDirections = food.cookingDirections || "";
        mf.description = food.description || "";
        mf.status = 0;
        
        foods.push(mf);
        
        const newState = { ...screenState };
        newState.recipe = screenState.recipe;
        newState.recipe.foods = foods;
        
        setScreenState(newState);
    };

    const onImageClicked = (fileModel, index) => {
        delete MediaUploader.options[uploadKey];
        //
        return true;
    };

    const saveIngredientAsync = async () => {
        const ingredient = screenState.saveIngredient || null;
        screenState.saveIngredient = null;
        
        if (!ingredient?.name) return;
        
        const json = {
            name: ingredient.name,
            description: ingredient.description,
            cooking_directions: ingredient.cookingDirections,
            amount_units: parseInt(ingredient.amountUnits + "") || 0,
            amount: parseFloat(ingredient.amount + "") || 1.0,
            meal_id: recipeId,
            image_id: null,
            food_id: ingredient.foodId || ingredient.id,
        };
        
        console.warn(JSON.stringify(json, null, 4));
        
        const rsp = await MealService.instance.saveMealFoodAsync(json, recipeId);
        delete screenState.saveIngredient;
        
        return rsp;
    };
    
    const onEditBlur = (ingredient, e) => {
        const newState = { ...screenState };
        newState.saveIngredient = ingredient;
        newState.editId = null;
        
        setScreenState(newState);
    }
    
    const onEditChange = (item, e) => {
        const fieldId = e?.target?.id || null;
        if (!fieldId) {
            console.error("No FieldId. e: ", e);
            return;
        }

        item[fieldId] = e?.target?.value;
        if (fieldId === "amountUnits") {
            item.amountUnitsName = e?.target?.selectedOptions[0]?.text || e?.target?.selectedOptions[0]?.innerText || "x";
        }
        
        console.log(JSON.stringify(item, null, 4));
    };
    
    const onDrop = (fileModels) => {
        MediaUploader.options[uploadKey] = 1;
    };
    
    const selectIngredient = (ingredient, e) => {
        DefReset.stopEvent(e);
        
        const newState = { ...screenState };
        newState.editId = ingredient?.id || null;
        
        setScreenState(newState);
    };

    useEffect(() => {
        if (!!screenState.saveIngredient?.name) {
            let _ = saveIngredientAsync();
        }
        
    }, [screenState]);
    
    useEffect(() => {
        let _ = getRecipeAsync(true);
    }, []);

    if (typeof recipe === "string") {
        return <Navigate to={recipe} />;
    }

    const emptyImageMessage =  (<span style={{fontSize: "12px"}}>Drag/Drop or<br/>Click Here<br/>Here to Upload<br/>A Thumbnail</span>);
    const isLoaded = !!recipe?.id;
    const contentElement = null;
    const imageUrl = recipe?.image?.url || null;
    const ingredients = recipe?.foods || [];

    const elements = ingredients.map((ingredient, index) => {
        const imageElement = !!ingredient.image?.url ?
            (<img src={ingredient.image.url} alt={ingredient.name} className={"thumb"} />) :
            null;
        
        const isEditing = screenState.editId === ingredient.id;
        
        const itemBody = isEditing ?
            (<span className={"details form form-item flex"} style={{width: "100%"}}>
                <span style={{width: "80%"}}>
                    <span className={"line-item-form"}>
                        <span><input id={"amount"} defaultValue={ingredient.amount} onChange={(e) => onEditChange(ingredient, e)} type={"number"} max={100} min={0}
                                 step={0.1} style={{width: "70px"}}/></span>
                        <span>
                            <select onChange={(e) => onEditChange(ingredient, e)} defaultValue={ingredient.amountUnits} id={"amountUnits"}>
                                <option value={4}>Cups</option>
                                <option value={7}>Tsp</option>
                                <option value={5}>Tbsp</option>
                                <option value={2}>Oz</option>
                                <option value={0}>Count</option>
                            </select>
                        </span>
                        
                        <span style={{width: "100%"}}><input type={"text"} placeholder={ingredient.name} defaultValue={ingredient.name} onChange={(e) => onEditChange(ingredient, e)} id={"name"}/></span>
                    </span>
                    
                    <span className={""}>
                        <span>
                            <textarea
                                id={"cookingDirections"}
                                defaultValue={ingredient.cookingDirections}
                                onChange={(e) => onEditChange(ingredient, e)}></textarea>
                        </span>
                    </span>
                </span>

                <span className={"div"}>
                    <DefButton onClick={(e) => onEditBlur(ingredient, e)}>Save</DefButton>
                    <span><a onClick={(e) => selectIngredient(null, e)}>Cancel</a></span>
                </span>
            </span>) : (<span><h3>{ingredient.amount} {ingredient.amountUnitsName} {ingredient.name}</h3><span className={"cooking-directions"}>{ingredient.cookingDirections}</span></span>);

        ingredient.sorting = index;

        return (<div key={"ingredient-" + ingredient.id} className={"line-item"} onClick={(e) => selectIngredient(ingredient, e)}>
            <div className={"image-item"}>
                <span>{imageElement}</span>
                {itemBody}
            </div>
        </div>);
    });

    return (<FuelMasterScreen selection={"meals"} title={"Meal Recipe"}>
        <div className={"pad"}>
            <h2 className={""}>
                <span>Recipe: {recipe?.name}</span>
                <span></span>
            </h2>

            <MealSubMenu meal={recipe} selection={"recipes"}/>

            <p>{recipe?.description}</p>
            <div></div>
            
            <div className={""}>
                <IngredientListSelector onSelected={onFoodSelected} />
            </div>
            
            <div className={"form"}>
                {elements}
            </div>
            <div>
                <pre>{JSON.stringify(recipe, null, 4)}</pre>
            </div>
        </div>
    </FuelMasterScreen>);

};

export default MealRecipeDetailsScreen;
