import React from 'react';
import "./LoadingBox.css";

const LoadingBox = (props) => {
    const {children} = props;

    return (<div className={"loading-box"}>
        <span>
            <span></span>
        </span>
        <span>{children}</span>
    </div>);
};

export default LoadingBox;