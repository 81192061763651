/* eslint-disable no-extend-native */
import React from 'react';

const DateTime = (props) => {
    let {
        value,
        date,
        className,
        children,
        options,
        showRelative,
        useRelative,
        useTitle,
        showRelativeDate,
        time,
        defaultValue,
        prefix,
        suffix,
        format,
        wrapWithStrong,
        weekDay,
        useUtc
    } = props;

    if (!value) value = date;
    if (!value) value = children;
    
    if (!value) {
        return defaultValue || 'No Date';
    }

    date = value.toDate();
    if (!useUtc) date = date.toLocalTime();
    
    if (date === null) return defaultValue || 'No Date';
    
    if (!options) {
        options = { month: 'short', day: 'numeric', year: 'numeric' };
        if (weekDay) options.weekday = 'long';
    }
    
    if (time === true) {
        options.hour = 'numeric';
        options.minute = 'numeric';
    }
    
    if (format) options.format = format;
    
    if (useRelative === true || showRelative === true || showRelativeDate === true) {
        value = DateTime.createRelativeDateTime(date, options);
    } else {
        // noinspection JSCheckFunctionSignatures
        if (!!format) value = DateTime.formatDate(date, format);
        else value = date.toLocaleDateString("en-US", options);
    }
    
    if (typeof prefix === 'undefined' || prefix === null) prefix = '';
    if (typeof suffix === 'undefined' || suffix === null) suffix = '';
    
    value = wrapWithStrong === true ?
        (<>{prefix}<strong>{value}</strong>{suffix}</>) :
        (<>{prefix + value + suffix}</>);
    
    const cn = typeof className === "string" && !!className ? " " + className : "";
    const prop = {};
    
    if (useTitle === true) prop.title = date.toString();
    prop.className = "dark-span date-time-text" + cn;
    
    const title = (date instanceof Date) ? DateTime.formatDate(date, "MMM DAY, yyyy hh:mm [ap]") : date?.toString();
    
    return (
        <span title={title} {...prop}>
            {value}
        </span>
    );
};

DateTime.defaultOptions = { month: 'short', day: 'numeric', year: 'numeric' };
DateTime.defaultTimeOptions = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };

DateTime.createRelativeDateTime = function (date, options) {
    if (typeof options === 'string') options = { format: options };
    if (typeof options !== 'object' || Array.isArray(options)) {
        options = {};
    }

    let justNowMessage = options.justNowMessage || 'Just now';
    let minSeconds = options.minSeconds || 4;

    if (minSeconds < 1) minSeconds = 4;
    if (minSeconds < 1000) minSeconds *= 1000;

    let span = new Date().getTime() - date.getTime();
    let isFuture = false;
    let ago = ' ' + (typeof options.ago === "string" ? options.ago : "ago") + ' ';

    if (span < minSeconds) {
        if (span > -1000) return justNowMessage;
        ago = ' ' + (typeof options.fromNow === "string" ? options.fromNow : "from now") + ' '
        span = -span;
        isFuture = true;
    }

    let secondsSpan = Math.floor(span / 1000);

    if (secondsSpan < 50) return (secondsSpan + ' seconds' + ago).trim();
    if (secondsSpan < 60 * 60) {
        let mm = Math.floor(secondsSpan / 60);
        let s = (mm === 1) ? '' : 's';
        return mm + ' minute' + s + ago;
    }

    if (secondsSpan < 60 * 60 * 24) {
        let h = Math.floor(secondsSpan / (60 * 60));
        let s = h === 1 ? '' : 's';
        return h + ' hour' + s + ago;
    }

    const maxDays = typeof options?.maxDays === 'number' && options.maxDays > 0 ? options.maxDays : 7;

    if (!isFuture && secondsSpan < 60 * 60 * 24 * maxDays) {
        let d = Math.floor(secondsSpan / (60 * 60 * 24));
        if (d === 1) return "Yesterday" + (options?.useTime === true ? " at " + date.toLocaleTimeString("en-US", options) : "");
        
        return (d + ' days ' + ago).trim();
    }

    return date.toLocaleDateString("en-US", options);
};

Date.prototype.toMidnight = function() { 
    return new Date(this.getFullYear(), this.getMonth(), this.getDate());
}

Date.prototype.toFirstOfTheMonth = function () {
    let date = new Date(this.valueOf());
    date.setDate(1);
    date.setHours(0, 0, 0, 0);
    return date;
}

Date.prototype.toFirstOfTheYear = function () {
    let date = new Date(this.valueOf());
    date.setDate(1);
    date.setMonth(0);
    
    return date;
}

Date.prototype.toDateTime = function (options) {
    if (typeof options !== 'object' || options === null || Array.isArray(options)) { 
        options = DateTime.defaultTimeOptions;
    }
    
    return this.toLocaleDateString('en-US', options);
}

Date.prototype.toFormDate = function(hasTime) {
    let dt = new Date(this.getTime());
    dt.setSeconds(0);
    dt.setMilliseconds(0);
    
    if (!hasTime) { 
        const ms = (new Date()).getTimezoneOffset();
        
        dt = dt.addMinutes(ms);
        dt.setHours(0, 0, 0, 0);
        
        const items = dt.toISOString().split('T');
        
        return items[0];
    }
    
    return dt.toISOString().replace(':00.000Z', '');
}

Date.prototype.addMonths = function (months) {
    let date = new Date(this.valueOf());
    date.setMonth(date.getMonth() + months);
    return date;
}

Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};

Date.prototype.addHours = function (hours) {
    return this.addMinutes(hours * 60);
};

Date.prototype.addMinutes = function (minutes) {
    let date = new Date(this.valueOf());
    
    date.setTime(date.getTime() + (minutes * 60 * 1000));
    
    return date;
};

Date.prototype.toDate = function() {
    return this;
};

Date.prototype.toLocalTime = function () {
    let date = new Date(this.valueOf());
    let tzo = date.getTimezoneOffset();
    if (tzo !== 0) date.setMinutes(date.getMinutes() - tzo);
    
    return date;
};

Date.prototype.toUtcDate = function() {
    let date = new Date(this.valueOf());
    let tz = date.getTimezoneOffset();
    
    if (tz !== 0) {
        date = date.addMinutes(tz);
    }
    
    return date;
};

Date.prototype.toRelativeTime = function (options) {
    const date = this.valueOf();
    return DateTime.createRelativeDateTime(date, options);
}

Date.prototype.getWeekDay = function (abbr) {
    let d = this.getDay();
    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let daysAbbr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    
    if (abbr) return daysAbbr[d];
    return days[d];
};

Date.prototype.getMonthName = function (abbr) {
    let d = this.getMonth();
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let monthsAbbr = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    if (abbr) return monthsAbbr[d];
    return months[d];
};

Date.prototype.formatDate = function (options) {
    return DateTime.formatDate(this, options);
};

// String conversions to date
String.prototype.formatDate = function (options) {
    return this.toDate().formatDate(options);
};

String.prototype.toDate = function (useUtc, defaultDate) {
    let dt = Date.parse(this + '');

    let newDate = new Date();
    if (!isNaN(dt)) newDate = new Date(dt);
    
    let offset = new Date().getTimezoneOffset();
    if (useUtc && offset !== 0) newDate = newDate.addMinutes(offset);
    
    return newDate;
}

String.prototype.toDateTime = function (options, useUtc) {
    if (typeof options === 'boolean') {
        useUtc = options;
        options = null;
    }

    return this.toDate(useUtc).toDateTime(options);
}

String.prototype.toRelativeTime = function (options, useUtc) {
    if (typeof options === 'boolean') { 
        useUtc = options;
        options = null;
    }
    
    return this.toDate(useUtc).toRelativeTime(options);
}

DateTime.isDate = function (value) {
    if (!value) return false;
    if (typeof value === 'string') value = value.toDate();

    if (Object.prototype.toString.call(value) === "[object Date]")
        return !isNaN(value);

    return false;
};

DateTime.formatDate = function (date, options) {
    if (typeof date?.getWeekDay !== "function") 
        return options?.defaultValue ?? "";
    
    if (!options) options = DateTime.defaultOptions;
    else if (options.format) options = options.format;

    if (typeof options === 'string') {
        let dateString = options;

        dateString = dateString.replaceAll(/WEEK-DAY-ABBR/g, date.getWeekDay().toString().substring(0, 3));
        dateString = dateString.replaceAll(/WEEK-DAY/g, date.getWeekDay().toString());

        dateString = dateString.replaceAll(/DATE-TIME/g, "MMM DAY, yyyy HOUR:mm [ap]");
        dateString = dateString.replaceAll(/TIME/g, "HOUR:mm [ap]");
        dateString = dateString.replaceAll(/YYYY/g, date.getFullYear().toString());
        dateString = dateString.replaceAll(/YY/g, (date.getFullYear() % 100).toString());
        dateString = dateString.replaceAll(/yyyy/g, date.getFullYear().toString());
        dateString = dateString.replaceAll(/yy/g, (date.getFullYear() % 100).toString());
        dateString = dateString.replaceAll(/MONTHNAME/g, date.toLocaleDateString("en-US", { month: 'long' }));
        dateString = dateString.replaceAll(/MMMM/g, date.toLocaleDateString("en-US", { month: 'long' }));
        dateString = dateString.replaceAll(/MMM/g, date.toLocaleDateString("en-US", { month: 'short' }));
        dateString = dateString.replaceAll(/MM/g, (date.getMonth() + 1).toString().padStart(2, '0'));
        dateString = dateString.replaceAll(/MONTH/g, (date.getMonth() + 1).toString());
        dateString = dateString.replaceAll(/dd/g, date.getDate().toString().padStart(2, '0'));
        dateString = dateString.replaceAll(/DAY/g, date.getDate().toString());
        dateString = dateString.replaceAll(/HH/g, date.getHours().toString());
        dateString = dateString.replaceAll(/HOUR/g, (date.getHours() % 12).toString());
        dateString = dateString.replaceAll(/HOUR24/g, date.getHours().toString());
        dateString = dateString.replaceAll(/hh/g, (date.getHours() % 12).toString());
        dateString = dateString.replaceAll(/mm/g, date.getMinutes().toString().padStart(2, '0'));
        dateString = dateString.replaceAll(/MINUTE/g, date.getMinutes().toString());
        dateString = dateString.replaceAll(/ss/g, date.getSeconds().toString());
        dateString = dateString.replaceAll(/SECOND/g, date.getSeconds().toString());

        let ap = date.getHours() >= 12 ? 'PM' : 'AM';
        dateString = dateString.replaceAll(/\[AP\]/g, ap);
        dateString = dateString.replaceAll(/\[ap\]/g, ap.toLowerCase());

        return dateString;
    }

    if (!(this instanceof Date)) console.warn("Not a date: " + this);

    return this.toLocaleDateString("en-US", options);
};

export default DateTime;
