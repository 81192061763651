import React from 'react';
import Initials from "../../common/ui/Initials";
import {Link} from "react-router-dom";
import "./ProfilePhoto.css";

const ProfilePhoto = (props) => {
    const { size, options, user, onClick } = props;

    const sz = typeof size === "number" && size > 0 ? size + "px" : "128px";
    const imageUrl = user?.image?.url || user?.url || "";
    
    if (!imageUrl) return (<Initials size={size} options={options}>{user.firstName + " " + user.lastName}</Initials>);
    const imageElement = (<img style={{width: sz, height: sz}} alt={user.firstName + " " + user.lastName + ""} src={imageUrl} />);
    
    if (typeof onClick === "function") return (<span onClick={onClick}>{imageElement}</span>);
    
    return (<Link to={"/people/" + user.id} className={"profile-photo"}>{imageElement}</Link>);
};

export default ProfilePhoto;
