import ImageThumbModel from "./ImageThumbModel";
import UserModel from "../../users/models/UserModel";
import VideoModel from "./VideoModel";

class ImageModel {
    static entityType = 6;
    
    static createThumbIndexMap(thumbs) {
        if (!Array.isArray(thumbs)) return {};
        
        let map = {};
        for(let i = 0; i < thumbs.length; i++) {
            const sizeTag = thumbs[i].sizeTag;
            if (!sizeTag) continue;
            
            map[sizeTag] = i;
        }
        
        return map;
    }
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        this.name = json.name || "";
        this.description = json.description || "";
        this.url = json.url || "";
        this.user = new UserModel(json.user);
        if (!this.user?.id) this.user = null;
        
        this.identifier = json.identifier || null;
        this.width = json.width || 0;
        this.height = json.height || 0;
        this.userId = json.user_id || "";
        this.user = !!json.user?.id ? new UserModel(json.user) : null;
        this.fileName = json.file_name || "";
        this.fileSize = json.file_size || 0;
        this.metaData = json.meta_data || {};
        this.entityType = json.entity_type || ImageModel.entityType;
        this.entityTypeName = json.entity_type_name || "Image";
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
        
        this.thumbs = ImageThumbModel.fromJsonArray(json.thumbs);
        this.thumbIndexMap = ImageModel.createThumbIndexMap(this.thumbs);
        
        //this.json = json;
    }    
    
    getThumb(sizeTag = "256x256", useFallback = true) {
        if (!this.thumbIndexMap) return null;
        
        const index = this.thumbIndexMap[sizeTag];
        
        if (typeof index !== "number") {
            if (useFallback !== true) return null;
            
            if (this.thumbs?.length > 0) {
                this.thumbs.sort((a, b) => a.width - b.width);
                return this.thumbs[0];
            } else {
                return this.url;
            }
            
        }
        
        return this.thumbs[index];
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new ImageModel(json));
    }
}

export default ImageModel;

