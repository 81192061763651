class HabitModel {
    static entityType = -1;
    static create = (json) => new HabitModel(json);
    
    static wakeupKey = "wakeup";
    static workoutKey = "workout";
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;

        this.description = json.description || "";
        this.name = json.name || "";
        this.userId = json.user_id;
        this.habitKey = json.habit_key;
        this.json = json;
        
        this.longDescription = json.long_description || "";
        this.actionDescription = json.action_description || "";
        this.leaderboardTitle = json.leaderboard_title || "";
        this.leaderboardDescription = json.leaderboard_description || "";
        
        this.sorting = json.sorting || 0;
        this.status = json.status || 0;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            name: this.name,
            description: this.description,
            long_description: this.longDescription,
            action_description: this.actionDescription,
            leaderboard_title: this.leaderboardTitle,
            leaderboard_description: this.leaderboardDescription,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.name?.toLowerCase().indexOf(term) >= 0) return true;
        
        if ((this.description || "").toLowerCase().indexOf(term) >= 0) return true;
        if ((this.longDescription || "").toLowerCase().indexOf(term) >= 0) return true;
        if ((this.actionDescription || "").toLowerCase().indexOf(term) >= 0) return true;
        if ((this.leaderboardTitle || "").toLowerCase().indexOf(term) >= 0) return true;
        if ((this.leaderboardDescription || "").toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => HabitModel.create(json));
    }
}

export default HabitModel;
