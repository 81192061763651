import HttpService from "../../common/services/HttpService";
import WorkoutModel from "../models/WorkoutModel";
import WorkoutExerciseModel from "../models/WorkoutExerciseModel";
import ExerciseModel from "../models/ExerciseModel";

class WorkoutService { 
    static instance = new WorkoutService();

    constructor() {
        this.workoutsMap = {};
        this.workouts = [];
        this.workoutMap = {};
        this.isSaving = false;
    }
    
    async saveWorkoutAsync (workoutJson, workoutId = null) {
        const path = "/api/admin/workout" + 
            (typeof workoutId === "string" && workoutId.length > 30 ? "/" + workoutId : "");
        
        if (!workoutJson?.name) {
            console.error("No workout name provided.");
            return;
        }
        
        if (this.isSaving) {
            console.error("Workout is already saving.");
            return;
        }
        
        this.isSaving = true;
        
        const workout = await HttpService.instance.postAsync(path, workoutJson).then((rsp) => {
            return new WorkoutModel(rsp.data);
        }).catch((ex) => {
            throw ex;
        });

        this.isSaving = false;
        
        if (!workout?.id) return workout;
        
        await this.getWorkoutsAsync();
        return await this.getWorkoutAsync(workout.id);
    }

    async saveWorkoutExerciseAsync(workoutExerciseJson, workoutExerciseId = null, workoutId = null, exerciseId = null) {
        workoutExerciseId ??= workoutExerciseJson.id || workoutExerciseJson.workout_exercise_id || null;
        workoutId ??= workoutExerciseJson.workout_id;
        exerciseId ??= workoutExerciseJson.exercise_id || workoutExerciseJson.exercise_id || null;

        delete workoutExerciseJson.id;
        delete workoutExerciseJson.workout_exercise_id;
        delete workoutExerciseJson.exercise_id;
        
        const path = !!workoutExerciseId && workoutExerciseId.length > 30 ?
            "/api/admin/workout-exercise/" + workoutExerciseId :
            "/api/admin/workout/" + workoutId + "/exercise/" + exerciseId;
        
        console.log("Saving Workout Exercise: " + path);
        
        const rsp = await HttpService.instance.postAsync(path, workoutExerciseJson);
        
        return new WorkoutExerciseModel(rsp.data);
    }
    
    async createExerciseWithWorkoutAsync(workoutId, exerciseJson) {
        if (typeof workoutId !== "string" || workoutId.length < 30) throw new Error("Missing WorkoutId");
        const path = "/api/admin/workout/" + workoutId + "/exercise";
        const rsp = await HttpService.instance.postAsync(path, exerciseJson);
        const workoutExercise = new WorkoutExerciseModel(rsp.data);
        
        if (!!workoutExercise?.id) return workoutExercise;
        
        return null;
    }

    async deleteWorkoutExerciseAsync(workoutExerciseId) {
        const path = "/api/admin/workout-exercise/" + workoutExerciseId;
        const rsp = await HttpService.instance.deleteAsync(path);
        
        return rsp?.data || true;
    }
    
    async getWorkoutAsync(workoutId) {
        const path = '/api/admin/workout/' + workoutId;
        const me = this;

        console.log("Getting workout...");

        return await HttpService.instance.getAsync(path).then((response) => {
            const w = new WorkoutModel(response.data);
            
            if (!w?.id) return null;
            
            me.workoutMap[workoutId] = w;
            
            if (!w.parentWorkoutId) {
                const index = me.workouts.findIndex((x) => x.id === workoutId);

                if (index >= 0) me.workouts[index] = w;
                else me.workouts.push(w);
            }
            
            return w;
        });
    }

    async getWorkoutsAsync(status = 0) {
        const path = "/api/admin/workout/all?status=" + status.toString();
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const model = response.data;
            if (!model?.workouts) return [];
            
            me.workouts = model.workouts?.map((workout) => {
                const w = new WorkoutModel(workout);
                if (!me.workoutMap[w.id]) me.workoutMap[w.id] = w;

                return w;
            });

            return me.workouts;
        });
    }
}

export default WorkoutService;
