import React, {useEffect, useRef, useState} from 'react';
import ChartBase from "./ChartBase";

const LineChart = (props) => {
    const { width, height, items:chartItems, className, id, name, maxCount, options:dateOptions, url, title } = props;

    return (<ChartBase 
        type={"line"}
        width={width}
        lineWidth={4}
        height={height}
        items={chartItems}
        className={className}
        id={id}
        name={name} 
        options={dateOptions}
        url={url}
        title={title} 
    />);
};

export default LineChart;
