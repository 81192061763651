import React, {useEffect, useRef, useState} from 'react';
import SettingsMasterScreen from "../SettingsMasterScreen";
import DefReset from "../../../components/common/models/DefReset";
import MessageService from "../../../components/messaging/services/MessageService";
import DateTime from "../../../components/common/ui/DateTime";
import LoadingBox from "../../../components/common/ui/LoadingBox";
import {Link, useParams} from "react-router-dom";
import ArticleService from "../../../components/learn/services/ArticleService";
import ReactModal from '../../../components/common/ui/react-modal/ReactModal';
import { faCheck, faCheckCircle, faGhost } from '@fortawesome/free-solid-svg-icons';

const MessagingScreen = (props) => {
    const { articleId } = useParams();
    const [screenState, setScreenState] = useState({
        message: null,
        id: 0,
        logs: MessageService.instance.messageLogs,
        article: null,
    });

    const titleRef = useRef();
    const messageRef = useRef();
    
    const onMessageTyped = (e) => {
        const newState = {...screenState}
        newState.message = e.target.value;
        setScreenState(newState);
    };

    const onSession = (session) => {
        if (!session?.user?.id) return;
        let _  = getMessageHistoryAsync();
    };

    const handleError = (ex) => {
        console.error(ex);
        const dialog = new ReactModal();
        
        console.error(ex);

        const message = ex?.response?.data?.message || ex?.message || "An unknown error occurred.";
        dialog.openErrorDialogAsync("Error Sending Push Notification: " + message, {});

        return null;
    };

    const sendMessageAsync = async (e) => {
        DefReset.stopEvent(e);
        
        const msg = messageRef.current?.value || "";
        const title = titleRef.current?.value || "";
        
        if (!title) return;
        
        const button = e?.currentTarget;

        if (typeof button?.classList?.add === "function")
            button.classList.add("disabled");
        
        console.log("Sending...");

        //await new Promise((resolve) => setTimeout(resolve, 3000));
        const rsp = await MessageService.instance.sendPushNotificationsAsync(msg, title, articleId).catch((ex) => handleError(ex));
        
        if (typeof button?.classList?.remove === "function")
            button.classList.remove("disabled");

        if (rsp === null) return;

        if (rsp.success !== true) return handleError({ message: rsp.message });
        
        const options = { icon: faCheckCircle, iconClassName: "success" };
        const dialog = new ReactModal();
        const s = rsp.send_count === 1 ? "" : "s";

        await dialog.openAlertDialogAsync("Push Notification Sent to " + rsp.send_count + " user" + s, options);
    };
    
    const getArticleAsync = async (force = false, rerender = true) => {
        if (!force && !!screenState.article?.id) return screenState.article;
        
        const article = await ArticleService.instance.getArticleAsync(articleId);
        const newState = {...screenState, article: article};
        if (rerender === true) setScreenState(newState);
        
        return article;
    };
    
    const getMessageHistoryAsync = async (force = false, e = null) => {
        DefReset.stopEvent(e);
        
        if (!force && Array.isArray(screenState.logs)) return screenState.logs;
        
        const logs = []; //await MessageService.instance.getMessageLogsAsync(options);
        
        const newState = {...screenState};
        newState.logs = logs;
        
        setScreenState(newState);
    };
    
    const refresh = (e) => {
        DefReset.stopEvent(e);
        
        const newState = {...screenState};
        newState.logs = null;
        
        setScreenState(newState);
    }
    
    useEffect(() => {
        //let _ = getMessageHistoryAsync();
        let _ = getArticleAsync();
    }, [screenState]);

    const charCount = (messageRef.current?.value || "").length;
    const countClassName = charCount > 5 ? "error" : "";

    const messageLogElements = screenState.logs?.map((log, index) => {
        return (<tr className={""} key={"send-log-" + index}>
            <td>{log.user?.name}</td>
            <td>{log.message}</td>
            <td><DateTime value={log.created} time={true} /></td>
        </tr>);
    });
    
    const refreshButton = messageLogElements != null ? (<a onClick={refresh}>Refresh</a>) : null;
    
    const tableElement = messageLogElements?.length > 0 ? (<table width={"100%"}>
        <thead>
        <tr>
            <th>Id</th>
            <th>Message</th>
            <th>Title</th>
            <th>Created</th>
        </tr>
        </thead>

        <tbody>
        {messageLogElements}
        </tbody>
    </table>) : messageLogElements === null ? (<LoadingBox />) : null;
    
    const pageTitle = !!articleId ? "Send Article Push Notification" : "Messaging";
    const article = screenState.article;
    const subject = !!article?.id ? article.title : "";
    const message = !!article?.id ? article.content : "";
    const preSendElement = !!article?.id ?
        (<div className={"pre-article-push"}>Sending Article Push Notifications: <strong>{subject}</strong></div>) : 
        null;
    
    const textContent = articleId ? (<><br/><Link to={"/learn/" + articleId}>Return to article: {subject}</Link></>) : null;
    
    return (<SettingsMasterScreen selection={"messaging"} onSession={onSession}>
        <div className={"pad constrain"}>
            <h2>{pageTitle}</h2>
            <p>
                This is where you can send messages (email, sms, push) to users.
                {" "}{textContent}
            </p>

            <div className={"form"}>
                <div className={"form-group"}>
                    <label>Title: </label>
                    <input ref={titleRef} defaultValue={subject} type={"text"} id={"title"} placeholder={"Ex: Remember to Workout!"}/>
                </div>

                <div className={"form-group"}>
                    <label>Text: </label>
                    <textarea ref={messageRef} defaultValue={message} onKeyUpCapture={onMessageTyped} placeholder={"Remember: No one really reads this part of the push notification if it's longer than 1-2 sentences."} id={"message"} style={{minHeight: "100px"}}></textarea>
                </div>
                <div className={"notes " + countClassName}>Character Count: {charCount}</div>
                
                <div className={"form-group"}>
                    
                </div>

                {preSendElement}
                <div className={"buttons"}>
                    <button onClick={(e) => sendMessageAsync(e)}>Send Push Notifications</button>
                </div>
            </div>

            <div style={{marginTop: "32px"}}>
                <h2 className={"nav with-button"} style={{display: "none"}}>
                    <span>Message History</span>
                    <span className={"add-new"}>{refreshButton}</span>
                </h2>

                {tableElement}

            </div>
        </div>
    </SettingsMasterScreen>
);
};

export default MessagingScreen;