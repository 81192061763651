import React from 'react';

const EmptyBox = (props) => {
    const { children, className } = props;

    const cn = typeof className === "string" ? className : "";
    
    return (<div className={("empty-box " + cn).trim()}>
        <span>{children || (<>No Data Available</>)}</span>
    </div>);
};

export default EmptyBox;
