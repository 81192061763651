import React, {useEffect, useState} from 'react';
import SettingsMasterScreen from "../SettingsMasterScreen";
import FeedbackService from "../../../components/messaging/services/FeedbackService";
import {useParams} from "react-router-dom";
import FeedbackList from "../../../components/messaging/ui/FeedbackList";

const FeedbackScreen = (props) => {
    const {} = props;
    const { feedbackId } = useParams();
    const [feedbackItem, setFeedbackItem] = useState(FeedbackService.instance.feedbacksMap[feedbackId] || null);
    const [options, setOptions] = useState({ refresh: null });

    const getAsync = async (force = false) => {
        //
    };

    useEffect(() => {
        //
    }, []);

    return (<SettingsMasterScreen selection={"feedback"} title={""}>
        <div className={"pad constrain"}>
            <FeedbackList options={options} />
        </div>
    </SettingsMasterScreen>);
};

export default FeedbackScreen;