import React, {useEffect, useState} from 'react';
import FileUploadModel from "../models/FileUploadModel";
import DefReset from "../models/DefReset";

const DropTarget = (props) => {
    const {
        onDrop,
        onClick, controller, onEnter,
        children, payload, styling,
        className, maxFiles, elementType,
        id, classes, colSpan, tr,
        onPaste, onPasteText, hoverContent,
        span, name } = props;
    
    const [hoverState, setHoverState] = useState(0);
    const eventId = useState(typeof id === "string" ? id : "drop-zone-" + (Math.random() * 99999999).toString(16).toUpperCase())[0];

    const initController = () => {
        if (typeof controller?.addEventListener !== "function") return;

        controller.addEventListener("update", (e) => {
            setHoverState(0);
        }, eventId);

        controller.addEventListener("reset", (sender) => {
            setHoverState(0);
        }, eventId);
    };

    const onWindowPaste = async (e) => {
        console.log("Trying to paste.");
        //DefReset.stopEvent(e);

        const clipFiles = e.clipboardData?.files;
        const textData = e.clipboardData?.getData("text");

        //if (typeof onPasteText === 'function' && !!textData) onPasteText(textData);
        
        if (!clipFiles || clipFiles.length === 0) {
            console.warn("No clip files pasted. Exiting.");
            return;
        }
        
        DefReset.stopEvent(e);
        
        if (typeof onPaste == 'function') onPaste(clipFiles);
    };
    
    const initOnPaste = () => {
        if (typeof onPaste !== 'function' && typeof onPasteText !== 'function') return () => null;
        
        window.addEventListener("paste", onWindowPaste);
        console.log("Paste Clipboard Initialized");
        
        return () => {
            window.removeEventListener("paste", onWindowPaste);
            console.log("Paste Clipboard Removed");
        }
    };

    const onDropTargetClick = (e) => {
        console.log("OnTarget Click");
        const rsp = typeof onClick === 'function' ? onClick(e) : null;
    };

    useEffect(() => {
        //
    }, [hoverState]);

    useEffect(() => {
        const x = initOnPaste();
        initController();
        
        return x;
    }, []);

    const createHoverContentElement = (e) => {
        if (typeof hoverContent === "undefined" || hoverContent === null) return null;
        const hc = (typeof hoverContent === "function") ? hoverContent(e) : hoverContent;
        return (<div className={"drop-target-hover-content"}>{hc}</div>);
    }

    const onItemDrop = (e) => {
        if (typeof e?.preventDefault === "function") e.preventDefault();
        if (typeof e?.stopPropagation === "function") e.stopPropagation();

        if (typeof onDrop !== 'function') {
            console.warn("No onDrop function defined");
            setHoverState(0);
            return;
        }

        let fileItems = e.dataTransfer.files;
        if (!fileItems || fileItems.length < 1) fileItems = [];

        let otherItems = e.dataTransfer.items;
        if (!otherItems || otherItems.length < 1) otherItems = [];

        if (fileItems.length > maxFiles && maxFiles > 0) {
            fileItems.splice(maxFiles, fileItems.length - maxFiles);
        }

        onDrop(fileItems, otherItems, payload, e);
        setHoverState(0);
    };

    const onDrag = (e) => {
        DefReset.stopEvent(e);
    };

    const onDragEnter = (e) => {
        e.preventDefault();

        const rsp = typeof onEnter === "function" ? onEnter(e) : null;
        if (rsp === false) return;

        setHoverState(2);
    };

    const onDragExit = (e) => {
        e.preventDefault();
        //console.log("Drag Exit");
        setHoverState(0);
    };

    const onDragOver = (e) => {
        e.preventDefault();

        const rsp = typeof onEnter === "function" ? onEnter(e) : null;
        if (rsp === false) return;

        setHoverState(2);
    };

    const elementId = id || ("drop-target-" + Math.random().toString(36).substring(7));
    const containerType = elementType || 'div';

    const getElementClass = () => {
        if (hoverState === 1) return classes?.hover || "hover";
        if (hoverState === 2) return classes?.dragOver || "drag-over";
        if (hoverState === 3) return classes?.hasFiles || "has-files";

        return (classes?.className || "inner-drop-target");
    };

    const elementClass = "drop-area " + getElementClass();
    const hoverContentElement = createHoverContentElement();

    if (tr === true) {
        return (<tr id={elementId} onClick={onDropTargetClick} className={elementClass} onDragLeave={onDragExit} onDragEnter={onDragEnter} onDragOver={onDragOver} onDrop={onItemDrop} onDrag={onDrag}>
            { children }
        </tr>);
    }
    
    if (span === true) {
        return (<span id={elementId} onClick={onDropTargetClick} className={elementClass} onDragLeave={onDragExit} onDragEnter={onDragEnter} onDragOver={onDragOver} onDrop={onItemDrop} onDrag={onDrag}>
            { children }
        </span>);
    }

    const mainClass = ((styling !== "none" ? "drop-target" : "no-style-target") + " " + (className || "")).trim();

    return (<div id={elementId} onClick={onDropTargetClick} className={mainClass} onDragLeave={onDragExit} onDragEnter={onDragEnter} onDragOver={onDragOver} onDrop={onItemDrop} onDrag={onDrag}>
        <div className={elementClass} {...{ "x-data": ""}}>
            { children || (<>Drop files here</>) }
        </div>
        { hoverContentElement }
    </div>);
};

DropTarget.loadFileContentAsync = async (files) => {
    if (!files || !(files?.length > 0)) {
        let filesType = (typeof files).toString();
        console.error("Failed to read HTML form file contents: no valid files provided (" + files?.length + "): " + filesType);
        return null;
    }

    const newFiles = [];

    let countDown = files.length;

    return new Promise((resolve, reject) => {
        for(let i = 0; i < files.length; i++) {
            const fileElement = files[i];

            if (!fileElement) {
                console.error("NO FILE ELEMENT [" + i + "]: ", fileElement);
                countDown--;
                continue;
            }

            const reader = new FileReader();

            reader.onload = (ev) => {
                const fileItem = {
                    filePath: typeof ev === "string" ? ev : (!!ev.target ? ev.target.result : null),
                    id: Math.floor(Math.random() * 999999999).toString(16) + "-" + (new Date()).getTime().toString(),
                    file: fileElement,
                    name: fileElement.name,
                    size: fileElement.size,
                    title: "",
                    isVideo: () => FileUploadModel.isVideoFile(fileElement?.name),
                    isImage: () => FileUploadModel.isImageFile(fileElement?.name),
                };

                if (!!fileItem.filePath) newFiles.push(fileItem);

                countDown--;

                if (countDown === 0) {
                    // When all files are read/loaded asynchronously, set the file list state view
                    countDown = -100;
                    resolve(newFiles);
                }
            };

            if (files[i] instanceof Blob) reader.readAsDataURL(files[i]);
            else {
                let itemValue = null;

                if (files[i].getAsFile === "function") {
                    itemValue = files[i].getAsFile(reader.onload);
                } else if (typeof files[i].getAsString === "function") {
                    itemValue = files[i].getAsString(reader.onload);
                    if (typeof itemValue === "undefined") {
                        countDown--;
                        continue;
                    }

                    console.error("String: " + itemValue);
                } else {
                    console.error("Don't know what to do with this: ", files[i]);
                    const ff = files[i];

                    for (let p in ff) {
                        const propType = typeof ff[p];
                        console.log(p + " (" + propType + "): ", propType !== "symbol" ? ff[p] : "(Symbol)");
                    }

                    countDown--;
                    continue;
                }

                if (!!itemValue) {
                    console.log("Item Value: ", itemValue);
                }
            }
        }

        if (countDown === 0) {
            countDown = -100;
            // When all files are read/loaded asynchronously, set the file list state view
            resolve(newFiles);
        } else {
            console.log("Drop drop in progress: " + countDown + " files remaining.");
        }

    });
}

export default DropTarget;
