import React from 'react';
import "./MediaGallery.css";
import DateTime from "../../common/ui/DateTime";
import DefReset from "../../common/models/DefReset";
import MediaService from "../services/MediaService";
import ImageModel from "../models/ImageModel";
import YouTubeVideo from "./YouTubeVideo";
import VideoModel from "../models/VideoModel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVideo} from "@fortawesome/free-solid-svg-icons";
import MediaForm from "./MediaForm";

const MediaGallery = (props) => {
    const { user, media:items, options, entityType, collectionId, title, onClick, onSave } = props;
    
    const hasCaptions = options?.captions !== false;
    
    const setNavigate = (mediaItem, index, e) => {
        
    };
    
    const onItemClick = (item, index, e) => {
        DefReset.stopEvent(e);
        
        const clickResult = typeof onClick === "function" ? onClick(item, index) : null;
        const elementId = "media-" + item.id;
        const videoElement = document.getElementById(elementId);
        
        if (!!videoElement) {
            MediaService.instance.createThumbFromVideoElementAsync(videoElement).then((f) => {
                console.log("Got Thumb. Good: ", f);
                
                if (!!f?.filePath) {
                    item.image ??= new ImageModel({ url: f.filePath });
                }
                
                if (typeof onClick === "function")
                    onClick(item, index);
                
            }).catch((ex) => {
                if (typeof onClick === "function")
                    onClick(item, index);
            });

            if (clickResult === false) return;
        }
        
        if (clickResult === true || typeof onClick !== "function") {
            const onSaved = (newMedia) => onMediaSaved(newMedia, index);
            const slug = ""; //DefReset.getSlug(item.entityType);
            const path = "/people/" + item.userId + "/" + slug + "/" + item.id;
            const dialogOptions = { path: path };
            
            let _ = MediaForm.openDialogAsync(item, index, null, dialogOptions, onSaved);
        }   
    };
    
    const deleteMediaItem = (mediaItem, e) => {
        DefReset.stopEvent(e);
        const elementId = "media-gallery-item-" + mediaItem?.id;
        const element = document.getElementById(elementId);
        if (!!element?.parentNode) element.parentNode.removeChild(element);
        
        let _ = MediaService.instance.deleteCollectionItemAsync(mediaItem.id, collectionId, entityType);
    };
    
    const onVideoError = (e) => {
        console.error("Video Error:");
        console.error(e);
    };
    
    const onMediaSaved = (savedMediaItem, index = -1) => {
        if (typeof onSave === "function") onSave(savedMediaItem, index);
        if (index < 0) return;

        items[index] = savedMediaItem;
    };
    
    const createVideoElement = (mediaItem, index, ignoreThumbnail = false) => {
        const itemKey = "media-item-" + (index || Math.random());
        const elementId = "media-" + mediaItem.id;

        const elementProps = {
            onClick: (e) => onItemClick(mediaItem, index, e),
            id: "media-gallery-item-" + mediaItem.id,
            //key: "media-item-" + index,
            className: "media-item",
            title: mediaItem?.id,
        };
        
        if (!ignoreThumbnail && !!mediaItem?.image?.url) {
            const itemUrl = MediaGallery.getImageUrlFromImage(mediaItem.image, options);
            return (<div key={itemKey} {...elementProps}>
                <span><img src={itemUrl} alt={mediaItem.name} /></span>
            </div>);
        }

        if (!ignoreThumbnail && !mediaItem.image?.size) {
            return (<div key={itemKey} {...elementProps}>
                <span><FontAwesomeIcon size={"3x"} icon={faVideo} /></span>
            </div>);
        }
        
        if (mediaItem.url.indexOf("youtube.com/") > 0) {
            let videoUrl = VideoModel.createYouTubeUrl(mediaItem.url);
            
            //let videoUrl = mediaItem.url; //"https://www.youtube.com/embed/6peu2w_whhY?si=DWaDTGjAYNTfhh6S"
            elementProps.className = "media-item youtube";
            elementProps.id = elementId;
            
            return (<div key={itemKey} {...elementProps}><span><YouTubeVideo video={mediaItem} videoUrl={videoUrl} /></span></div>);
        }

        elementProps.title = mediaItem.url || "No Url";
        
        return (<div key={itemKey} {...elementProps}>
            <video onError={(e) => onVideoError(e)} id={elementId} autoPlay={false} preload={"on"} src={mediaItem.url} playsInline={true} controls />
        </div>);
    };
    
    const elements = (items || []).map((mediaItem, i) => {
        let imageUrl = null;
        
        if (mediaItem.isVideo) {
            imageUrl = createVideoElement(mediaItem, i);
            if (typeof imageUrl !== "string" && !!imageUrl) return imageUrl;
        }

        imageUrl = MediaGallery.getImageUrlFromImage(mediaItem.image, options) || mediaItem.url;
        
        const deleteElement = entityType > 0 && typeof collectionId === "string" && collectionId.length > 30 ?
            (<span><a onClick={(e) => deleteMediaItem(mediaItem, e)}>Delete</a></span>) : 
            null;
        
        const captionElements = hasCaptions ? (<span className={"captions"}>
                <span className={"items"}>
                    <span>
                        <strong>{mediaItem.name || "(Untitled)"}</strong>
                        <span>{mediaItem.description || "(Untitled)"}</span>
                    </span>
                    <span className={"date-info"}>
                        <DateTime value={mediaItem.created} time={true} />
                        {deleteElement}
                    </span>
                </span>
            </span>) : null;
        
        return (<div onClick={(e) => onItemClick(mediaItem, i, e)} id={"media-gallery-item-" + mediaItem.id} key={"media-item-" + i} className={"media-item"} title={mediaItem?.id}>
            <span><img src={imageUrl} alt={mediaItem.name} /></span>
            {captionElements}
        </div>);
    });
    
    const elementClassName = (options?.grid === false ? "line-items" : "wrap-grid");
    const className = options?.className || "";
    const titleElement = typeof title === "string" && elements.length > 0 ? (<h3>{title}</h3>) : null;
    
    return (<div className={("media-gallery " + className).trim()}>
        {titleElement}
        <div className={elementClassName}>
            {elements}
        </div>
    </div>);
};

MediaGallery.getImageUrlFromImage = (image, options) => {
    if (typeof image?.getThumb === "function") {
        return (typeof options?.sizeTag === "string" && !!options.sizeTag) ?
            image.getThumb(options.sizeTag)?.url :
            image.url;
    }

    //console.error("No Thumb Found for Image: ", image);
    return image?.url || null;
};


MediaGallery.createVideoElement = (mediaItem, index, options, onItemClick, onVideoError, ignoreThumbnail = false) => {
    const itemKey = "media-item-" + (index || Math.random());
    const elementId = "media-" + mediaItem.id;

    if (!ignoreThumbnail && !!mediaItem?.image?.url) {
        const itemUrl = MediaGallery.getImageUrlFromImage(mediaItem.image, options);
        return (<div onClick={(e) => onItemClick(mediaItem, index, e)} id={"media-gallery-item-" + mediaItem.id} key={"media-item-" + index} className={"media-item"} title={mediaItem?.id}>
            <span><img src={itemUrl} alt={mediaItem.name} /></span>
        </div>);
    }

    if (!ignoreThumbnail && !mediaItem.image?.size) {
        return (<div id={"media-gallery-item-" + mediaItem?.id} key={"media-item-" + index} className={"media-item"} title={mediaItem?.id}>
            <span><FontAwesomeIcon size={"3x"} icon={faVideo} /></span>
        </div>);
    }

    if (mediaItem.url.indexOf("youtube.com/") > 0) {
        let videoUrl = VideoModel.createYouTubeUrl(mediaItem.url);

        //let videoUrl = mediaItem.url; //"https://www.youtube.com/embed/6peu2w_whhY?si=DWaDTGjAYNTfhh6S"

        return (<div id={elementId} onClick={(e) => onItemClick(mediaItem, index, e)} className={"media-item youtube"} key={itemKey}><span><YouTubeVideo video={mediaItem} videoUrl={videoUrl} /></span></div>);
    }

    return (<div onClick={(e) => onItemClick(mediaItem, index, e)} className={"media-item"} key={itemKey} title={mediaItem.url}>
        <video onError={(e) => onVideoError(e)} id={elementId} autoPlay={false} preload={"on"} src={mediaItem.url} playsInline={true} controls />
    </div>);

}

export default MediaGallery;


