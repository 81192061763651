import React, {useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faCreditCardAlt} from "@fortawesome/free-solid-svg-icons";
import AuthenticationService from "../../components/authentication/services/AuthenticationService";
import DefReset from "../../components/common/models/DefReset";
import DefButton from "../../components/common/ui/elements/DefButton";

const LoginScreen = (props) => {
    const {} = props;
    const buttonId = "login-button";
    const [errorMessage, setErrorMessage] = useState(null);
    const username = useRef();
    const password = useRef();
    
    const authenticateAsync = async (e) => {
        DefReset.stopEvent(e);
            
        console.log("Auth");
        
        if (!username.current?.value) return;
        if (!password.current?.value) return;
        
        await AuthenticationService.instance.authenticateAsync(username.current?.value, password.current?.value).catch((ex) => {
            const msg = ex?.response?.data?.message || ex?.message || ex?.toString() || "Unknown Error";
            console.error(ex);
            
            if (!msg) return;
            
            setErrorMessage(msg);
            alert(msg);
        });
        
        return false;
    };

    const onKeyPressed = (e) => {
        // Capture <Enter> key

        let _;
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13) _ = authenticateAsync(e);
    };
    
    return (<div id={"login"}>
        <form onSubmit={() => false}>
            <h2>Login Here</h2>
            
            <div className={"form"}>
                <div className={"form-group"}>
                    <label>Email:</label>
                    <input autoComplete={"on"} type={"text"} placeholder={"Username"} ref={username} id={"username"} />
                </div>
                <div className={"form-group"}>
                    <label>Password:</label>
                    <input onKeyUpCapture={onKeyPressed} autoComplete={"on"} type={"password"} placeholder={"Password"} ref={password} id={"password"} />
                </div>
                
                <div className={"form-error"}>
                    {errorMessage}
                </div>
                
                <div className={"buttons"}>
                    <DefButton id={buttonId} onClick={authenticateAsync}>Login</DefButton>
                </div>
            </div>
        </form>
    </div>);

};

export default LoginScreen;
