import React from 'react';
import {
    faCarrot,
    faCode,
    faFeed,
    faFileCode,
    faFileText,
    faGear,
    faList,
    faMessage, faObjectGroup
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

const SettingsSubMenu = (props) => {
    const { selection } = props;

    const classes = {};
    classes[selection || "overview"] = "selected";

    const basePath = "/settings/";

    const elements = SettingsSubMenu.menuItems.map((m, i) => {
        const key = m.path || "";
        return (<li key={"menu-item-" + i} className={classes[key || "overview"] || ""}><Link to={basePath + key}>
            {m.caption}
        </Link></li>);
    });

    return (<div className={"settings-menu"}>
        <ul className={"sub-menu"}>
            {elements}
        </ul>
    </div>);
};

SettingsSubMenu.menuItems = [
    { path: "", caption: "Overview", icon: faGear, description: "Management area of the Portal" },
    { path: "content", caption: "Content", icon: faCode, description: "Text and Media Content" },
    { path: "feedback", caption: "Feedback", icon: faFeed, description: "Feedback and incoming contact requests" },
    { path: "messaging", caption: "Messaging", icon: faMessage, description: "Send different types of messages to users" },
    { path: "message-templates", caption: "Message Templates", icon: faObjectGroup, description: "Edit email, sms, and push messages" },
    { path: "documents", caption: "Documentation", icon: faFileCode, description: "Internal Content" },
    { path: "activities", caption: "Logs and Activity", icon: faList, description: "Individual food items that make up a meal" },
];

export default SettingsSubMenu;
