import WorkoutExerciseModel from "./WorkoutExerciseModel";

class WorkoutModel {
    static entityType = -1;
    static create = (json) => new WorkoutModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.status = json.status || 0;
        this.name = json.name || "";
        this.description = json.description || "";
        this.duration = json.workout_duration  || 0;
        this.durationUnits = json.workout_duration_units || 0;
        this.workoutType = json.workout_type || 0;
        this.workoutTypeName = json.workout_type_name || "";
        this.parentWorkoutId = json.parent_workout_id || null;
        
        this.image = json.image || null;
        this.imageId = json.imageId || null;
        
        this.exercises = WorkoutExerciseModel.fromJsonArray(json.exercises);
        this.workouts = WorkoutModel.fromJsonArray(json.workouts);
        this.exerciseCount = this.exercises?.length || 0;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            name: this.name,
            description: this.description,
            workout_duration: this.duration,
            workout_duration_units: this.durationUnits,
            workout_type: this.workoutType,
            parent_workout_id: this.parentWorkoutId,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.name?.toLowerCase().indexOf(term) >= 0) return true;
        if (this.description?.toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => WorkoutModel.create(json));
    }
}

export default WorkoutModel;
