import HttpService from "../../common/services/HttpService";
import CommentModel from "../models/CommentModel";

class CommentService { 
    static instance = new CommentService();

    constructor() {
        this.commentsMap = {};
        this.comments = [];
        this.commentMap = {};
    }

    async getCommentAsync(commentId) {
        const path = '/api/comment/' + commentId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.commentMap[commentId] = new CommentModel(response.data);
            return me.commentMap[commentId];
        });
    }

    async getCommentsAsync(options = { startDate: null, endDate: null, userId: null, entityId: null, entityType: 0 }) {
        let path = "/api/comment/all";
        const me = this;

        if (!options || typeof options !== "object") options = {};
        
        if (typeof options.entityId === "string" && options.entityId.length > 30)
            path = "/api/comments/" + options.entityId;
        else if (typeof options.userId === "string" && options.userId.length > 30)
            path = "/api/user/" + options.userId + "/comment";
        
        const sd = !!options?.startDate ? options.startDate : null;
        const ed = !!options?.endDate ? options.endDate : null;
        
        return await HttpService.instance.getWithDateRangeAsync(path, sd, ed).then((response) => {
            me.comments = response.data.map((comment) => {
                return new CommentModel(comment);
            });

            return me.comments;
        });
    }
}

export default CommentService;
