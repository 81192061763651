import React, {useEffect, useState} from 'react';
import MediaService from "../services/MediaService";
import LoadingBox from "../../common/ui/LoadingBox";
import "./VideoPlayer.css";
import DebugJson from "../../common/ui/DebugJson";
import ErrorBox from "../../common/ui/ErrorBox";
import VideoModel from "../models/VideoModel";
import YouTubeVideo from "./YouTubeVideo";
import DefReset from "../../common/models/DefReset";

const VideoPlayer = (props) => {
    const { id, children, className, video:initialVideo, videoId, options:initialOptions, width, height, onSize } = props;
    const elementId = useState(typeof id === "string" ? id : "video-" + (Math.random(9999999).toString(16)))[0];
    const options = useState(initialOptions)[0];
    const [errors, setErrors] = useState({ general: "" });
    const [video, setVideo] = useState(initialVideo);
    
    const getVideoAsync = async (force = false) => {
        if (!force && !!video?.url) return video;
        
        return await MediaService.instance.getVideoAsync(videoId).then((v) => {
            if (v === null) return;
            
            setVideo(v);
            
            return v;
        });
    };
    
    const setControls = () => {
        if (!!options && typeof options === "object") {
            options.refresh = async (force) => getVideoAsync(force);
        }
    };
    
    const getVideoSize = () => {
        const videoElement = document.getElementById(elementId);
        if (!videoElement) return { width: 0, height: 0 };
        
        const rect = videoElement.getBoundingClientRect();
        
        return { width: rect.width, height: rect.height };
    };
    
    const measure = (e) => {
        DefReset.stopEvent(e);
        if (typeof onSize !== "function") return;
        
        console.log("Measure");
        onSize(getVideoSize());        
    };
    
    const createVideoElement = (w = null, h = null) => {
        if (typeof video?.url !== "string") {
            if (!!errors?.general) return (<ErrorBox>{errors.general}</ErrorBox>);
            return (<LoadingBox>{children || "Loading Video"}</LoadingBox>);
        }

        if (VideoModel.isYouTube(video.url)) {
            return (<YouTubeVideo video={video} videoUrl={video.url} />);
        }
        
        return (<video id={elementId} src={video.url} width={w} height={h} onLoadedData={measure}>
            <source src={video} type="video/mp4" />
        </video>);        
    };

    useEffect(() => {
        getVideoAsync().then((_) => {
            //if (typeof onSize === "function") onSize(getVideoSize());
        });
    }, [video]);
    
    useEffect(() => {
        setControls();
    }, []);
    
    if (!videoId && !video?.url && !options?.id)
        return (<div className={"error-box"}>No VideoId Provided.</div>);

    const w = width > 0 ? width : video?.width || 0;
    const h = height > 0 ? height : video?.height || 0;
    const cn = typeof className === "string" ? className : "";
    const videoElement = createVideoElement();
    
    const debugElement = !!video?.url ? (<DebugJson value={video} />) : null;
    
    return (<div className={"video-player " + cn} id={elementId + "-container"}>
        <span onClick={(e) => {
            const sz = getVideoSize();
            alert("Video Size: " + sz.width + "x" + sz.height);
        }} className={"video-container"}>{videoElement}</span>
    </div>);
};

export default VideoPlayer;
