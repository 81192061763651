import React, { useRef } from "react";
import Modal from './Modal.js';
import './Modal.css';
import ReactDOM from "react-dom/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faCircleCheck,
    faGear,
    faGhost,
    faRoadBarrier,
    faSkull
} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

class ReactModal extends Modal {
    static dialogBackgroundClassName = "pi-modal-background";

    constructor(json) {
        if (!json) json = {};
        if (!json.modalType) json.modalType = "dialog";

        super(json);

        if (typeof json.onClose === "function")
            this.onClose = json.onClose;

        this.reactBody = null;
        this.defaultBackgroundClassName = (ReactModal.dialogBackgroundClassName + " " + this.userClassName).trim();
        this.backgroundClassName = this.defaultBackgroundClassName;
        this.payload = null;

        this.onContainerSet = (container) => this.setDialogContainerY(container);

        this.onPreOpen = (me, containerRect) => {
            //
            return true;
        };
    };

    getToastPositionClassName(options) {
        if (typeof options?.positionClassName === "string")
            return options.positionClassName;

        if (typeof options?.placement !== "string") {
            return "";
        }

        const placements = options.placement.trim().toLowerCase().split(" ");
        let cn = "";

        if (placements.includes("top")) cn += "top ";
        else if (placements.includes("bottom")) cn += "bottom ";
        else cn += "center ";

        return cn.trim();
    }

    async openToastDialogAsync(content, options = {}) {
        if (typeof options === "number") {
            options = { duration: options };
        } else if (typeof options === "string") {
            options = { placement: options };
        } else if (typeof options === "function") {
            options = { onClose: options };
        } else if (typeof options !== "object") {
            options = {};
        }

        if (typeof options.duration !== "number" || options.duration <= 0 || isNaN(options.duration))
            options.duration = 13000;

        const duration = options.duration;
        const closeDuration = typeof options.closeDuration === "number" && options.closeDuration > 0 ?
            options.closeDuration :
            1200;

        const positionClassName = this.getToastPositionClassName(options);
        options.userClassName = ((options.userClassName || "") + " dialog toast " + positionClassName).trim();

        await this.openDialogAsync(content, options);

        await new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true);
            }, duration);
        });

        if (typeof options.onBackgroundClick === "function") {
            this.onBackgroundClick = options.onBackgroundClick;
        } else {
            this.onBackgroundClick = null;
        }

        const rsp = typeof options.onClose === "function" ? options.onClose() : null;
        const result = typeof rsp?.then === "function" ? await rsp : rsp;

        if (result === false) return false;

        await this.closeAsync(options?.e, { duration: closeDuration });

        return true;
    }

    async toAlertAsync(content, options = {}) {
        await this.closeContainerAsync(options, 0);

        if (typeof content === "string")
            content = (<div className="pi-dialog-alert-content">{content}</div>);

        if (typeof options === "function") {
            options = { onClick: options };
        } else if (typeof options !== "object") {
            options = {};
        }

        options.icon = faBell;

        return await this.openAlertDialogAsync(content, options);
    }

    async toCompletedAsync(content, options = {}) {
        await this.closeContainerAsync(options, 0);

        if (typeof options === "function") {
            options = { onClick: options };
        } else if (typeof options !== "object") {
            options = {};
        }

        options.icon = faCircleCheck;

        if (typeof content === "string") {
            options.iconClassName = "complete";
            content = this.createRectIconContent(content, options, faBell);
        }

        if (this.onBackgroundClick === Modal.suppressBackgroundClick) {
            this.onBackgroundClick = null;
        }

        return await this.openAlertDialogAsync(content, options);
    }

    async toErrorAsync(content, options = {}) {
        await this.closeContainerAsync(options, 0);

        if (typeof content === "string")
            content = (<div className="pi-dialog-error-content">{content}</div>);

        if (typeof options === "function") {

        } else if (typeof options !== "object") {
            options = {};
        }

        options.icon = faSkull;
        if (!options.title) options.title = "Oops...";

        return await this.openAlertDialogAsync(content, options);
    }

    async openActivityAsync(content, options = {}) {
        if (typeof options === "function") {
            options = { onClick: options };
        } else if (typeof options === "string") {
            options = { title: options };
        } else if (typeof options !== "object") {
            options = {};
        }

        if (typeof options === "function") {
            options = { onClick: options };
        } else if (typeof options !== "object") {
            options = {};
        }

        if (!options.title) options.title = "Working...";
        if (!options.iconClassName) options.iconClassName = "pi-dialog-activity-icon";
        if (!options.icon) options.icon = faGear;

        options.userClassName = "dialog working";

        this.onBackgroundClick = Modal.suppressBackgroundClick;

        if (typeof content === "string") {
            options.iconClassName = "activity";
            content = this.createRectIconContent(content, options, faBell);
        }

        this.onBackgroundClick = Modal.suppressBackgroundClick;

        return await this.openDialogAsync(content, options, []);
    }

    async openErrorDialogAsync(content, options = {}, ...args) {
        options.icon = faGhost;
        options.iconClassName = "error";
        return await this.openAlertDialogAsync(content, options, ...args);
    }

    /***
     *
     * @param content {object|string} - The content to display in the dialog. Can be a string or a React component
     * @param options {object|string|function} - Settings that govern the behavior and appearance of the dialog. Notable properties include:
     *  - icon: The icon to display in the dialog. Defaults to faBell
     *  - iconClassName: The class name to apply to the icon. Defaults to "alert"
     *  - buttonCaption: The caption to display on the button. Defaults to "Okay"
     *  - onClick: The function to call when the user clicks the Okay button
     * @returns {Promise<ReactModal>} - Return itself
     */
    async openAlertDialogAsync(content, options = {}, ...args) {
        if (typeof options === "function") {
        } else if (typeof options === "string") {
            options = { title: options };
        } else if (typeof options !== "object") {
            options = {};
        }

        if (args?.length > 0) {
            let argType = typeof args[0];

            if (argType === "function") {
                options.onClick = args[0];
            } else if (argType === "object" && typeof args[0].onClick === "function") {
                options.onClick = args[0].onClick;
            }
        }

        const okCaption = options.buttonCaption || "Okay";

        const buttons = [
            {
                caption: okCaption,
                onClick: async (e) => {
                    const rsp = typeof options?.onClick === "function" ? options.onClick(e) : null;
                    const isAsync = typeof rsp?.then === "function";
                    const result = (isAsync) ? await rsp : rsp;

                    if (result === false) return false;

                    if (isAsync) await this.closeAsync(e);
                    else this.closeAsync(e);

                    return true;
                },
            },
        ];

        options.userClassName = "dialog alert";

        if (typeof content === "string") {
            if (!options.iconClassName) options.iconClassName = "alert";
            content = this.createRectIconContent(content, options, faBell);
        }

        return await this.openDialogAsync(content, options, options.useButtons === false ? [] : buttons);
    }

    /**
     *
     * @param content {object|string} - The content to display in the dialog. Can be a string or a React component
     * @param onClick {function|null} - The function to call when the user clicks the Okay button
     * @param options {object|string|function} - Settings that govern the behavior and appearance of the dialog
     * @returns {Promise<ReactModal>} - Return itself
     */
    async openConfirmDialogAsync(content, onClick, options = {}) {
        // Ensure params
        if (typeof options === "function" && typeof onClick !== "function") {
            const tmp = onClick;
            onClick = options;
            options = tmp;
        }

        if (typeof options === "string") {
            options = { title: options };
        }

        // noinspection JSDeprecatedSymbols
        if (typeof options?.fixed !== "boolean") {
            options.fixed = true;
        }

        const okCaption = options.buttonCaption || "Okay";
        const cancelCaption = options.cancelButtonCaption || "Cancel";

        const buttonDescriptors = [
            {
                caption: cancelCaption,
                onClick: async (e) => await this.closeAsync(e),
                isCancel: true,
            },
            {
                caption: okCaption,
                id: options.buttonId || ("button-" + (new Date()).getTime().toString(16).toLowerCase()),
                onClick: async (e) => {
                    const rsp = typeof onClick === "function" ? onClick(e) : null;
                    const result = (typeof rsp?.then === "function") ? await rsp : rsp;

                    if (result === false) return false;

                    return this.closeAsync(e);
                },
            },
        ];

        options.userClassName = "dialog confirm";

        if (typeof content === "string") {
            options.frameClassName = "confirm";
            content = this.createRectIconContent(content, options, options?.icon || faRoadBarrier);
        }

        return await this.openDialogAsync(content, options, buttonDescriptors);
    }

    async openFormDialogAsync(formElement, onClick, options = {}) {
        if (!ReactModal.isReact(formElement)) throw new Error("Invalid formElement passed to openFormDialogAsync. Must be a react component.");
        if (typeof onClick !== "function" && !options?.buttons) throw new Error("Invalid onClick function passed to openFormDialogAsync");

        if (typeof options === "string") {
            options = { title: options };
        } else if (typeof options !== "object") {
            options = {};
        }

        options.userClassName = ("dialog form " + (options.userClassName || "")).trim();

        if (typeof options?.fixed !== "boolean")
            options.fixed = true;

        if (options.close === null || options.close === undefined) options.close = true;

        if (options.backgroundCloses !== true) {
            this.onBackgroundClick = Modal.suppressBackgroundClick;
        }

        const buttonsIncluded = Array.isArray(options.buttons);
        let buttons = buttonsIncluded ? options.buttons : [];

        // If the buttons are included in the options object, but not an array of them, add them to the buttons array
        if (typeof options.buttons === "object" & !!options.buttons && !Array.isArray(options.buttons)) {
            if (options.append !== true) buttons = [];
            buttons.push(options.buttons);
            delete options.buttons;
        }

        const cancelClassName = "pi-modal-cancel-button";

        if (options?.hasCancelButton !== false && options?.cancelButton !== false) {
            const cancelButtonElement = (<button
                onClick={(e) => this.closeAsync(e, { ...options, source: "cancel" })}
                className={"react-modal-button " + cancelClassName + " cancel"}
                key="cancel-button">{options.cancelCaption || "Cancel"}</button>);

            if (buttonsIncluded && options?.appendButtons !== true) buttons.splice(0, 0, cancelButtonElement);
            else buttons.push(cancelButtonElement);
        }

        const buttonText = typeof options?.buttonCaption === "string" ? options.buttonCaption : "Submit";
        const buttonClassName = "pi-modal-button";
        const buttonElementId = options?.buttonId || ("pi-modal-submit-button-" + (new Date()).getTime().toString(16));
        const labelElementId = options?.buttonLabelId || "pi-model-submit-button-label-" + (new Date()).getTime().toString(16);

        const submitButtonLabel = !options?.buttonCaption || typeof options?.buttonCaption === "string" ?
            (<>
                <span id={labelElementId}>{buttonText}</span>
                <span className="activity"><label></label></span>
            </>) :
            options?.buttonCaption;

        const setButtonWorkingClass = (cn, workingLabel = null) => {
            const buttonElement = document.getElementById(buttonElementId);
            const buttonLabel = document.getElementById(labelElementId);

            if (!buttonElement) {
                console.warn("No Button with Id: " + buttonElement);
                return;
            }

            if (typeof cn !== "string") cn = "";
            buttonElement.className = (buttonClassName + " " + cn).trim();

            if (!!buttonLabel) {
                buttonLabel.innerText = workingLabel;
            } else if (buttonElement.innerHTML && buttonElement.innerHTML.indexOf(">") < 0) {
                buttonElement.innerHTML = workingLabel;
            }
        };

        if (!buttonsIncluded || options.appendButtons === true) {
            const onClickAsync = async (e) => {
                if (typeof options?.onPreClose === "function") {
                    const rx = options.onPreClose(e);
                    const rz =  (typeof rx?.then === "function") ? await rx : rx;

                    if (rz === false) return;
                }

                const workingLabel = options?.workingCaption || "Processing";
                const rsp = onClick(e);
                const isAsync = (typeof rsp?.then === "function");

                console.warn("Dialog onClick");

                if (isAsync) setButtonWorkingClass("working", workingLabel);
                else console.warn("Dialog is not async");

                const result = isAsync ? await rsp : rsp;

                if (isAsync) setButtonWorkingClass("", buttonText);

                let _;
                if (result !== false) _ = this.closeAsync();

                return result;
            };

            buttons.push(<button id={buttonElementId} className="react-modal-button submit-button default-button" key="onClick-button" onClick={onClickAsync}>{submitButtonLabel}</button>);
        }

        options.append = !buttonsIncluded;

        return await this.openDialogAsync(formElement, options, buttons);
    }

    async openDialogAsync(content, options = {}, buttonDescriptors = []) {
        const dc = typeof options?.className === "string" && !!options.className ? options.className : "";
        content = (<div className={"pi-modal-content " + dc}>{content}</div>);

        if (!Array.isArray(buttonDescriptors) && Array.isArray(options)) {
            const tmp = buttonDescriptors;
            buttonDescriptors = options;
            options = tmp || {};
        } else if (options === "function") {
            const tmp = options;
            options = buttonDescriptors;
            buttonDescriptors = tmp || [];
        }

        if (typeof options.userClassName === "string") this.userClassName = options.userClassName;
        if (!this.userClassName) this.userClassName = "dialog";

        if (typeof buttonDescriptors === "function" || (!Array.isArray(buttonDescriptors) && typeof buttonDescriptors === "object"))
            buttonDescriptors = [buttonDescriptors];

        if (!Array.isArray(buttonDescriptors)) buttonDescriptors = [buttonDescriptors].filter((b) => !!b);
        if (Array.isArray(options.buttons)) {
            if (options.append !== true) buttonDescriptors = [];
            buttonDescriptors.push(...options.buttons);
        }

        if (!options) options = {};

        options.duration = 175;

        this.position = null;
        this.options = options;

        if (options.backgroundCloses === false) {
            this.onBackgroundClick = Modal.suppressBackgroundClick;
        }

        const sections = [];

        // Top Right Close[x] button
        if (options.close === true) {
            sections.push((<span key={"dialog-close-panel"} onClick={(e) => this.closeAsync(e)} className="close-panel">x</span>));
        } else if (ReactModal.isReact(options.close) || (typeof options.close === "string" && options.close.length > 0)) {
            sections.push((<span key={"dialog-close-panel"} onClick={(e) => this.closeAsync(e)} className="close-panel">{options.close}</span>));
        }

        const isClickable = typeof options?.titlePath === "string";
        const titleClassName = isClickable ? "clickable" : "";
        const dialogTitle = isClickable ? (<a href={options.titlePath}>{options?.title}</a>) : options.title;
        
        if (typeof options.title === "string" || ReactModal.isReact(options.title))
            sections.push((<h2 key={"dialog-section-title"} className={"pi-modal-dialog-title " + titleClassName}>
                {dialogTitle}
            </h2>));

        sections.push((<React.Fragment key={"body-key"}>{content}</React.Fragment>));

        const reactButtons = buttonDescriptors.map((button, index) => this.createReactButton(button, "button-" + index));
        if (Array.isArray(options.actions)) {
            options.actions.forEach((action, index) => {
                reactButtons.unshift(action);
            });
        }
        if (reactButtons.length > 0) sections.push((<div key={"buttons-key"} className="pi-modal-buttons">{reactButtons}</div>));

        const body = (<div className="pi-modal-body">{sections}</div>);

        if (typeof options?.onClose === "function") this.onClose = options.onClose;

        return await super.openAsync(body, buttonDescriptors, options || {});
    };

    setDialogContainerY(container) {
        // Set the dialog container, taking scroll position into account
        if (!container) {
            console.error("No container element with .styles property passed to setDialogContainerY");
            return false;
        }

        const scrollPos = this.getScrollOffset();
        container.style.top = ((scrollPos.y + 64)).toString() + "px";
        console.log("OnDialogContainer Set: " + container?.className + ":  " + scrollPos.y);
        console.log(" > Style:: " + container.style.top + "");

        return true;
    }

    getScrollOffset() {
        // Get current scroll position of the browser window
        const pos = { x: 0, y: 0 };

        if (typeof document !== "undefined") {
            pos.x = document.body.scrollLeft; // + document.documentElement.scrollLeft;
            pos.y = document.body.scrollTop; // + document.documentElement.scrollTop;
        }

        return pos;
    }

    createRectIconContent(content, options, defaultIcon) {
        if (!options) options = {};

        const icon = ReactModal.isReact(options?.icon) ? options.icon : (<FontAwesomeIcon icon={options.icon || defaultIcon} />);
        const frameClassName = typeof options.iconClassName === "string" ? options.iconClassName : "";

        return (<div className={("pi-modal-dialog-frame " + frameClassName).trim()}>
            <div className="pi-modal-dialog-icon">{icon}</div>
            <div className="pi-modal-dialog-pp-content">{content}</div>
        </div>);
    }

    createReactButton(button, key, caption = "Okay", timeout = 12000) {
        if (ReactModal.isReact(button)) return (<React.Fragment key={key}>{button}</React.Fragment>);

        const isCancelButton = button?.isCancel === true;
        const cancelClassName = "pi-modal-cancel-button";
        const buttonClassName = "pi-modal-button";
        const buttonId = button?.id || "pi-modal-button-" + (Math.floor(Math.random() * 99999999).toString(16));

        const me = this;
        /**
         *
         * @param buttonElementId {string} - The id of the button element
         * @param workingClassName {string|null} - Button className
         * @param workingLabel {string|null} - Text or HTML to display when the button is in a animated, working state
         */
        const setButtonWorkingClass = (buttonElementId, workingClassName, workingLabel = null) => {
            const buttonElement = document.getElementById(buttonElementId);
            if (!buttonElement) return;

            if (typeof workingClassName !== "string") workingClassName = "";

            const ccn = isCancelButton ? " " + cancelClassName : "";
            buttonElement.className = (buttonClassName + ccn + " " + workingClassName).trim();

            if (!!workingLabel) {
                if (!workingClassName) buttonElement.innerText = workingLabel;
                else {
                    const animatedElement = document.createElement("span");
                    const spinnerElement = document.createElement("span");
                    spinnerElement.className = "pi-modal-spinner-icon";
                    spinnerElement.innerHTML = '&nbsp;';

                    const labelElement = document.createElement("label");
                    labelElement.className = "pi-dialog-animated";
                    labelElement.innerHTML = workingLabel;

                    buttonElement.innerHTML = "";
                    animatedElement.appendChild(spinnerElement);
                    buttonElement.appendChild(animatedElement);
                    buttonElement.appendChild(labelElement);

                    // Allow context reset to the css transition fires
                    const t = setTimeout(() => {
                        animatedElement.className = "pi-dialog-animated-button";
                        clearTimeout(t);
                    }, 1);
                }
            }
        }

        if (typeof button === "object") {
            const props = { ...button };

            if (typeof props.className !== "string") props.className = "";
            props.className = (buttonClassName + " " + props.className).trim();

            if (isCancelButton) {
                props.className += " " + cancelClassName;
                delete props.isCancel;
            }

            const buttonCaption = (props.children || props.caption) || (props.body || props.text) || (props.label || caption) || "Okay";
            const workingCaption = !!props.workingCaption ? props.workingCaption : "Working...";

            delete props?.workingCaption;

            if (props.id !== buttonId) props.id = buttonId;

            const clicker = async (e) => {
                if (me.isWorking === true) {
                    if (typeof e?.preventDefault === "function") e.preventDefault();
                    if (typeof e?.stopPropagation === "function") e.stopPropagation();

                    return;
                }

                const rsp = typeof props.onClick === "function" ? props.onClick(e) : null;
                const isAsync = typeof rsp?.then === "function";
                const isAnimated = isAsync && !isCancelButton;

                me.isWorking = true;

                let cb = null;
                if (isAnimated) {
                    setButtonWorkingClass(buttonId, "working", workingCaption);
                    cb = document.querySelector("." + cancelClassName);

                    if (cb !== null && !!cb.classList && !cb.classList.contains("cancel-working"))
                        cb.classList.add("cancel-working");

                    if (timeout > 0) {
                        const tt = setTimeout(() => {
                            if (me.isWorking === true) {
                                delete me.isWorking;
                                if (!!cb && !!cb?.classList && cb.classList.contains("cancel-working"))
                                    cb.classList.remove("cancel-working");
                                //setButtonWorkingClass(buttonId, "", !!workingCaption ? buttonCaption : null);
                            }
                        }, timeout);
                    }
                }

                const result = isAsync ? await rsp : rsp;

                if (isAnimated) {
                    setButtonWorkingClass(buttonId, "", !!workingCaption ? buttonCaption : null);

                    if (!!cb && !!cb?.classList && cb.classList.contains("cancel-working"))
                        cb.classList.remove("cancel-working");
                }

                delete me.isWorking;

                if (result === false) return;

                await this.closeAsync(me);
            };

            return (<button key={key} {...props} onClick={clicker}>{buttonCaption}</button>);
        }

        if (typeof button === "function") {
            const clicker = async (e) => {
                const rsp = button(e);
                const isAsync = typeof rsp?.then === "function";

                if (isAsync) setButtonWorkingClass(buttonId, "working");

                const result = isAsync ? await rsp : rsp;
                if (result === false) return;

                await this.closeAsync(this);
            };

            return (<button key={key} onClick={clicker}>{caption || "Okay"}</button>);
        }

        return (<button key={key} onClick={this.closeAsync}>{button}</button>);
    }

    setPlacement(placement, emptyOk = true) {
        const placementTokens = typeof placement === "string" && placement.trim().length > 0 ?
            placement.split(" ").map((tok) => tok.toLowerCase()) : [];

        if (placementTokens.includes("top")) this.verticalPlacement = "top";
        else if (placementTokens.includes("bottom")) this.verticalPlacement = "bottom";
        else if (emptyOk) this.verticalPlacement = "";

        if (placementTokens.includes("left")) this.horizontalPlacement = "left";
        else if (placementTokens.includes("right")) this.horizontalPlacement = "right";
        else if (emptyOk) this.horizontalPlacement = "";
    }

    getPlacementClassName() {
        let className = "";
        if (this.verticalPlacement.length > 0) className += " " + this.verticalPlacement;
        if (this.horizontalPlacement.length > 0) className += " " + this.horizontalPlacement;

        return className.trim();
    }

    createContainerWithContent(content, buttons) {
        if (!!this.reactRoot) return;

        this.reactRoot = this.container;

        const container = this.getContainerElement();
        const defaultId = this.id + "-body";
        const durationStyle = { transitionDuration: Modal.transitionDurations.containerBody + "ms" };

        this.reactBody = ReactModal.toReactBody(content, defaultId, durationStyle);
        this.bodyElementId = this.reactBody.props.id;

        while (container.hasChildNodes())
            container.removeChild(container.firstChild);

        const root = ReactDOM.createRoot(this.container);
        root.render(this.reactBody);

        return this.container;
    }

    async openAsync(content, buttons = [], options = {}) {
        return await super.openAsync(content, buttons, options);
    }

    /**
     * Checks to see if a given variable is that of a React component (function class or JSX)
     * @param {object|any} component - The object in question
     * @returns {boolean}
     */
    static isReact(component) {
        if (!component) return false;
        return (typeof component.ref !== 'undefined' && typeof component.props === 'object');
    }

    /**
     * Converts a class to a React component
     */
    static toReactBody(body, elementId, style = null, className = null) {
        if (body !== 0 && !body) return null;

        let elementType = "div";
        let props = {};

        if (ReactModal.isReact(body)) {
            props = { ...body.props };
            if (props.id?.length > 0) elementId = props.id;

            elementType = body.type;
            body = props.children;

            delete props.children;
            delete props.id;
        }

        if (typeof className !== "string") className = Modal.classes.body;

        props.id = elementId;
        props.className = ((props.className || "") + " " + className).trim();
        if (!!style) props.style = { ...props.style, ...style };

        return React.createElement(elementType, props, body);
    }
}

export default ReactModal;
