import MediaModel from "../../media/models/MediaModel";
import HabitService from "../services/HabitService";
import UserModel from "../../users/models/UserModel";

class UserHabitModel {
    static entityType = 52;
    static create = (json) => new UserHabitModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.habitId = json.habit_id || null;
        this.userId = json.user_id || null;
        this.user = !!json.user?.id ? new UserModel(json.user) : null;
        this.description = json.description || null;
        this.value = json.value || null;
        this.lat = json.lat || null;
        this.lon = json.lon || null;
        this.data = json.data || {};
        this.notes = json.notes || "";
        this.habitKey = json.habit_key || "";
        this.timezoneOffset = json.timezone_offset || 0;
        this.habitDate = !!json.habit_date ? new Date(json.habit_date) : null;
        this.completedDate = !!json.completed_date ? new Date(json.completed_date).addMinutes(-this.timezoneOffset) : null;
        this.status = json.status || 0;
        this.habit = HabitService.instance.habitMap[this.habitId] || null;
        this.media = MediaModel.fromJsonArray(json.media) || [];
        this.json = json;
        
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            habit_id: this.habitId,
            user_id: this.userId,
            description: this.description,
            value: this.value,
            lat: this.lat,
            lon: this.lon,
            timezone_offset: this.timezoneOffset,
            habit_date: this.habitDate,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.description?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.lat?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.lon?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.timezoneOffset?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => UserHabitModel.create(json));
    }
}

export default UserHabitModel;
