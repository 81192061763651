import UserModel from "../../users/models/UserModel";
import StackTraceModel from "./StackTraceModel";

class SystemErrorModel {
    // public Guid? UserId { get; set; }
    //
    // public int HttpStatus { get; set; }
    // public int PlatformErrorCode { get; set; }
    //
    // public string? Message { get; set; }
    // public string? UserAgent { get; set; }
    // public string? IpAddress { get; set; }
    // public string? RequestPath { get; set; }
    // public string? DomainName { get; set; }
    // public string? EndPoint { get; set; }
    // public string? WebSession { get; set; }
    // public string? ViewPort { get; set; }

    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id;
        this.userId = json.user_id || null;
        this.httpStatus = json.http_status || 0;
        this.platformErrorCode = json.platform_error_code || 0;
        this.platformErrorCode = json.platform_error_code_name || "";
        
        this.message = json.message || null;
        this.stack = new StackTraceModel(json.message, json.user_id);
        if ((this.stack.message || "").length > 0) this.message = this.stack.message;
        
        this.userAgent = json.user_agent || null;
        this.ipAddress = json.ip_address || null;
        this.requestPath = json.request_path || null;
        this.domainName = json.domain_name || null;
        this.endPoint = json.end_point || null;
        this.webSession = json.web_session || null;
        this.viewPort = json.view_port || null;
        
        this.user = !!json.user ? new UserModel(json.user) : null;
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    searchFor(term) {
        if (!term || typeof term !== "string" || term.length < 3) return true;
        
        if (this.message?.toLowerCase().indexOf(term) >= 0) return true;
        if (this.userAgent?.toLowerCase().indexOf(term) >= 0) return true;
        if (this.ipAddress?.toLowerCase().indexOf(term) >= 0) return true;
        if (this.requestPath?.toLowerCase().indexOf(term) >= 0) return true;
        if (this.domainName?.toLowerCase().indexOf(term) >= 0) return true;
        if (this.endPoint?.toLowerCase().indexOf(term) >= 0) return true;
        if (this.webSession?.toLowerCase().indexOf(term) >= 0) return true;
        if (this.viewPort?.toLowerCase().indexOf(term) >= 0) return true;
        if (this.stack?.message?.toLowerCase().indexOf(term) >= 0) return true;
        
        return false;
    }
    
    static fromJsonArray(jsonArray) {
        if (!Array.isArray(jsonArray)) return [];
        return jsonArray.map((json) => new SystemErrorModel(json));
    }
}

export default SystemErrorModel;
