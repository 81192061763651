import React, {useEffect, useState} from 'react';
import AdminMasterScreen from "../AdminMasterScreen";
import {useParams} from "react-router-dom";
import ChallengeService from "../../components/challenges/services/ChallengeService";
import ChallengeSubMenu from "../../components/challenges/ui/ChallengeSubMenu";

const ChallengeMasterScreen = (props) => {
    const { onChallenge, title, children, selection, challengeId:initialChallengeId } = props;
    const { challengeId: queryChallengeId } = useParams();
    const challengeId = initialChallengeId || queryChallengeId;
    const [challenge, setChallenge] = useState(ChallengeService.instance.challengeMap[challengeId] || []); // ChallengeService.instance.challenges[queryChallengeId] || [
    
    const getChallengeAsync = async (force = false) => {
        if (!!challenge?.id && force !== true) return challenge;
        
        const ch = await ChallengeService.instance.getChallengeAsync(challengeId);
        
        if (!!ch?.id) {
            setChallenge(ch);
            return ch;
        }
        
        return challenge;
    };

    const onSession = (sh) => {
        if (!sh?.id) return;
        let _ = getChallengeAsync();
    };
    
    useEffect(() => {
        if (!!challenge?.id && typeof onChallenge === "function")
            onChallenge(challenge);
        
    }, [challenge]);
    
    useEffect(() => {
        let _ = getChallengeAsync();
    }, []);

    const subMenu = !!challenge?.id ? (<ChallengeSubMenu selection={selection} challenge={challenge} />) : null;
    const subMenuElement = !!subMenu ? (<div className={"column"}>{subMenu}</div>) : null;
    
    return (<AdminMasterScreen selection={"challenges"} title={"Challenges"}>
            <div className={"column-content person-details"}>
                {subMenuElement}
                <div className={"content"}>
                    {children}
                </div>
            </div>
        </AdminMasterScreen>
    );

};

export default ChallengeMasterScreen;
