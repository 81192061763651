import React, {useEffect, useState} from 'react';
import UserMasterScreen from "./UserMasterScreen";
import {useParams} from "react-router-dom";
import PointsService from "../../components/metrics/services/PointsService";
import LoadingBox from "../../components/common/ui/LoadingBox";
import DateTime from "../../components/common/ui/DateTime";
import DatePicker from "../../components/common/ui/date-picker/DatePicker";
import UserService from "../../components/users/services/UserService";
import DefReset from "../../components/common/models/DefReset";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faPersonArrowDownToLine} from "@fortawesome/free-solid-svg-icons";

const UserPointsScreen = (props) => {
    const {} = props;
    const { userId } = useParams();
    const [screenState, setScreenState] = useState({ user: UserService.instance.userMap[userId], points: null, id: 0, startDate: null, endDate: null });

    const getAsync = async (force = false, usr = null) => {
        if (force !== true && screenState.points?.length > 0) {
            if (!!usr?.id) {
                const newState = {...screenState};
                newState.user = usr;
                setScreenState(newState);
            }
            
            return screenState.points;
        }
        
        const pts = await PointsService.instance.getUserPointsAsync(userId, screenState);
        const newState = {...screenState};
        
        newState.points = pts;
        if (!!usr?.id) newState.user = usr;
        setScreenState(newState);
        
        return pts;
    };
    
    const syncUserAsync = async (e) => {
        DefReset.stopEvent(e);
        const u = await UserService.instance.syncUserAsync(userId);
        
        if (!!u?.id) {
            const newState = {...screenState};
            newState.user = u;
            setScreenState(newState);
        }
    };
    
    const onUser = (u) => {
        console.log("User: ", u);
        let _ = getAsync(false, u);
    };
    
    const onDateChange = (startDate, endDate, elementId) => {
        
    };

    const onDateClick = (startDate, endDate) => {
        console.log("onDateClick: ");
        const newState = {...screenState};
        newState.startDate = startDate;
        newState.endDate = endDate;
        newState.points = null;
        
        setScreenState(newState);
    };
    
    useEffect(() => {
        let _ = getAsync();
    }, [screenState]);
    
    const elements = Array.isArray(screenState.points) ? screenState.points?.map((point, i) => {
        return (<tr key={"point-" + i}>
            <td>{point.description}</td>
            <td>{point.value}</td>
            <td>{point.remoteId}</td>
            <td><DateTime value={point.created} time={true} /></td>
        </tr>);
    }) : (<LoadingBox>Loading Points...</LoadingBox>);

    return (<UserMasterScreen onUser={onUser}>
        <div className={"pad"}>
            <h2 className={"nav with-button"}>
                <span>{screenState.user?.name }: Points</span>
                <span className={"add-new"}>
                    <a onClick={syncUserAsync} className={"icon-right"}>
                        <span>Source</span>
                        <span><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></span>
                    </a>
                </span>
                <span className={"add-new"}>
                    <a onClick={syncUserAsync}>Sync</a>
                </span>
            </h2>
            <p>Points for user</p>
            
            <div>
                <div>
                    <DatePicker onChange={onDateChange} onClick={onDateClick} />
                </div>
                
                <table className={"table"}>
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Points</th>
                            <th>RemoteId</th>
                            <th>Created</th>
                        </tr>
                    </thead>
                    <tbody>{elements}</tbody>
                </table>
            </div>
        </div>
    </UserMasterScreen>);

};

export default UserPointsScreen;
