import React, {useEffect, useState} from 'react';
import FuelMasterScreen from "./FuelMasterScreen";
import ProductService from "../../components/fuel/services/ProductService";
import LoadingBox from "../../components/common/ui/LoadingBox";
import AddNewButton from "../../components/common/ui/AddNewButton";
import DefReset from "../../components/common/models/DefReset";
import RefreshButton from "../../components/common/ui/RefreshButton";

const ProductsScreen = (props) => {
    const {} = props;
    const [screenState, setScreenState] = useState({
        products: ProductService.instance.products || null,
        refreshOptions: {},
        id: 0,
    });
    
    const setProducts = (products) => {
        setScreenState({ ...screenState, products: products, force: null });
    };
    
    const handleError = (ex) => {
        const message = (typeof ex === "string") ? ex : ex?.response?.data?.message || ex?.message || "An error occurred";
        console.error("Error: ", message);
        
        setScreenState({ ...screenState, force: null, error: message });
    };
    
    const getProductsAsync = async (force = false) => {
        if (Array.isArray(products) && products.length > 0 && force !== true)
            return products;

        console.log("Getting products...");
        
        const m = await ProductService.instance.getProductsAsync().catch((ex) => handleError(ex));
        
        if (Array.isArray(m)) {
            setProducts(m);
            return m;
        }
        
        setScreenState({ ...screenState, force: null });

        return products;
    };
    
    const refresh = (e) => {
        DefReset.stopEvent(e);
        const p = screenState.products;
        const newState = {...screenState, products: p, id: new Date().getTime(), force: true };
        
        setScreenState(newState);
    };

    const onSession = (session) => {
        if (!!session?.id) {
            let _ = getProductsAsync(true);
        }
    }

    useEffect(() => {
        if (screenState.force === true || screenState.products === null) {
            let _ = getProductsAsync(true);
        }
        
        if (typeof screenState.refreshOptions?.setState === "function") {
            screenState.refreshOptions.setState(screenState.force || !Array.isArray(screenState.products) ? 1 : 0);
        }
        
    }, [screenState]);
    
    useEffect(() => {
        let _ = getProductsAsync();
    }, []);

    const products = screenState.products;
    const isLoading = !Array.isArray(products) || screenState.force === true;
    const elements = !isLoading ? products.map((product, index) => {
        return (<div key={"product-" + index}>
            <span>{product.name}</span>
            <span>{product.remoteId + ""}</span>
        </div>);
    }) : (<LoadingBox>Loading Products</LoadingBox>);

    const errorBox = !!screenState.error ? (<div className={"error-box"}>{screenState.error}</div>) : null;
    const st = isLoading ? 1 : 0;
    
    return (<FuelMasterScreen selection={"products"} onSession={onSession}>
        <div className={"pad"}>
            <h2 className={"nav with-button"}>
                <span>Products Settings</span>
                <span><RefreshButton onClick={(e) => refresh(e)} options={screenState.refreshOptions} state={st}>Refresh</RefreshButton></span>
            </h2>
            
            <p className={"form"}>
                Products are pulled from Shopify.
            </p>
            {errorBox}
            <div>
                {elements}
            </div>
        </div>
    </FuelMasterScreen>);

};

export default ProductsScreen;

