import VideoViewModel from "./VideoViewModel";
import ImageModel from "./ImageModel";
import VideoModel from "./VideoModel";
import UserModel from "../../users/models/UserModel";

class MediaModel {
    
    constructor(json) {
        this.id = json.id;
        this.name = json.name || "";
        this.fileName = json.file_name || "";
        this.description = json.description || "";
        this.url = json.url || "";
        
        this.identifier = json.identifier || "";
        this.video = typeof json.image_id !== "undefined" ? new VideoModel(json) : null;
        this.isYouTube = VideoModel.isYouTube(this.url);
        this.isVideo = !!this.video?.id || this.isYouTube;
        this.videoId = (!!this.video?.id ? this.video?.id : null);
        this.imageId = json.image_id || null;
        this.image = !!json.image?.url ? new ImageModel(json.image) : (!this.videoId ? new ImageModel(json) : null);
        
        this.user = !!json.user?.id ? new UserModel(json.user) : this.image?.user || this.video?.user || null;
        this.userId = json.user_id || json.user?.id || json.image?.user_id || json.video?.user_id || null;
        
        this.width = json.width || 0;
        this.height = json.height || 0;
        this.fileSize = json.file_size || 0;
        
        const ext = (!!this.url ? this.url.split('.').pop().toLowerCase() : null) || "png";
        
        this.contentType = this.isYouTube ? "video/youtube" : json.content_type || (this.isVideo ? "video/" + ext : "image/" + ext);

        this.entityType = this.video?.entityType || this.image?.entityType || (this.isVideo ? VideoModel.entityType : ImageModel.entityType);
        this.entityTypeName = this.video?.entityTypeName || this.image?.entityTypeName || (this.isVideo ? "Video" : "Image");
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
        
        //this.json = json;
    }

    static fromVideo = (video) => {}
    
    static fromJsonArray(jsonArray) {
        if (!Array.isArray(jsonArray)) return [];
        
        return jsonArray.map(json => {
            return new MediaModel(json);
        });
    }

}

VideoModel.prototype.toMediaModel = function() {
    
    const imageJson = !!this.image?.url ? {
        id: this.image.id,
        url: this.image.url,
        width: this.image.width,
        height: this.image.height,
        file_name: this.image.fileName,
        file_size: this.image.fileSize,
    } : null;
    
    const videoJson = {
        id: this.id,
        name: this.name,
        file_name: this.fileName,
        description: this.description,
        url: this.url,
        image: imageJson,
        user_id: this.userId,
        identifier: this.identifier,
        image_id: this.imageId,
        width: this.width,
        height: this.height,
        file_size: this.fileSize,
        content_type: this.contentType,
        created: this.created,
        modified: this.modified,
    };

    const m = new MediaModel(videoJson);
    m.user = this.user;

    return m;
};


ImageModel.prototype.toMediaModel = function() {

    const json = {
        id: this.id,
        name: this.name,
        user_id: this.userId,
        file_name: this.fileName,
        description: this.description,
        url: this.url,
        identifier: this.identifier,
        meta_data: this.metaData || {},
        width: this.width,
        height: this.height,
        file_size: this.fileSize,
        json: this.json,
        content_type: this.contentType,
        created: this.created,
        modified: this.modified,
    };

    const m = new MediaModel(json);
    m.user = this.user;
    
    return m;
};

export default MediaModel;
