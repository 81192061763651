import React, {useEffect, useState} from 'react';
import ContentService from "../../../components/settings/services/ContentService";
import SettingsMasterScreen from "../SettingsMasterScreen";
import "../../../components/settings/ui/Settings.css";

const DocumentsScreen = (props) => {
    const {children} = props;
    const [contentList, setContentList] = useState(ContentService.instance.content || null);

    const getContentAsync = async (collectionId, force = false) => {
        if (contentList?.length > 0 && force !== true) return contentList;

        const c = await ContentService.instance.getContentsAsync(collectionId);

        if (c?.length > 0) {
            setContentList(c);
        }
    }

    const onSession = (session) => {
        //
    };

    useEffect(() => {
        let _ = getContentAsync();
    }, []);

    const list = Array.isArray(contentList) ? contentList : contentList || [];

    return (<SettingsMasterScreen selection={"documents"}>
        <div className={"pad constrain"}>
            <h2>Documentation</h2>
            <p className={"p"}>
                Serves as an internal knowledge base and general company repository for app-related documents and content
            </p>
            
            <div className={"document-item"}>
                <h3>Documents and Content</h3>
                <ul>
                    <li><a href={"https://drive.google.com/drive/u/2/folders/1zhTMQGVobuK1eL7i0zT5oF-rny874s7W"} target={"_blank"}>DEF Reset Content: Google Drive</a></li>
                    <li><a href={"https://drive.google.com/drive/u/2/folders/0AKS5xw0mKdiUUk9PVA"} target={"_blank"}>Penumbra Labs: Jocko Fuel Shared Project Folder</a></li>
                    <li><a href={"https://portal.azure.com/?websitesextension_ext=asd.featurePath%3Dtools%2Feventviewer&feature.tokencaching=true&feature.internalgraphapiversion=true#view/Microsoft_Azure_Storage/ContainerMenuBlade/~/overview/storageAccountId/%2Fsubscriptions%2F972421cd-364b-4a75-9f0d-d252dbdedba6%2FresourceGroups%2FDEF-Reset-Resource-Group%2Fproviders%2FMicrosoft.Storage%2FstorageAccounts%2Fdefreset/path/images/etag/%220x8DC64B41C515E80%22/defaultEncryptionScope/%24account-encryption-key/denyEncryptionScopeOverride~/false/defaultId//publicAccessVal/Blob"} target={"_blank"}>Azure Cloud Storage</a></li>
                </ul>
            </div>

            <div className={"document-item"}>
                <h3>Codebase Management</h3>
                <ul>
                    <li><a href={"https://github.com/JockoFuel"} target={"_blank"}>GitHub Repository</a></li>
                    <li><a href={"https://portal.azure.com/?websitesextension_ext=asd.featurePath%3Dtools%2Feventviewer&feature.tokencaching=true&feature.internalgraphapiversion=true#@defreset.onmicrosoft.com/resource/subscriptions/972421cd-364b-4a75-9f0d-d252dbdedba6/resourceGroups/DEF-Reset-Resource-Group/providers/Microsoft.Web/sites/def-reset/environmentVariablesAppSettings"} target={"_blank"}>Azure Web App Deployment Settings</a></li>
                </ul>
            </div>

            <div className={"document-item"}>
                <h3>Other Tools and Services</h3>
                <ul>
                    <li><a href={"https://admin.shopify.com/store/jocko-fuel"} target={"_blank"}>Shopify: Authentication and Products</a></li>
                    <li><a href={"https://loyaltyapi.yotpo.com/reference/introduction-1"} target={"_blank"}>Yotpo: Points and Loyalty Service</a></li>
                    <li><a href={"https://developer.apple.com/account"} target={"_blank"}>AppStore Connect: Apple Developer Portal</a></li>
                    <li><a href={"https://play.google.com/console/u/2/developers/6167664709109863428/app-list"} target={"_blank"}>Google Play Store: Android Developer Portal</a></li>
                    <li><a href={"https://dashboard.pushy.me/login"} target={"_blank"}>Pushy.me: Push Notification Middleware</a></li>
                </ul>
            </div>
            
        </div>
    </SettingsMasterScreen>);
};

export default DocumentsScreen;
