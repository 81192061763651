import React, {useEffect, useState, useRef} from 'react';
import Chart from 'chart.js/auto';
import './BarChart.css';
import DatePicker from "../../../common/ui/date-picker/DatePicker";
import ChartOptions from "./ChartOptions";
import DefReset from "../../../common/models/DefReset";
import HttpService from "../../../common/services/HttpService";
import GraphItemModel from "../../models/GraphItemModel";

const BarChart = (props) => {
    const { width, height, items:chartItems, className, id, name, maxCount, options:dateOptions, url, title } = props;
    const canvas = useRef();
    const [chartState, setChartState] = useState({ items: chartItems || [], startDate: dateOptions?.startDate || new Date().addDays(-14), endDate: dateOptions?.endDate || new Date() });
    const [chart, setChart] = useState(null);
    const options = useState(dateOptions || {})[0];
    const fetchUrl = useState(url || options?.url || null)[0];
    
    const cn = typeof className === "string" ? className  : "";
    const elementId = useState(typeof id === "string" ? id  : "bar-chart-" + (Math.random() * 99999999).toString(16))[0];
    const canvasId = useState("bar-canvas-" + (typeof id === "string" ? id  : (Math.random() * 99999999).toString(16)))[0];

    const setItems = (items) => {
        const newState = {...chartState};
        newState.items = items;
        setChartState(newState);
    };

    const items = chartState.items;
    
    const createChartOptions = (listItems, maxValue = -1) => {
        const colorCount = BarChart.defaultColors.length;
        
        const tmz = new Date().getTimezoneOffset();
        const endDate = !!chartState.endDate ? new Date(chartState.endDate).addMinutes(tmz) : null;
        
        listItems = listItems.filter((item, idx) => {
            if (!endDate || !item.date || item.date.getTime() <= endDate.getTime()) return true;
            
            console.log(item);
            console.warn("  > ", tmz);
            console.warn("  > ", endDate);
            
            return false;
        });
        
        const labels = listItems.map((item) => item.name || item.label);
        const colors = listItems.map((item, i) => item.color || BarChart.defaultColors[i % colorCount]);
        const borderColors = listItems.map((item) => item.borderColor || "transparent");
        const values = listItems.map((item) => item.value);
        const chartName = typeof name === "string" && !!name ? name : " ";

        const titleJson = {
            display: false, // !!chartName,
        };

        const scaleJson = maxValue > 0 ? {
            y: {
                min: 0,
                max: (Math.round(maxValue / 10)) * 10,
            },
        } : undefined;

        return {
            type: 'bar',
            id: elementId,
            data: {
                labels: labels,
                datasets: [
                    {
                        label: chartName,
                        data: values,
                        backgroundColor: colors,
                        borderColor: borderColors,
                        borderWidth: 1
                    },
                ],
            },
            options: {
                layout: {
                    autoPadding: true
                },
                scales: scaleJson,
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                        display: false,
                    },
                    title: titleJson,
                },
            },
        };
    };

    const updateChartOptions = (listItems, ctx) => {
        const maxValue = listItems.reduce((a, b) => Math.max(a.value, b.value), 0);
        const chartOptions = createChartOptions(listItems, maxValue + (maxValue * 0.1));
        
        if (typeof chart?.setOptions === "function") chart.setOptions(chartOptions);
        else setChart(new Chart(ctx, chartOptions))

        console.log("Updating Chart Options:");
        console.log(listItems);
        
        return chartOptions;
    };
    
    const createChart = () => {
        //if (!!chart) return;
        if (typeof document === "undefined" || !canvas?.current) return;
        const ctx = canvas.current.getContext("2d");

        if (typeof chart?.destroy === "function") chart.destroy();

        const maxItems = typeof maxCount === "number" && maxCount > 0 ? maxCount : 300;
        const listItems = (chartState.items || []).filter((x, i) => i < maxItems);
        BarChart.instanceCount++;

        updateChartOptions(listItems, ctx);
    };
    
    const parseItems = (data) => {
        if (!Array.isArray(data)) return [];
        
        const graphItems = GraphItemModel.fromJsonArray(data);
        
        return graphItems.map((item) => {
            return {
                name: item.label || item.date?.toString(),
                value: item.value,
                date: item.date,
                isDate: (item.date instanceof Date),
            };
        });
    };
    
    const getItemsAsync = async (force = false) => {
        if (typeof options?.fetch === "function") {
            const opts = {...options};
            opts.startDate = chartState.startDate || opts.startDate || null;
            opts.endDate = chartState.endDate || opts.endDate || null;
            opts.force = force;
            
            const rsp = options.fetch(opts);
            const result = (typeof rsp?.then === "function") ? await rsp : rsp;
            
            //if (Array.isArray(result)) setItems(result);
            if (Array.isArray(result))  {
                const items = parseItems(result);
                
                createChartOptions(result);
                setItems(items);
                
                return items;
            }
        }
        
        if (!fetchUrl || typeof fetchUrl !== "string") return items;
        
        const q = fetchUrl.indexOf("?") > 0 ? "&" : "?";
        let qs = "";
        
        if (fetchUrl.indexOf("start-date=") < 0)
            qs += "start-date=" + chartState.startDate.toISOString().split("T")[0];
        
        if (fetchUrl.indexOf("end-date=") < 0)
            qs += (qs.length > 0 ? "&" : "") + "end-date=" + chartState.endDate.toISOString().split("T")[0];
        
        const url = fetchUrl + q + qs;
        
        console.log("Refreshing...");
        console.log(" > ", url);
        
        await HttpService.instance.getAsync(url).then((response) => {
            const data = response.data;
            
            if (!Array.isArray(data)) {
                console.warn("BarChart Fetch is not an Array: ", data);
                return items;
            }
            
            const chartItems = GraphItemModel.fromJsonArray(data);
            
            setItems(chartItems);
        });
    };
    
    const setControls = () => {
        if (typeof options === "object" && !!options) {
            options.refresh = getItemsAsync;
            options.setItems = (items) => setItems(items);
        }
    };

    const onDateChange = (startDate, endDate, fieldElementId) => {
        // Typed...
        const newState = {...chartState};
        newState.startDate = startDate;
        newState.endDate = endDate;
        setChartState(newState);
    };

    const onRefreshClicked = async (startDate, endDate) => {
        console.log("Refresh Clicked: ");
        console.log("  > StartDate: ", startDate);
        console.log("  > EndDate: ", endDate);
        
        const rsp = await getItemsAsync(true);
        console.log("Refreshed: ", rsp?.length);
        
        return rsp;
    };
    
    useEffect(() => {
        createChart();
    }, [items]);
    
    useEffect(() => {
        setControls();
        let _ = getItemsAsync();
    }, []);

    const style = {};
    const w = (typeof width === "number" && width > 0) ? width : 0;
    const h = (typeof height === "number" && height > 0) ? height : 0;

    if (w > 0) style.width = w.toString() + "px";
    if (h > 0) style.height = h.toString() + "px";

    const titleElement = typeof title === "string" && !!title ? (<h3 className={"chart-header"}>{title}</h3>) : null;
    
    return (<div id={elementId} className={("chart bar-chart " + cn).trim()}>
        {titleElement}
        <div className={"chart-body"}>
            <DatePicker options={options} onClick={onRefreshClicked} onChange={onDateChange} />
            <div style={style}>
                <canvas id={canvasId} ref={canvas} />
            </div>
        </div>
    </div>);
};

BarChart.instanceCount = 0;

BarChart.defaultColors = [
    "#0f5779",
    "#FF7700",
    "#800000",
    "#008000",
    "#555555",
];

export default BarChart;