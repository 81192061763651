import React, {useEffect, useRef} from 'react';
import DefReset from "../../common/models/DefReset";

const WorkoutExerciseForm = (props) => {
    const { options, defaultValue } = props;

    const notesRef = useRef();
    const setLabelRef = useRef();
    const repLabelRef = useRef();
    const volumeRef = useRef();
    const repUnitRef = useRef();
    const volumeUnitRef = useRef();
    
    const createJson = () => {
        return {
            notes: notesRef.current?.value || null,
            set_count_label: setLabelRef.current?.value || null,
            rep_count_label: repLabelRef.current?.value || null,
            volume: volumeRef.current?.value || null,
            rep_unit_type: parseInt(repUnitRef.current?.value + "") || 0,
            volume_unit_type: parseInt(volumeUnitRef.current?.value + "") || 0
        };
    };
    
    const setControllers = () => {
        if (!!options && typeof options === "object") {
            options.getData = () => createJson();
        }
    };

    useEffect(() => {
        setControllers();
    }, []);
    
    return (<div className={"form workout-exercise-form"}>
        <div className={"form-group"}>
            <label>Description Override / Notes</label>
            <textarea onDoubleClick={(e) => {
                DefReset.stopEvent(e);
                if (!!e?.target?.value) return;
                e.target.value = defaultValue?.exercise?.description;
                if (!!notesRef.current) notesRef.current.value = defaultValue?.exercise?.description;
                
            }} id={"notes"} className={"small"} ref={notesRef} defaultValue={defaultValue?.notes} placeholder={defaultValue?.exercise?.description}></textarea>
        </div>
        
        <div className={"form-group double"}>
            <div>
                <label>Set Count Label</label>
                <input type={"text"} ref={setLabelRef} id={"set-count-label"} defaultValue={defaultValue?.setCountLabel} />
            </div>
            <div>
                <label>Rep Count Label</label>
                <input type={"text"} ref={repLabelRef} id={"rep-count-label"} defaultValue={defaultValue?.repCountLabel} />
            </div>
        </div>
    </div>);
};

export default WorkoutExerciseForm;
