import React from 'react';
import {Link} from "react-router-dom";

const ModerationSubMenu = (props) => {
    const { selection } = props;
    const classes = {};
    classes[selection || "comments"] = "selected";

    return (<div className={"sub-menu"} id={"moderator-menu"}>
        <ul className={"sub-menu"}>
            <li className={classes["comments"] || ""}><Link to={"/moderation/comments"}>Comments</Link></li>
            <li className={classes["media"] || ""}><Link to={"/moderation/media"}>Photos and Videos</Link></li>
            <li className={classes["habits"] || ""}><Link to={"/moderation/habits"}>Habits</Link></li>
        </ul>
    </div>);
};

export default ModerationSubMenu;
