/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import LoginScreen from "./common/LoginScreen";
import AuthenticationService from "../components/authentication/services/AuthenticationService";
import DefReset from "../components/common/models/DefReset";
import DefAppState, {useAppContext} from "../DefAppState";
import HabitService from "../components/habits/services/HabitService";
import MainMenuLink from "../components/common/ui/MainMenuLink";
import { faBook, faCar, faCog, faDumbbell, faGavel, faHeart, faHome, faRunning, faShield, faStopwatch, faStopwatch20, faTrophy, faUsers, faWeight, faWeightScale } from "@fortawesome/free-solid-svg-icons";

const AdminMasterScreen = (props) => {
    const {children, className, title, selection, options, onSession } = props;
    const classNames = {};
    const [masterScreenState, setMasterScreenState] = useState({ title: title, init: false });
    const { sessionId, user } = useAppContext();
    
    if (!!selection) classNames[selection] = "selected";

    const setTitle = (title) => {
        setMasterScreenState({...masterScreenState, title: title });
    }
    
    const isLoggedIn = () => {
        return AuthenticationService.instance.isLoggedIn();
    };
    
    const signOut = (e) => {
        DefReset.stopEvent(e);
        AuthenticationService.instance.signOutAsync().then(() => {
            console.warn("Sign out Master");
            setMasterScreenState({ signedOut: true });
        });
    }
    
    const addListeners = () => {
        const ia = isLoggedIn();
        AuthenticationService.instance.logFromStorage();
        
        const ss = localStorage.getItem(AuthenticationService.sessionKey);
        
        if (typeof options === "object" && !!options) {
            options.setTitle = setTitle;
        }
        
        if (DefAppState.setPageTitle === null) {
            DefAppState.setPageTitle = (title) => {
                const newState = {...masterScreenState, title: title };
                console.warn("Setting Title: " + title);
                
                setMasterScreenState(newState);
            };
        }
        
        const screenId = "admin-master-screen";
        AuthenticationService.instance.addEventListener("auth", (session) => {
            const newState = {...masterScreenState, sessionId: session?.id, auth: false };
            
            if (!!session?.id) {
                newState.auth = true;
                let _ = HabitService.instance.getHabitsAsync().then((habits) => {
                    //
                });
            }
            
            setMasterScreenState(newState);
        }, screenId);
        
        if (!ia && !!ss) setMasterScreenState({...masterScreenState, auth: true });
        
        return async () => {
            AuthenticationService.instance.removeEventListener("auth", screenId);
        };
    };
    
    const createTitle = () => {
        if (typeof masterScreenState?.title === "string") return masterScreenState.title;
        if (typeof title === "function") return title() || "NO TITLE";
        
        return title || "TITLE";
    };

    useEffect(() => {
        if (masterScreenState.auth === true) {
            if (typeof onSession === "function") {
                console.warn("onSession");
                onSession(masterScreenState);
            }

            setMasterScreenState({...masterScreenState, auth: false});
        }
    }, [masterScreenState]);
    
    useEffect(() => {
        addListeners();
    }, []);

    const titleElement = isLoggedIn() ? (<h1 className={"main-title"}>{createTitle()}</h1>) : null;
    
    if (!isLoggedIn()) return (<LoginScreen onAuthenticated={() => console.log("Done")} />);
    
    return (<div id={"body"}>
            <div id={"main-column"}>
                <div>
                    <div id={"logo"}>
                        <h1><img src={"/jf_logo_256x256.png"} className={"logo"} /></h1>
                    </div>
                    <div id={"main-menu"}>
                        <ul className={"main"}>
                            <li className={classNames["home"] || ""}><MainMenuLink icon={faHome} to={"/"}>Home</MainMenuLink></li>
                            <li className={classNames["moderation"] || ""}><MainMenuLink icon={faGavel} to={"/moderation/comments"}>Moderate Content</MainMenuLink></li>
                            <li className={classNames["people"] || ""}><MainMenuLink icon={faUsers} to={"/people"}>Users</MainMenuLink></li>
                            <li className={classNames["learn"] || ""}><MainMenuLink icon={faBook} to={"/learn"}>Learn</MainMenuLink></li>
                            <li className={classNames["challenges"] || ""}><MainMenuLink icon={faShield} to={"/challenges"}>Challenges</MainMenuLink></li>
                            <li className={classNames["workouts"] || ""}><MainMenuLink icon={faDumbbell} to={"/workouts"}>Workouts</MainMenuLink></li>
                            <li className={classNames["fuel"] || ""}><MainMenuLink icon={faCar} to={"/fuel"}>Fuel</MainMenuLink></li>
                            <li className={classNames["habits"] || ""}><MainMenuLink icon={faStopwatch} to={"/habits"}>Habits</MainMenuLink></li>
                            <li className={classNames["settings"] || ""}><MainMenuLink icon={faCog} to={"/settings"}>App Management</MainMenuLink></li>
                        </ul>
                        
                        <ul className={"small"}>
                            <li><a onClick={(e) => signOut(e)}>Sign Out</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div id={"main-content"} className={className || "content"}>
                {titleElement}
                <div id={"content"}>{children}</div>
            </div>
        </div>);

};

export default AdminMasterScreen;
