import React, {useEffect, useState} from 'react';
import DateTime from "../DateTime";
import EmptyBox from "../EmptyBox";

const DefTable = (props) => {
    const { id, options: initialOptions, defaultValue } = props;
    const elementId = useState(typeof id === "string" && !!id ? id : "def-table-" + (Math.random() * 99999999).toString(16))[0];
    
    const [tableState, setTableState] = useState({
        options: initialOptions || {
            columns: [{ name: "Item", field: "name" }, { name: "Date", field: "created" }],
            fetch: async (options) => [],
        },
        needsInit: true,
        items: Array.isArray(defaultValue) ? defaultValue : null,
        startDate: initialOptions?.startDate || null,
        endDate: initialOptions?.endDate || null,
    });

    const getColumnDefinitions = () => {
        const options = tableState.options;
        const columns = Array.isArray(options?.columns) ? options.columns : [];

        if (columns.length === 0) {
            let nameField = "name";
            let dateField = "created";

            if (tableState.items?.length > 0) {
                if (typeof tableState.items[0].name === "undefined") nameField = "title";
                if (typeof tableState.items[0].created === "undefined") dateField = "date";
            }

            columns.push({ name: "Item", field: nameField });
            columns.push({ name: "Date", field: dateField });
        }
        
        return columns;
    };
    
    const getItemsAsync = async (force = false) => {
        const options = tableState.options;
        if (typeof options?.fetch !== "function") return tableState.items;
        if (force !== true && Array.isArray(tableState.items)) return tableState.items;
        
        const opts = {...options};
        opts.startDate = tableState.startDate || opts.startDate || null;
        opts.endDate = tableState.endDate || opts.endDate || null;
        opts.force = force;
        
        const rsp = options.fetch(opts);
        const responseItems = typeof rsp?.then === "function" ? await rsp : rsp;
        
        if (Array.isArray(responseItems)) {
            const newState = {...tableState};
            newState.items = responseItems;
            setTableState(newState);
            return responseItems;
        }
    };
    
    const createTableHeaderRow = () => {
        const columns = getColumnDefinitions();
        const elements = columns.map((c, i) => (<th key={"th-" + i}>{c.name || "Item " + i}</th>));
        
        return (<thead>
            <tr>{elements}</tr>
        </thead>);
    };

    const createTableRow = (item, rowIndex, getter) => {
        if (!item) return (<tr key={"pad-x"}><td colSpan={2}>No Dice {rowIndex + ""}</td> </tr>);
        
        if (typeof getter !== "function") 
            getter = (lineItem, c, i) => lineItem[c.field] || "";
        
        const columns = getColumnDefinitions();
        
        const elements = columns.map((c, i) => {
            let value = getter(item, c, i);
            if (value instanceof Date) value = (<DateTime value={value} time={true} />);
            return (<td key={"td-" + i}>{value}</td>);
        });
        
        return (<tr key={"row-" + rowIndex}>{elements}</tr>);
    };
    
    const createTable = () => {
        const options = tableState.options;
        const columns = getColumnDefinitions();
        const getValue = typeof options.getValue === "function" ? options.getValue : (item, column, columnIndex) => item[column.field];
        
        const elements = Array.isArray(tableState.items) ? tableState.items.map((item, i) => {
            
            return createTableRow(item, i, getValue);
        }) : [];

        if (elements.length === 0) return (<EmptyBox>No Records Found</EmptyBox>);
        
        return <table className={"table def-table"} id={"table-" + elementId}>
            {createTableHeaderRow()}
            <tbody>
            {elements}
            </tbody>
        </table>;
    };
    
    useEffect(() => {
        if (tableState.needsInit === true) {
            let _ = getItemsAsync();
        }
        
    }, [tableState]);
    
    
    return (<div id={elementId} className={"def-table-container"}>
        {createTable()}
    </div>);
};

export default DefTable;
