import HttpService from "../../common/services/HttpService";
import FeedbackModel from "../models/FeedbackModel";

class FeedbackService { 
    static instance = new FeedbackService();

    constructor() {
        this.feedbacksMap = {};
        this.feedbacks = [];
        this.feedbackMap = {};
    }

    async getFeedbackItemAsync(feedbackId) {
        const path = '/api/feedback/' + feedbackId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.feedbackMap[feedbackId] = new FeedbackModel(response.data);
            return me.feedbackMap[feedbackId];
        });
    }
    
    async deleteFeedbackAsync(feedbackId) {
        const path = "/api/feedback/" + feedbackId;
        return await HttpService.instance.deleteAsync(path);
    }

    async getFeedbackItemsAsync(options = { startDate: null, endDate: null }) {
        const sd = !!options?.startDate ? options.startDate : null;
        const ed = !!options?.endDate ? options.endDate : null;
        const status = Array.isArray(options?.status) ?
            options.status : 
            typeof options?.status === "number" ? [options.status] : [0, 1];

        const me = this;
        const path = "/api/feedback?status=" + status.join(",");
        
        return await HttpService.instance.getWithDateRangeAsync(path, sd, ed).then((response) => {
            const  items = FeedbackModel.fromJsonArray(response.data);
            if (Array.isArray(items))
                me.feedbacks = items;

            return items;
        });
    }
    
    async resendFeedbackAsync(feedbackId) {
        const path = "/api/feedback/" + feedbackId + "/re-send";
        return await HttpService.instance.postAsync(path);
    }
    
    async saveFeedbackReplyAsync(json, replyToFeedbackId) {
        const path = "/api/feedback/" + replyToFeedbackId + "/reply";
        
        return await HttpService.instance.postAsync(path, json).then((rsp) => {
            return FeedbackModel.create(rsp.data);
        });
    }
}

export default FeedbackService;
