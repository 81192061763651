import HttpService from "../../common/services/HttpService";
import MealModel from "../models/MealModel";
import MealFoodModel from "../models/MealFoodModel";

class MealService {
    static instance = new MealService();

    constructor() {
        this.mealsMap = {};
        this.meals = null;
        this.mealMap = {};
    }

    async getMealAsync(mealId) {
        const path = '/api/meal/' + mealId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.mealMap[mealId] = new MealModel(response.data);
            return me.mealMap[mealId];
        });
    }
    
    async updateMealSortingAsync(sortItems) {
        const path = "/api/meal/sort";
        return await HttpService.instance.postAsync(path, sortItems).then((rsp) => {
            return rsp.data;
        });
    }

    async getMealsAsync() {
        const path = "/api/fuel?status=0";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.meals = response.data.map((meal) => {
                return new MealModel(meal);
            });

            return me.meals;
        });
    }
    
    async saveMealAsync(json, mealId = null) {
        const path = "/api/meal" + (!!mealId ? "/" + mealId : "");
        const me = this;
        
        return await HttpService.instance.postAsync(path, json).then((response) => {
            return new MealModel(response.data);
        });
    }
    
    async deleteMealAsync(mealId) {
        const path = "/api/meal/" + mealId;
        const me = this;
        
        return await HttpService.instance.deleteAsync(path).then((response) => {
            return response.data;
        });
    }
    
    async saveMealFoodAsync(json, mealId) {
        const path = "/api/meal/" + mealId + "/food";
        const me = this;
        
        return await HttpService.instance.postAsync(path, json).then((response) => {
            return new MealFoodModel(response.data);
        });
    }
}

export default MealService;
