import React from 'react';
import ReactDOM from 'react-dom/client';
import DefAppState, {useAppContext} from "./DefAppState";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PeopleScreen from "./routes/people/UsersScreen";
import SettingsScreen from "./routes/settings/SettingsScreen";
import ContentScreen from "./routes/settings/content/ContentScreen";
import WorkoutsScreen from "./routes/workouts/WorkoutsScreen";
import FuelScreen from "./routes/fuel/FuelScreen";
import ChallengesScreen from "./routes/challenges/ChallengesScreen";
import HabitsScreen from "./routes/habits/HabitsScreen";
import UserChallengesScreen from "./routes/people/UserChallengesScreen";
import UserDetailsScreen from "./routes/people/UserDetailsScreen";
import UserHabitsScreen from "./routes/people/UserHabitsScreen";
import UserAbuseScreen from "./routes/people/UserAbuseScreen";
import UserEditScreen from "./routes/people/UserEditScreen";
import WorkoutDetailsScreen from "./routes/workouts/WorkoutDetailsScreen";
import WorkoutMediaScreen from "./routes/workouts/WorkoutMediaScreen";
import WorkoutExercisesScreen from "./routes/workouts/WorkoutExercisesScreen";
import ExercisesScreen from "./routes/workouts/ExercisesScreen";
import ExerciseDetailsScreen from "./routes/workouts/ExerciseDetailsScreen";
import ArticlesScreen from "./routes/learn/ArticlesScreen";
import ArticleDetailsScreen from "./routes/learn/ArticleDetailsScreen";
import FoodsScreen from "./routes/fuel/FoodsScreen";
import MealsScreen from "./routes/fuel/MealsScreen";
import MealDetailsScreen from "./routes/fuel/MealDetailsScreen";
import FoodDetailsScreen from "./routes/fuel/FoodDetailsScreen";
import ProductsScreen from "./routes/fuel/ProductsScreen";
import FuelSettingsScreen from "./routes/fuel/FuelSettingsScreen";
import ChallengeDetailsScreen from "./routes/challenges/ChallengeDetailsScreen";
import ChallengeEntriesScreen from "./routes/challenges/ChallengeEntriesScreen";
import ChallengeEditScreen from "./routes/challenges/ChallengeEditScreen";
import HabitDetailsScreen from "./routes/habits/HabitDetailsScreen";
import UserHabitDetailsScreen from "./routes/people/UserHabitDetailsScreen";
import WorkoutSettingsScreen from "./routes/workouts/WorkoutSettingsScreen";
import MessagingScreen from "./routes/settings/messaging/MessagingScreen";
import FeedbackScreen from "./routes/settings/messaging/FeedbackScreen";
import FeedbackDetailsScreen from "./routes/settings/messaging/FeedbackDetailsScreen";
import DocumentsScreen from "./routes/settings/content/DocumentsScreen";
import UserPointsScreen from "./routes/people/UserPointsScreen";
import ChallengeEntryDetailsScreen from "./routes/challenges/ChallengeEntryDetailsScreen";
import SystemErrorsScreen from "./routes/settings/activities/SystemErrorsScreen";
import CommentsScreen from "./routes/moderation/CommentsScreen";
import UserMediaScreen from "./routes/people/media/UserMediaScreen";
import UserMediaDetailsScreen from "./routes/people/media/UserMediaDetailsScreen";
import MediaScreen from "./routes/moderation/ModeratedMediaScreen";
import UserBaseHabitsScreen from "./routes/moderation/UserBaseHabitsScreen";
import MessageTemplateScreen from "./routes/settings/messaging/MessageTemplateScreen";
import MealRecipeDetailsScreen from "./routes/fuel/MealRecipeDetailsScreen";
import MealRecipeDirectionsScreen from "./routes/fuel/MealRecipeDirectionsScreen";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <DefAppState>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/people" element={<PeopleScreen />} />
                <Route path="/people/:userId" element={<UserDetailsScreen />} />
                <Route path="/people/:userId/habits" element={<UserHabitsScreen />} />
                <Route path="/people/:userId/challenges" element={<UserChallengesScreen />} />
                <Route path="/people/:userId/edit" element={<UserEditScreen />} />
                <Route path="/people/:userId/abuse" element={<UserAbuseScreen />} />
                <Route path="/people/:userId/user-habit/:userHabitId" element={<UserHabitDetailsScreen />} />
                <Route path="/people/:userId/points" element={<UserPointsScreen />} />
                <Route path="/people/:userId/media" element={<UserMediaScreen />} />
                <Route path="/people/:userId/images" element={<UserMediaScreen entityType={6} />} />
                <Route path="/people/:userId/videos" element={<UserMediaScreen entityType={35} />} />
                <Route path="/people/:userId/media/:id" element={<UserMediaDetailsScreen />} />
                <Route path="/people/:userId/images/:id" element={<UserMediaDetailsScreen entityType={6} />} />
                <Route path="/people/:userId/videos/:id" element={<UserMediaDetailsScreen entityType={35} />} />

                <Route path="/learn" element={<ArticlesScreen />} />
                <Route path="/learn/:articleId" element={<ArticleDetailsScreen />} />

                <Route path="/challenges" element={<ChallengesScreen />} />
                <Route path="/challenges/:challengeId" element={<ChallengeDetailsScreen />} />
                <Route path="/challenges/:challengeId/entries" element={<ChallengeEntriesScreen />} />
                <Route path="/challenges/:challengeId/entries/:challengeEntryId" element={<ChallengeEntryDetailsScreen />} />
                <Route path="/challenges/:challengeId/edit" element={<ChallengeEditScreen />} />

                <Route path="/fuel" element={<FuelScreen />} />
                <Route path="/fuel/meals" element={<MealsScreen />} />
                <Route path="/fuel/meals/:mealId" element={<MealDetailsScreen />} />
                <Route path="/fuel/meals/:mealId/recipes" element={<MealRecipeDetailsScreen />} />
                <Route path="/fuel/meals/:mealId/directions" element={<MealRecipeDirectionsScreen />} />
                <Route path="/fuel/foods" element={<FoodsScreen />} />
                <Route path="/fuel/foods/:foodId" element={<FoodDetailsScreen />} />
                <Route path="/fuel/products" element={<ProductsScreen />} />
                <Route path="/fuel/settings" element={<FuelSettingsScreen />} />
                
                <Route path="/workouts" element={<WorkoutsScreen />} />
                <Route path="/workouts/:workoutId" element={<WorkoutDetailsScreen />} />
                <Route path="/workouts/:workoutId/media" element={<WorkoutMediaScreen />} />
                <Route path="/workouts/:workoutId/exercises" element={<WorkoutExercisesScreen />} />
                <Route path="/workouts/:parentWorkoutId/workouts/:workoutId" element={<WorkoutDetailsScreen />} />
                <Route path="/workouts/:parentWorkoutId/workouts/:workoutId/media" element={<WorkoutMediaScreen />} />
                <Route path="/workouts/:parentWorkoutId/workouts/:workoutId/exercises" element={<WorkoutExercisesScreen />} />
                <Route path="/workouts/exercises" element={<ExercisesScreen />} />
                <Route path="/workouts/exercises/:exerciseId" element={<ExerciseDetailsScreen />} />
                <Route path="/workouts/settings" element={<WorkoutSettingsScreen />} />
                
                <Route path="/habits" element={<HabitsScreen />} />
                <Route path="/habits/:habitId" element={<HabitDetailsScreen />} />
                
                <Route path="/settings" element={<SettingsScreen />} />
                <Route path="/settings/content" element={<ContentScreen />} />
                <Route path={"/settings/activities"} element={<SystemErrorsScreen />} />
                <Route path={"/settings/messaging"} element={<MessagingScreen />} />
                <Route path={"/settings/messaging/:articleId"} element={<MessagingScreen />} />
                <Route path={"/settings/message-templates"} element={<MessageTemplateScreen />} />
                <Route path={"/settings/message-templates/:templateId"} element={<MessageTemplateScreen />} />
                <Route path={"/settings/feedback"} element={<FeedbackScreen />} />
                <Route path={"/settings/feedback/:feedbackId"} element={<FeedbackDetailsScreen />} />
                <Route path={"/settings/documents"} element={<DocumentsScreen />} />

                <Route path={"/moderation/comments"} element={<CommentsScreen />} />
                <Route path={"/moderation/comments/:entityId"} element={<CommentsScreen />} />
                <Route path={"/moderation/media"} element={<MediaScreen />} />
                <Route path={"/moderation/habits"} element={<UserBaseHabitsScreen />} />
            </Routes>
        </BrowserRouter>
    </DefAppState>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
