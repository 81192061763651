import ImageThumbModel from "./ImageThumbModel";
import ImageModel from "./ImageModel";
import UserModel from "../../users/models/UserModel";

class VideoModel {
    static entityType = 35;
    
    static isYouTube(url) {
        return (!!url && (url.indexOf("youtube.com/") >= 0 || url.indexOf("youtu.be/") >= 0))
    }
    
    static createYouTubeUrl(videoUrl) {
        if (!videoUrl) return null;

        let idx = videoUrl.indexOf("watch?v=");
        if (idx < 0) idx = videoUrl.indexOf("youtu.be/");

        if (idx < 0) {
            idx = videoUrl.indexOf("embed/");
            
            if (idx > 0) return videoUrl;
            else return null;
        }

        if (idx > 0) {
            let videoId = videoUrl.substring(idx + 8);
            idx = videoId.indexOf("&");
            if (idx > 0) videoId = videoId.substring(0, idx);
            return "https://www.youtube.com/embed/" + videoId;
        }

        return null;
    }
    
    constructor(json) {
        if (!json) json = {};

        this.id = json.id || null;
        this.name = json.name || "";
        this.description = json.description || "";
        this.imageId = json.image_id || null;
        this.userId = json.user_id || "";
        this.user = new UserModel(json.user);
        if (!this.user?.id) this.user = null;
        
        this.image = !!json.image?.url ? new ImageModel(json.image) : null;
        this.fileName = json.file_name || "";
        this.url = json.url || "";
        this.identifier = json.identifier || null;
        this.width = json.width || 0;
        this.height = json.height || 0;
        this.fileSize = json.file_size || 0;
        this.metaData = json.meta_data || {};
        this.entityType = json.entity_type || VideoModel.entityType;
        this.entityTypeName = json.entity_type_name || "Video";

        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
        
        //this.json = json;
    }    
}

export default VideoModel;
