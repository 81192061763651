import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faCreditCardAlt} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import "./Workouts.css";

const WorkoutsSubMenu = (props) => {
    const { selection } = props;
    const classes = {};
    
    classes[selection || "main"] = "selected";

    const basePath = "/workouts/";

    return (<div className={"workouts-menu"}>
        <ul className={"sub-menu"}>
            <li className={classes["main"] || ""}><Link to={basePath}>Workouts</Link></li>
            <li className={classes["exercises"] || ""}><Link to={basePath + "exercises"}>Exercises</Link></li>
            <li className={classes["settings"] || ""}><Link to={basePath + "settings"}>Units and Settings</Link></li>
        </ul>
    </div>);
};

export default WorkoutsSubMenu;