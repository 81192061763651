import React from 'react';
import {Link} from "react-router-dom";
import {faBowlRice, faCarrot, faGears, faJar} from "@fortawesome/free-solid-svg-icons";

const FuelSubMenu = (props) => {
    const { selection } = props;
    
    const classes = {};
    classes[selection || "overview"] = "selected";

    const basePath = "/fuel/";

    const elements = FuelSubMenu.menuItems.map((m, i) => {
        const key = m.path || "";
        return (<li key={"menu-item-" + i} className={classes[key || "overview"] || ""}><Link to={basePath + key}>
            {m.caption}
        </Link></li>);
    });

    return (<div className={"person-menu"}>
        <ul className={"sub-menu"}>
            {elements}
        </ul>
    </div>);
};

FuelSubMenu.menuItems = [
    { path: "", caption: "Overview" },
    { path: "meals", caption: "Meals", icon: faBowlRice, description: "Meals Available within the App" },
    { path: "foods", caption: "Foods", icon: faCarrot, description: "Individual food items that make up a meal" },
    { path: "products", caption: "Products", icon: faJar, description: "Shopify Collection of Products" },
    { path: "settings", caption: "Fuel Settings", icon: faGears, description: "Meals Available within the App" },
];

export default FuelSubMenu;
