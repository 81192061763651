import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTrendUp, faCreditCardAlt} from "@fortawesome/free-solid-svg-icons";

import AdminMasterScreen from "../AdminMasterScreen";
import WorkoutService from "../../components/workouts/services/WorkoutService";
import {useParams} from "react-router-dom";
import WorkoutSubMenu from "../../components/workouts/ui/WorkoutSubMenu";

const WorkoutMasterScreen = (props) => {
    const { children, title, options, selection, onWorkout, workout:initialWorkout, workoutId:initialWorkoutId } = props;
    const { parentWorkoutId, workoutId:queryWorkoutId } = useParams();
    const workoutId = initialWorkoutId || queryWorkoutId;
    const [workouts, setWorkouts] = useState({ workout: initialWorkout || WorkoutService.instance.workoutMap[workoutId] || null, parentWorkout: WorkoutService.instance.workoutMap[parentWorkoutId] || null});

    const setWorkout = (w) => {
        setWorkouts({ workout: w, parentWorkout: workouts?.parentWorkout }); 
    };

    const setParentWorkout = (w) => {
        setWorkouts({ workout: workouts?.workout, parentWorkout: w });
    };

    const getWorkoutAsync = async (force = false) => {
        console.log("Getting Workout: " + workoutId);
        
        const ids = [workoutId];
        
        if (!!parentWorkoutId) ids.push(parentWorkoutId);
        
        const w = await Promise.all(ids.map(async (id) => WorkoutService.instance.getWorkoutAsync(id)));
        
        if (w?.length > 0) {
            if (!w[0]?.id) {
                console.error("Workout not found: ", workoutId);
                return workouts.workout;
            }
            
            setWorkouts({ workout: w[0], parentWorkout: w.length > 1 ? w[1] : null});
        }

        return w;
    };
    
    const setControls = () => {
        if (!options || typeof options !== "object") return;
        options.refresh = async (force = false) => await getWorkoutAsync(force);
    }
    
    useEffect(() => {
        const workout = workouts?.workout;
        const parentWorkout = workouts?.parentWorkout;
        
        if (!!workout?.id && typeof onWorkout === "function")
            onWorkout(workout, parentWorkout || null);
        
    }, [workouts]);
    
    useEffect(() => {
        setControls();
        const id = setTimeout(() => {
            let _ = getWorkoutAsync();
        }, 10);
    }, []);


    const workout = workouts?.workout;
    
    if (!!workout?.id && workoutId !== workout.id) {
        let _ = getWorkoutAsync(true);
    }
    
    const activeWorkout = workouts?.parentWorkout ?? workouts?.workout;
    const workoutImage = !!activeWorkout?.image?.url ? (<img src={activeWorkout?.image?.url} alt={activeWorkout?.name} className={"workout-menu-image"} />) : null;
    const subMenu = !!activeWorkout?.id ? (<WorkoutSubMenu selection={selection} parentWorkout={workouts?.parentWorkout} workout={workout} />) : null;

    return (<AdminMasterScreen selection={"workouts"} title={title || "Workouts"}>
            <div className={"column-content person-details"}>
                <div className={"column"}>
                    <div className={"center"}>{ workoutImage }</div>
                    { subMenu }
                </div>
                <div className={"content"}>
                    {children}
                </div>
            </div>
        </AdminMasterScreen>);
};

export default WorkoutMasterScreen;
