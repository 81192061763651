import ImageModel from "../../media/models/ImageModel";

class UserModel {
    constructor(json) {
        if (!json) json = {};
        this.id = json.id;
        
        this.firstName = json.first_name;
        this.lastName = json.last_name;
        this.email = json.email;
        this.phone = json.phone || "";
        this.name = this.firstName + " " + this.lastName;
        this.auth = json.auth;
        this.roleId = json.auth?.role_id || 0;
        this.imageId = this.image_id || null;
        this.image = !!json.image?.url ? new ImageModel(json.image) :
            (!!json.image_url ? new ImageModel({ url: json.image_url, id: json.image_id }) : null);
        
        this.created = !!json.created ? new Date(Date.parse(json.created)) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new UserModel(json));
    }
}

export default UserModel;
