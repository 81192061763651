import React, {createContext, useContext} from "react";
import AuthenticationService from "./components/authentication/services/AuthenticationService";
import HttpService from "./components/common/services/HttpService";
const AppContext = createContext(null);

const SessionLoader = {
    status: 0,
    id: null,
    count: 0,
};

export default function DefAppState({ screenId, children, onMenuSelect, onMetaSelect, onLoad, source }) {
    let _;

    const appStateId = "my-app-state-" + (Math.random() * 9999999999).toString(16).toLowerCase();
    const getSessionId = () => AuthenticationService.instance?.sessionId || localStorage.getItem("session-id") || "";
    const isLoggedIn = () => AuthenticationService.instance.isLoggedIn();
    
    const getCurrentState = () => {
        return {
            id: appStateId,
            setTitle: DefAppState.setPageTitle,
            screenId: screenId,
            user: AuthenticationService.instance.session?.user,
            isLoggedIn: isLoggedIn(),
            sessionId: getSessionId(),
            session: AuthenticationService.instance.session,
            traceId: new Date().getTime().toString(16).toLowerCase(),
            onLoad: onLoad,
            date: new Date(),
        };
    };

    const currentState = getCurrentState();
    if (typeof source === "undefined") source = "None";

    SessionLoader.count++;

    if (source === "session") return new Date().getTime().toString(16);

    if (!!currentState?.sessionId && !HttpService.instance.sessionId) {
        //
        HttpService.instance.setSessionId(currentState.sessionId);
        AuthenticationService.instance.sessionId = currentState.sessionId;
        AuthenticationService.instance.getSessionAsync(true);
        AuthenticationService.instance.raiseEvent("auth", currentState.session, screenId);
    } else {
        //
    }
    
    return (<AppContext.Provider value={currentState}>
        {children}
    </AppContext.Provider>);
}

DefAppState.id = "";
DefAppState.setPageTitle = null;

export function useAppContext() {
    return useContext(AppContext);
}
