import React, {useEffect, useState} from 'react';
import {Navigate, useParams} from "react-router-dom";
import UserMasterScreen from "../UserMasterScreen";
import VideoPlayer from "../../../components/media/ui/VideoPlayer";
import ImageModel from "../../../components/media/models/ImageModel";
import VideoModel from "../../../components/media/models/VideoModel";
import MediaService from "../../../components/media/services/MediaService";
import DebugJson from "../../../components/common/ui/DebugJson";
import DefReset from "../../../components/common/models/DefReset";
import CommentsList from "../../../components/messaging/ui/comments/CommentsList";

const UserMediaDetailsScreen = (props) => {
    const { entityType } = props;
    const { id, userId } = useParams();
    const mediaId = id || videoId || imageId;

    const [screenState, setScreenState] = useState({
        media: null,
        refresh: false,
        user: null,
        entityType: entityType,
        id: id || null,
        userId: userId || null,
        path: null,
        masterOptions: {},
        commentOptions: {entityLink: false, entityId: mediaId },
    });

    const et = entityType > 0 ? entityType : parseInt(entityType?.toString()) || 0;
    const imageId = et === ImageModel.entityType ? id : null;
    const videoId = et === VideoModel.entityType ? id : null;

    const getSlug = () => {
        return et === VideoModel.entityType ? "videos" : "images";
    }
    
    const getMediaAsync = async (force = false, userModel = null) => {
        if (force !== true && !!screenState.media?.url) return screenState.media;
        
        const isVideo = et === VideoModel.entityType;
        if (!isVideo && window.location.pathname.indexOf("video/") > 0) {
            const msg = "Not Video, but is has it in the url: ";
            console.error(msg, et);
            throw new Error(msg);
        } else console.warn("Is Video: ", isVideo, entityType, et);
        
        const m = isVideo ?
            await MediaService.instance.getVideoAsync(mediaId) :
            await MediaService.instance.getImageAsync(mediaId);
        
        if (!!m?.url && typeof m?.toMediaModel === "function") {
            const media = m.toMediaModel();
            console.log("Setting UserMediaDetailsScreen State: ", media);
            const newState = {...screenState, media: media, value: m };
            const slug = getSlug();
            
            if (!!userModel?.id) newState.user = userModel;
            
            if (m.userId !== userId && !!userId)
                newState.path = "/people/" + m.userId + "/" + slug + "/" + mediaId;
            
            setScreenState(newState);
        } else {
            console.error("Failed to load media: ", m);
        }
    }
    
    const onUser = (u) => {
        const newState = { ...screenState, user: u };
        let _ = getMediaAsync(false, u);
    };
    
    const setMasterUser = (e) => {
        DefReset.stopEvent(e);
        
        if (!screenState.media?.user?.id) {
            return;
        }
        
        const u = screenState.media.user;
        
        if (typeof screenState.masterOptions?.setUser === "function") {
            console.warn("Setting User: ", u);
            screenState.masterOptions.setUser(screenState.media.user);
        } else {
            console.error("No User to Set");
        }
    };

    useEffect(() => {
        if (!!screenState.media?.user?.id && screenState.media.user?.id !== userId) {
            console.log("Setting new UserId: ", userId, screenState.media.user?.id);
            setMasterUser();
        }
    }, [screenState]);
 
    useEffect(() => {
        //
    }, []);

    if (typeof screenState.path === "string" && screenState.path.length > 0) {
        console.warn("Path: ", screenState.path);
        //return (<Navigate to={screenState.path} />);
    }
    
    const user = screenState.user;
    const titleWidget = !!user?.id ? (<h2>{user?.name}: Media Details</h2>) : null;
    const playerElement = !!videoId ?
        (<VideoPlayer className={"media-details"} videoId={mediaId} />) :
        (<img alt={"Media Details Image"} src={screenState.media?.url || ""} className={"media-image"} />);

    const commentElements = (<CommentsList options={screenState.commentOptions} />);
    
    return (<UserMasterScreen onUser={onUser} selection={"media"} options={screenState.masterOptions}>
        <div className={"pad"}>
            {titleWidget}
            
            <p>
                Media View
            </p>
            
            {playerElement}

            <div className={"comments constrain1024"}>{commentElements}</div>
            
            <div style={{paddingTop: "64px"}}>
                <DebugJson json={screenState.media} />
            </div>
        </div>
        
    </UserMasterScreen>);
};

export default UserMediaDetailsScreen;
