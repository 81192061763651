import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import AdminMasterScreen from "../AdminMasterScreen";
import FuelSubMenu from "../../components/fuel/ui/FuelSubMenu";

const FuelMasterScreen = (props) => {
    const { selection, onMeal, onFood, onProduct, title, children } = props;
    const { mealId, foodId, productId } = useParams();

    const getMealAsync = (force = false) => {
        //
    };

    useEffect(() => {
        //
    }, []);

    const titleElement = !!title ? <h2>{title}</h2> : null;
    
    return (<AdminMasterScreen selection={"fuel"} title={title || "Fuel"}>
        <div className={"column-content person-details"}>
            <div className={"column"}>
                <FuelSubMenu selection={selection} />
            </div>
            <div className={"content"}>
                {children}
            </div>
        </div>
        </AdminMasterScreen>);

};

export default FuelMasterScreen;
