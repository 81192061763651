class StackTraceModel {
    static exampleJson = {"error_code":5,"error_code_name":"Server Error","messages":["We have sent an email to haydenjohnson5353@gmail.com, please click the link included to verify your email address."],"objects":[{"index":0,"field_name":"general","message":"We have sent an email to haydenjohnson5353@gmail.com, please click the link included to verify your email address."}],"meta_code":0,"message":"We have sent an email to haydenjohnson5353@gmail.com, please click the link included to verify your email address.","stack_trace":"No StackTrack Available","source":"","Data":{},"InnerException":null,"HelpLink":null,"HResult":-2146233088};
    constructor(json, userId = null) {
        if (typeof json === "string") {
            try {
                json = JSON.parse(json);
                if (!json.message) json.message = "Parsed Empty";
            } catch (e) {
                json = {};
                if (!json.message)
                    json.message = "Error: " + e?.toString() + "";
            }
        } else if (typeof json === "object" && json !== null) {
            if (!json.message) json.message = "Passed Empty";
        }
        
        if (!json) json = {};

        this.errorCode = json.error_code || 0;
        this.errorCodeName = json.error_code_name || "";
        this.messages = json.messages || [];
        this.objects = json.objects || [];
        this.metaCode = json.meta_code || 0;
        this.message = json.message || "";
        this.userId = userId || json.user_id || null;
    }
}

export default StackTraceModel;

