import HttpService from "../../common/services/HttpService";
import MessageSendLogModel from "../models/MessageSendLogModel";
import MessageTemplateModel from "../models/MessageTemplateModel";

class MessageService {
    static instance = new MessageService();

    constructor() {
        this.messageLogs = null;
        this.messageTemplatesMap = {};
        this.messageTemplates = [];
        this.messageTemplateMap = {};
        
    }

    async getMessageLogsAsync(options) {
        const path = "/api/message-log";
        const sd = options?.startDate || null;
        const ed = options?.endDate || null;
        
        const rsp = await HttpService.instance.getWithDateRangeAsync(path, sd, ed);
        if (!rsp?.data) return null;
        
        this.messageLogs = MessageSendLogModel.fromJsonArray(rsp.data);
        return this.messageLogs;
    }
    
    async sendPushNotificationsAsync(message, subject, articleId = null) {
        const path = "/api/messaging/user-base/" + (articleId || "");
        const json = {
            message: message,
            subject: subject,
        };
        
        const rsp = await HttpService.instance.postAsync(path, json);
        
        return rsp.data;
    }


    async getMessageTemplateAsync(messageTemplateId) {
        const path = '/api/message-template/' + messageTemplateId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.messageTemplateMap[messageTemplateId] = new MessageTemplateModel(response.data);
            return me.messageTemplateMap[messageTemplateId];
        });
    }

    async getMessageTemplatesAsync() {
        const path = "/api/message-template";
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            me.messageTemplates = MessageTemplateModel.fromJsonArray(response.data);

            return me.messageTemplates;
        });
    }
    
    async saveMessageTemplateAsync(json, messageTemplateId) {
        const path = '/api/message-template/' + (messageTemplateId || "");
        const me = this;

        return await HttpService.instance.postAsync(path, json).then((response) => {
            const template = new MessageTemplateModel(response.data);

            if (!!template?.id) {
                me.messageTemplateMap[template.id] = template
                return template;
            }

            return null;
        });
    }
    
}

export default MessageService;