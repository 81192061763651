import React, {useEffect, useRef, useState} from 'react';
import ExerciseService from "../services/ExerciseService";
import MediaUploader from "../../media/ui/MediaUploader";
import DefReset from "../../common/models/DefReset";
import GalleryUploader from "../../media/ui/GalleryUploader";
import ExerciseModel from "../models/ExerciseModel";
import DefButton from "../../common/ui/elements/DefButton";

const ExerciseForm = ({ exerciseId, options, defaultValue }) => {
    const initialValue = {
        exercise: defaultValue || ExerciseService.instance.exerciseMap[exerciseId],
        state: null,
        imageId: (defaultValue || ExerciseService.instance.exerciseMap[exerciseId])?.imageId,
        save: false,
    };

    const [screenState, setScreenState] = useState(initialValue);
    const buttonId = useState(options?.buttonId || "sav-exercise-button")[0];
    const mainImageId = useState("main-image-" + (exerciseId || "new"))[0];

    const descriptionRef = useRef();
    const nameRef = useRef();
    const imageRef = useRef();

    const setExercise = (ex) => {
        const newState = {...screenState};
        newState.exercise = ex;
        
        delete newState.save;

        setScreenState(newState);
    };

    const setImageId = (imageId) => {
        const newState = {...screenState};
        newState.imageId = imageId;
        newState.save = MediaUploader.options[mainImageId] === 2;

        if (!!options) options.imageId = imageId;
        
        setScreenState(newState);
    };

    const exercise = screenState.exercise;

    const createJson = () => {
        return {
            image_id: screenState.imageId || imageRef.current?.value || options?.imageId || null,
            name: nameRef.current?.value || null,
            description: descriptionRef.current?.value || null,
        };
    };

    const saveExerciseAsync = async (e) => {
        DefReset.stopEvent(e);

        const uploadStatus = MediaUploader.options[mainImageId] || 0;
        if (e !== true && !DefReset.isEnabled(buttonId)) return;

        if (uploadStatus > 0) {
            // Still uploading...
            console.log("Still uploading. Waiting for upload to complete...");
            MediaUploader.options[mainImageId] = 2;
            return;
        }

        const json = createJson();
        const updateExercise = await ExerciseService.instance.saveExerciseAsync(json, exerciseId);

        setExercise(updateExercise);
    };

    const getExerciseAsync = async (force = false) => {
        if (typeof exerciseId !== "string" || exerciseId.length < 30) return;
        console.log("Getting exercise: " + exerciseId);

        const ex = await ExerciseService.instance.getExerciseAsync(exerciseId);
        if (!!ex?.id) {
            if (!!nameRef.current) nameRef.current.value = ex.name;
            if (!!descriptionRef.current) descriptionRef.current.value = ex.description;
            
            setExercise(ex);
        }
    };

    const onMainPhoto = (fileModels) => {
        fileModels = fileModels.filter((m) => m.isVideo() === false);
        if (fileModels.length === 0) return;

        MediaUploader.options[mainImageId] = 1;
        //const fileModel = fileModels[0];
    };

    const onMainImageUploaded = (mediaModels) => {
        if (mediaModels.length === 0) return;
        setImageId(mediaModels[0]?.id)
    };

    const onMedia = (fileModels) => {
        console.log("OnMedia: ", fileModels?.length);
    };

    const setControllers = () => {
        
        if (!!options && typeof options === "object") {
            options.saveAsync = (e) => saveExerciseAsync(e);
            options.getData = () => createJson();
        }
    };
    
    useEffect(() => {
        const uploadState = MediaUploader.options[mainImageId] || 0;
        
        if (screenState.save === true) {
            delete MediaUploader.options[mainImageId];
            let _ = saveExerciseAsync(true);
        }
        
    }, [screenState]);

    useEffect(() => {
        setControllers();
        let _ = getExerciseAsync(true);
    }, []);

    const canEdit = !!exercise?.id || exerciseId === "000";
    const mainImageUploadUrl = "/api/media/upload";
    const imageElement = (<MediaUploader onDrop={onMainPhoto} count={1} size={128} onComplete={onMainImageUploaded} uploadUrl={mainImageUploadUrl} className={"image-dropper"}>{ exercise?.image ?
        (<img src={exercise?.image?.url} className={"image128"} alt={""} />) :
        (<>Main Image<br/>Goes Here</>)}</MediaUploader>);

    const mediaUploader = !!exercise?.id ? (<GalleryUploader entityId={exercise.id} entityType={ExerciseModel.entityType}>
        Drop Image or Video Files Here.
    </GalleryUploader>) : null;

    const buttonsElement = options?.button !== false ? (<div className={"form-buttons flex"}>
        <span></span>
        <DefButton id={buttonId} onClick={saveExerciseAsync}>Save</DefButton>
    </div>) : null;
    
    return (<div className={"form bottom16"}>
        <div className={"flex"} style={{alignItems: "flex-start"}}>
            <div className={"image"} style={{paddingTop: "8px"}}>{imageElement}</div>
            
            <div className={"fields"} style={{flexGrow: "4"}}>
                <div className={"form-group"}>
                    <label htmlFor={"name"}>Exercise Name:</label>
                    <input defaultValue={exercise?.name} ref={nameRef} id={"name"} />
                </div>
            </div>
        </div>

        <div className={"form-group"} style={{marginTop: "32px"}}>
            <label htmlFor={"description"}>Description and Directions:</label>
            <textarea className={"large"} defaultValue={exercise?.description} ref={descriptionRef} id={"description"}></textarea>
        </div>

        <h4>Additional Media</h4>
        {mediaUploader}
        {buttonsElement}
    </div>);

};

export default ExerciseForm;
