import React, {useEffect, useState} from 'react';
import FuelMasterScreen from "./FuelMasterScreen";
import FoodService from "../../components/fuel/services/FoodService";
import {Link} from "react-router-dom";
import DateTime from "../../components/common/ui/DateTime";
import AddNewButton from "../../components/common/ui/AddNewButton";
import {faCarrot, faFootball, faPlus, faUtensils} from "@fortawesome/free-solid-svg-icons";

const FoodsScreen = (props) => {
    const {} = props;
    const [screenState, setScreenState] = useState({ id: 0 });

    const getFoodsAsync = async (force = false) => {
        let foods = FoodService.instance.foods;
        if (Array.isArray(foods) && foods.length > 0 && force !== true)
            return foods;

        foods = await FoodService.instance.getFoodsAsync();
        setScreenState({ id: new Date().getTime() });
        return foods;
    };

    useEffect(() => {
        let _ = getFoodsAsync();
    }, []);

    const foods = FoodService.instance.foods;
    const styles = {
        imageTd: { width: "80px"},
        nameTd: { whiteSpace: "nowrap", width: "256px" },
        previewTd: { whiteSpace: "wrap" },
        dateTd: { width: "128px" },
    };
    
    const elements = foods.map((food, index) => {
        const imageElement = !!food.image?.url ?
            (<img src={food.image.url} alt={food.name} className={"td-image"} />) :
            null;
        
        return (<tr key={"food-" + index}>
            <td style={styles.imageTd} className={"thumb"}><Link to={"/fuel/foods/" + food.id}>{imageElement}</Link></td>
            <td style={styles.nameTd}>
                <Link to={"/fuel/foods/" + food.id}>{food.name}</Link>
            </td>
            <td style={styles.previewTd}></td>
            <td style={styles.dateTd}><DateTime value={food.created} /></td>
        </tr>);
    });

    return (<FuelMasterScreen selection={"foods"}>
        <div className={"pad"}>
            <h2 className={"nav with-button"}>
                <span>Foods</span>
                <AddNewButton href={"/fuel/foods/00-00"} icon={faCarrot}>Add Food</AddNewButton>
            </h2>

            <table width={"100%"} className={"table"}>
                <thead>
                <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Preview</th>
                    <th>Date</th>
                </tr>
                </thead>
                <tbody>
                {elements}
                </tbody>
            </table>
        </div>
    </FuelMasterScreen>);
};

export default FoodsScreen;

