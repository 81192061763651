import HttpService from "../../common/services/HttpService";
import ArticleModel from "../models/ArticleModel";

class ArticleService { 
    static instance = new ArticleService();

    constructor() {
        this.articlesMap = {};
        this.articles = [];
        this.articleMap = {};
    }

    async getArticleAsync(articleId) {
        const path = '/api/learn/' + articleId;
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const a = new ArticleModel(response.data);
            if (!a?.id) return null;
            
            me.articleMap[articleId] = a;
            return me.articleMap[articleId];
        });
    }
    
    async getWebsiteMetaAsync(url) {
        const path = "/api/learn/meta";
        const json = { input: url };
        
        console.log("Getting Url Meta: ", url);
        
        const metaItems = await HttpService.instance.postAsync(path, json).then((rsp) => rsp?.data);
        
        const rsp = {
            title: "",
            description: "",
            imageUrl: "",
            url: url,
            titleField: "",
            descField: "",
            imageField: "",
        };
        
        if (Array.isArray(metaItems)) {
            for (let i = 0; i < metaItems.length; i++) {
                const item = metaItems[i];
                
                if (item?.property === "og:title") {
                    rsp.title = item?.content;
                }
                
                if (item?.property === "og:description") rsp.description = item?.content;
                if (item?.property === "og:image") rsp.imageUrl = item?.content;
            }
            
            return rsp;
        }
        
        return rsp;
    }

    async getArticlesAsync(status = 0) {
        const path = "/api/v2/learn?status=" + status.toString();
        const me = this;

        return await HttpService.instance.getAsync(path).then((response) => {
            const articles = ArticleModel.fromJsonArray(response.data?.articles);
            
            if (articles.length > 0) { 
                me.articles = articles;
                me.updateMap();
            } else {
                console.error("No Articles Found");
                console.log(JSON.stringify(response.data, null, 4));
            }

            return me.articles;
        });
    }
    
    updateMap() {
        for (let i = 0; i < this.articles.length; i++) {
            this.articleMap[this.articles[i].id] = this.articles[i];
        }
    }
    
    async deleteArticleAsync(articleId) {
        const path = "/api/learn/" + articleId;
        await HttpService.instance.deleteAsync(path);
    }
    
    async saveArticleAsync(articleJson, articleId = null) {
        const path = '/api/learn' + (typeof articleId === "string" && articleId.length > 30 ? "/" + articleId : "");
        console.log("Saving: ", path);
        
        const rsp = await HttpService.instance.postAsync(path, articleJson);
        const json = rsp?.data;
        const article = new ArticleModel(json);
        
        if (!article?.id) {
            console.log(json);
            return null;
        }
        
        this.articleMap[articleId] = article;
        
        return article;
    }
}

export default ArticleService;
