import React, {useEffect, useState} from 'react';
import HabitService from "../../habits/services/HabitService";
import UserService from "../services/UserService";
import SearchFilter from "../../common/ui/search-filter/SearchFilter";
import DatePicker from "../../common/ui/date-picker/DatePicker";
import DateTime from "../../common/ui/DateTime";
import {Link} from "react-router-dom";
import LoadingBox from "../../common/ui/LoadingBox";
import UserMasterScreen from "../../../routes/people/UserMasterScreen";

const UserHabitsTable = (props) => {
    const { user, userId, options } = props;
    const [screenState, setScreenState] = useState({
        users: UserService.instance.users || [],
        habits: HabitService.instance.userHabitsMap[userId] || null,
        habitItems: HabitService.instance.userHabitsMap[userId] || null,
        habitModels: HabitService.instance.habits || [],
        id: 0,
        term: "",
        filterOptions: {},
        dateOptions: { startDate: null, endDate: null, reactClassName: "PersonHabitsScreen" },
        lastCount: -1
    });

    const setNewState = (key, value) => {
        const newState = {...screenState};
        newState[key] = value;
        
        setScreenState(newState);
    };

    const setUser = (user) => setNewState("user", user);
    const setHabits = (habits) => setNewState("habits", habits);
    
    const onUser = (u) => {
        let _ = getHabitsAsync();
        setUser(u);
    };

    const setControls = () => {
        if (!options) return;
        
        if (!!user?.id && typeof options?.setTitle == "function")
            options.setTitle(user?.name);

        options.setUser = (user) => setUser(user);
        options.setHabits = (habits) => setHabits(habits);
        options.refresh = async () => getHabitsAsync(true, screenState.term);
    };

    const getHabitModels = async (force = false) => {
        let hs = HabitService.instance.habits || null;
        if (Array.isArray(hs) && hs.length > 0 && force !== true) return hs;

        hs = await HabitService.instance.getHabitsAsync();
        
        return hs || [];
    };

    const getHabitsAsync = async (force = false, term = null) => {
        const habits = screenState.habits || null;
        if (force !== true && !!term && habits.length > 0) return habits;

        const hs = await getHabitModels();
        const userHabits = !!userId && userId.length > 30 ? 
            await HabitService.instance.getUserHabitsAsync(userId, options) :
            await HabitService.instance.getAdminUserHabitsAsync(screenState.dateOptions);

        console.log("Good.");
        
        const newState = {...screenState};
        newState.habits = userHabits;
        newState.habitItems = userHabits;
        newState.habitModels = hs;

        setScreenState(newState);
        
        return newState.habits;
    };

    const onSearchType = (e, items) => {
        console.log("Filter Type: ", e);
        console.log("Item Count: ", items?.length);

        setHabits(items);
    }

    const onSearchAsync = async (term) => {
        console.log("Search Term: ", term);
        return await getHabitsAsync(true, term);
    }

    const onDateChanged = async (startDate, endDate) => {
        //
    };

    const onDateClicked = async (startDate, endDate) => {
        //
    };

    const searchForHabit = (uh, index) => {
        if (!uh?.habit) {
            console.error("No Habit. Assuming false");
            return false;
        }

        if (index === 1) return uh.habit.description || "";
        if (index === 2) return uh.habit.longDescription || "";
        if (index === 3) return uh.habit.actionDescription || "";
        if (index === 4) return uh.habit.leaderboardTitle || "";
        if (index === 5) return uh.habit.leaderboardDescription || "";

        return uh.habit.name;
    };

    useEffect(() => {
        setControls();
    }, [user]);

    useEffect(() => {
        const id = Math.round(Math.random() * 999999).toString();
        let _ = getHabitsAsync();
    }, []);

    const searchFields = [
        (item, index) => searchForHabit(item, 0),
        (item, index) => searchForHabit(item, 1),
        (item, index) => searchForHabit(item, 2),
        (item, index) => searchForHabit(item, 3),
        (item, index) => searchForHabit(item, 4),
    ];

    const habitItems = screenState.habitItems;
    
    const searchHeader = (<div className={"date-search"}>
        <div><SearchFilter options={screenState.dateOptions} fields={searchFields} items={habitItems} onChange={(e, items) => onSearchType(e, items)} onClick={onSearchAsync} /></div>
        <div style={{flex: "4"}}></div>
        <div><DatePicker onChange={onDateChanged} onClick={onDateClicked} /></div>
    </div>);

    let lastDateKey = -100;
    const habits = screenState.habits || null;
    const elements = Array.isArray(habits) ? habits.map((habit, index) => {
        const habitModel = HabitService.instance.habitMap[habit.habitId] || null;
        let text = habit.data?.workout_name || "";

        if (!!text) text = (<><strong>Workout: </strong><span>{text}</span></>);
        else text = !!habit.notes ? (<><strong>Notes: </strong><span>{habit.notes}</span></>) : null;

        let dt = habit.completedDate;
        if (typeof dt?.toUtcDate === "function") dt = dt.toUtcDate().addMinutes(-habit.timezoneOffset);

        const dateKey = typeof dt?.getDate === "function" ? dt.getDate() : 0;

        const dateSeparator = (dateKey !== lastDateKey) ? (<tr key={"date-" + dateKey} className={"header"}>
            <td colSpan={4} style={{textAlign: "left"}}>
                <strong><DateTime value={habit.completedDate} time={false} /></strong>
            </td>
        </tr>) : null;

        lastDateKey = dateKey;

        return (<React.Fragment key={"habit-" + index}>
            {dateSeparator}
            <tr title={"date-key: " + dateKey + "  :  " + habit?.completedDate?.toISOString() + "  ::  " + habit?.timezoneOffset + ""}>
                <td className={"habit-name"}><Link to={"/people/" + habit.userId + "/user-habit/" + habit.id}>{habitModel?.name}</Link></td>
                <td className={"date"}><DateTime value={habit?.completedDate} time={true} /></td>
                <td className={"medium"}>{text}</td>
                <td className={"date"}><DateTime value={habit?.completedDate} /></td>
            </tr>
        </React.Fragment>);
    }) : null;

    const loadingBox = habits === null ? (<LoadingBox>Loading Habits...</LoadingBox>) : null;

    return (<div>
        {searchHeader}
        <table className={"table"}>
            <thead>
            <tr>
                <th className={"habit-name"}>Habit ({habits?.length})</th>
                <th>Completed</th>
                <th>Details</th>
                <th>Created</th>
            </tr>
            </thead>
            
            <tbody>
            {elements}
            </tbody>
        </table>
        {loadingBox}
    </div>);

};

export default UserHabitsTable;

