import React, {useEffect, useState} from 'react';
import SettingsMasterScreen from "../SettingsMasterScreen";
import MessagesTable from "../../../components/messaging/ui/MessagesTable";
import DefReset from "../../../components/common/models/DefReset";
import RefreshButton from '../../../components/common/ui/RefreshButton';

const MessageTemplateScreen = (props) => {
    const {} = props;
    const [screenState, setScreenState] = useState({ tableOptions: {}});
    
    const onSession = (session) => {
        if (!session?.id) return;
        let _ = refreshAsync();
    };

    const refreshAsync = async (e) => {
        DefReset.stopEvent(e);
        
        if (typeof screenState.tableOptions.refreshAsync === "function")
            await screenState.tableOptions.refreshAsync();
    };
    
    return (<SettingsMasterScreen selection={"media"} onSession={onSession}>
        <div className={"pad"}>
            <h2 className={"nav with-button"}>
                <span>Automated Message Templates</span>
                <span>
                    <RefreshButton onClick={refreshAsync} />
                </span>
            </h2>

            <p>
                Below are the message templates that are used to send automated messages to users when certain events occur.
            </p>
            
            <div className={"message-templates"}>
                <MessagesTable options={screenState.tableOptions} />
            </div>
        </div>
    </SettingsMasterScreen>);
};

export default MessageTemplateScreen;
