import React, {useState} from 'react';
import UserMasterScreen from "./UserMasterScreen";
import PersonForm from "../../components/users/ui/PersonForm";
import UserService from "../../components/users/services/UserService";
import {useParams} from "react-router-dom";
import DefReset from "../../components/common/models/DefReset";

const UserEditScreen = (props) => {
    const {} = props;
    const { userId } = useParams();
    const [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    const options = useState({ userJson: {} })[0];
    
    const onUser = (u) => {
        if (typeof options.setValue === "function")
            options.setValue(u);
        
        setUser(u);
    };
    
    const saveUserAsync = async (e) => {
        DefReset.stopEvent(e);
        
        console.log(JSON.stringify(options.userJson, null, 4));
        
        const u = await UserService.instance.updateUserAsync(userId, options.userJson);
        await UserService.instance.getUsersAsync();
        
        setUser(user);
    };
    
    const onFormChange = (json) => {
        options.userJson = json;
        if (!options.userJson.phone) options.userJson.phone = null;
    };
    
    const debugElement = !!user ? (<pre>{JSON.stringify(user, null, 4)}</pre>) : null;
    const personForm = !!user?.id || (!!userId && userId.length < 30) ? <PersonForm onChange={onFormChange} options={options} value={user} /> : null;
    
    return (<UserMasterScreen onUser={onUser} selection={"edit"}>
        <div className={"pad form constrain600"}>
            <h2 className={"def-header"}>Edit {user?.name || "User"}</h2>
            { personForm }
            
            <div className={"form-buttons"}>
                <button id={"user-submit"} onClick={(e) => saveUserAsync(e)}>Save User</button>
            </div>
            
            <div style={{marginTop: "64px"}}>
                {debugElement}
            </div>
        </div>
    </UserMasterScreen>);
};

export default UserEditScreen;