class DashboardModel {
    constructor(json) {
        if (!json) json = {};
        
        this.title = json.title || "";
        this.description = json.description || "";
        this.startDate = !!json.start_date ? new Date(json.start_date) : null;
        this.endDate = !!json.end_date ? new Date(json.end_date) : null;
    }
}

export default DashboardModel;
