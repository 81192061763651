import React, {useEffect, useState} from 'react';
import SettingsMasterScreen from "../SettingsMasterScreen";
import ActivityService from "../../../components/activities/services/ActivityService";
import LoadingBox from "../../../components/common/ui/LoadingBox";
import "../../../components/activities/ui/SystemError.css";
import DateTime from "../../../components/common/ui/DateTime";
import EmptyBox from "../../../components/common/ui/EmptyBox";
import DatePicker from "../../../components/common/ui/date-picker/DatePicker";
import SearchFilter from "../../../components/common/ui/search-filter/SearchFilter";
import DefReset from "../../../components/common/models/DefReset";
import ReactModal from "../../../components/common/ui/react-modal/ReactModal";
import DebugJson from "../../../components/common/ui/DebugJson";
import ProfilePhoto from "../../../components/users/ui/ProfilePhoto";
import {Link} from "react-router-dom";

const SystemErrorsScreen = (props) => {
    const {} = props;
    const [screenState, setScreenState] = useState({
        systemErrors: null,
        items: null,
        startDate: new Date().toDate(),
        endDate : new Date(),
    });
    
    const getSystemErrorsAsync = async (force = false) => {
        if (force !== true && Array.isArray(screenState.items))
            return screenState.systemErrors;
        
        const opts = { term: screenState.term, startDate: screenState.startDate, endDate: screenState.endDate };
        const items = await ActivityService.instance.getSystemErrorsAsync(opts);
        
        if (Array.isArray(items)) {
            const newState = {...screenState};
            
            if ((newState.systemErrors?.length || 0) <= 0)
                newState.systemErrors = items;
            
            newState.items = items;
            
            setScreenState(newState);
        }
        
        return items;
    };
    
    const onFilter = (term, items) => {
        if (Array.isArray(items)) {
            console.log("onFilter: ", items?.length);
            const newState = {...screenState};
            newState.items = items;
            newState.term = term;
            setScreenState(newState);
            return true;
        } else {
            console.error("Not Array: ", items);
        }
        
        return false;
    };
    
    const onSearch = (term, items) => {
        console.log("onSearch: ", term, items);
        setScreenState({...screenState, items: null, term: term});
        return false;
    };
    
    const onDateChange = (date, elementId) => {
        console.log("Date Change: ", elementId);
        
        return false;
    };

    const onDateClick = (startDate, endDate, e) => {
        console.log("onDateClick: ", startDate, endDate, e);
        
        const newState = {...screenState};
        newState.items = null;
        newState.startDate = startDate;
        newState.endDate = endDate;
        
        setScreenState(newState);
        
        return false;
    };
    
    const showErrorDialogAsync = async (item, e) => {
        DefReset.stopEvent(e);
        
        const options = {};
        const dialog = new ReactModal();
        
        const jsonElement = (<DebugJson value={item} title={item.message || "System Error"} />);
        
        await dialog.openFormDialogAsync(jsonElement, () => true, options);
    };
    
    const onSession = (s) => {
        let _ = getSystemErrorsAsync();
    };

    useEffect(() => {
        let _ = getSystemErrorsAsync();
    }, [screenState]);
    
    const elements = Array.isArray(screenState.items) ? screenState.items.length === 0 ? (<EmptyBox>No Errors Found</EmptyBox>) : screenState.items.map((item, index) => {
        const userElement = !!item.user?.id ?
            (<Link to={"/people/" + item.userId + ""} className={"error-user"}>
                <span><ProfilePhoto user={item.user} size={24} /></span>
                <span>{item.user.name || item.user.id}</span>
            </Link>) :
            null;
        
        return (<div key={"system-error-" + index} className={"system-error"}>
            <div>
                <span className={"message"} onClick={(e) => showErrorDialogAsync(item, e)}>
                    <span>
                        <strong>Request Path: </strong>
                        {item.requestPath + ""}
                    </span>
                    <span>{item.message || item.stack?.message || "No Data"}</span>
                </span>
                <span className={"date"}>
                    <span className={"ip"}>IP: <strong>{item.ipAddress}</strong></span>
                    {userElement}
                    <DateTime useRelative={true} value={item.created} time={true} />
                </span>
            </div>
        </div>);
        
    }) : (<LoadingBox>Loading System Log...</LoadingBox>);
    
    const headerElement = Array.isArray(screenState.systemErrors) ? (<div className={"search-date"}>
            <SearchFilter items={screenState.systemErrors} onChange={onFilter} onClick={onSearch} />
            <DatePicker startDate={screenState.startDate} endDate={screenState.endDate} onChange={onDateChange} onClick={onDateClick} />
        </div>) : null;
    
    return (<SettingsMasterScreen selection={"activities"} onSession={onSession}>
        <div className={"pad constrain"}>
            <h2 className={""}>
                System Errors
            </h2>
            {headerElement}
            {elements}
        </div>
    </SettingsMasterScreen>);
};

export default SystemErrorsScreen;
