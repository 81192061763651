import ExerciseModel from "./ExerciseModel";
import ImageModel from "../../media/models/ImageModel";

class WorkoutExerciseModel {
    static entityType = 62;
    static create = (json) => new WorkoutExerciseModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.workoutId = json.workout_id || null;
        this.exerciseId = json.exercise_id || null;
        this.userId = json.user_id || null;
        this.imageId = json.image_id || null;
        this.notes = json.notes || null;
        this.exercise = !!json.exercise ? new ExerciseModel(json.exercise) : null;
        
        this.setCountLabel = json.set_count_label || null;
        this.repCountLabel = json.rep_count_label || null;
        
        this.name = json.name || this.exercise?.name || "";
        this.description = json.description || this.exercise?.description || "";
        this.imageUrl = this.exercise?.imageUrl || null;
        this.image = !!json.image?.url ?
            new ImageModel(json.image) :
            (!!this.exercise?.image?.url ? new ImageModel(this.exercise.image) : null); 
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            workout_id: this.workoutId,
            exercise_id: this.exerciseId,
            notes: this.notes,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.notes?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromExercise(exerciseModel, workoutId = null) {
        if (exerciseModel instanceof ExerciseModel) {
            const json = {
                id: null,
                exercise_id: exerciseModel.id,
                name: exerciseModel.name,
                description: exerciseModel.description,
                created: new Date(),
                workout_id: workoutId,
            };
            
            const we = new WorkoutExerciseModel(json);
            we.exercise = exerciseModel;
            we.imageUrl = exerciseModel.imageUrl;
            
            return we;
        }
        
        return null;
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => WorkoutExerciseModel.create(json));
    }
}

export default WorkoutExerciseModel;
