import React, {useState} from 'react';
import WorkoutsMasterScreen from "./WorkoutsMasterScreen";
import ExercisesList from "../../components/workouts/ui/ExercisesList";
import AddNewButton from "../../components/common/ui/AddNewButton";
import {faWalking} from "@fortawesome/free-solid-svg-icons";
import DefReset from "../../components/common/models/DefReset";
import ExerciseForm from "../../components/workouts/ui/ExerciseForm";
import ReactModal from "../../components/common/ui/react-modal/ReactModal";
import WorkoutService from "../../components/workouts/services/WorkoutService";
import ExerciseService from "../../components/workouts/services/ExerciseService";

const ExercisesScreen = (props) => {
    const {} = props;
    const [options, setOptions] = useState({ force: false, persistKey: "ExercisesScreen" });
    
    const onExerciseClick = (ex) => {
        console.log("Exercise");
        return false;
    };

    const showExerciseForm = async (e, exerciseModel = null) => {
        DefReset.stopEvent(e);

        const buttonId = "exercise-dialog-button";
        const dialogOptions = { className: "exercise-form-dialog", buttonId: buttonId, status: "1" };
        const formOptions = { button: false, buttonId: buttonId };
        const formElement = (<ExerciseForm defaultValue={exerciseModel} options={formOptions} exerciseId={"000"} />);
        const dialog = new ReactModal();

        await dialog.openFormDialogAsync(formElement, async (e) => {
            const json = formOptions.getData();
            json.status = 1;
            
            const exercise = await ExerciseService.instance.saveExerciseAsync(json, exerciseModel?.id);
            
            if (typeof options?.refresh === "function") 
                await options.refresh();
            
            return true;
        }, dialogOptions);
    };

    return (<WorkoutsMasterScreen selection={"exercises"}>
            <div className={"pad constrain"}>
                <h2 className={"def-header nav with-buttons"}>
                    <span>Exercises</span>
                    <AddNewButton icon={faWalking} onClick={(e) => showExerciseForm(e)} href={""}>Add Exercise</AddNewButton>
                </h2>
                <p className={"p"}>
                    Below are individual exercises that can be added to a workout. A single exercise can be
                    added to multiple workouts.
                </p>
                
                <div className={"large-list"}>
                    <ExercisesList onClick={onExerciseClick} options={options} />
                </div>
                
            </div>
        </WorkoutsMasterScreen>);
};

export default ExercisesScreen;
