import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const MainMenuLink = (props) => {
    const { to, icon, children, className } = props;

    const body = !!icon ?
        (<>
            <FontAwesomeIcon icon={icon} />
            <span>{children}</span>
        </>) :
        children;
    
    const cn = !!icon ? " with-icon" : "";
    return (<Link className={(className || "") + cn} to={to}>{body}</Link>);
}

export default MainMenuLink;
