import React, {useEffect, useState} from 'react';
import MediaGallery from "../../media/ui/MediaGallery";
import LoadingBox from "../../common/ui/LoadingBox";
import UserService from "../services/UserService";
import "./UserMediaGallery.css";

const UserMediaGallery = (props) => {
    const { userId, options:initialOptions } = props;
    const [mediaItems, setMediaItems] = useState(UserService.instance.userMap[userId]?.media || null);
    const [options, setOptions] = useState(initialOptions || {
        captions: false,
        fullSize: true,
    });

    const getMediaAsync = async (force = false) => {
        if (!force && Array.isArray(mediaItems)) return mediaItems;
        
        const options = {};
        
        return await UserService.instance.getUserMediaAsync(userId, options).then((media) => {
            setMediaItems(media);
            return media;
        });
    };
    
    const onMediaSaved = (mediaItem, index) => {
        if (index >= 0 && !!mediaItem?.id) {
            mediaItems[index] = mediaItem;
            console.log("SAVED [" + index + "]: ", mediaItem);
            
            setMediaItems([...mediaItems]);
        } else {
            console.error("Not saved: ", mediaItem);
            console.log(" > Index: ", index);
        }
    };
    
    useEffect(() => {
        let _ = getMediaAsync();
    }, []);

    // 
    
    const mediaGallery = Array.isArray(mediaItems) ?
        (<MediaGallery options={options} media={mediaItems} onSave={onMediaSaved} />) :
        (<LoadingBox>Loading Media...</LoadingBox>);
    
    return (<div className={"user-media-gallery"}>{mediaGallery}</div>);
};

export default UserMediaGallery;
