import React, {useEffect, useState} from 'react';
import FeedbackService from "../services/FeedbackService";
import "./Messaging.css";
import DateTime from "../../common/ui/DateTime";
import DefReset from "../../common/models/DefReset";
import MediaService from "../../media/services/MediaService";
import {Link} from "react-router-dom";
import ProfilePhoto from "../../users/ui/ProfilePhoto";
import EmptyBox from "../../common/ui/EmptyBox";

const FeedbackList = (props) => {
    const {options, className} = props;
    const [screenState, setScreenState] = useState({ refresh: false });
    
    const getFeedbackAsync = async (force = false) => {
        const items = FeedbackService.instance.feedbacks;
        if (items?.length > 0 && force !== true) return items;
        
        await FeedbackService.instance.getFeedbackItemsAsync(options);
        setScreenState({refresh: false});
    };

    const showImage = (e, imageUrl) => {
        DefReset.stopEvent(e);
        MediaService.instance.showImage(imageUrl);
    };
    
    const setControls = () => {
        if (!options || typeof options !== "object") return;
        options.refresh = () => {
            let _ = getFeedbackAsync(true);
        };
    };
    
    const refresh = (e) => {
        DefReset.stopEvent(e);
        setScreenState({refresh: true});
    };
    
    useEffect(() => {
        if (screenState.refresh === true) {
            let _ = getFeedbackAsync(true);
        }
    }, [screenState]);
    
    useEffect(() => {
        setControls();
        let _ = getFeedbackAsync(options?.force === true);
    }, []);
    
    let status = options.status || null;
    if (typeof status === "number") status = [status];
    else if (!Array.isArray(status)) status = null;
    
    // const items = status?.length > 0 ? 
    //     FeedbackService.instance.feedbacks?.filter((f) => status.includes(f.status)) :
    //     FeedbackService.instance.feedbacks || null;
    const items = FeedbackService.instance.feedbacks || null;
    
    const elements = Array.isArray(items) ? items.map((item, index) => {
        if (!item) return null;

        const user = item?.user;
        const imageUrl = user?.image?.url;
        const isUnRead = !item?.readDate;
        const readClass = isUnRead ? "un-read" : "";
        const newBadge = isUnRead ? (<span className={"badge"}>New</span>) : null;

        return (<Link to={"/settings/feedback/" + item.id} key={"feedback-item-" + index} className={"message-item " + readClass}>
            <p>
                {newBadge}
                {item.message}
            </p>
            
            <div className={"message-item-footer"}>
                <span><ProfilePhoto user={user} size={24} /></span>
                <span className={"name"}><Link to={"/people/" + item.userId}>{item.user?.name || "No Name"}</Link></span>
                <span>{item.deviceTypeName} version <strong>{item.version}</strong></span>
                <span><DateTime value={item.created} time={true} /></span>
            </div>
        </Link>);
    }) : null 
    
    const isRefreshing = screenState.refresh === true;
    const refreshButton = isRefreshing ? 
        (<a>Refreshing</a>) :
        (<a onClick={refresh} className={"refresh"}>Refresh</a>);
    
    if (!isRefreshing && (elements?.length || 0) <= 0) {
        if (options?.empty === true) return (<></>);
        return (<EmptyBox>No Open Feedback Requests</EmptyBox>);
    }
    
    return (<div className={"feedback " + (className || "")}>
        <h2 className={"def-header flex"}>
            <span>Feedback Messages</span>
            <span className={"top-right"}>{refreshButton}</span>
        </h2>
        {elements}
    </div>);
};

export default FeedbackList;
