import React, {useEffect, useState} from 'react';
import AdminMasterScreen from "../AdminMasterScreen";
import ChallengeService from "../../components/challenges/services/ChallengeService";
import DateTime from "../../components/common/ui/DateTime";
import PreviewText from "../../components/common/ui/PreviewText";
import {Link} from "react-router-dom";
import AddNewButton from "../../components/common/ui/AddNewButton";
import {faShield} from "@fortawesome/free-solid-svg-icons";

const ChallengesScreen = (props) => {
    const {} = props;
    const [challenges, setChallenges] = useState(ChallengeService.instance.challenges || []); // ChallengeService.instance.challenges[queryChallengeId] || [
    
    const getChallengesAsync = async (force = false) => {
        if (Array.isArray(challenges) && challenges.length > 0 && force !== true)
            return challenges;
        
        const ch = await ChallengeService.instance.getChallengesAsync();
        
        if (Array.isArray(ch)) {
            setChallenges(ch);
            return ch;
        }
        
        return challenges;
    }
    
    const onSession = (sh) => {
        let _ = getChallengesAsync();
    };

    useEffect(() => {
        let _ = getChallengesAsync();
    }, []);
    
    const elements = Array.isArray(challenges) ? challenges.map((ch, idx) => {
        const link = "/challenges/" + ch.id;
        const className = ch.status === 0 ? "draft" : "";
        
        return (<tr className={className} key={"challenge-" + idx}>
            <td><Link to={link}>{ch.name?.toString()}</Link></td>
            <td className={"medium"}><Link to={link}><PreviewText maxLength={64}>{ch.description?.toString()}</PreviewText></Link></td>
            <td><DateTime value={ch.startDate} /></td>
            <td><DateTime value={ch.endDate} /></td>
        </tr>);
    }) : null;
    
    return (<AdminMasterScreen selection={"challenges"} title={"Challenges"}>
           <div className={"pad"}>
               <div>
                   <h2 className={"nav with-button"}>
                       <span>Challenges</span>
                       <AddNewButton icon={faShield} href={"/challenges/00-00/edit"}>New Challenge</AddNewButton>
                   </h2>
                   <p className={"p"}>Below are all the challenges issued; past, present, and future</p>
               </div>
               
               <div>
                   <table className={"table"}>
                       <thead>
                       <tr>
                           <th>Name</th>
                           <th>Preview</th>
                           <th>Starts</th>
                           <th>Ends</th>
                       </tr>
                       </thead>
                       
                       <tbody>{elements}</tbody>
                   </table>
               </div>
           </div>
        
        </AdminMasterScreen>);
};

export default ChallengesScreen;
