import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import UserService from "../../components/users/services/UserService";
import MediaService from "../../components/media/services/MediaService";
import Initials from "../../components/common/ui/Initials";
import PersonMenu from "../../components/users/ui/UserSubMenu";
import AdminMasterScreen from "../AdminMasterScreen";
import ModerationSubMenu from "../../components/moderation/ui/ModerationSubMenu";

const ModerationMasterScreen = (props) => {
    const { options: initialOptions, selection, children, onUser } = props;
    const [options, setOptions] = useState(initialOptions || { reactClassName: "PersonMasterScreen" });
    const [user, setUser] = useState(null);

    const onSession = (sh) => {
        const u = sh?.user || null;
        if (typeof onUser === "function") onUser(u);
        setUser(u);
    };

    useEffect(() => {
        //
    }, []);

    return (<AdminMasterScreen options={options} onSession={onSession} selection={"moderation"} title={"Community Moderation"}>
        <div className={"column-content person-details"}>
            <div className={"column"}><ModerationSubMenu selection={selection} /></div>
            <div className={"content"}>{children}</div>
        </div>
    </AdminMasterScreen>);

};

export default ModerationMasterScreen;
