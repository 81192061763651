import HttpService from "../../common/services/HttpService";
import MediaModel from "../models/MediaModel";
import FileUploadModel from "../../common/models/FileUploadModel";
import VideoModel from "../models/VideoModel";
import ImageModel from "../models/ImageModel";

class MediaService {
    static fullScreenClassName = "full-screen";
    static instance = new MediaService();
    
    constructor() {
        this.platformMediaItems = null;
    }
    
    async getPlatformMediaAsync(options) {
        const path = "/api/image?count=" + (options?.count || 120);
        const ed = options?.endDate;
        
        const items = await HttpService.instance.getWithDateRangeAsync(path, null, ed).then((response) => MediaModel.fromJsonArray(response.data));
        
        if (Array.isArray(items) && items.length > 0) {
            this.platformMediaItems = items;
            return items;
        }
        
        return null;
    }
    
    async getVideoAsync(videoId) {
        const path = "/api/video/" + videoId;
        return await HttpService.instance.getAsync(path).then((response) => new VideoModel(response.data));
    }

    async getImageAsync(imageId) {
        const path = "/api/image/" + imageId;
        return await HttpService.instance.getAsync(path).then((response) => new ImageModel(response.data));
    }
    
    showImage(imageUrl) {
        console.log("Showing Image: ", imageUrl);
        const container = document.getElementById("full-screen-image") || document.createElement("div");
        const isNew = !container.parentNode;

        if (isNew) {
            console.log("IsNew");
            const imageElement = document.createElement("img");
            imageElement.src = imageUrl;

            container.appendChild(imageElement);
            document.body.appendChild(container);

            container.addEventListener("click", (e) => {
                this.hideImage();
            });

        } else {
            console.log("Exists");
        }

        container.className = MediaService.fullScreenClassName;

        const id = setTimeout(() => {
            clearTimeout(id);
            container.className = MediaService.fullScreenClassName + " view";
        }, 1);
    };
    
    async getMediaCollectionAsync(entityId, entityType) {
        const path = "/api/collection/" + entityId + "/media-resource";
        const me = this;
        
        return await HttpService.instance.getAsync(path).then((response) => MediaModel.fromJsonArray(response.data));
    }

    async uploadMediaItemsAsync(fileModels, entityId, entityType) {
        const path = "/api/collection/" + entityType + "/media/" + entityId;
        const me = this;
        const files = fileModels.map((f) => f.file);

        return await HttpService.instance.uploadAsync(path, files, null).then((response) => {
            return MediaModel.fromJsonArray(response.data);
        });
    }
    
    async saveVideoAsync (videoJson, videoId) {
        if (typeof videoId !== "string") throw new Error("Invalid VideoId. Must be a string, or you can upload a video first.");
        const path = "/api/video/" + videoId;
        
        return await HttpService.instance.postAsync(path, videoJson).then((response) => {
            return new MediaModel(response.data);
        });
    }

    async saveImageAsync (imageJson, imageId) {
        if (typeof imageId !== "string") throw new Error("Invalid ImageId. Must be a string, or you can upload an image first.");
        const path = "/api/image/" + imageId;

        return await HttpService.instance.postAsync(path, imageJson).then((response) => {
            return new MediaModel(response.data);
        });
    }

    /**
     * Deletes a collection media item.
     * @param id {string} - CollectionItemId or Media EntityId. If collectionId is provided, this is the CollectionItemId.
     * @param collectionId {string|null|undefined} - If omitted, id will be treated as a collectionItemId. Otherwise, it will be treated as a media entity parent collectionId.
     * @param entityType {number|null|undefined} - Required if collectionId is passed
     * @returns {Promise<boolean>}
     */
    async deleteCollectionItemAsync(id, collectionId = null, entityType = 0) {
        const path = typeof collectionId !== "string" || !collectionId ? 
            "/api/collection-item/" + id :
            "/api/collection/" + collectionId + "/" + entityType + "/" + id;
        
        await HttpService.instance.deleteAsync(path);
        
        return true;
    }
    
    hideImage() {
        document.querySelectorAll(".full-screen").forEach((element) => {
            element.className = MediaService.fullScreenClassName;
            
            const id = setTimeout(() => {
                clearTimeout(id);
                element.parentNode.removeChild(element);
            }, 350);
        });
    };

    async uploadVideoThumbnailAsync(videoId, fileModel) {
        if (!fileModel?.file && !Array.isArray(fileModel)) {
            console.error("File model has no file");
            return null;
        }
        
        const path = "/api/video/" + videoId + "/thumb";
        const me = this;
        
        return await HttpService.instance.uploadAsync(path, [fileModel.file], null).then((response) => {
            return new MediaModel(response.data);
        });
    }
    
    async createThumbFromVideoElementAsync(videoElement, options) {
        const imageFileName = typeof options === "string" ? options : (options?.fileName || "video-thumb.png");

        if (!videoElement?.src) {
            console.warn("No video element while creating videoElement thumb... Creating videoElement manually.");

            if (!options?.filePath) {
                console.error("No file path when creating videoElement thumb when there is no videoElement.src");
                return null;
            }

            console.warn("Setting video source and event listeners to capture onLoadData and onError events.");

            videoElement = await new Promise((resolve, reject) => {
                const v = document.createElement("video");

                v.controls = true;
                v.muted = true;
                v.autoplay = false;
                v.loop = false;
                v.preload = "auto";
                v.playsInline = true;
                v.currentTime = v.duration / 2.0;
                
                v.onloadeddata = () => {
                    console.warn("Loaded Video Data for Thumbnail Creation: ", v.currentTime);
                    console.log("  > Duration: ", v.duration);
                    
                    const ix = setTimeout(() => {
                        clearTimeout(ix);
                        resolve(v);
                    }, 150);
                };
                
                v.onerror = (e) => {
                    console.error("Video Error: " + e.target.error);
                    const code = e?.target?.error?.code || 0;
                    console.log(" > Code: " + code);

                    resolve(v);
                }

                const z = !!options.filePath ? options.filePath.substring(0, 64) : "NO FILE PATH";
                console.warn("Video Element Source: " + z + "...");

                v.src = options.filePath || "";
            });

            if (!videoElement) {
                return null;
            }
        }

        const fileName = imageFileName;
        const canvasElement = document.createElement("canvas");
        const context = canvasElement.getContext("2d");
        
        const pos = options?.rect || this.calculateVideoPosition(videoElement, options?.width || 128, options?.height || 128);

        const setAttribute = (name, value) => {
            const att = document.createAttribute(name);
            att.value = value + "";
            canvasElement.setAttributeNode(att);
        }

        videoElement.currentTime = videoElement.duration / 2.0;
        
        setAttribute("width", pos.width);
        setAttribute("height", pos.height);
        
        context.fillStyle = "black";
        context.fillRect(0, 0, pos.width, pos.height);
        context.drawImage(videoElement, pos.x, pos.y, pos.width, pos.height);
        context.save();
        
        console.log("Duration: ", videoElement.duration);
        console.log(" > Current Time: ", videoElement.currentTime);
        console.log(" > Pos: ", pos);
        
        const thumbNailContentType = "image/png";
        let fileContent = null;
        
        try {
            fileContent = canvasElement.toDataURL(thumbNailContentType);
            console.log("Video File Content: " + (typeof fileContent));
        } catch (ex) {
            console.error("Could not create video thumb: ", ex);
            return null; //await new Promise((resolve, reject) => reject(ex));
        }

        const videoThumbFormFile = FileUploadModel.createFileFromFilePath(fileContent, fileName);
        console.log("Creating Video Thumb Successfully.");

        return await new Promise((resolve, reject) => {
            const _f = new FileUploadModel(videoThumbFormFile, fileName, (f) => {
                resolve(f);
            });
        });
    }

    calculateVideoPosition(videoElement, containerWidth = 128, containerHeight = 128) {
        const videoHeight = videoElement.videoHeight;
        const videoWidth = videoElement.videoWidth;

        if (!containerWidth) containerWidth = window.clientWidth;
        if (!containerHeight) containerHeight = window.clientHeight;

        // Calculate the height, width, x, and y of the video if we want it to behave like CSS object-fit: cover

        console.warn("Calculating Video Position...")
        console.log(" > Video Size: " + videoWidth + "x" + videoHeight);
        console.log(" > Container Size: " + containerWidth + "x" + containerHeight);

        const videoRatio = videoWidth / videoHeight;
        const containerRatio = containerWidth / containerHeight;

        console.log(" > VideoRatio: " + videoRatio);
        console.log(" > ContainerRatio: " + containerRatio);

        let width = containerWidth;
        let height = containerHeight;

        if (videoRatio > containerRatio) {
            width = containerHeight * videoRatio;
            height = containerHeight;
        } else {
            width = containerWidth;
            height = containerWidth / videoRatio;
        }

        const x = (containerWidth - width) / 2;
        const y = (containerHeight - height) / 2;

        return {
            x: Math.round(x),
            y: Math.round(y),
            width: Math.round(width),
            height: Math.round(height)
        };
    }

}

export default MediaService;
