import UserModel from "../../users/models/UserModel";

class ChallengeEntryModel {
    static entityType = 50;
    static create = (json) => new ChallengeEntryModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.challengeId = json.challenge_id || null;
        this.userId = json.user_id || null;
        this.imageId = json.image_id || null;
        this.description = json.description || null;
        this.user = new UserModel(json.user);
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            challenge_id: this.challengeId,
            user_id: this.userId,
            image_id: this.imageId,
            description: this.description,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.description?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => ChallengeEntryModel.create(json));
    }
}

export default ChallengeEntryModel;
