import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import ExerciseService from "../../components/workouts/services/ExerciseService";
import MediaUploader from "../../components/media/ui/MediaUploader";
import WorkoutsMasterScreen from "./WorkoutsMasterScreen";
import DefReset from "../../components/common/models/DefReset";
import GalleryUploader from "../../components/media/ui/GalleryUploader";
import ExerciseModel from "../../components/workouts/models/ExerciseModel";
import ExerciseForm from "../../components/workouts/ui/ExerciseForm";
import AuthenticationService from "../../components/authentication/services/AuthenticationService";

const ExerciseDetailsScreen = (props) => {
    const {} = props;
    const { exerciseId } = useParams();
    
    const initialValue = { 
        exercise: ExerciseService.instance.exerciseMap[exerciseId], 
        state: null,
        imageId: null,
        save: false,
    };
    
    const [screenState, setScreenState] = useState(initialValue);
    const exercise = screenState.exercise;

    const onSession = (session) => {
        const newState = {...screenState};
        
        console.log("On Session!");
        setScreenState(newState);
    };
    
    const canEdit = !!AuthenticationService.instance.session?.id;
    const formElement = canEdit ? (<ExerciseForm exerciseId={exerciseId} />) : null;
    
    return (<WorkoutsMasterScreen onSession={onSession} selection={"exercises"} title={"Exercises"}>
        <div className={"pad constrain"}>
            <h2>Exercise Details: {exercise?.name}</h2>
            {formElement}
            
            <br/><br/><br/>
            <pre>{JSON.stringify(exercise, null, 4)}</pre>
        </div>
    </WorkoutsMasterScreen>);
};

export default ExerciseDetailsScreen;
