import React, {useState} from 'react';
import {Link} from "react-router-dom";
import "./Workouts.css";
import ReactModal from "../../common/ui/react-modal/ReactModal";
import WorkoutForm from "./WorkoutForm";
import DefReset from "../../common/models/DefReset";
import WorkoutService from "../services/WorkoutService";

const WorkoutSubMenu = (props) => {
    const { selection, workout } = props;
    
    const classes = {};
    classes[selection || ""] = "selected";
    
    const basePath = workout?.parentWorkout?.id ? 
        "/workouts/" + workout.parentWorkout.id + "/workouts/" + workout.id :
        "/workouts/" + workout.id;
    
    const openFormAsync = async (e) => {
        const dialog = new ReactModal();
        const formTitle = "Edit: " + workout?.name;

        const options = {};
        const form = (<WorkoutForm defaultValue={workout} options={options} title={formTitle} />);

        const rsp = await dialog.openFormDialogAsync(form, async(e) => {
            //Week A: Rest and Recover
            DefReset.stopEvent(e);
            
            const json = typeof options.getData === "function" ?
                options.getData() :
                null;
            
            console.log("getData:");
            console.log(json);
            
            //await new Promise((resolve) => setTimeout(resolve, 1000));
            await WorkoutService.instance.saveWorkoutAsync(json, workout.id);

            return true;
        }, options);
        
        console.log("Form opened.");
        const element = document.getElementById("workout-name");
        if (typeof element?.focus === "function") element.focus();
    };

    return (<div className={"workout-menu"}>
        <ul className={"sub-menu"}>
            <li className={classes["details"] || ""}><Link to={basePath}>Details</Link></li>
            <li className={classes["exercises"] || ""}><Link to={basePath + "/exercises"}>Exercises</Link></li>
            <li className={classes["media"] || ""}><Link to={basePath + "/media"}>Media</Link></li>
            <li className={classes["edit"] || ""}><a onClick={openFormAsync}>Edit</a></li>
        </ul>
    </div>);
};

export default WorkoutSubMenu;