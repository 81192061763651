import HttpService from "../../common/services/HttpService";

class CollectionService {
    static instance = new CollectionService();
    
    constructor() {
        //
    }
    
    async updateSortingAsync(sortModels, collectionId = null) {
        const path = collectionId?.length > 30 ? "/api/collection/" + collectionId + "/sort" : "/api/collection/sort";
        if (!Array.isArray(sortModels)) throw new Error("sortModels is not an array.");
         // Clean Models
        
        sortModels = sortModels.map((m) => {
            if (!m.id) return null;
            if (typeof m.id !== "string" || m.length < 30) return null;
            if (m.id.length > 36) m.id = m.id.substring(m.id.length - 36);
            
            return { 
                id: m.id,
                sort: m.sort,
            };
        }).filter((mm) => !!mm);
        
        const items = await HttpService.instance.postAsync(path, sortModels).then((rsp) => rsp.data);
        
        console.log(items);
        
        return items;
    }

}

export default CollectionService;
