import React, {useEffect, useState} from 'react';
import LearnMasterScreen from "./LearnMasterScreen";
import AdminMasterScreen from "../AdminMasterScreen";
import SearchFilter from "../../components/common/ui/search-filter/SearchFilter";
import DateTime from "../../components/common/ui/DateTime";
import ArticleService from "../../components/learn/services/ArticleService";
import PreviewText from "../../components/common/ui/PreviewText";
import {Link} from "react-router-dom";

const ArticlesScreen = (props) => {
    const {} = props;
    const [articles, setArticles] = useState(ArticleService.instance.articles || []);
    
    const getArticlesAsync = async (force = false) => {
        const a = await ArticleService.instance.getArticlesAsync();
        if (Array.isArray(a)) {
            setArticles(a);
            return a;
        }
        
        return articles;
    }
    
    const onFilterChange = (term, items) => {
        setArticles(items);
    };
    
    const onSession = (session) => {
        let _ = getArticlesAsync();
    };

    useEffect(() => {
        let _ = getArticlesAsync();

    }, []);
    
    const elements = Array.isArray(articles) ? articles.map((article, i) => {
        let text = article.content || "";
        const link = "/learn/" + article.id + "";
        const className = article.status === 0 ? "draft" : "";
        
        return (<tr key={"article-" + i} className={className}>
            <td><Link to={link}>{article.title}</Link></td>
            <td><Link to={link}><span style={{paddingRight: "12px"}} className={"medium"}><PreviewText maxLength={64}>{text}</PreviewText></span></Link></td>
            <td><DateTime value={article.created} /></td>
        </tr>);
    }) : null;

    const addLink = (<span className={"add-new"}><Link to={"/learn/000"}>New Article</Link></span>);
    
    return (
        <AdminMasterScreen onSession={onSession} title={"Learn"} selection={"learn"}>
            <h3 className={"nav with-button"}>
                <span>Learn: Articles</span>
                {addLink}
            </h3>
            
            <div className={"filter-header"}>
                <SearchFilter placeholder={"Filter Articles"} items={ArticleService.instance.articles || []} fields={["title", "content"]} onChange={(items, term) => onFilterChange(items, term)} />
            </div>
            
            <div className={"body"}>
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Preview</th>
                        <th>Posted</th>
                    </tr>
                    </thead>
                    <tbody>{elements}</tbody>
                </table>
            </div>
        </AdminMasterScreen>
    );

};

export default ArticlesScreen;