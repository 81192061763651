import React from 'react';
import {Link} from "react-router-dom";
import Initials from "../../common/ui/Initials";
import MediaService from "../../media/services/MediaService";

const UserSubMenu = (props) => {
    const { user, selection } = props;
    const classes = {};
    classes[selection || "details"] = "selected";

    const showImage = (imageUrl) => {
        MediaService.instance.showImage(imageUrl);
    };

    const imageUrl = user?.imageUrl || user?.image?.url;
    const profileImage = !!imageUrl ? (<a onClick={(e) => showImage(imageUrl)}><img alt={"Profile Photo"} className={"profile size128"} src={imageUrl} /></a>) :
        (<Initials size={128}>{user?.name}</Initials>)
    
    return (<div className={"sub-menu"} id={"user-menu"}>
        <div className={"menu-heading"}>{ profileImage }</div>
        <ul className={"sub-menu"}>
            <li className={classes["details"] || ""}><Link to={"/people/" + user?.id}>Details</Link></li>
            <li className={classes["habits"] || ""}><Link to={"/people/" + user?.id + "/habits"}>Habits</Link></li>
            <li className={classes["media"] || ""}><Link to={"/people/" + user?.id + "/media"}>Media</Link></li>
            <li className={classes["challenges"] || ""}><Link to={"/people/" + user?.id + "/challenges"}>Challenges</Link></li>
            <li className={classes["edit"] || ""}><Link to={"/people/" + user?.id + "/edit"}>Edit</Link></li>
            <li className={classes["abuse"] || ""}><Link to={"/people/" + user?.id + "/abuse"}>Abuse</Link></li>
        </ul>
    </div>);
};

export default UserSubMenu;
