class ImageThumbModel {
    static entityType = 7;

    constructor(json) {
        if (!json) json = {};

        this.imageId = json.image_id || "";
        this.width = json.width || 0;
        this.height = json.height || 0;
        this.fileSize = json.file_size || 0;
        this.sizeTag = json.size_tag || "";
        this.url = json.url || "";
        this.status = json.status || 0;
        this.id = json.id || "";
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }

    static fromJsonArray(jsonArray) {
        if (!Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => new ImageThumbModel(json));
    }
}

export default ImageThumbModel;
