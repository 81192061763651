import React, {useEffect, useRef, useState} from 'react';
import FuelMasterScreen from "./FuelMasterScreen";
import MealService from "../../components/fuel/services/MealService";
import {useParams} from "react-router-dom";
import DefButton from "../../components/common/ui/elements/DefButton";
import MediaUploader from "../../components/media/ui/MediaUploader";
import {Navigate} from "react-router-dom";
import MealSubMenu from "../../components/fuel/ui/MealSubMenu";
import ReactModal from '../../components/common/ui/react-modal/ReactModal';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const MealDetailsScreen = (props) => {
    const {} = props;
    const { mealId } = useParams();
    const [meal, setMeal] = useState(MealService.instance.meals || []);
    const nameRef = useRef();
    const descriptionRef = useRef();
    const statusRef = useRef();
    const uploadKey = meal?.id || "new";

    const getMealAsync = async (force = false) => {
        if (!!meal?.id && meal.id.length > 30 && force !== true)
            return meal;

        const m = await MealService.instance.getMealAsync(mealId);

        if (!!m?.id) {
            console.log("Got meal good: ", m);
            setMeal(m);
            return m;
        }

        console.error("Failed to get meal: ", m);
        return meal;
    };
    
    const deleteMeal = async () => {
        if (!meal?.id) return;
        await MealService.instance.deleteMealAsync(meal.id);
        
        // Redirect route back to meals screen
        setMeal("/fuel/meals")
    };
    
    const saveMealAsync = async () => {
        const json = {
            name: nameRef.current.value,
            description: descriptionRef.current.value,
            image_id: await getImageId(),
            status: parseInt(statusRef.current?.value || "0") || 0,
        };
        
        const isNew = !meal?.id;
        const m = await MealService.instance.saveMealAsync(json, meal?.id);
        
        if (isNew) {
            setMeal("/fuel/meals/" + m.id);
            return;
        }
        
        MealService.instance.getMealsAsync();
        await getMealAsync(true);

        const dialog = new ReactModal();
        await dialog.openAlertDialogAsync("Meal saved successfully", { title: "#GOOD", iconClassName: "success", icon: faCheckCircle });

    };

    const onImageClicked = (fileModel, index) => {
        delete MediaUploader.options[uploadKey];
        //
        return true;
    };

    const onDrop = (fileModels) => {
        MediaUploader.options[uploadKey] = 1;
    };

    const getImageId = async () => {
        console.log("Getting Image Id...")
        while (!!MediaUploader.options[uploadKey]) {
            await new Promise((resolve) => setTimeout(resolve, 500));
            console.log("Waiting...");
        }
        
        console.log("Good to go.");
        return meal?.imageId || null;
    };
    
    const onImageUploaded = (mediaModels) => {
        const imageModels = mediaModels.filter((mediaItem) => mediaItem?.content_type?.startsWith("image/"));
        const videoModel = mediaModels.filter((mediaItem) => mediaItem?.content_type?.startsWith("video/"));

        if (imageModels.length > 0) {
            console.log("Good Image");
            
            const imageModel = imageModels[0];
            meal.image = imageModel;
            meal.imageId = imageModel.id;

            delete MediaUploader.options[uploadKey];
            
            setMeal(meal);
        }
    };
    
    useEffect(() => {
        let _ = getMealAsync(true);
    }, []);
    
    if (typeof meal === "string") {
        return <Navigate to={meal} />;
    }

    const emptyImageMessage =  (<span style={{fontSize: "12px"}}>Drag/Drop or<br/>Click Here<br/>Here to Upload<br/>A Thumbnail</span>);
    const isLoaded = !!meal?.id;
    const contentElement = null;

    const imageUrl = meal?.image?.url || null;
    const img = imageUrl ?
        (<img alt={"Article Thumb"} className={"image128"} src={imageUrl} />) :
        emptyImageMessage;
    
    const imageElement = isLoaded ? (<MediaUploader onClick={onImageClicked} onComplete={onImageUploaded} uploadUrl={"/api/media/upload"} className={"image-dropper"} count={1} size={128} onDrop={onDrop}>
        {img}
    </MediaUploader>) : null;

    const subMenu = !!meal?.id ? (<MealSubMenu meal={meal} />) : null;
    const statusElement = isLoaded ? (<select ref={statusRef} defaultValue={meal?.status}>
        <option value={"0"}>Draft</option>
        <option value={"1"}>Published</option>
    </select>) : null;
    
    return (<FuelMasterScreen selection={"meals"} title={meal?.name || "Meal Details"}>
        <div className={"pad"}>
            <h2 className={""}>
                <span>{meal?.name}</span>
                <span></span>
            </h2>

            {subMenu}
            
            <div className={"form"}>
                <div className={"flex"} style={{alignItems: "flex-start"}}>
                    <div className={"image top"} style={{marginTop: "32px"}}>{imageElement}</div>
                    <div className={"fields"} style={{flexGrow: "4"}}>
                        <div className={"form-group"}>
                            <label>Name/Title:</label>
                            <input ref={nameRef} id={"title"} type={"text"} defaultValue={meal?.name || ""}/>
                        </div>

                        <div className={"form-group"}>
                            <label>Content:</label>
                            <textarea ref={descriptionRef} className={"wide tall"} id={"content"}
                                      defaultValue={meal?.description || ""}></textarea>
                            <div className={"notes"}></div>
                        </div>

                        <div className={"form-buttons flex"}>
                            <DefButton onClick={saveMealAsync} style={{minWidth: "100px"}}
                                       className={"button"}>Save {meal?.status || 0}</DefButton>
                            <span style={{marginLeft: "0"}}>
                                {statusElement}
                            </span>
                            <span style={{marginLeft: "auto"}}>
                                <a onClick={deleteMeal}>Delete Meal</a>
                            </span>
                        </div>

                    </div>
                </div>


                <br/><br/>

                <pre className={"debug"}>{JSON.stringify(meal, null, 4)}</pre>
            </div>
        </div>

    </FuelMasterScreen>);

};

export default MealDetailsScreen;
