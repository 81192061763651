import React, {useEffect, useRef, useState} from 'react';
import DefReset from "../../common/models/DefReset";
import MediaUploader from "../../media/ui/MediaUploader";

const WorkoutForm = (props) => {
    const { onChange, value, id, options, title, defaultValue } = props;
    const mainImageId = useState("main-image-" + (id || "new"))[0];
    const [imageId, setImageId] = useState(defaultValue?.imageId);
    
    const nameRef = useRef();
    const descriptionRef = useRef();
    const durationRef = useRef();
    const durationUnitsRef = useRef();
    const workoutTypeRef = useRef();
    const statusRef = useRef();
    const parentWorkoutIdRef = useRef();

    const createJson = () => {
        return {
            name: nameRef.current?.value || null,
            description: descriptionRef.current?.value || null,
            workout_duration: parseInt(durationRef.current?.value || "") || 0,
            workout_duration_units: parseInt(durationUnitsRef.current?.value || "") || 0,
            workout_type: parseInt(workoutTypeRef.current?.value || "") || 0,
            status: parseInt(statusRef.current?.value || "0") || 0,
            image_id: imageId,
            parent_workout_id: id || defaultValue?.id || null,
        };
    };

    const onInputChange = (e, id) => {
        DefReset.stopEvent(e);
        const json = createJson();
        if (typeof onChange === "function") onChange(json, e);
    };

    const onMainPhoto = (fileModels) => {
        fileModels = fileModels.filter((m) => m.isVideo() === false);
        if (fileModels.length === 0) return;

        MediaUploader.options[mainImageId] = 1;
        //const fileModel = fileModels[0];
    };

    const onMainImageUploaded = (mediaModels) => {
        if (mediaModels.length === 0) return;
        setImageId(mediaModels[0]?.id)
    };
    
    const setController = () => {
        if (!!options && typeof options === "object") {
            options.getData = () => createJson();
        }
    };
    
    useEffect(() => {
        setController();
    }, [imageId]);
    
    useEffect(() => {
    }, []);
    
    const titleElement = typeof title === "string" ? (<h2>{title}</h2>) : null;
    const mainImageUploadUrl = "/api/admin/workout/" + defaultValue?.id + "/media?overwrite=1";
    const imageElement = (<MediaUploader onDrop={onMainPhoto} count={1} size={128} onComplete={onMainImageUploaded} uploadUrl={mainImageUploadUrl} className={"image-dropper"}>{ defaultValue?.image ?
        (<img src={defaultValue?.image?.url} className={"image128"} alt={""} />) :
        (<>Main Image<br/>Goes Here</>)}</MediaUploader>);
    
    return (<div className={"workout-form form"}>
            {titleElement}
            <div className={"small"}>{defaultValue?.id}</div>

        <div className={"flex"} style={{alignItems: "flex-start", flexGrow: "3"}}>
            <div style={{paddingTop: "16px", paddingRight: "8px"}}>{imageElement}</div>
            <div style={{width: "100%"}}>
                <div className={"form-group"}>
                    <label htmlFor={"workout-name"}>Name:</label>
                    <input defaultValue={defaultValue?.name} type={"text"} id={"workout-name"} name={"workout-name"} ref={nameRef} onChange={(e) => onInputChange(e, "workout-name")} />
                </div>

                <div className={"form-group"}>
                    <label htmlFor={"workout-duration"}>Duration:</label>
                    <input defaultValue={defaultValue?.duration} type={"text"} id={"workout-duration"} name={"workout-duration"} ref={durationRef} onChange={(e) => onInputChange(e, "workout-duration")} />
                </div>
            </div>
        </div>
        
            <div className={"form-group"}>
                <label htmlFor={"workout-type"}>Workout Type:</label>
                <select style={{width: "100%"}} defaultValue={defaultValue?.workoutType} id={"workout-type"} name={"workout-type"} ref={workoutTypeRef} onChange={(e) => onInputChange(e, "workout-type")}>
                    <option value={0}>General</option>
                    <option value={1}>Strength</option>
                    <option value={2}>Cardio</option>
                    <option value={4}>Rest or Recover</option>
                    <option value={3}>Other</option>
                </select>
            </div>

            <div className={"form-group"}>
                <label htmlFor={"description"}>Description:</label>
                <textarea defaultValue={defaultValue?.description} id={"description"} onChange={(e) => onInputChange(e, "description")} name={"description"} ref={descriptionRef}></textarea>
            </div>
        
        </div>);
};

export default WorkoutForm;

