import React from 'react';
import ProfilePhoto from "../../../users/ui/ProfilePhoto";
import {Link, useParams} from "react-router-dom";
import EntityLink from "../../../common/ui/EntityLink";
import DateTime from "../../../common/ui/DateTime";
import DefReset from "../../../common/models/DefReset";

const CommentLineItem = (props) => {
    const { comment, hasLink, options, onClick, onChange, entityId } = props;
    const text = comment.text || "";
    const user = comment.user;

    const onMoreCommentsClick = (e) => {
        DefReset.stopEvent(e);
        
        if (typeof onChange === "function")
            onChange(e);
    };
    
    const onCommentClick = (e) => {
        DefReset.stopEvent(e);
        
        if (typeof onClick === "function")
            onClick(comment, e);
    };
    
    const entityLink = hasLink !== false && typeof comment?.entityTypeName === "string" ?
        (<span className={"badge-link"}><EntityLink userId={comment.userId} value={comment}>{comment.entityTypeName}</EntityLink></span>) :
        null;
    
    const moreCommentsLink = hasLink !== false && (!entityId || entityId !== comment.entityId) && typeof comment?.entityTypeName === "string" ?
        (<span style={{marginLeft: "12px"}}><a onClick={onMoreCommentsClick}>More {comment.entityTypeName} Comments</a></span>) :
        null; 
    
    return (<div className={"message-item " + ""}>
        <p onClick={onCommentClick}>
            <span className={"delete"}><a onClick={(e) => null}>Delete</a></span>
            {text}
        </p>

        <div className={"message-item-footer"}>
            <span><ProfilePhoto user={user} size={24} /></span>
            <span className={"name"}><Link to={"/people/" + comment.userId}>{user?.name || "No Name"}</Link></span>
            {entityLink}
            {moreCommentsLink}
            <span><DateTime useRelative={options?.useRelative === true} value={comment.created} time={true} /></span>
        </div>
    </div>);};

export default CommentLineItem;
