import React, { useState, useRef, useEffect } from 'react';
import AdminMasterScreen from "../AdminMasterScreen";
import {useParams} from "react-router-dom";
import UserService from "../../components/users/services/UserService";
import PersonMenu from "../../components/users/ui/UserSubMenu";
import PersonMasterScreen from "./UserMasterScreen";
import DateTime from "../../components/common/ui/DateTime";
import UserDashboard from "../../components/users/ui/UserDashboard";
import UserScreenViews from "../../components/users/ui/UserScreenViews";

const PersonDetailsScreen = (props) => {
    const {} = props;
    const { userId } = useParams();
    const [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    const [options, setOptions] = useState({ reactClassName: "PersonDetailsScreen" });

    const setControllers = () => {
        // const screenId = "person-details";
        // AuthenticationService.instance.addEventListener("auth", (session) => {
        //     getUserAsync();
        // }, screenId);
        // return async () => {
        //     console.warn("Removing Details Event Listener");
        //     AuthenticationService.instance.removeEventListener("auth", screenId);
        // };
    };
    
    const onUser = (u) => {
        setUser(u);
    };

    useEffect(() => {
        if (!!user?.id && typeof options?.setTitle == "function")
            options.setTitle(user?.name);
        
    }, [user]);

    useEffect(() => {
        const id = Math.round(Math.random() * 999999).toString();
        setControllers();
    }, []);
    
    const pageTitle = user?.name || "Loading User...";
    const memberSince = !!user?.created ? <DateTime date={user?.created} /> : null;
    const subTitleElement = !!user?.name ? (<h2>{user.name} Details</h2>) : (<h2>Loading Details...</h2>);
    
    return (<PersonMasterScreen options={options} onUser={onUser} userId={userId}>
        <div className={"pad constrain"}>
            {subTitleElement}
            
            <ul>
                <li>Member Since: {memberSince}</li>
            </ul>

            <UserDashboard userId={userId} />
            
            <div className={"top32"}>
                <UserScreenViews userId={userId} />
            </div>
            
        </div>
    </PersonMasterScreen>);

};

export default PersonDetailsScreen;