import React, {useEffect, useRef, useState} from 'react';
import WorkoutMasterScreen from "./WorkoutMasterScreen";
import ExercisesList from "../../components/workouts/ui/ExercisesList";
import WorkoutService from "../../components/workouts/services/WorkoutService";
import {Link, useParams} from "react-router-dom";
import Initials from "../../components/common/ui/Initials";
import DefReset from "../../components/common/models/DefReset";
import WorkoutExerciseModel from "../../components/workouts/models/WorkoutExerciseModel";
import EmptyBox from "../../components/common/ui/EmptyBox";
import ReactModal from "../../components/common/ui/react-modal/ReactModal";
import ExerciseForm from "../../components/workouts/ui/ExerciseForm";
import ExerciseService from "../../components/workouts/services/ExerciseService";
import WorkoutExerciseForm from "../../components/workouts/ui/WorkoutExerciseForm";
import AddNewButton from "../../components/common/ui/AddNewButton";
import {faWalking} from "@fortawesome/free-solid-svg-icons";

const WorkoutExercisesScreen = (props) => {
    
    const {} = props;
    const {parentWorkoutId, workoutId} = useParams();
    const [exerciseListOptions, setExerciseListOptions] = useState({});
    const screenOptions = useState({})[0];
    const [workoutData, setWorkoutData] = useState({ workout: WorkoutService.instance.workoutMap[workoutId || ""], parentWorkout: WorkoutService.instance.workoutMap[parentWorkoutId || ""]});
    const [editingId, setEditingId] = useState(null);

    const workoutExerciseNameRef = useRef();
    const workoutExerciseDescriptionRef = useRef();
    
    const getExerciseIds = () => workoutData.workout?.exercises?.map((e) => e.exerciseId) ?? [];
    const excludedIds = getExerciseIds();

    const onWorkouts = (w, p) => {
        w.parentWorkout = p;
        setWorkoutData({ workout: w, parentWorkout: p });
    };
    
    const onItemClick = (exercise, actionIndex) => {
        const we = WorkoutExerciseModel.fromExercise(exercise, workoutId);
        console.log(JSON.stringify(we, null, 4));
        
        workoutData?.workout.exercises.push(we);
        const ids = workoutData.workout.exercises.map((e) => e.exerciseId);
        
        setWorkoutData({ ...workoutData });
        
        return ids;
    };
    
    const onExerciseClick = (exercise, index, e) => {
        DefReset.stopEvent(e);
        console.log("onExerciseClick: ", index);
    };

    const removeExercise = (exercise, index, e) => {
        DefReset.stopEvent(e);
        
        if (!workoutData?.workout?.exercises) {
            console.error("No workout [" + index + "]");
            return;
        }
        
        const item = workoutData?.workout?.exercises[index];
        let _ = WorkoutService.instance.deleteWorkoutExerciseAsync(item.id);
        
        console.log("Pre:  Exercise Count [" + index + "]: ", workoutData.workout.exercises.length);
        workoutData.workout.exercises.splice(index, 1);
        console.log("Post:  Exercise Count [" + index + "]: ", workoutData.workout.exercises.length);
        
        setWorkoutData({ workout: workoutData.workout, parentWorkout: workoutData.parentWorkout });
    };

    const filterIds = (items, term) => {
        const ids = workoutData?.workout?.exercises?.map((e) => e.exerciseId) || [];
        
        if (ids?.length === 0) return items;

        return items.filter((item) => !ids.includes(item.id));
    };

    const saveWorkoutExercisesAsync = async (e) => {
        DefReset.stopEvent(e);
    };
    
    const saveWorkoutExerciseAsync = async (workoutExercise, e) => {
        DefReset.stopEvent(e);
        
        if (!workoutExercise) return;

        DefReset.setEnabled("workout-exercise-description", false);
        DefReset.setEnabled("workout-exercise-save-button", false);
        
        workoutExercise.notes = workoutExerciseDescriptionRef.current?.value || "";
        const json = workoutExercise.toJson();
        
        await WorkoutService.instance.saveWorkoutExerciseAsync(json, workoutExercise.id);
        await WorkoutService.instance.getWorkoutAsync(workoutId);
        
        DefReset.setEnabled("workout-exercise-save-button", true);
        DefReset.setEnabled("workout-exercise-description", true);
        
        setEditingId(null);
    };

    const openWorkoutExerciseFormAsync = async (e, workoutExerciseModel) => {
        DefReset.stopEvent(e);

        const buttonId = "workout-exercise-dialog-button";
        const options = { className: "exercise-form-dialog", buttonId: buttonId };
        const formOptions = { button: false, buttonId: buttonId };
        const formElement = (<WorkoutExerciseForm defaultValue={workoutExerciseModel} options={formOptions} />);
        const dialog = new ReactModal();
        const isNew = !workoutExerciseModel?.id || workoutExerciseModel.id.length < 30;

        await dialog.openFormDialogAsync(formElement, async (e) => {
            const json = formOptions.getData();

            if (isNew) {
                //
            } else {
                console.log("Updating Workout Exercise: ", json.name);
                console.log(" > ImageId: ", json.image_id);
                console.log(json);

                await WorkoutService.instance.saveWorkoutExerciseAsync(json, workoutExerciseModel.id);
            }

            await WorkoutService.instance.getWorkoutAsync(workoutId);

            if (typeof screenOptions.refresh === "function")
                await screenOptions.refresh(true);

            return true;
        }, options);
    };

    const showExerciseForm = async (e, exerciseModel = null) => {
        DefReset.stopEvent(e);
        
        const buttonId = "exercise-dialog-button";
        const options = { className: "exercise-form-dialog", buttonId: buttonId };
        const formOptions = { button: false, buttonId: buttonId };
        const formElement = (<ExerciseForm defaultValue={exerciseModel} options={formOptions} exerciseId={"000"} />);
        const dialog = new ReactModal();
        const isNew = !exerciseModel?.id || exerciseModel.id.length < 30;
        
        await dialog.openFormDialogAsync(formElement, async (e) => {
            const json = formOptions.getData();
            
            if (isNew) {
                console.log("Creating New Exercise: ", json.name);
                await WorkoutService.instance.createExerciseWithWorkoutAsync(workoutId, json);
            } else {
                console.log("Updating Exercise: ", json.name);
                console.log(" > ImageId: ", json.image_id);
                console.log(json);
                
                await ExerciseService.instance.saveExerciseAsync(json, exerciseModel.id);
            }
            
            await WorkoutService.instance.getWorkoutAsync(workoutId);
            
            if (typeof screenOptions.refresh === "function")
                await screenOptions.refresh(true);
            
            return true;
        }, options);
    };
    
    useEffect(() => {
        if (!!editingId) {
            setTimeout(() => {
                const element = document.getElementById("edit-" + editingId);
                if (typeof element?.focus) element.focus();
            }, 10);
        }

    }, [editingId]);

    useEffect(() => {
        console.log("Workout Exercise Count: ", workoutData?.workout?.exercises?.length);
        const ids = getExerciseIds();
        console.log("Workout ExerciseId Count: ", ids?.length);

    }, [workoutData]);
    
    useEffect(() => {
        if (typeof exerciseListOptions.filter !== "function") {
            const options = { filter: (items, term) => filterIds(items, term) };
            setExerciseListOptions(options);
        }
    }, []);

    const workout = workoutData?.workout;
    const hasSubWorkouts = (workout?.workouts?.length || 0) > 0;
    
    const elements = !hasSubWorkouts ? workout?.exercises?.map((exercise, index) => {
        const imageElement = exercise.imageUrl ?
            (<img src={exercise.imageUrl} alt={exercise.name} />) :
            (<Initials borderRadius={8}>{(exercise.name || "E")}</Initials>);

        const id = exercise.id || exercise.exerciseId || "X";
        
        const formElement = !!editingId && editingId === id ?
            (<div className={"form"} onClick={(e) => setEditingId("")}>
                <div className={"form-group"}>
                    <label>Description</label>
                    <input id={"edit-" + id} onClick={(e) => DefReset.stopEvent(e)} style={{marginRight: "24px"}} type={"text"} ref={workoutExerciseDescriptionRef} defaultValue={exercise.description || exercise.exercise?.description} />
                </div>
            </div>) :
            null;

        if (!!formElement) {
            return (<tr key={exercise.id || "new-exercise-" + index + ""} className={"clickable"}>
                <td className={"image"}>{imageElement}</td>
                <td>{formElement}</td>
                <td style={{paddingLeft: "12px", paddingTop: "14px"}}>
                    <button id={"workout-exercise-save-button"} onClick={(e) => saveWorkoutExerciseAsync(exercise, e)}>Update</button>
                    <span style={{paddingLeft: "24px"}} onClick={(e) => {
                        DefReset.stopEvent(e);
                        setEditingId("");
                    }}>Cancel</span>
                    <a title={exercise.exerciseId} onClick={(e) => showExerciseForm(e, exercise.exercise)} style={{paddingLeft: "24px"}}>Edit</a>
                </td>
            </tr>);
        }
        
        return (<tr key={exercise.id || "new-exercise-" + index + ""} className={"clickable"} onClick={(e) => openWorkoutExerciseFormAsync(e, exercise)}>
            <td className={"image"}>{imageElement}</td>
            <td>
                <h3>{exercise.name} (Edit)</h3>
                <p>{exercise.notes || exercise.exercise?.description || "No description available"}</p>
            </td>
            <td><a onClick={(e) => removeExercise(exercise, index, e)}>Remove</a></td>
        </tr>);
    }) : (<div>Nothing</div>);
    
    const navWorkoutElements = !!workout?.parentWorkout?.id ?
        (<><Link to={"/workouts/" + workout.parentWorkout.id}>{workout.parentWorkout?.name}</Link> <span className={"bullet"}>&#10095;</span> </>) :
        null;
    
    const panelActions = [
        { name: "Add", className: "add-button" },
    ];

    let isDirty = Array.isArray(workoutData?.workout?.exercises) &&
        (workoutData?.workout?.exercises?.filter((ex) => !ex?.id).length > 0 || workoutData.workout.exerciseCount !== workoutData.workout.exercises.length);
    
    const exerciseList = !!workout?.id && typeof exerciseListOptions.filter === "function" ?
        (<div className={"active large-list"}><ExercisesList options={exerciseListOptions} onClick={onItemClick} onActionClick={onItemClick} excludeIds={excludedIds} actions={panelActions} /></div>) :
        null;
    
    const currentExercisesTable = elements?.length > 0 ? (<table className={"exercises-table"} width={"100%"}>
            <tbody>
            {elements}
            </tbody>
        </table>) : (<div><EmptyBox>No Exercises Associated with this Workout</EmptyBox></div>);

    const publishButton = (<button disabled={!isDirty} onClick={(e) => saveWorkoutExercisesAsync(null, e)}>Publish Exercises</button>);
    
    return (<WorkoutMasterScreen onWorkout={onWorkouts} options={screenOptions} selection={"exercises"}>
        <div className={"pad"}>
            <h3 className={"nav"}>
                {navWorkoutElements}
                <span>{workout?.name}</span>
            </h3>
            
            <br/>
            
            <h2 className={"h with-button"}>
                <span>Exercises</span>
                <AddNewButton icon={faWalking} onClick={(e) => showExerciseForm(e, null)}>Add Exercise</AddNewButton>
            </h2>
            
            {exerciseList}
            {currentExercisesTable}
            
            <pre>
                WorkoutExerciseScreen ExcludedIds:<br/>
                {getExerciseIds()?.map((x, i) => (<span key={"x-" + i}>{x}<br/></span>))}
            </pre>
            
            <br/><br/>
            <pre>
                {JSON.stringify(workoutData.workout, null, 4)}
            </pre>
        </div>
    </WorkoutMasterScreen>);
};

export default WorkoutExercisesScreen;
