import React, {useEffect, useRef, useState} from 'react';
import FuelMasterScreen from "./FuelMasterScreen";
import FoodService from "../../components/fuel/services/FoodService";
import {Navigate, useParams} from "react-router-dom";
import MealService from "../../components/fuel/services/MealService";
import MediaUploader from "../../components/media/ui/MediaUploader";
import DefButton from "../../components/common/ui/elements/DefButton";
import FoodModel from "../../components/fuel/models/FoodModel";

const FoodDetailsScreen = (props) => {
    const {} = props;
    const { foodId } = useParams();
    const [food, setFood] = useState(null);
    const nameRef = useRef();
    const descriptionRef = useRef();
    const uploadKey = foodId || "new";

    const getFoodAsync = async (force = false) => {
        if (!!food?.id && food.id.length > 30 && force !== true)
            return food;

        let id = (typeof food === "string") ? food : foodId;
        const tokens = id.split("/");
        if (tokens.length > 1) id = tokens[tokens.length - 1];
        
        const m = await FoodService.instance.getFoodAsync(id);

        if (!!m?.id) {
            console.log("Got food good: ", m);
            setFood(m);
            return m;
        }

        console.error("Failed to get food: ", id);
        
        return food;
    };

    const deleteFood = async () => {
        if (!food?.id) return;
        await FoodService.instance.deleteFoodAsync(food.id);
        await FoodService.instance.getFoodsAsync();

        // Redirect route back to foods screen
        setFood("/fuel/foods")
    };

    const saveFoodAsync = async () => {
        const json = {
            name: nameRef.current.value,
            description: descriptionRef.current.value,
            image_id: await getImageId(),
        };

        const isNew = !food?.id;
        const m = await FoodService.instance.saveFoodAsync(json, food?.id);

        await Promise.all([
            FoodService.instance.getFoodsAsync(),
            getFoodAsync(true),
        ]);
        
        if (isNew) {
            setFood("/fuel/foods/" + m.id);
        }
    };

    const onImageClicked = (fileModel, index) => {
        delete MediaUploader.options[uploadKey];
        //
        return true;
    };

    const onDrop = (fileModels) => {
        MediaUploader.options[uploadKey] = 1;
        console.warn("Dropped: ", fileModels);
        console.log("Upload key:", uploadKey);
    };

    const getImageId = async () => {
        const imageId = await MediaUploader.getImageIdAsync(uploadKey);
        return imageId || food?.imageId || null;
    };

    const onImageUploaded = (mediaModels) => {
        const imageModels = mediaModels.filter((mediaItem) => mediaItem?.content_type?.startsWith("image/"));
        const videoModel = mediaModels.filter((mediaItem) => mediaItem?.content_type?.startsWith("video/"));

        if (imageModels.length > 0) {
            console.log("Good Image");

            const imageModel = imageModels[0];
            
            if (!!food) {
                food.image = imageModel;
                food.imageId = imageModel.id;
            }

            MediaUploader.options[uploadKey] = imageModel;

            setFood(food ?? new FoodModel({ image: imageModel, image_id: imageModel.id }));
        } else {
            alert("No Good.");
        }
    };

    useEffect(() => {
        
        let _ = getFoodAsync(foodId !== food?.id);
    }, [food]);
    
    useEffect(() => {
        let _ = getFoodAsync(true);
    }, []);

    if (typeof food === "string") {
        return <Navigate to={food} />;
    }

    const emptyImageMessage =  (<span style={{fontSize: "12px"}}>Drag/Drop or<br/>Click Here<br/>Here to Upload<br/>A Thumbnail</span>);
    const isLoaded = !!food?.id;
    const contentElement = null;

    const imageUrl = food?.image?.url || null;
    const img = imageUrl ?
        (<img alt={"Food Thumb"} className={"image128"} src={imageUrl} />) :
        emptyImageMessage;

    const imageElement = isLoaded || uploadKey === "new" ? (<MediaUploader
        onClick={onImageClicked}
        onComplete={onImageUploaded} 
        uploadUrl={"/api/media/upload"}
        className={"image-dropper"} 
        count={1} 
        size={128} 
        id={"food-details"}
        onDrop={onDrop}>{img}</MediaUploader>) : null;

    return (<FuelMasterScreen selection={"foods"} title={food?.name || "Food Details"}>
        <div className={"pad"}>
            <h2 className={""}>
                <span>{food?.name}</span>
                <span>
                    
                </span>
            </h2>

            <div className={"form"}>
                <div className={"flex"} style={{alignItems: "flex-start"}}>
                    <div className={"image top"} style={{marginTop: "32px"}}>{imageElement}</div>
                    <div className={"fields"} style={{flexGrow: "4"}}>
                        <div className={"form-group"}>
                            <label>Name/Title:</label>
                            <input ref={nameRef} id={"title"} type={"text"} defaultValue={food?.name || ""}/>
                        </div>

                        <div className={"form-group"}>
                            <label>Content:</label>
                            <textarea ref={descriptionRef} className={"wide tall"} id={"content"}
                                      defaultValue={food?.description || ""}></textarea>
                            <div className={"notes"}></div>
                        </div>

                        <div className={"form-buttons flex"}>
                            <DefButton onClick={saveFoodAsync} style={{minWidth: "100px"}}
                                       className={"button"}>Save</DefButton>
                            <span style={{marginLeft: "auto"}}>
                        <a onClick={deleteFood}>Delete Food</a>
                    </span>
                        </div>

                    </div>
                </div>


                <br/><br/>

                <pre>{JSON.stringify(food, null, 4)}</pre>
            </div>
        </div>

    </FuelMasterScreen>);



};

export default FoodDetailsScreen;
