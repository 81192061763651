class GraphItemModel {
    constructor(json) {
        if (!json) json = {};
        this.value = json.value || 0;
        this.label = json.label || 0;
        this.date = new Date(json.date || 0);
        this.format = json.format || null;
        this.json = json;
    }
    
    static fromJsonArray(jsonArray) {
        if (!Array.isArray(jsonArray)) return [];
        return jsonArray.map((json) => new GraphItemModel(json));
    }
}

export default GraphItemModel;
