import UserModel from "../../users/models/UserModel";

class MessageSendLogModel {
    static entityType = 51;
    static create = (json) => new MessageSendLogModel(json);
    
    constructor(json) {
        if (!json) json = {};
        
        this.id = json.id || null;
        
        this.userId = json.user_id || null;
        this.messageType = json.message_type || 0;
        this.recipient = json.recipient || null;
        this.message = json.message || null;
        this.success = json.success || null;
        this.user = new UserModel(json.user);
        
        this.created = !!json.created ? new Date(json.created) : null;
        this.modified = !!json.modified ? new Date(json.modified) : null;
    }
    
    toJson() {
        return {
            user_id: this.userId,
            message_type: this.messageType,
            recipient: this.recipient,
            message: this.message,
            success: this.success,
        };
    }

    searchFor(term) { 
        if (typeof term === "number") term = term.toString();
        if (!term) return true;
        
        term = term.toLowerCase();
        
        if (this.recipient?.toString().toLowerCase().indexOf(term) >= 0) return true;
        if (this.message?.toString().toLowerCase().indexOf(term) >= 0) return true;
        
        return (term === this.id?.toLowerCase());
    }
    
    static fromJsonArray(jsonArray) {
        if (!jsonArray || !Array.isArray(jsonArray)) return [];
        return jsonArray.map(json => MessageSendLogModel.create(json));
    }
}

export default MessageSendLogModel;
