// JockoFuel.DefResetApi/my-app/src/components/common/ui
import React, {useEffect, useRef} from 'react';
import './SearchFilter.css';
import DefReset from "../../models/DefReset";

const SearchFilter = (props) => {
    const { items, onChange, onClick, onFieldClick, className, onFocusChange, placeholder, onEmpty, idField, fields, options } = props;

    const termRef = useRef();
    const hasItems = Array.isArray(items) && items.length > 0;
    const searchFields = Array.isArray(fields) && fields.length > 0 ? fields : ["name", "description", "title", "firstName", "lastName", "phone", "email"];
    const hasSearchButton = typeof onClick === "function";
    
    const filterFor = (term, item) => {
        if (!term || term.length < 3 || !item) {
            return true;
        }
        
        if (typeof item?.searchFor === "function") return item.searchFor(term);
        
        for(let i = 0; i < searchFields.length; i++) {
            const fieldName = searchFields[i];
            const v = (typeof fieldName === "function") ? fieldName(item, i)?.toString().toLowerCase() : null;
            const value = v || (item[fieldName]?.toString() ?? "").toLowerCase();
            
            if (typeof value?.indexOf !== "function") {
                console.error(typeof value);
                console.error(value);
                return false;
            }
            
            if (value.indexOf(term) >= 0) return true;
        }
        
        return false;
    };
    
    const onKeyPressed = (e) => {
        // Capture <Enter> key

        let _;
        const keyCode = e.keyCode || e.which;
        if (keyCode === 13) _ = onSearch(e);
    };
    
    const onSearch = async (e) => {
        DefReset.stopEvent(e);
        
        const term = termRef.current?.value || "";
        const x = onClick(term);
        const isPromise = (typeof x?.then === "function");
        const rsp = isPromise ? await x : x;
        
        if (rsp === false) return;
        if (Array.isArray(rsp)) onChange(term, rsp);
    }
    
    const onBlur = (e) => {
        DefReset.stopEvent(e);
        
        const term = e?.target?.value;
        console.log("Blur Good: " + term);
        
        const rsp = (typeof onFocusChange === "function") ? onFocusChange(false, e) : true;
        if (rsp === false) return;

        onType(e);
    };
    
    const onInputFieldClick = (e) => {
        if (typeof onFieldClick === "function")
            onFieldClick(e);
    };
    
    const onFocus = (e) => {
        DefReset.stopEvent(e);
        const term = e?.target?.value;

        console.log("Focus Good: " + term);
        
        const rsp = (typeof onFocusChange === "function") ? onFocusChange(true, e) : true;
        if (rsp === false) return;
        
        onType(e);
    };
    
    const onType = (e, term = null) => {
        //DefReset.stopEvent(e);
        if (typeof onChange !== "function") {
            console.warn("No onChange callback found for filter.");
            return;
        }
        
        term ??= (e?.target.value || termRef?.current.value || "").toLowerCase();
        
        if (!!termRef.current) termRef.current.value = term;
        setPersistValue(term);
        
        if (!hasItems) {
            console.error("NO ITEMS!");
            return onChange(term, []);
        }
        
        const filteredItems = items.filter(item => filterFor(term, item) === true);

        const isEmptyCallback = typeof onEmpty === "function" && filteredItems.length === 0;
        const rsp = isEmptyCallback ? onEmpty(term) : null;
        
        if (rsp !== false) onChange(term, filteredItems);
    };
    
    const hasFocus = () => document.activeElement === termRef.current;
    
    const setPersistValue = (value) => {
        if (typeof options?.persistKey === "string" && !!options.persistKey) {
            SearchFilter.persisted[options.persistKey] = value;
        }
    };
    
    const setControls = () => {
        if (!options || typeof options !== "object")
            return;

        if (!!termRef.current && typeof options.persistKey === "string" && !!options.persistKey) {
            termRef.current.value = SearchFilter.persisted[options.persistKey] || "";
            
            if (!!termRef.current.value) {
                const id = setTimeout(() => {
                    clearTimeout(id);
                    termRef.current.click();
                }, 10);
            }
        }
        
        options.updateExclude = (ids) => {
            options.exclude = ids;
            termRef.current?.focus();
        };
        
        options.setText = (text = null) => {
            if (!termRef.current) return false;
            termRef.current.value = text || "";
            return true;
        };
        
        options.hasFocus = () => hasFocus();
    };
    
    const searchButton = hasSearchButton ? (<button onClick={onSearch}>Search</button>) : null;

    useEffect(() => {
        setControls();
    }, []);
    
    const cn = typeof className === "string" ? className : "search-filter";
    
    return (<div className={"filter-panel"}>
        <span><input 
            defaultValue={options?.value || ""}
            type={"text"} 
            className={cn}
            autoComplete={"off"}
            onKeyUpCapture={onKeyPressed} 
            placeholder={typeof placeholder === "string" ? placeholder : "Filter"}
            id={"filter-term"}
            ref={termRef}
            onClick={onInputFieldClick}
            onFocus={onFocus} 
            onBlur={onBlur} 
            onChange={onType} /></span>
        <span>{searchButton}</span>
    </div>);

};

SearchFilter.persisted = {};

export default SearchFilter;
