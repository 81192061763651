import React, {useEffect, useState} from 'react';
import MediaUploader from "./MediaUploader";
import FileUploadModel from "../../common/models/FileUploadModel";
import MediaService from "../services/MediaService";
import MediaGallery from "./MediaGallery";
import DefReset from "../../common/models/DefReset";
import MediaForm from "./MediaForm";
import ReactModal from "../../common/ui/react-modal/ReactModal";

/***
* Gallery Uploader: Upload media to a Collection and displays thumbnails (or line items) of the media (photos AND video)
* Basically a MediaGallery with a DropTarget that triggers an upload to a given collection
* @param props
 */
const GalleryUploader = (props) => {
    const { options, entityType, id, entityId, defaultValue, children, className, onClick } = props;
    const elementId = useState(typeof id === "string" && id.length > 0 ? id : "gallery-uploader-" + (Math.random() * 999999).toString().toLowerCase())[0];
    const [viewState, setViewState] = useState({
        mediaItems: defaultValue || [],
        fileModels: [],
    });
    
    const onDrop = (fileModels, a, b) => {
        console.log("Files Dropped: ", fileModels);
        
        const newState = {...viewState};
        newState.fileModels ??= []
        newState.fileModels = [...newState.fileModels, ...fileModels];
        
        setViewState(newState);
    };
    
    const onPaste = (fileModels, b, c) => {
        console.log("Pasted: ", fileModels);

        const newState = {...viewState};
        newState.fileModels ??= []
        newState.fileModels = [...newState.fileModels, ...fileModels];

        setViewState(newState);
    };
    
    const getMediaItemsAsync = async (force = false) => {
        if (force !== true && viewState.mediaItems.length > 0) {
            console.warn("Already has media items.");
            return viewState.mediaItems;
        }
        
        console.log("Getting Media Collection (" + entityType + ", Id: " + entityId + ")...");
        
        const mediaItems = await MediaService.instance.getMediaCollectionAsync(entityId, entityType);
        console.log(" > Got Collection: ", mediaItems);
        
        if (Array.isArray(mediaItems)) {
            const newState = {...viewState};
            newState.mediaItems = mediaItems;
            
            setViewState(newState);
            
            return mediaItems;
        }
        
        return null;
    };
    
    const showMediaEditorFormAsync = async (mediaItem, e) => {
        DefReset.stopEvent(e);

        const options = {};
        const mediaForm = (<MediaForm defaultValue={mediaItem} options={options} />);
        const dialog = new ReactModal();
        
        await dialog.openFormDialogAsync(mediaForm, async (e) => {
            const json = (typeof options.getData === "function") ? options.getData() : null;
            
            if (!json) {
                if (typeof options?.setError === "function")
                    options.setError("Invalid Data");
                console.error("Invalid Data: ", json);
                
                return false;
            }
            
            if (mediaItem.isVideo) await MediaService.instance.saveVideoAsync(json, mediaItem.id);
            else await MediaService.instance.saveImageAsync(json);
            
            return true;
        }, options);
        
    };
    
    const onClickAsync = async (mediaItem, index) => {
        const rsp = (typeof onClick === "function") ? onClick(mediaItem, index) : null;
        const result = (typeof rsp?.then === "function") ? await rsp : rsp;
        if (result === false) return;
        
        console.log("Media Item Click: ", index);
        console.log(mediaItem);

        await showMediaEditorFormAsync(mediaItem);
    };

    useEffect(() => {
        let _ = getMediaItemsAsync();
    }, []);
    
    const uploadUrl = "/api/collection/" + entityType + "/media/" + entityId;
    const errorMessage = (typeof entityId !== "string" || entityId.length < 30 ? "Missing EntityId": "") + " : " +
        (entityType > 0 ? "" : "Missing EntityType: " + entityType);

    const body = errorMessage.length > 5 ? (<div className={"error"}>{errorMessage}</div>) : children;
    const elements = (<MediaGallery collectionId={entityId} entityType={entityType} className={options?.className} onClick={onClickAsync} media={viewState.mediaItems} options={options} />);
    
    return (<div className={"gallery-uploader " + (className || "")} id={elementId}>
        <MediaUploader id={"gallery-uploader-" + (id || "null")} onPaste={onPaste} onDrop={onDrop} uploadUrl={uploadUrl}>
            <div className={"media-gallery gallery-items"}>
                {elements}
            </div>
            
        </MediaUploader>
    </div>);
};

export default GalleryUploader;
