import React, {useEffect, useState} from 'react';
import DefReset from "../../models/DefReset";
import "./DefElements.css";

const DefButton = (props) => {
    const { children, style, onClick, options, id, className } = props;
    const [buttonState, setButtonState] = useState({ event: null, error: null });

    const handleError = (ex) => {
        const message = ex?.response?.data?.message || ex?.message || ex?.toString() || "Unknown Error";
        console.error(message);
        
        setButtonState({...buttonState, error: message })
    };
    
    const onButtonClick = async (e) => {
        if (typeof onClick !== "function") {
            console.error("No onClick function defined for DefButton");
            if (!!buttonState.event) {
                setButtonState({ ...buttonState, event: null});
            }
            
            return;
        }
        
        DefReset.stopEvent(e);
        const rsp = onClick(e);
        const result = typeof rsp?.then === "function" ? await rsp.catch((ex) => handleError(ex)) : rsp;
        
        console.log("Button Pressed: ", result);
        setButtonState({ ...buttonState, event: null});
    };

    useEffect(() => {
        if (!!buttonState?.event) {
            let _ = onButtonClick(buttonState.event);
        }
    }, [buttonState]);
    
    const isWorking = !!buttonState?.event;
    const cn = typeof className === "string" ? className : "";
    const elementId = typeof id === "string" ? id : "def-button-" + (Math.random() * 99999999).toString(16);
    const body = isWorking && !!options?.activeChildren ? options.activeChildren : children;
    
    return (<button disabled={isWorking} id={elementId} className={"def-button " + cn} onClick={(e) => setButtonState({...buttonState, event: e})} style={style || null}>
        <span className={"def-button-left-span"}><label></label></span>
        <span className={"def-button-body"}>{body || "Submit"}</span>
        <span className={"def-button-right-span"}><label></label></span>
    </button>);
};

export default DefButton;
