import React, {useEffect, useRef, useState} from 'react';
import HabitService from "../../components/habits/services/HabitService";
import AdminMasterScreen from "../AdminMasterScreen";
import {Link, useParams} from "react-router-dom";
import DefReset from "../../components/common/models/DefReset";
import DefButton from "../../components/common/ui/elements/DefButton";

const HabitDetailsScreen = (props) => {
    const {} = props;
    
    const buttonId = "save-button";
    const { habitId } = useParams();
    const [habit, setHabit] = useState(HabitService.instance.habitMap[habitId] || null);

    const nameRef = useRef();
    const descriptionRef = useRef();
    const longDescriptionRef = useRef();
    const actionDescriptionRef = useRef();
    const leaderboardTitleRef = useRef();
    const leaderboardDescriptionRef = useRef();
    
    const getHabitAsync = async (force = false) => {
        if (Array.isArray(habit) && habit.length > 0 && force !== true)
            return habit;

        const m = await HabitService.instance.getHabitAsync(habitId);

        if (Array.isArray(m)) {
            setHabit(m);
            return m;
        }

        return habit;
    };
    
    const createJson = () => {
        return {
            name: nameRef.current?.value || null,
            description: descriptionRef.current?.value || null,
            long_description: longDescriptionRef.current?.value || null,
            action_description: actionDescriptionRef.current?.value || null,
            leaderboard_title: leaderboardTitleRef.current?.value || null,
            leaderboard_description: leaderboardDescriptionRef.current?.value || null,
        };
    };
    
    const saveHabitAsync = async (e) => {
        DefReset.stopEvent(e); 
        
        const json = createJson();
        console.log(JSON.stringify(json, null, 4));
        await HabitService.instance.saveHabitAsync(json, habitId);
        const h = await HabitService.instance.getHabitAsync(habitId);
        
        if (!!h?.id) setHabit(h);
    };

    const onSession = (session) => {
        if (!!session?.id) {
            let _ = getHabitAsync();
        }
    }
    
    useEffect(() => {
        let _ = getHabitAsync();
    }, []);
    
    const jsonElement = !!habit?.id ? (<div className={"top64"}>
        <pre>{JSON.stringify(habit.json, null, 4)}</pre>
    </div>) : null;

    return (<AdminMasterScreen selection={"habits"} title={"Habits"}>
        <div className={"pad"}>
            <div className={"constrain"}>
                <h2>{habit?.name}</h2>
                <p className={"p"}>{habit?.longDescription}</p>
            </div>

            <div className={"form constrain"}>
                <div style={{marginTop: "32px"}}>
                    <h4>Basic Information</h4>
                    <p>Basic habit information displayed on habit-related screens</p>
                </div>
                
                <div className={"form-group"}>
                    <label>Name</label>
                    <input ref={nameRef} id={"habit-name"} defaultValue={habit?.name} />
                </div>

                <div className={"form-group"}>
                    <label>Short Description</label>
                    <input ref={descriptionRef} id={"habit-description"} defaultValue={habit?.description} />
                </div>

                <div className={"form-group"}>
                    <label>Long Description</label>
                    <textarea className={"small"} ref={longDescriptionRef} id={"habit-long-description"} defaultValue={habit?.longDescription}></textarea>
                </div>

                <div className={"form-group"}>
                    <label>Action Description</label>
                    <textarea className={"small"} ref={actionDescriptionRef} id={"habit-action-description"} defaultValue={habit?.actionDescription}></textarea>
                </div>

                <div style={{marginTop: "32px"}}>
                    <h4>Leaderboard Info</h4>
                    <p>When Leaderboards are displayed in reference to a Habit, this info blow will be shown</p>
                </div>
                
                <div className={"form-group"}>
                    <label>Leaderboard Title</label>
                    <input ref={leaderboardTitleRef} id={"habit-leaderboard-title"} defaultValue={habit?.leaderboardTitle} />
                </div>

                <div className={"form-group"}>
                    <label>Leaderboard Description</label>
                    <textarea className={"small"} ref={leaderboardDescriptionRef} id={"habit-action-description"} defaultValue={habit?.leaderboardDescription}></textarea>
                </div>
                
                <div className={"form-buttons"}>
                    <span></span>
                    <DefButton id={buttonId} onClick={saveHabitAsync}>Save Habit</DefButton>
                </div>

                {jsonElement}
            </div>
        </div>

    </AdminMasterScreen>);

};

export default HabitDetailsScreen;
