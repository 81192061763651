import React, {useEffect, useState} from 'react';
import AdminMasterScreen from "../AdminMasterScreen";
import UserService from "../../components/users/services/UserService";
import PersonMenu from "../../components/users/ui/UserSubMenu";
import Initials from "../../components/common/ui/Initials";
import MediaService from "../../components/media/services/MediaService";
import {useParams} from "react-router-dom";

const UserMasterScreen = (props) => {
    const { userId:initialUserId, options: initialOptions, user: initialUser, selection, children, onUser } = props;
    const { userId: queryUserId } = useParams();
    const userId = initialUser?.id || initialUserId || queryUserId;
    
    const [user, setUser] = useState(UserService.instance.userMap[userId] || null);
    const [options, setOptions] = useState(initialOptions || {
        reactClassName: "PersonMasterScreen"
    });

    const getUserAsync = async (force = false) => {
        if (force !== true && !!user?.id) {
            console.warn("Cashed User. Good.");
            return user;
        }
        
        const u = await UserService.instance.getUserAsync(userId);
        
        if (!!u?.id) {
            console.warn("User is Good.");
            setUser(u);
        }
        
        return u;
    };
    
    const setControls = () => {
        if (!!options && typeof options === "object") {
            if (typeof options.setUser !== "function")
                options.setUser = (u) => setUser(u);
        }
        
        return () => {
            //
        };
    };

    useEffect(() => {
        if (!!user?.id && typeof onUser === "function") {
            onUser(user);
        }

    }, [user]);
    
    useEffect(() => {
        const r = setControls();
        let _ = getUserAsync(true);
        
        return r;
    }, []);

    const userSubMenu = !!user?.id ? (<PersonMenu selection={selection} user={user} />) : null;

    return (<AdminMasterScreen options={options} onSession={getUserAsync} selection={"people"} title={"Users"}>
        <div className={"column-content person-details"}>
            <div className={"column"}>
                { userSubMenu }
            </div>
            <div className={"content"}>
                {children}
            </div>
        </div>
    </AdminMasterScreen>);

};

export default UserMasterScreen;
