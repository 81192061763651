import HttpService from "../../common/services/HttpService";
import SystemErrorModel from "../models/SystemErrorModel";

class ActivityService {
    static instance = new ActivityService();
    
    constructor() {
        //
    }
    
    async getSystemErrorsAsync(options = {startDate: null, endDate: null }) {
        const path = "/api/system-error?term=" + encodeURI(options?.term || "");
        const sd = options?.startDate || null;
        const ed = options?.endDate || null;
        
        return await HttpService.instance.getWithDateRangeAsync(path, sd, ed).then((response) => {
            return SystemErrorModel.fromJsonArray(response.data);
        });
    }
}

export default ActivityService;

