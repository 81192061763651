class FileUploadModel {
    static videoExtensions = [
        "mov",
        "mp4",
        "avi",
        "wmv",
        "flv",
        "mkv",
        "webm",
        "vob",
        "3gp",
        "3g2",
        "mpg",
        "mpeg",
        "m2v",
        "m4v",
    ];

    static imageExtensions = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
        "tiff",
        "tif",
        "svg",
        "pjpeg",
        "pjpg",
    ];

    constructor(formFile, title = "", onLoaded = null) {
        this.filePath = null;
        this.file = formFile || null;
        this.name = formFile?.name;
        this.contentType = formFile?.type || "file";
        this.title = typeof title === 'string' ? title : "";
        this.id = (Math.random() * 100000000000000000).toString() + "-" + (new Date()).getTime().toString();
        this.imageData = null;
        this.width = -1;
        this.height = -1;
        this.scaleX = 1.0;
        this.scaleY = 1.0;

        if (typeof title === 'function' && typeof onLoaded !== 'function')
            onLoaded = title;

        if (!!formFile) {
            let reader = new FileReader();
            const me = this;

            reader.onload = (ev) => {
                me.filePath = ev.target.result;
                if (typeof onLoaded === "function") {
                    const rsp = onLoaded(me);
                }
            };

            reader.onerror = (e) => {
                console.error(e);
                throw e;
            };

            reader.readAsDataURL(formFile);
        }
    }

    isImage() {
        return FileUploadModel.isImageFile(this);
    }

    isVideo() {
        return FileUploadModel.isVideoFile(this);
    }

    static async getDimensionsAsync(fileModel, force = false) {
        let w = fileModel.width || -1;
        let h = fileModel.height || -1;

        if (!fileModel.filePath) return { width: w, height: h };
        if (w > 0 && h > 0 && force !== true) return { width: w, height: h };

        return await new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = (e) => {
                fileModel.width = img.width;
                fileModel.height = img.height;
                resolve({ width: img.width, height: img.height });
            };

            img.onerror = (er) => {
                reject(er);
            };

            img.src = fileModel.filePath;
        });
    }

    static isJson(text) {
        try {
            JSON.parse(text);
            return true;
        } catch (e) {
            return false;
        }
    }

    static getFileNameExtension(fileModel) {
        if (typeof fileModel !== "string")
            fileModel = fileModel?.filePath || fileModel?.name;

        if (!fileModel || typeof fileModel !== "string")
            return "";

        const idx = fileModel.lastIndexOf(".");
        if (idx < 0) return "";

        return fileModel.substring(idx + 1).toLowerCase();
    }

    static isVideoFile(fileModel) {
        const extension = FileUploadModel.getFileNameExtension(fileModel);
        return FileUploadModel.videoExtensions.indexOf(extension) >= 0;
    };

    static isImageFile(fileModel) {
        const extension = FileUploadModel.getFileNameExtension(fileModel);
        return FileUploadModel.imageExtensions.indexOf(extension) >= 0;
    };

    static formatJson(text, fallback = null) {
        if (!FileUploadModel.isJson(text)) return (fallback || text);
        return JSON.stringify(JSON.parse(text), null, 2);
    }

    /**
     * Calculates the dimensions of a given width/height
     * and returns the resized dimensions based on the
     * LARGER of the width vs. height. (See calculateFitSize for the smaller of the two)
     * @param imageWidth {number} - Width of the original image. Must be greater than 0
     * @param imageHeight {number} - Height of the original image. Must be greater than 0
     * @param size {number} - The size to fit the image to
     * @returns {{imageWidth, width: number, imageHeight, height: number}} */
    static calculateCoverSize(imageWidth, imageHeight, size) {
        const result = FileUploadModel.createDefaultSizeResult(imageWidth, imageHeight, size);
        if (!!result.error) throw new Error(result.error);

        let height = size;
        let width = size;

        if (imageWidth > imageHeight) { // Landscape
            const scaleX = (imageWidth / imageHeight);
            width = Math.round(scaleX * size);
            result.scaleX = scaleX;
        } else {                    // Portrait
            const scaleY = (imageHeight / imageWidth);
            height = Math.round(scaleY * size);
            result.scaleY = scaleY;
        }

        result.width = width;
        result.height = height;
        result.square = {
            x: Math.round((width / 2.0) - (size / 2.0)),
            y: Math.round((height / 2.0) - (size / 2.0)),
            size: size
        };

        return result;
    }

    /**
     * Calculates the dimensions of a given width/height
     * and returns the resized dimensions based on the
     * SMALLER of the width vs. height. (See calculateFitSize for the smaller of the two)
     * @param imageWidth {number} - Width of the original image. Must be greater than 0
     * @param imageHeight {number} - Height of the original image. Must be greater than 0
     * @param size {number} - The size to fit the image to
     * @returns {{imageWidth, width: number, imageHeight, height: number}} */
    static calculateFitSize(imageWidth, imageHeight, size) {
        const result = FileUploadModel.createDefaultSizeResult(imageWidth, imageHeight, size);
        if (!!result.error) throw new Error(result.error);

        let scaleX = 0;
        let scaleY = 0;

        let height = size;
        let width = size;

        if (imageWidth > imageHeight) {
            scaleY = (imageHeight / imageWidth);
            height = Math.round(scaleY * size);
        } else {
            scaleX = (imageWidth / imageHeight);
            width = Math.round(scaleX * size);
        }

        result.width = width;
        result.height = height;
        result.square = {
            x: Math.round((width / 2.0) - (size / 2.0)),
            y: Math.round((height / 2.0) - (size / 2.0)),
            size: size
        };

        return result;
    }

    static createDefaultSizeResult(imageWidth, imageHeight, size) {
        const result = {
            scaleX: 1.0,
            scaleY: 1.0,
            width: imageWidth,
            height: imageHeight,
            imageWidth: imageWidth,
            imageHeight: imageHeight,
        };

        if (typeof imageWidth !== "number" || typeof imageHeight !== "number")
            result.error = "ImageWidth: " + imageWidth + ", ImageHeight: " + imageHeight + " had an issue.";
        else if (!imageWidth || !imageHeight || imageWidth < 0 || imageHeight < 0)
            result.error = "ImageWidth: " + imageWidth + ", ImageHeight: " + imageHeight + " had an issue.";

        return result;
    }

    static async resizeAsync(fileModel, size, options) {
        if (typeof options === "boolean") options = { includeImageData: options };
        else if (typeof options === "string") options = { fit: options };
        else if (typeof options === "number") options = { maxSize: options };
        else if (!options) options = {};

        if (options.fit !== "fit") options.fit = "cover";

        const includeImageData = options?.includeImageData === true;

        // calculate ratio
        let width = null;
        let height = null;
        let scaleX = 1.0;
        let scaleY = 1.0;

        // Setup which values will be given, vs. values that will be calculated
        if (typeof size === "object") {
            if (size.width >= 0) width = size.width;
            if (size.height >= 0) height = size.height;

            if (width > 0 && height > 0) {
                if (width > height) scaleY = (height / width);
                else scaleX = (width / height);
            } else if (width > 0) {
                height = 0;
            } else if (height > 0) {
                width = 0;
            }

            size = null;

        } else if (typeof size === "number") {
            width = size;
            height = size;
        } else throw new Error("Invalid size: " + size + ". Must be a nunber or object value with width and/or height properties.");

        if (!width) width = size;
        if (!height) height = size;

        if (fileModel.width <= 0 || fileModel.height <= 0) {
            console.log("Getting fileModel size...");
            await new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = (e) => {
                    fileModel.width = img.width;
                    fileModel.height = img.height;
                    console.log(" > Got it: " + fileModel.width + " x " + fileModel.height + "");
                    resolve(fileModel);
                };

                img.onerror = (er) => {
                    reject(er);
                };

                img.src = fileModel.filePath;
            });
        }

        if (fileModel.width !== fileModel.height) {
            // Calculate the size from the values we setup earlier
            if (typeof size === "number") {
                const rsp = FileUploadModel.calculateCoverSize(fileModel.width, fileModel.height, size);
                width = rsp.width;
                height = rsp.height;

                scaleX = rsp.scaleX;
                scaleY = rsp.scaleY;

                // if (fileModel.width > fileModel.height) {
                //     scaleY = (fileModel.height / fileModel.width);
                //     height = Math.round(scaleY * size);
                // } else {
                //     scaleX = (fileModel.width / fileModel.height);
                //     width = Math.round(scaleX * size);
                // }
            } else if (!height) {
                height = Math.round(width *  (fileModel.height / fileModel.width));
            } else if (!width) {
                width = Math.round(height * (fileModel.height / fileModel.width));
            }
        }

        if (!height) height = width;

        const id = (Math.random() * 100000000000000000).toString() + "-" + (new Date()).getTime().toString();
        const imageFile = fileModel.file;
        const canvas = document.createElement("canvas");
        const canvasWidth = document.createAttribute("width");
        const canvasHeight = document.createAttribute("height");

        canvasWidth.value = width;
        canvasHeight.value = height;

        canvas.attributes.setNamedItem(canvasWidth);
        canvas.attributes.setNamedItem(canvasHeight);
        canvas.id = "preview-resize-canvas-" + id;

        const ctx = canvas.getContext("2d");

        // Actual resizing
        const croppedId = "resized-image-" + id;
        const img = document.getElementById(croppedId) ?? document.createElement("img");

        console.log("Resize: " + width + " x " + height + " from " + fileModel.width + " x " + fileModel.height);
        console.log(" > Scale: " + scaleX + " x " + scaleY);

        const x = 0;
        const y = 0;

        return await new Promise((resolve, reject) => {
            img.onerror = (er) => {
                console.error(er);
                reject(er);
            };

            img.onload = (e) => {
                try {
                    ctx.drawImage(img, x, y, width, height);
                    fileModel.filePath = canvas.toDataURL(imageFile.type);

                    if (includeImageData === true)
                        fileModel.imageData = ctx.getImageData(x, y, width, height);

                    fileModel.width = width;
                    fileModel.height = height;
                    fileModel.scaleX = scaleX;
                    fileModel.scaleY = scaleY;

                    fileModel.file = FileUploadModel.createFileFromFilePath(fileModel.filePath, fileModel.name);

                    console.log("File Type: " + fileModel.file?.type + "");

                    img.parentNode.removeChild(img);

                    resolve(fileModel);
                } catch (e) {
                    e.id = id;
                    reject(e);
                }
            };

            img.className = "none";
            img.id = croppedId;

            if (!img.parentNode)
                document.body.appendChild(img);

            img.src = fileModel.filePath;

            return img;
        });
    }

    static async cropSmartAsync(fileModel, size, options) {
        const dims = await FileUploadModel.getDimensionsAsync(fileModel);

        fileModel = await this.resizeAsync(fileModel, Math.max(dims.width, dims.height), options);

        return await this.cropAsync(fileModel, 0, 0, size, size, true);
    }

    static async cropAsync(fileModel, x, y, width, height, includeImageData = false) {
        // Dynamically create a canvas element
        const id = (Math.random() * 100000000000000000).toString() + "-" + (new Date()).getTime().toString();
        const imageFile = fileModel.file;
        const canvas = document.createElement("canvas");
        const canvasWidth = document.createAttribute("width");
        const canvasHeight = document.createAttribute("height");

        canvasWidth.value = width;
        canvasHeight.value = height;

        canvas.attributes.setNamedItem(canvasWidth);
        canvas.attributes.setNamedItem(canvasHeight);
        canvas.id = "preview-canvas-" + id;

        const ctx = canvas.getContext("2d");

        // Actual resizing
        const croppedId = "cropped-image-" + id;
        fileModel = await this.resizeAsync(fileModel, Math.max(width, height), {}, true); //

        const img = new Image(); //document.getElementById(croppedId) ?? document.createElement("img");

        return await new Promise((resolve, reject) => {
            img.onerror = (er) => {
                console.log(fileModel.filePath);
                console.error(er);
                reject(er);
            };

            img.onload = (e) => {
                try {
                    const widthScale = width / img.width;
                    const heightScale = height / img.height;

                    let scaledWidth = width;
                    let scaledHeight = height;

                    if (img.height > img.width) {
                        height = img.height * widthScale;
                        scaledWidth = width;
                    } else {
                        width = img.width * heightScale;
                        scaledHeight = height;
                    }

                    //FileUploadModel.createSquarePath(ctx, x, y, scaledWidth, scaledHeight);
                    if (x < 0) x = Math.round((scaledWidth / 2.0) - (width / 2));
                    if (y < 0) y = Math.round((scaledHeight / 2.0) - (height / 2));

                    ctx.drawImage(img, x, y, width, height);
                    fileModel.filePath = canvas.toDataURL(imageFile.type);

                    if (includeImageData === true)
                        fileModel.imageData = ctx.getImageData(x, y, width, height);

                    fileModel.width = canvasWidth.value;
                    fileModel.height = canvasHeight.value;
                    fileModel.file = FileUploadModel.createFileFromFilePath(fileModel.filePath, fileModel.name);

                    img.parentNode.removeChild(img);

                    resolve(fileModel);
                } catch (e) {
                    e.id = id;
                    reject(e);
                }
            };

            img.className = "none";
            img.id = croppedId;

            if (!img.parentNode) document.body.appendChild(img);
            if (!!img.src) {
                resolve(img);
                return img;
            }

            img.src = fileModel.filePath;

            return img;
        });
    }

    static createFileFromFilePath(dataUrl, fileName) {
        if (!dataUrl) throw new Error("No dataUrl provided to create a file from.");

        let arr = dataUrl.split(',');
        const matchData = arr[0].match(/:(.*?);/);

        if (!matchData || matchData.length < 2) {
            console.error("Failed to parse FormFile DataUrl: " + matchData?.length ?? "<null>");
            if (!dataUrl) dataUrl = "<EMPTY>";
            else if (dataUrl.length > 64) dataUrl = dataUrl.substring(0, 64) + "...";

            console.log(" > DataUrl is Empty: " + dataUrl);

            return null;
        }

        const mime = matchData[1];

        let bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], fileName, {type:mime});
    }

    static createSquarePath(context, x, y, width, height) {
        context.moveTo(x, y);
        context.rect(x, y, width, height);
    }

    static async fromTextItemsAsync(textItems) {
        let newFiles = [];
        let countDown = textItems?.length || 0;
        const map = {};

        return new Promise((resolve, reject) => {
            if (countDown <= 0) {
                resolve(newFiles);
                return;
            }

            for(let i = 0; i < textItems.length; i++) {
                const textItem = textItems[i];
                const kind = textItem.kind;
                const contentType = textItem.type;

                if (kind === "file") {
                    countDown--;

                    if (countDown <= 0) {
                        resolve(newFiles);
                        return;
                    }

                    continue;
                }

                textItem.getAsString((text) => {
                    countDown--;

                    const isUrl = contentType === "text/uri-list";
                    const name = isUrl ?
                        "URL: " + (text.length > 64 ? text.substring(0, 64) + "..." : text) :
                        contentType + ": " + (text.length > 64 ? text.substring(0, 64) + "..." : text);

                    const newId = (Math.random() * 100000000000000000).toString() + "-" + (new Date()).getTime().toString();
                    const item = {
                        name: name,
                        text: FileUploadModel.formatJson(text),
                        contentType: contentType || kind,
                        id: newId,
                        uri: isUrl ? text : null
                    };

                    newFiles.push(item);

                    if (countDown <= 0) {
                        resolve(newFiles);
                    }
                });
            }
        });
    }

    static async fromFilesAsync(formFiles) {
        let newFiles = [];
        let countDown = formFiles?.length || 0;
        console.warn("fromFilesAsync: " + countDown);

        return new Promise((resolve, reject) => {
            //if (!Array.isArray(formFiles)) reject("Invalid files array. Found type of: " + (typeof formFiles));
            if (countDown <= 0) {
                console.log("No files to load. Resolving");
                resolve(newFiles);
                return;
            }

            for(let i = 0; i < formFiles.length; i++) {
                const fm = new FileUploadModel(formFiles[i], "Item " + i.toString(), () => {
                    countDown--;
                    console.log("File[" + i.toString() + " of " + formFiles.length + "] Countdown = " + countDown);

                    if (countDown <= 0) {
                        // When all files are read/loaded asynchronously, set the file list state view
                        console.log("Files resolved");
                        resolve(newFiles);
                    }
                });

                newFiles.push(fm);
            }
        });

    };
}

export default FileUploadModel;